// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const ICONS = [
  {
    "size": "12px",
    "icons": [
      {
        "path": "assets/icons/12px/chevron-down-12px.svg",
        "name": "chevron-down-12px",
        "dimensions": {
          "width": 12,
          "height": 12
        }
      },
      {
        "path": "assets/icons/12px/chevron-up-12px.svg",
        "name": "chevron-up-12px",
        "dimensions": {
          "width": 12,
          "height": 12
        }
      }
    ]
  },
  {
    "size": "16px",
    "icons": [
      {
        "path": "assets/icons/16px/alarm-circle.svg",
        "name": "alarm-circle",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/arrow-down-small.svg",
        "name": "arrow-down-small",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/arrow-up-small.svg",
        "name": "arrow-up-small",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/arrow.svg",
        "name": "arrow",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/checked-disabled.svg",
        "name": "checked-disabled",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/checked.svg",
        "name": "checked",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/close-16.svg",
        "name": "close-16",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/completed.svg",
        "name": "completed",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/critical-circle.svg",
        "name": "critical-circle",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/down.svg",
        "name": "down",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/drag-handle.svg",
        "name": "drag-handle",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/erase.svg",
        "name": "erase",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/file-document.svg",
        "name": "file-document",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      },
      {
        "path": "assets/icons/16px/file-video.svg",
        "name": "file-video",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/filter.svg",
        "name": "filter",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/forward-media.svg",
        "name": "forward-media",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/indeterminate.svg",
        "name": "indeterminate",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/information-circle.svg",
        "name": "information-circle",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/more-vertical.svg",
        "name": "more-vertical",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/sorting.svg",
        "name": "sorting",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/trash-small.svg",
        "name": "trash-small",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/unchecked-disabled.svg",
        "name": "unchecked-disabled",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/unchecked.svg",
        "name": "unchecked",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/up.svg",
        "name": "up",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/warning-circle.svg",
        "name": "warning-circle",
        "dimensions": {
          "width": 16,
          "height": 16
        }
      },
      {
        "path": "assets/icons/16px/warning-small.svg",
        "name": "warning-small",
        "dimensions": {
          "width": 16,
          "height": 17
        }
      }
    ]
  },
  {
    "size": "24px",
    "icons": [
      {
        "path": "assets/icons/24px/add-prod-order.svg",
        "name": "add-prod-order",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/add-small.svg",
        "name": "add-small",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/add.svg",
        "name": "add",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/admin.svg",
        "name": "admin",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/arrived.svg",
        "name": "arrived",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/back.svg",
        "name": "back",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/builder.svg",
        "name": "builder",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/calendar.svg",
        "name": "calendar",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/cancelled.svg",
        "name": "cancelled",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/checkmark.svg",
        "name": "checkmark",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-down-disabled.svg",
        "name": "chevron-down-disabled",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-down-error.svg",
        "name": "chevron-down-error",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-down-warning.svg",
        "name": "chevron-down-warning",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-down.svg",
        "name": "chevron-down",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-left.svg",
        "name": "chevron-left",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-right.svg",
        "name": "chevron-right",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-up-error.svg",
        "name": "chevron-up-error",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-up-warning.svg",
        "name": "chevron-up-warning",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/chevron-up.svg",
        "name": "chevron-up",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/clear-files.svg",
        "name": "clear-files",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/clock.svg",
        "name": "clock",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/close-disabled.svg",
        "name": "close-disabled",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/close-menu.svg",
        "name": "close-menu",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/close-small.svg",
        "name": "close-small",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/close.svg",
        "name": "close",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/complete.svg",
        "name": "complete",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/configure.svg",
        "name": "configure",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/confirm.svg",
        "name": "confirm",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/copy.svg",
        "name": "copy",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/create.svg",
        "name": "create",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/creel-change.svg",
        "name": "creel-change",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/currency.svg",
        "name": "currency",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/design-library.svg",
        "name": "design-library",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/deviating.svg",
        "name": "deviating",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/disconnected.svg",
        "name": "disconnected",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/download-24px.svg",
        "name": "download-24px",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/draft.svg",
        "name": "draft",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/drag.svg",
        "name": "drag",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/edit.svg",
        "name": "edit",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/error-number.svg",
        "name": "error-number",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/error.svg",
        "name": "error",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/execute.svg",
        "name": "execute",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/executed-complete.svg",
        "name": "executed-complete",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/executed-partial.svg",
        "name": "executed-partial",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/expandhorizontally.svg",
        "name": "expandhorizontally",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/export.svg",
        "name": "export",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/eye.svg",
        "name": "eye",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/failed.svg",
        "name": "failed",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/filter.svg",
        "name": "filter",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/free-zone-modified-with-errors.svg",
        "name": "free-zone-modified-with-errors",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/free-zone-modified.svg",
        "name": "free-zone-modified",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/free-zone-selected.svg",
        "name": "free-zone-selected",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/free-zone.svg",
        "name": "free-zone",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/groups.svg",
        "name": "groups",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/hourglass-green.svg",
        "name": "hourglass-green",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/hourglass.svg",
        "name": "hourglass",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/import-or-load.svg",
        "name": "import-or-load",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/info-24px.svg",
        "name": "info-24px",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/inline.svg",
        "name": "inline",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/list.svg",
        "name": "list",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/machine-unavailable.svg",
        "name": "machine-unavailable",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/maintenance.svg",
        "name": "maintenance",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/menu.svg",
        "name": "menu",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/mirror.svg",
        "name": "mirror",
        "dimensions": {
          "width": 25,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/more-vertical.svg",
        "name": "more-vertical",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/more.svg",
        "name": "more",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/next.svg",
        "name": "next",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/no-connection.svg",
        "name": "no-connection",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/open-external.svg",
        "name": "open-external",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/pdf-file.svg",
        "name": "pdf-file",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/priority.svg",
        "name": "priority",
        "dimensions": {
          "width": 16,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/processing-green.svg",
        "name": "processing-green",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/processing.svg",
        "name": "processing",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/prod-cancelled.svg",
        "name": "prod-cancelled",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/reconfirm.svg",
        "name": "reconfirm",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/remote-connection.svg",
        "name": "remote-connection",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/repeat.svg",
        "name": "repeat",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/report-items.svg",
        "name": "report-items",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/reset.svg",
        "name": "reset",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/rest-zone-modified-with-errors.svg",
        "name": "rest-zone-modified-with-errors",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/rest-zone-modified.svg",
        "name": "rest-zone-modified",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/rest-zone-selected.svg",
        "name": "rest-zone-selected",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/rest-zone.svg",
        "name": "rest-zone",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/rotate-left.svg",
        "name": "rotate-left",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/rotate-right.svg",
        "name": "rotate-right",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/schedule-24px.svg",
        "name": "schedule-24px",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/search.svg",
        "name": "search",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/select.svg",
        "name": "select",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/send.svg",
        "name": "send",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/settings.svg",
        "name": "settings",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/stats.svg",
        "name": "stats",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/subtract-small.svg",
        "name": "subtract-small",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/to-bottom.svg",
        "name": "to-bottom",
        "dimensions": {
          "width": 32,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/to-top.svg",
        "name": "to-top",
        "dimensions": {
          "width": 32,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/trash.svg",
        "name": "trash",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/upload.svg",
        "name": "upload",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/verified-error.svg",
        "name": "verified-error",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/verified-with-warnings.svg",
        "name": "verified-with-warnings",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/verified.svg",
        "name": "verified",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/verify.svg",
        "name": "verify",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/warning.svg",
        "name": "warning",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/warp-change.svg",
        "name": "warp-change",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/zoom-in.svg",
        "name": "zoom-in",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/24px/zoom-out.svg",
        "name": "zoom-out",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      }
    ]
  },
  {
    "size": "30px",
    "icons": [
      {
        "path": "assets/icons/30px/carpet-loom-machine.svg",
        "name": "carpet-loom-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/carpet-loom-no-jq-machine.svg",
        "name": "carpet-loom-no-jq-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/carpet-quality.svg",
        "name": "carpet-quality",
        "dimensions": {
          "width": 18,
          "height": 22
        }
      },
      {
        "path": "assets/icons/30px/colortec-machine.svg",
        "name": "colortec-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/colorteclplus-machine.svg",
        "name": "colorteclplus-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/dyeing-machine.svg",
        "name": "dyeing-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/extrusion-machine.svg",
        "name": "extrusion-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/finishing-machine.svg",
        "name": "finishing-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/folder.svg",
        "name": "folder",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/jacquard-machine.svg",
        "name": "jacquard-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/jaquard-focus.svg",
        "name": "jaquard-focus",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/myriad-machine.svg",
        "name": "myriad-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/new-machine.svg",
        "name": "new-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/plastic-machine.svg",
        "name": "plastic-machine",
        "dimensions": {
          "width": 64,
          "height": 64
        }
      },
      {
        "path": "assets/icons/30px/premium.svg",
        "name": "premium",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/smartcreel.svg",
        "name": "smartcreel",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/superbadye-machine.svg",
        "name": "superbadye-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/superbaheat-machine.svg",
        "name": "superbaheat-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/superbawinder-machine.svg",
        "name": "superbawinder-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/tuft-machine.svg",
        "name": "tuft-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/users.svg",
        "name": "users",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/velvet-machine.svg",
        "name": "velvet-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/velvet-no-jq-machine.svg",
        "name": "velvet-no-jq-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/velvet-quality.svg",
        "name": "velvet-quality",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/weaving-machine.svg",
        "name": "weaving-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      },
      {
        "path": "assets/icons/30px/winder-machine.svg",
        "name": "winder-machine",
        "dimensions": {
          "width": 30,
          "height": 30
        }
      }
    ]
  },
  {
    "size": "36px",
    "icons": [
      {
        "path": "assets/icons/36px/add-blue.svg",
        "name": "add-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/arrange.svg",
        "name": "arrange",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/articles.svg",
        "name": "articles",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/automatic-stop-groups.svg",
        "name": "automatic-stop-groups",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/bms-config-blue.svg",
        "name": "bms-config-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/bot-blue.svg",
        "name": "bot-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/builder-blue.svg",
        "name": "builder-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/builder-summary-blue.svg",
        "name": "builder-summary-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/clients.svg",
        "name": "clients",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/color-blue.svg",
        "name": "color-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/color-sets-blue.svg",
        "name": "color-sets-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/colored-yarn-sets-blue.svg",
        "name": "colored-yarn-sets-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/company-blue.svg",
        "name": "company-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/connectivity-blue.svg",
        "name": "connectivity-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/credits-blue.svg",
        "name": "credits-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/creel-change-blue.svg",
        "name": "creel-change-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/creel-position.svg",
        "name": "creel-position",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/creels-blue.svg",
        "name": "creels-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/data-unit-setup.svg",
        "name": "data-unit-setup",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/datasheets-blue.svg",
        "name": "datasheets-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/details-blue.svg",
        "name": "details-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/device-templates.svg",
        "name": "device-templates",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/documents-blue.svg",
        "name": "documents-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/download-36px.svg",
        "name": "download-36px",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/drawings-blue.svg",
        "name": "drawings-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/educate-manage.svg",
        "name": "educate-manage",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/educate.svg",
        "name": "educate",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/error-full-36px.svg",
        "name": "error-full-36px",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/events.svg",
        "name": "events",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/finishing-templates-blue.svg",
        "name": "finishing-templates-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/help-blue.svg",
        "name": "help-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/image-label-blue.svg",
        "name": "image-label-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/info-blue.svg",
        "name": "info-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/info-full-blue.svg",
        "name": "info-full-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/info.svg",
        "name": "info",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/label-blue.svg",
        "name": "label-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/language-settings-blue.svg",
        "name": "language-settings-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/library-blue.svg",
        "name": "library-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/machine-data-sources.svg",
        "name": "machine-data-sources",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/machine-overview-blue.svg",
        "name": "machine-overview-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/machine-quality-blue.svg",
        "name": "machine-quality-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/maintenance-blue.svg",
        "name": "maintenance-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/news-feed-blue.svg",
        "name": "news-feed-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/news-item-blue.svg",
        "name": "news-item-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/operators.svg",
        "name": "operators",
        "dimensions": {
          "width": 37,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/order-items-blue.svg",
        "name": "order-items-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/orderbook.svg",
        "name": "orderbook",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/orderlines.svg",
        "name": "orderlines",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/physical-quantities-blue.svg",
        "name": "physical-quantities-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/planning-blue.svg",
        "name": "planning-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/preview.svg",
        "name": "preview",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/process-blue.svg",
        "name": "process-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/product-configuration.svg",
        "name": "product-configuration",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/production-order-blue.svg",
        "name": "production-order-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/profile-blue.svg",
        "name": "profile-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/reject-reasons.svg",
        "name": "reject-reasons",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/report-items-blue.svg",
        "name": "report-items-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/reports-blue.svg",
        "name": "reports-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/resource-types-blue.svg",
        "name": "resource-types-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/sales-order.svg",
        "name": "sales-order",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/select-blue.svg",
        "name": "select-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/settings-blue.svg",
        "name": "settings-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/stop-reason-texts.svg",
        "name": "stop-reason-texts",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/terms.svg",
        "name": "terms",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/texfab-blue.svg",
        "name": "texfab-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/text-label-blue.svg",
        "name": "text-label-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/textile-blue.svg",
        "name": "textile-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/tuft-blue.svg",
        "name": "tuft-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/unavailable-blue.svg",
        "name": "unavailable-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/update-blue.svg",
        "name": "update-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/verify-blue.svg",
        "name": "verify-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/vibration.svg",
        "name": "vibration",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/video.svg",
        "name": "video",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/warning-blue.svg",
        "name": "warning-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/warning-error.svg",
        "name": "warning-error",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/warning-full-36px.svg",
        "name": "warning-full-36px",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/warning-red-36px.svg",
        "name": "warning-red-36px",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/weave-patterns-blue.svg",
        "name": "weave-patterns-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/wifi-1-bar.svg",
        "name": "wifi-1-bar",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/wifi-2-bars.svg",
        "name": "wifi-2-bars",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/wifi-3-bars.svg",
        "name": "wifi-3-bars",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/wifi-4-bars.svg",
        "name": "wifi-4-bars",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/wifi-no-signal.svg",
        "name": "wifi-no-signal",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/yarn-sets-blue.svg",
        "name": "yarn-sets-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      },
      {
        "path": "assets/icons/36px/yarn-types-blue.svg",
        "name": "yarn-types-blue",
        "dimensions": {
          "width": 36,
          "height": 36
        }
      }
    ]
  },
  {
    "size": "38px",
    "icons": [
      {
        "path": "assets/icons/38px/bms-config.svg",
        "name": "bms-config",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/38px/bot.svg",
        "name": "bot",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/collapse.svg",
        "name": "collapse",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/credits.svg",
        "name": "credits",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/dashboard.svg",
        "name": "dashboard",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/expand.svg",
        "name": "expand",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/help.svg",
        "name": "help",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/label.svg",
        "name": "label",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/38px/library.svg",
        "name": "library",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/logout.svg",
        "name": "logout",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/38px/machine-overview.svg",
        "name": "machine-overview",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/notifications.svg",
        "name": "notifications",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/process-done.svg",
        "name": "process-done",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/38px/process-error.svg",
        "name": "process-error",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/38px/process-working.svg",
        "name": "process-working",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/38px/process.svg",
        "name": "process",
        "dimensions": {
          "width": 38,
          "height": 38
        }
      },
      {
        "path": "assets/icons/38px/production-order-2.svg",
        "name": "production-order-2",
        "dimensions": {
          "width": 38,
          "height": 40
        }
      },
      {
        "path": "assets/icons/38px/profile.svg",
        "name": "profile",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/reports.svg",
        "name": "reports",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/schedule-38px.svg",
        "name": "schedule-38px",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/settings-38px.svg",
        "name": "settings-38px",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/texfab.svg",
        "name": "texfab",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/textile.svg",
        "name": "textile",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      },
      {
        "path": "assets/icons/38px/update.svg",
        "name": "update",
        "dimensions": {
          "width": 38,
          "height": 39
        }
      }
    ]
  },
  {
    "size": "52px",
    "icons": [
      {
        "path": "assets/icons/52px/error-full-52px.svg",
        "name": "error-full-52px",
        "dimensions": {
          "width": 52,
          "height": 52
        }
      },
      {
        "path": "assets/icons/52px/info-52px.svg",
        "name": "info-52px",
        "dimensions": {
          "width": 52,
          "height": 52
        }
      },
      {
        "path": "assets/icons/52px/manual-stop.svg",
        "name": "manual-stop",
        "dimensions": {
          "width": 52,
          "height": 52
        }
      },
      {
        "path": "assets/icons/52px/warning-full-52px.svg",
        "name": "warning-full-52px",
        "dimensions": {
          "width": 52,
          "height": 52
        }
      }
    ]
  },
  {
    "size": "illustrations",
    "icons": [
      {
        "path": "assets/icons/illustrations/atoms-arrow.svg",
        "name": "atoms-arrow",
        "dimensions": {
          "width": 24,
          "height": 24
        }
      },
      {
        "path": "assets/icons/illustrations/edit-with-container.svg",
        "name": "edit-with-container",
        "dimensions": {
          "width": 46,
          "height": 46
        }
      },
      {
        "path": "assets/icons/illustrations/empty-2.svg",
        "name": "empty-2",
        "dimensions": {
          "width": 100,
          "height": 72
        }
      },
      {
        "path": "assets/icons/illustrations/empty.svg",
        "name": "empty",
        "dimensions": {
          "width": 269,
          "height": 213
        }
      },
      {
        "path": "assets/icons/illustrations/error-with-container.svg",
        "name": "error-with-container",
        "dimensions": {
          "width": 32,
          "height": 32
        }
      },
      {
        "path": "assets/icons/illustrations/fetching.svg",
        "name": "fetching",
        "dimensions": {
          "width": 160,
          "height": 112
        }
      },
      {
        "path": "assets/icons/illustrations/img-404.svg",
        "name": "img-404",
        "dimensions": {
          "width": 272,
          "height": 188
        }
      },
      {
        "path": "assets/icons/illustrations/img-loading.svg",
        "name": "img-loading",
        "dimensions": {
          "width": 268,
          "height": 214
        }
      },
      {
        "path": "assets/icons/illustrations/not-found.svg",
        "name": "not-found",
        "dimensions": {
          "width": 269,
          "height": 192
        }
      },
      {
        "path": "assets/icons/illustrations/report-warning.svg",
        "name": "report-warning",
        "dimensions": {
          "width": 180,
          "height": 125
        }
      },
      {
        "path": "assets/icons/illustrations/rotate-device.svg",
        "name": "rotate-device",
        "dimensions": {
          "width": 268,
          "height": 214
        }
      },
      {
        "path": "assets/icons/illustrations/unauthorized.svg",
        "name": "unauthorized",
        "dimensions": {
          "width": 272,
          "height": 188
        }
      },
      {
        "path": "assets/icons/illustrations/update-available-center-image.svg",
        "name": "update-available-center-image",
        "dimensions": {
          "width": 264,
          "height": 170
        }
      },
      {
        "path": "assets/icons/illustrations/uploading.svg",
        "name": "uploading",
        "dimensions": {
          "width": 277,
          "height": 214
        }
      },
      {
        "path": "assets/icons/illustrations/version-info.svg",
        "name": "version-info",
        "dimensions": {
          "width": 264,
          "height": 170
        }
      }
    ]
  },
  {
    "size": "patterns",
    "icons": [
      {
        "path": "assets/icons/patterns/diagonal-pattern.svg",
        "name": "diagonal-pattern",
        "dimensions": {
          "width": 72,
          "height": 12
        }
      },
      {
        "path": "assets/icons/patterns/dotted-pattern.svg",
        "name": "dotted-pattern",
        "dimensions": {
          "width": 72,
          "height": 8
        }
      },
      {
        "path": "assets/icons/patterns/line-pattern.svg",
        "name": "line-pattern",
        "dimensions": {
          "width": 72,
          "height": 4
        }
      },
      {
        "path": "assets/icons/patterns/striped-pattern.svg",
        "name": "striped-pattern",
        "dimensions": {
          "width": 72,
          "height": 4
        }
      }
    ]
  }
];
