import {Component} from '@angular/core';
import {FinishingTemplate} from '@domain/textile-data/finishing-and-finishing-template/finishing-template/finishing-template';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './finishing-type.component.html'
})
export class FinishingTypeComponent implements ICellRendererAngularComp {
  public finishingTemplate: FinishingTemplate;

  public agInit(params: ICellRendererParams): void {
    this.finishingTemplate = params.data;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
