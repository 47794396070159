import {animate, style, transition, trigger} from '@angular/animations';
import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-thumbnail-selected-indicator',
  templateUrl: './thumbnail-selected-indicator.component.html',
  styleUrls: ['./thumbnail-selected-indicator.component.scss'],
  animations: [trigger('fadeInOut', [transition(':enter', [style({opacity: 0}), animate(250, style({opacity: 1}))]), transition(':leave', [animate(250, style({opacity: 0}))])])]
})
export class ThumbnailSelectedIndicatorComponent {
  @HostBinding('@fadeInOut') public fadeInOut = true;
}
