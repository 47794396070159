import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DndDraggable, DndListSettings} from '@ryware/ngx-drag-and-drop-lists';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../../../base-component';
import {AssertionUtils} from '../../../../common/utils/assertion-utils';
import {DndDragDropHelperService} from '../dnd-drag-drop-helper-service/dnd-drag-drop-helper.service';
import {DndDragStartedData} from '../dnd-drop-list-interfaces/dnd-drag-started-data';
import {DndDraggableDropListEntry} from '../dnd-drop-list-interfaces/dnd-draggable-drop-list-entry';

@Component({
  selector: 'vdw-dnd-draggable-drop-list',
  templateUrl: './dnd-draggable-drop-list.component.html',
  styleUrls: ['./dnd-draggable-drop-list.component.scss']
})
export class DndDraggableDropListComponent extends BaseComponent implements OnInit {
  @Input() public dropListContent: TemplateRef<any>;
  @Input() public dragPreviewContent: TemplateRef<any>;
  @Input() public parentEntry: DndDraggableDropListEntry;
  @Input() public dropListEntry: DndDraggableDropListEntry;
  @Input() public parentOnlyClass: string = '';
  @Input() public childOnlyClass: string = '';

  @ViewChild('dragPreview') public dragPreview: ElementRef<HTMLElement>;
  @ViewChild(DndDraggable, {static: true}) public dndDraggable: DndDraggable;

  public dndListSettings: DndListSettings = {
    disabled: false,
    allowedTypes: [],
    effectAllowed: 'move'
  };

  public get dragActive(): boolean {
    return this.dragDropHelperService.dragActive;
  }

  public set dragActive(value: boolean) {
    this.dragDropHelperService.dragActive = value;
  }

  private nestedDepth: number;

  public get isEmpty(): boolean {
    return AssertionUtils.isEmpty(this.dropListEntry?.childEntries);
  }

  public constructor(protected readonly dragDropHelperService: DndDragDropHelperService) {
    super();
  }

  @Input()
  public isDropAllowed: (parent: DndDraggableDropListEntry, child: DndDraggableDropListEntry) => boolean = () => true;

  public ngOnInit(): void {
    this.dndLibraryFixes();
    this.updateAllowedTypesOnDragStart();

    this.nestedDepth = this.dragDropHelperService.getNestedDepth(this.dropListEntry);
  }

  public removeGroup(): void {
    this.parentEntry.childEntries.splice(this.parentEntry.childEntries.indexOf(this.dropListEntry), 1);

    this.dragDropHelperService.entryRemoved.emit({parentEntry: this.parentEntry, removedEntry: this.dropListEntry});
  }

  public dragDrop(entry: DndDraggableDropListEntry): void {
    this.dragDropHelperService.dragDrop.emit(entry);
  }

  private dndLibraryFixes(): void {
    const originalHandleDragStart = this.dndDraggable.handleDragStart;
    this.dndDraggable.handleDragStart = (event: DragEvent): void => {
      originalHandleDragStart.call(this.dndDraggable, event);
      const element = this.dragPreview.nativeElement.nextSibling as HTMLElement;
      event.dataTransfer.setDragImage(element, 28, 28);

      this.dragDropHelperService.dragStarted.emit({sourceParent: this.parentEntry, draggedEntry: this.dropListEntry});
    };

    const originalDragEnd = this.dndDraggable.handleDragEnd;
    this.dndDraggable.handleDragEnd = (event: DragEvent): void => {
      originalDragEnd.call(this.dndDraggable, event);
      this.dndDraggable.dndDragEnd.emit(event);

      this.dragDropHelperService.dragEnded.emit(this.dropListEntry);
    };
  }

  private getNumberOfChildLevels(entry: DndDraggableDropListEntry): number {
    if (AssertionUtils.isEmpty(entry.childEntries)) {
      return 0;
    }
    return 1 + Math.max(...entry.childEntries.map((childEntry: DndDraggableDropListEntry) => this.getNumberOfChildLevels(childEntry)));
  }

  private updateAllowedTypesOnDragStart(): void {
    this.dragDropHelperService.dragStarted.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((data: DndDragStartedData) => {
      const childDepth = this.getNumberOfChildLevels(data.draggedEntry);
      let dropAllowed = this.nestedDepth + childDepth < this.dragDropHelperService.maxNestedDepth ?? this.dragDropHelperService.DEFAULT_MAX_NESTED_DEPTH;
      dropAllowed &&= this.isDropAllowed(this.dropListEntry, data.draggedEntry);
      this.dndListSettings.allowedTypes = dropAllowed ? ['allowed'] : ['not_allowed'];
    });
  }
}
