export enum TextileDataType {
  ARTICLE = 'article',
  COLOR = 'color',
  COLOR_SET = 'colorSet',
  COLORED_YARN = 'coloredYarn',
  COLORED_YARN_SET = 'coloredYarnSet',
  COMPANY_WEAVE_STRUCTURE = 'companyWeaveStructure',
  CREEL = 'creel',
  CUSTOMER = 'customer',
  DATA_UNIT_SETUP = 'dataUnitSetup',
  DATA_UNIT_SETUP_CATALOG = 'dataUnitSetupCatalog',
  EVENT = 'event',
  EVENT_ALERT = 'eventAlert',
  WEAVE_PRODUCT = 'weaveProduct',
  FINISHING = 'finishing',
  FINISHING_TEMPLATE = 'finishingTemplate',
  MACHINE_QUALITY = 'machineQuality',
  QUALITY_FINISHING = 'qualityFinishing',
  ORDERBOOK = 'orderbook',
  PRODUCT_CONFIGURATION = 'productConfiguration',
  PRODUCTION_ORDER_LITE = 'productionOrderLite',
  PRODUCTION_SCHEDULE = 'productionSchedule',
  PRODUCTION_SCHEDULE_NEW = 'productionScheduleNew',
  SALES_ORDER = 'salesOrder',
  WEAVE_PATTERN = 'weavePattern',
  WEAVE_STRUCTURE = 'weaveStructure',
  GROUPED_WEAVE_STRUCTURE = 'groupedWeaveStructure',
  WEFT_COLORED_YARN_SET = 'weftColoredYarnSet',
  YARN_SET = 'yarnSet',
  YARN_TYPE = 'yarnType',
  TUFT_PRODUCT = 'tuftProduct',
  TUFT_PRODUCTION_ORDER = 'tuftProductionOrder',
  DEVICE_TEMPLATE_CATALOG = 'deviceTemplateCatalog',
  DEVICE_TEMPLATE_COUNTER_CATALOG = 'deviceTemplateCounterCatalog',
  DEVICE_TEMPLATE_CONFIGURATION_CATALOG = 'deviceTemplateConfigurationCatalog',
  DEVICE_TEMPLATE = 'deviceTemplate',
  DEVICE_TEMPLATE_COUNTER = 'deviceTemplateCounter',
  DEVICE_TEMPLATE_CONFIGURATION = 'deviceTemplateConfiguration',
  PRODUCTION_ORDER_WEAVING = 'productionOrderWeaving',
  PATH_LAYOUT_TEMPLATE = 'pathLayoutTemplate',
  OPERATOR = 'operator',
  RESOURCE_TYPE = 'resourceType',
  PHYSICAL_QUANTITY = 'physicalQuantity',
  REPORT_ITEM = 'reportItem',
  DECLARATION = 'declaration',
  AUTOMATIC_STOP_GROUP = 'automaticStopGroup',
  PLASTIC_PRODUCT = 'plasticProduct',
  PLASTIC_PRODUCTION_ORDER = 'plasticProductionOrder',
  TOOL = 'tool',
  REJECT_REASON = 'rejectReason',
  FIXED_SCHEDULE = 'fixedSchedule',
  BUGGY = 'buggy',
  SHIFT = 'shift'
}
