<div class="table">
  <article>
    <div class="column position" fxLayout="column" fxFlex="0 0 40px">
      <div class="header cell"></div>
      <div class="b1 cell" fxLayout="column" fxLayoutAlign="center center" *ngFor="let creelPositionNumber of getCreelPositions()" [style.height.px]="cellHeightInPx">
        {{ creelPositionNumber }}
      </div>
    </div>
    <div class="column" fxLayout="column" fxFlex="0 0 207px" *ngFor="let coloredYarnSetWithStartDent of mappedColoredYarnSetsWithStartDent; let index = index">
      <div class="header cell">
        <h4 class="font-weight-semibold" vdw-ellipsis-label [text]="coloredYarnSetWithStartDent.coloredYarnSet.name" matTooltipPosition="above"></h4>
      </div>
      <div
        class="b1 cell"
        fxLayout="column"
        fxLayoutAlign="center start"
        *ngFor="let creelPosition of coloredYarnSetWithStartDent.coloredYarnSet.overviewListColorSet.creelPositions; let creelPositionIndex = index; trackBy: trackByCreelPosition"
        [class.mapped]="isCreelPositionDifferentFromTheoreticalCreel(index, creelPosition.position)"
        [style.height.px]="cellHeightInPx"
      >
        <div fxLayout="row" fxLayoutGap="10px" class="full-width">
          <ng-container *ngIf="isOfPreviewTypeColor()">
            <app-color-grid-preview fxFlex="1 0 calc(100% - 20px)" [colors]="creelPosition.getUniqueItems()" [showRgbValues]="false"> </app-color-grid-preview>
          </ng-container>

          <ng-container *ngIf="!isOfPreviewTypeColor()">
            <div fxLayout="column" fxLayoutGap="6px" fxFlex="1 0 calc(100% - 20px)">
              <vdw-ellipsis-label
                *ngFor="let item of coloredYarnSetWithStartDent.coloredYarnSet.overviewListYarnSet.creelPositions[creelPositionIndex].getUniqueItems()"
                [text]="item.name"
                matTooltipPosition="above"
              >
              </vdw-ellipsis-label>
            </div>
          </ng-container>
          <span class="original-position" fxFlexAlign="center" *ngIf="isCreelPositionDifferentFromTheoreticalCreel(index, creelPosition.position)">
            {{ '(' + creelPosition.position + ')' }}
          </span>
        </div>
      </div>
    </div>
  </article>
</div>
