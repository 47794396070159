<section fxLayout="column" fxFlex>
  <vdw-header
    [title]="'GENERAL.ACTIONS.NEW_OBJECT' | bmsTranslate: {object: patternTranslationKey | bmsTranslate | lowercase}"
    svgIcon="select-blue"
    [canShowClose]="true"
    (closeEvent)="close()"
  ></vdw-header>
  <article class="dialog-content" fxLayout="row">
    <form fxFlex fxLayout="column" [formGroup]="addPatternForm">
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex>
          <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error *ngIf="canShowErrorForFormControl('required', 'name')">
            {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
          </mat-error>
          <mat-error *ngIf="canShowErrorForFormControl('identifierTaken', 'name')">
            {{ 'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate }}
          </mat-error>
        </mat-form-field>
        <img [attr.src]="image.data" fxFlex="129px" height="66" />
      </div>
    </form>
  </article>
  <vdw-footer chooseEventName="GENERAL.ACTIONS.ADD" [locked]="addPatternForm.invalid" [chooseEventInProgress]="saving" [centered]="true" (cancelEvent)="close()" (chooseEvent)="add()"> </vdw-footer>
</section>
