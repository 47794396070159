import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'vdw-mobile-position-indicator',
  templateUrl: './mobile-position-indicator.component.html',
  styleUrls: ['./mobile-position-indicator.component.scss']
})
export class MobilePositionIndicatorComponent implements OnInit {
  @Input() public currentPositionIndex: number;
  @Input() public totalPositions: number;
  public positions: number[];

  public ngOnInit(): void {
    this.positions = [...new Array(this.totalPositions).keys()];
  }

  public isCurrent(position: number): boolean {
    return position === this.currentPositionIndex;
  }
}
