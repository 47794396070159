import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {WeavePattern} from '@domain/textile-data/weave-structure/weave-pattern/weave-pattern';
import {GetPatternVisualisationByPatternId} from '@infrastructure/http/patterns/get-pattern-visualisation-by-pattern-id';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {PatternPreviewDialogComponent} from '@presentation/pages/textile-data/weave-structure/add/grid-weave-pattern-preview/pattern-preview-dialog/pattern-preview-dialog.component';
import {AssertionUtils, BaseComponent, DialogBuilderFactoryService, observeIntersection} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {cloneDeep} from 'lodash-es';
import {BehaviorSubject, combineLatest, forkJoin} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-pattern-example',
  templateUrl: './pattern-example.component.html',
  styleUrls: ['./pattern-example.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternExampleComponent extends BaseComponent implements ICellRendererAngularComp, AfterViewInit, OnDestroy {
  @Input() public weaveStructureId: number;
  @Input() public weaveQualityType: WeaveQualityType;
  @Input() public canShowColor: boolean;
  @Input() public observePatternBeforeVisible: boolean;
  @Input() public set pattern(pattern: Pattern | WeavePattern) {
    this._pattern = pattern instanceof WeavePattern ? pattern.pattern : pattern;

    const colorVisualisation = this._pattern.visualisations.find((visualisation: PatternVisualisation) => visualisation.type === PatternVisualisationType.COLOR);
    if (!AssertionUtils.isNullOrUndefined(colorVisualisation) && !AssertionUtils.isNullOrUndefined(this.canShowColor)) {
      this._pattern.visualisations.splice(this._pattern.visualisations.indexOf(colorVisualisation), 1);
      if (pattern.weavingChores?.length && this.canShowColor) {
        this._pattern.visualisations.push(colorVisualisation);
      }
    }

    const patternVisualisation = this._pattern.visualisations.find((visualisation: PatternVisualisation) => visualisation.type === PatternVisualisationType.PATTERN);
    if (!AssertionUtils.isNullOrUndefined(patternVisualisation) && !AssertionUtils.isNullOrUndefined(this.weaveQualityType) && !pattern.weavingChores?.length) {
      this._pattern.visualisations.splice(this._pattern.visualisations.indexOf(patternVisualisation), 1);
      if (this.weaveQualityType === WeaveQualityType.CARPET) {
        this._pattern.visualisations.push(patternVisualisation);
      }
    }

    this.patternVisualisations.next(this._pattern.visualisations);
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  @Output() public patternReceived = new EventEmitter<void>();

  @ViewChild('container') public container: ElementRef;
  @ViewChild('plusButton', {read: ElementRef}) public plusButton;
  @ViewChild('thumbnailsContainer') public thumbnailsContainer: ElementRef;

  public numberOfPatternVisualisationsToShow = new BehaviorSubject<number>(1);
  public patternVisualisations: BehaviorSubject<PatternVisualisation[]> = new BehaviorSubject<PatternVisualisation[]>([]);
  public numberOfHiddenPatternVisualisations = combineLatest([this.numberOfPatternVisualisationsToShow, this.patternVisualisations]).pipe(
    distinctUntilChanged(
      (
        [previousNumberOfPatternVisualisationsToShow, previousPatternVisualisations]: [number, PatternVisualisation[]],
        [currentNumberOfPatternVisualisationsToShow, currentPatternVisualisations]: [number, PatternVisualisation[]]
      ) => previousNumberOfPatternVisualisationsToShow === currentNumberOfPatternVisualisationsToShow && previousPatternVisualisations.length === currentPatternVisualisations.length
    ),
    map(([numberOfPatternVisualisationsToShow, patternVisualisations]: [number, PatternVisualisation[]]) => patternVisualisations.length - numberOfPatternVisualisationsToShow)
  );

  private _pattern: Pattern;
  private resizeObserver: ResizeObserver;
  private thumbnailsContainerOffsetWidth = new BehaviorSubject<number>(undefined);
  private containerOffsetWidth = new BehaviorSubject<number>(undefined);

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly getPatternVisualisationByPatternId: GetPatternVisualisationByPatternId
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(() => this.onResize());
    if (this.container) {
      this.resizeObserver.observe(this.container.nativeElement, {box: 'border-box'});
    }
    if (this.thumbnailsContainer) {
      this.resizeObserver.observe(this.thumbnailsContainer.nativeElement, {box: 'border-box'});
    }

    this.subscribeToPatternVisualisations();

    if (this.pattern?.visualisations?.every((visualisation: PatternVisualisation) => visualisation.hasVisualisationData())) {
      this.patternReceived.emit();
    }
  }

  public agInit(params: ICellRendererParams & {visualisations: PatternVisualisation[]} & {weaveStructureId: number}): void {
    const pattern = cloneDeep(params.data as Pattern);
    if (!AssertionUtils.isNullOrUndefined(params.visualisations)) {
      pattern.visualisations = params.visualisations;
    }

    this.pattern = pattern;
    this.weaveStructureId = params.weaveStructureId;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public openWeavePatternPreviewDialog(patternIndex: number = 0): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass(['overflow-hidden-dialog', 'box-shadow-none-dialog'])
      .withCustomBackdrop('white-backdrop')
      .withWidth('fit-content')
      .withHeight('fit-content')
      .withMaxWidth('100vw')
      .withMaxHeight('100vh')
      .openDialog(PatternPreviewDialogComponent, {pattern: this.pattern, index: patternIndex});
  }

  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    super.ngOnDestroy();
  }

  private getThumbnailsContainerOffsetWidth(): void {
    if (this.thumbnailsContainer?.nativeElement?.offsetWidth) {
      this.thumbnailsContainerOffsetWidth.next(this.thumbnailsContainer.nativeElement.offsetWidth);
    }
  }

  private getContainerOffsetWidth(): void {
    if (this.container?.nativeElement?.offsetWidth) {
      this.containerOffsetWidth.next(this.container.nativeElement.offsetWidth);
    }
  }

  private getButtonOffsetWidth(): number {
    return this.plusButton.nativeElement.offsetWidth;
  }

  private calculateNumberOfPatternVisualisationsToShow(): void {
    this.getThumbnailsContainerOffsetWidth();
    this.getContainerOffsetWidth();
    if (this.containerOffsetWidth.value > this.thumbnailsContainerOffsetWidth.value * this.pattern.visualisations.length) {
      this.numberOfPatternVisualisationsToShow.next(this.pattern.visualisations.length);
    } else {
      const numberOfVisualisationsToShowRoundedDown = Math.floor((this.containerOffsetWidth.value - this.getButtonOffsetWidth() - 4) / this.thumbnailsContainerOffsetWidth.value);
      this.numberOfPatternVisualisationsToShow.next(Math.max(numberOfVisualisationsToShowRoundedDown, 1));
    }
  }

  private onResize(): void {
    this.calculateNumberOfPatternVisualisationsToShow();
  }

  private subscribeToPatternVisualisations(): void {
    const patternObservable = this.observePatternBeforeVisible
      ? this.patternVisualisations.pipe(
          debounceTime(250),
          filter((patternVisualisations: PatternVisualisation[]) =>
            patternVisualisations.some((visualisation: PatternVisualisation) => !AssertionUtils.isNullOrUndefined(visualisation) && !visualisation.hasVisualisationData())
          ),
          map((patternVisualisations: PatternVisualisation[]) => patternVisualisations.filter((visualisation: PatternVisualisation) => !visualisation.hasVisualisationData()))
        )
      : combineLatest([this.patternVisualisations, observeIntersection(this.elementRef.nativeElement)]).pipe(
          debounceTime(250),
          filter(
            ([visualisations, isIntersecting]: [PatternVisualisation[], boolean]) =>
              isIntersecting && visualisations.some((visualisation: PatternVisualisation) => !AssertionUtils.isNullOrUndefined(visualisation) && !visualisation.hasVisualisationData())
          ),
          map(([patternVisualisations]: [PatternVisualisation[], boolean]) => patternVisualisations.filter((visualisation: PatternVisualisation) => !visualisation.hasVisualisationData()))
        );

    patternObservable
      .pipe(
        switchMap((visualisations: PatternVisualisation[]) =>
          forkJoin(
            visualisations.map((visualisation: PatternVisualisation) =>
              this.getPatternVisualisationByPatternId.execute({weavePatternId: this.pattern.id, type: visualisation.type, weaveStructureId: this.weaveStructureId})
            )
          )
        ),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((patternVisualisations: PatternVisualisation[]) => {
        this.pattern.visualisations = patternVisualisations;

        this.patternVisualisations.next(patternVisualisations);
        this.patternReceived.emit();
      });
  }
}
