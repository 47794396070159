<section fxLayout="column" fxFlex>
  <vdw-header
    [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'TEXTILE_DATA.MACHINE_QUALITY.JUTE_CONDITIONS.PATTERN_FIELDS' | bmsTranslate | lowercase} | bmsTranslate"
    svgIcon="select-blue"
    [canShowClose]="true"
  ></vdw-header>
  <article fxFlex appDataCy="grid" fxLayout="column" class="dialog-content">
    <ag-grid-angular #patternFieldsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfPatternFields"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectPatternFields()" [centered]="true" (chooseEvent)="selectPatternFields()"></vdw-footer>
</section>
