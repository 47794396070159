import {ComponentType} from '@angular/cdk/portal';
import {ChangeDetectorRef} from '@angular/core';
import {DialogPosition, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {cloneDeep} from 'lodash-es';
import {concat, Observable, skip, tap} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {UuidUtils} from '../../common/utils/uuid-utils';
import {AlertDialogConfirmationType, AlertDialogData, AlertDialogResult} from '../alert-dialog/alert-dialog-data';
import {DialogOpenerService} from '../dialog-opener/dialog-opener.service';
import {DialogOptions} from '../dialog-options';
import {ArrowPosition} from '../dialog-reposition/arrow-position.enum';
import {RepositionWatchDialog} from '../dialog-reposition/reposition-watch-dialog.interface';
import {DialogType} from '../dialog-type';
import {SelectGridDialog} from '../select-grid-dialog.interface';

export class DialogBuilder {
  private readonly dialogConfig: MatDialogConfig = {
    autoFocus: false,
    restoreFocus: false,
    id: UuidUtils.generateV4Uuid()
  };

  private refData: object;
  private autoWidth: boolean;
  private autoHeight: boolean;
  private onDialogOpened: (dialogRef: MatDialogRef<any, any>) => void;

  public constructor(
    private readonly repositionWatchDialog: RepositionWatchDialog,
    private readonly dialogOpener: DialogOpenerService
  ) {}

  public withId(id: string): DialogBuilder {
    this.dialogConfig.id = id;
    return this;
  }

  public withClass(panelClass: string | string[]): DialogBuilder {
    this.dialogConfig.panelClass = panelClass;
    return this;
  }

  public withWidth(width: string): DialogBuilder {
    this.dialogConfig.width = width;
    return this;
  }

  public withMinWidth(minWidth: string | number): DialogBuilder {
    this.dialogConfig.minWidth = minWidth;
    return this;
  }

  public withMaxWidth(maxWidth: string | number): DialogBuilder {
    this.dialogConfig.maxWidth = maxWidth;
    return this;
  }

  public withAutoWidth(): DialogBuilder {
    this.autoWidth = true;
    return this;
  }

  public withHeight(height: string): DialogBuilder {
    if (!AssertionUtils.isNullOrUndefined(height)) {
      this.dialogConfig.height = height;
    }
    return this;
  }

  public withMinHeight(minHeight: string | number): DialogBuilder {
    this.dialogConfig.minHeight = minHeight;

    return this;
  }

  public withMaxHeight(maxHeight: string | number): DialogBuilder {
    this.dialogConfig.maxHeight = maxHeight;
    return this;
  }

  public withAutoHeight(): DialogBuilder {
    this.autoHeight = true;
    return this;
  }

  public withoutBackdrop(): DialogBuilder {
    this.dialogConfig.hasBackdrop = false;
    return this;
  }

  public withCustomBackdrop(backdropClass?: string | string[]): DialogBuilder {
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.backdropClass = backdropClass;
    return this;
  }

  public withCloseDisabled(): DialogBuilder {
    this.dialogConfig.disableClose = true;
    return this;
  }

  public withAutoFocus(): DialogBuilder {
    this.dialogConfig.autoFocus = true;
    return this;
  }

  public withRefData(data: object): DialogBuilder {
    this.refData = data;
    return this;
  }

  public withPosition(position: DialogPosition): DialogBuilder {
    this.dialogConfig.position = position;
    return this;
  }

  public withOnDialogOpened(onDialogOpened: (dialogRef: MatDialogRef<any, any>) => void): DialogBuilder {
    this.onDialogOpened = onDialogOpened;
    return this;
  }

  public open<T, S extends object>(component: ComponentType<T>, data?: S): MatDialogRef<T> {
    return this.dialogOpener.open(component, this.buildWithData(data));
  }

  public openDialog<T, S extends object, R>(component: ComponentType<T>, data?: S): Observable<R> {
    return this.dialogOpener.openDialog(component, this.buildWithData(data));
  }

  public openCrudDialog<T, S extends object, R>(component: ComponentType<T>, data?: S): Observable<R> {
    return this.dialogOpener.openCrudDialog(component, this.buildWithData(data));
  }

  public openAlertDialog(options: DialogOptions): Observable<AlertDialogResult> {
    let data: AlertDialogData;

    if (options.type === DialogType.CONFIRM) {
      data = AlertDialogData.createConfirmData(options.titleText, options.messageText);
    } else if (options.type === DialogType.INFORMATION) {
      data = AlertDialogData.createInformativeData(options.titleText, options.messageText);
    } else if (options.type === DialogType.DISCARD) {
      data = AlertDialogData.createDiscardData();
    } else if (options.type === DialogType.DISCARD_AND_SAVE) {
      data = AlertDialogData.createDiscardAndSaveData();
    } else if (options.type === DialogType.ERROR) {
      data = AlertDialogData.createErrorData(options.titleText, options.messageText, options.extraMessageText);
    } else if (options.type === DialogType.DELETE) {
      data = AlertDialogData.createConfirmationData('', options.titleText, options.messageText, AlertDialogConfirmationType.DELETE);
    } else if (options.type === DialogType.CONFIRM_DELETE) {
      data = AlertDialogData.createConfirmationDeleteData(options.titleText, options.messageText, options.extraMessageText, options.entities, options.buttonText);
    } else if (options.type === DialogType.REPORT) {
      data = AlertDialogData.createReportData(options.titleText, options.messageText, options.extraMessageText, options.confirmButtonText, options.informationUrl);
    } else if (options.type === DialogType.OVERRIDE) {
      data = AlertDialogData.createOverrideData(options.titleText, options.messageText);
    }

    return this.dialogOpener.openAlertDialog(this.withId('alert-dialog').withClass('alert-dialog').buildWithData(data));
  }

  public openSelectGridDialog<T extends object, R>(component: ComponentType<SelectGridDialog>, data?: T): Observable<R> {
    return this.dialogOpener.openSelectGridDialog(component, this.withClass('large-modal').buildWithData(data));
  }

  public openAtElement<T, S extends object, R>(
    element: Element,
    arrowPosition: ArrowPosition,
    component: ComponentType<T>,
    data: S,
    changeDetectorRef: ChangeDetectorRef,
    showArrow: boolean = true,
    align: 'left' | 'right' = null,
    verticalOffset: number = 0,
    horizontalOffset: number = 0,
    margin: number = undefined
  ): Observable<R> {
    const dialogRef = this.dialogOpener.open(component, this.buildWithData(data));

    this.repositionWatchDialog.initWithElement(dialogRef, changeDetectorRef, arrowPosition, element, showArrow, align, verticalOffset, horizontalOffset, this.autoHeight, this.autoWidth, margin);

    if (!AssertionUtils.isNullOrUndefined(this.onDialogOpened)) {
      this.onDialogOpened(dialogRef);
    }

    return concat(dialogRef.beforeClosed().pipe(tap(() => this.repositionWatchDialog.destroy())), dialogRef.afterClosed()).pipe(skip(1));
  }

  public openAtPosition<T, S extends object, R>(
    x: number,
    y: number,
    arrowPosition: ArrowPosition,
    component: ComponentType<T>,
    data: S,
    changeDetectorRef: ChangeDetectorRef,
    showArrow: boolean = true
  ): Observable<R> {
    const dialogRef = this.dialogOpener.open(component, this.buildWithData(data));

    this.repositionWatchDialog.initWithPosition(dialogRef, changeDetectorRef, arrowPosition, x, y, showArrow, this.autoHeight, this.autoWidth);

    return concat(dialogRef.beforeClosed().pipe(tap(() => this.repositionWatchDialog.destroy())), dialogRef.afterClosed()).pipe(skip(1));
  }

  public close(value?: any): void {
    this.dialogOpener.close(this.dialogConfig.id, value);
  }

  private buildWithData<T extends object>(data: T): MatDialogConfig<T> {
    if (AssertionUtils.isNullOrUndefined(this.refData)) {
      if (data instanceof Object && 'grid' in data) {
        return {...cloneDeep(this.dialogConfig), data: data};
      } else {
        return {...cloneDeep(this.dialogConfig), data: cloneDeep(data)};
      }
    }

    return {...cloneDeep(this.dialogConfig), data: {...cloneDeep(data), ...this.refData}};
  }
}
