<section class="flex-column full-height">
  <vdw-header
    [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'BMSCONFIG.MACHINE_DATA_SOURCES.DATA_SOURCE_TYPE.DATA_UNIT' | bmsTranslate | lowercase}"
    svgIcon="select-blue"
    [canShowClose]="true"
  ></vdw-header>
  <article class="dialog-content full-height">
    <ag-grid-angular #dataUnitsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfDataUnits" [rowData]="listOfDataUnits"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectDataUnit()" [centered]="true" (chooseEvent)="selectDataUnit()"></vdw-footer>
</section>
