import {DyeingMachineType} from '@domain/machine/dyeing-machine-type';
import {MachineType} from '@domain/machine/machine-type.enum';
import {PlasticsMachineType} from '@domain/machine/plastics-machine-type';
import {TuftingMachineType} from '@domain/machine/tufting-machine-type';
import {WeavingMachineType} from '@domain/machine/weaving-machine-type';
import {WinderMachineType} from '@domain/machine/winder-machine-type';

const equipmentTypeClassFromEquipmentTypeMap = new Map<MachineType, any>([
  [MachineType.WEAVING_MACHINE, WeavingMachineType],
  [MachineType.TUFT, TuftingMachineType],
  [MachineType.WINDER, WinderMachineType],
  [MachineType.DYEING, DyeingMachineType],
  [MachineType.PLASTIC_MACHINE, PlasticsMachineType]
]);

export function getEquipmentTypeClassFromEquipmentType(equipmentType: MachineType): any {
  return equipmentTypeClassFromEquipmentTypeMap.get(equipmentType);
}
