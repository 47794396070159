import {ChangeDetectionStrategy, Component, computed, effect, EventEmitter, Input, input, InputSignal, OnInit, Output, Signal, signal, WritableSignal} from '@angular/core';
import {CustomSettingsRepository} from '@infrastructure/http/custom-settings-repository';
import {AssertionUtils, BaseComponent} from '@vdw/angular-component-library';
import {debounce, interval, Observable, Subject, switchMap, takeUntil} from 'rxjs';

@Component({
  selector: 'app-name-suggestion-hint',
  templateUrl: './name-suggestion-hint.component.html',
  styleUrl: './name-suggestion-hint.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NameSuggestionHintComponent<TNameGenerationEntity> extends BaseComponent implements OnInit {
  public currentName: InputSignal<string> = input();
  public nameGenerationEntity: InputSignal<TNameGenerationEntity> = input.required();
  public httpRepository: InputSignal<CustomSettingsRepository<any, TNameGenerationEntity>> = input.required();
  @Input() public externalRefresh: Observable<void>;
  @Output() public applySuggestion: EventEmitter<string> = new EventEmitter();

  protected nameSuggestion: WritableSignal<string> = signal(null);
  protected showNameSuggestion: Signal<boolean> = computed(() => {
    return !AssertionUtils.isNullOrWhitespace(this.nameSuggestion()) && this.currentName() !== this.nameSuggestion();
  });

  private entityUpdates: Subject<TNameGenerationEntity> = new Subject();
  private nameGenerationEntityChanged = effect(() => {
    this.refreshGeneratedName(this.nameGenerationEntity());
  });

  public ngOnInit(): void {
    this.externalRefresh?.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => this.refreshGeneratedName(this.nameGenerationEntity()));

    this.entityUpdates
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        debounce((_: any) => interval(500)),
        switchMap((nameGenerationEntity: TNameGenerationEntity) => this.httpRepository().generateName(nameGenerationEntity))
      )
      .subscribe((suggestion: string) => this.nameSuggestion.update((_: string) => suggestion));
  }

  private refreshGeneratedName(entity: TNameGenerationEntity): void {
    if (!AssertionUtils.isNullOrUndefined(entity)) {
      this.entityUpdates.next(entity);
    }
  }
}
