<mat-form-field class="bms-theme full-width">
  <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.NAME_GENERATION' | bmsTranslate }}</mat-label>
  <vdw-object-selection
    [formControl]="patternPreview"
    [objectName]="'PRODUCTION_ORDER.CUSTOM_SETTINGS.NAME_GENERATION' | bmsTranslate | lowercase"
    [canShowMultiSelectPlaceHolder]="false"
    [canShowBMSTheme]="true"
    [withDialogObjectSelection]="true"
    (selectClicked)="selectNameGenerationPattern()"
    (clearClicked)="clearNameGeneratorParts()"
  />
</mat-form-field>

<mat-form-field class="bms-theme full-width">
  <mat-label>{{ 'GENERAL.PLACEHOLDER.NAME_PLACEHOLDER_SEPARATOR' | bmsTranslate }}</mat-label>
  <mat-select [formControl]="nameGeneratorForm.controls.separator" panelClass="bms-theme">
    @for (separator of getNameGeneratorSeparators(); track separator) {
      <mat-option [value]="separator">{{ 'GENERAL.PLACEHOLDER.SEPARATOR.' + separator | bmsTranslate }}</mat-option>
    }
  </mat-select>
</mat-form-field>
