<section appDataCy="select-color-set-dialog" fxLayout="column" fxFlex>
  <vdw-header [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'TEXTILE_DATA.COLOR_SET.COLOR_SET' | bmsTranslate: {count: 1} | lowercase}" svgIcon="select-blue"></vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button
      *appHasPermission="COLOR_SET_EDIT_PERMISSION"
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate"
      (click)="navigateToAddColorSet()"
    >
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #colorSetsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfColorSets" [rowData]="listOfColorSets"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectColorSet()" [centered]="true" (chooseEvent)="selectColorSet()"></vdw-footer>
</section>
