import {EventEmitter, Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {Permission} from '@domain/profile/permission.enum';
import {PropertyValue} from '@domain/property-value';
import {Tool} from '@domain/utilities/tool/tool';
import {HttpToolsService} from '@infrastructure/http/tool/http-tools.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {
  AssertionUtils,
  ContentSwitcherDialogService,
  CrudGridContextService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  DialogBuilderFactoryService,
  DialogType,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {IRowNode} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {ToolOverviewService} from './overview/tool-overview.service';

@Injectable()
export class ToolSelectionService {
  private config: CrudOverviewDataConfig<Tool>;
  private readonly reloadData = new EventEmitter<PropertyValue[]>();

  private routes = [
    NavigationUtils.getNavigationRoute(NavigationId.TOOL_MODULE),
    NavigationUtils.getNavigationRoute(NavigationId.TOOL),
    NavigationUtils.getNavigationRoute(NavigationId.ADD_TOOL),
    NavigationUtils.getNavigationRoute(NavigationId.EDIT_TOOL),
    NavigationUtils.getNavigationRoute(NavigationId.DUPLICATE_TOOL)
  ];

  public constructor(
    private readonly injector: Injector,
    private readonly tools: HttpToolsService,
    private readonly translate: TranslateService,
    private readonly overview: ToolOverviewService,
    private readonly textileService: TextileService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly crudGridContextService: CrudGridContextService,
    private readonly gridActionButtonsHelper: GridActionButtonsHelper,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<Tool>
  ) {
    this.init();
  }

  public init(): void {
    this.contentSwitcher.parentInjector = this.injector;

    this.config = this.builderFactoryService
      .getBuilder()
      .withHeader(this.translate.instant('UTILITIES.TOOL.TOOL', {count: 1}))
      .withRowData(this.tools.getAll())
      .withRouting(NavigationUtils.getNavigationRoute(NavigationId.ADD_TOOL))
      .withDefaultButtons(
        this.gridActionButtonsHelper.getActionButtonsWithRouting(this.routes, Permission.TEXSTYLE_PLASTIC_TOOL_EDIT, (grid: AgGridAngular | IRowNode) => {
          const data = grid instanceof AgGridAngular ? (grid?.api?.getSelectedRows()[0] as Tool) : (grid.data as Tool);

          if (!AssertionUtils.isEmpty(this.config.selectedObjects) && data.id === this.config.selectedObjects[0]?.id) {
            this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
              titleText: this.translate.instant('CONFLICTS.DIALOG.IN_USE', {object: data.name}),
              messageText: this.translate.instant('CONFLICTS.DIALOG.DESELECT'),
              type: DialogType.DELETE
            });
            return;
          }

          this.textileService.removeConfirmation(
            grid instanceof AgGridAngular ? grid?.api?.getSelectedRows()[0] : grid.data,
            TextileDataType.TOOL,
            false,
            null,
            this.tools,
            null,
            null,
            this.reloadData,
            null,
            false,
            null,
            null,
            null,
            this.crudGridContextService.grid.api,
            true
          );
        })
      )
      .withGridOptions(this.overview.getColumnDefs(false), GridIdentifier.TOOL_OVERVIEW, null, (builder: GridOptionsBuilder) => builder.withRowSelection(false, false, true, false).build())
      .build();
  }

  public openContentSwitcherDialog(inputValue: Tool): Observable<Tool[]> {
    this.config.selectedObjects = [inputValue];
    this.contentSwitcher.addRouting(this.routes);
    this.contentSwitcher.navigateForward(NavigationUtils.getNavigationRoute(NavigationId.TOOL), null, {config: this.config});

    return this.builderFactoryService.openContentSwitcherDialog(this.translate.instant('UTILITIES.TOOL.TOOL', {count: 1}));
  }

  public setSelected(inputValue: Tool): void {
    this.config.selectedObjects = [inputValue];
  }
}
