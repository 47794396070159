import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToggleButtons} from './toggle-buttons.interface';

@Component({
  selector: 'vdw-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.scss']
})
export class ToggleButtonsComponent {
  @Input() public toggleButtons: ToggleButtons[];

  @Output() public selectedToggleButton: EventEmitter<ToggleButtons> = new EventEmitter<ToggleButtons>();

  public onClick(value: ToggleButtons): void {
    this.selectedToggleButton.emit(value);
  }
}
