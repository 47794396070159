export class StopReasonText {
  private _id: number;
  private _text: string;
  private _abbreviation: string;
  private _translations: {[language: string]: string};
  private _abbreviationTranslations: {[language: string]: string};

  public constructor(id: number, text: string, abbreviation: string, translations: {[language: string]: string}, abbreviationTranslations: {[language: string]: string}) {
    this._id = id;
    this._text = text;
    this._abbreviation = abbreviation;
    this._translations = translations;
    this._abbreviationTranslations = abbreviationTranslations;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get text(): string {
    return this._text;
  }

  public set text(text: string) {
    this._text = text;
  }

  public get abbreviation(): string {
    return this._abbreviation;
  }

  public set abbreviation(abbreviation: string) {
    this._abbreviation = abbreviation;
  }

  public get translations(): {[language: string]: string} {
    return this._translations;
  }

  public set translations(translations: {[language: string]: string}) {
    this._translations = translations;
  }

  public get abbreviationTranslations(): {[language: string]: string} {
    return this._abbreviationTranslations;
  }

  public set abbreviationTranslations(abbreviationTranslations: {[language: string]: string}) {
    this._abbreviationTranslations = abbreviationTranslations;
  }

  public static fromJSON(stopReasonTextJSON: any): StopReasonText {
    return new StopReasonText(stopReasonTextJSON.id, stopReasonTextJSON.text, stopReasonTextJSON.abbreviation, stopReasonTextJSON.translations, stopReasonTextJSON.abbreviationTranslations);
  }

  public toJSON(): JSON {
    return {
      text: this._text,
      abbreviation: this._abbreviation,
      translations: this._translations,
      abbreviationTranslations: this._abbreviationTranslations
    } as any as JSON;
  }
}
