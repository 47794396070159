<mat-card fxLayout="column" class="full-height no-margin">
  <mat-card-content fxFlex="100" fxLayout="row">
    <article class="container" fxFlex="100" fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="start center">
        <h4 class="font-weight-semibold">
          {{ 'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'GENERAL.POSSIBLE_OBJECTS' | bmsTranslate: {objects: 'MACHINE.MACHINE' | bmsTranslate: ({count: 2})} | lowercase} }}
        </h4>
        <div fxFlex fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
            <mat-slide-toggle [ngModel]="showSelected" (ngModelChange)="onShowSelectedToggleChange($event)">{{ 'GENERAL.SELECTED' | bmsTranslate }}</mat-slide-toggle>
            <vdw-filter *ngIf="showSearchBar" fxFlex="184px" [filteredText]="filteredText" (filteredTextChanged)="filterMachineGroupsAndMachines($event)"></vdw-filter>
          </div>
        </div>
      </div>
      <div fxFlex fxLayout="row">
        <ag-grid-angular #machinesGrid fxFlex class="ag-theme-vdw" [gridOptions]="gridOptions" [rowData]="machines"> </ag-grid-angular>
      </div>
    </article>
  </mat-card-content>
</mat-card>
