import {Component} from '@angular/core';
import {ImageForFinishingImageLabel} from '@domain/textile-data/finishing-and-finishing-template/image-for-finishing-image-label';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './grid-image.component.html',
  styleUrls: ['./grid-image.component.scss']
})
export class GridImageComponent implements ICellRendererAngularComp {
  public image: ImageForFinishingImageLabel;

  public agInit(params: ICellRendererParams): void {
    this.image = params.data;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
