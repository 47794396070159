import {MachineType} from '@domain/machine/machine-type.enum';
import {AssertionUtils} from '@vdw/angular-component-library';

export function mapEquipmentKindToMachineType(equipmentKind: string): string {
  switch (equipmentKind) {
    case 'CARPET':
      return MachineType.CARPET_LOOM;
    case 'CARPET_NO_JQ':
      return MachineType.CARPET_LOOM_NO_JQ;
    case 'WEAVING':
      return MachineType.WEAVING_MACHINE;
    case 'PLASTIC':
      return MachineType.PLASTIC_MACHINE;
  }

  return equipmentKind;
}

export function mapEquipmentKindToMachineTypeEnum(machineTypeName: string): MachineType {
  switch (machineTypeName) {
    case 'CARPET':
      return MachineType.CARPET_LOOM;
    case 'CARPET_NO_JQ':
      return MachineType.CARPET_LOOM_NO_JQ;
    case 'WEAVING':
      return MachineType.WEAVING_MACHINE;
    case 'PLASTIC':
      return MachineType.PLASTIC_MACHINE;
    default:
      return !AssertionUtils.isNullOrUndefined(MachineType[machineTypeName]) ? MachineType[machineTypeName] : MachineType.UNDEFINED;
  }
}
