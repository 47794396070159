import {Injectable} from '@angular/core';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {YarnType} from '@domain/textile-data/yarn-type/yarn-type';
import {BaseComponent, ColDefBuilderFactoryService, LinkIconRendererComponent, StringUtils} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams, ValueGetterParams} from 'ag-grid-community';
import {map, ReplaySubject, takeUntil} from 'rxjs';
import {GridYarnSetPreviewComponent} from './grid-yarn-set-preview/grid-yarn-set-preview.component';
import {OverviewListYarnSet} from './overview-list-yarn-set';

@Injectable()
export class OverviewListYarnSetService extends BaseComponent {
  private pageUrls = LastModifiedCardUtils.getPageUrls('yarnSet');

  public constructor(private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService) {
    super();
  }

  public getColumnDefsForListOfYarnSets(possibleValuesSubject: ReplaySubject<OverviewListYarnSet[]>, canClickName: boolean): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('name')
        .withField('name', true)
        .withHeaderName('GENERAL.NAME')
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            canClick: canClickName,
            editPageUrl: this.pageUrls.edit
          };
        })
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('creelPositions')
        .withHeaderName('GENERAL.POSITION', 2, true)
        .withValueGetter((params: ValueGetterParams) => params.data.creelPositions.length, true)
        .withNumericMultiFilter(
          possibleValuesSubject.asObservable().pipe(
            map((listOfYarnSet: OverviewListYarnSet[]) => this.getPossibleNumberOfPositions(listOfYarnSet)),
            takeUntil(this.unSubscribeOnViewDestroy)
          )
        )
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColId('numberOfYarns')
        .withHeaderName('TEXTILE_DATA.YARN_TYPE.YARN_TYPE', 2, true)
        .withValueGetter((params: ValueGetterParams) => this.getNumberOfYarns(params.data), true)
        .withNumericMultiFilter(
          possibleValuesSubject.asObservable().pipe(
            map((listOfYarnSet: OverviewListYarnSet[]) => this.getPossibleNumberOfYarns(listOfYarnSet)),
            takeUntil(this.unSubscribeOnViewDestroy)
          )
        )
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.YARN_TYPE.YARN_TYPE', 2)
        .withCellRenderer(GridYarnSetPreviewComponent)
        .withValueGetter((params: ValueGetterParams) =>
          params.data
            .getUniqueYarnTypes()
            .map((yarnType: YarnType) => yarnType.name)
            .join(', ')
        )
        .withComparator(StringUtils.stringComparator)
        .build()
    ];
  }

  private getPossibleNumberOfPositions(listOfYarnSet: OverviewListYarnSet[]): string[] {
    return listOfYarnSet.map((yarnSet: OverviewListYarnSet) => yarnSet.creelPositions.length.toString());
  }

  private getPossibleNumberOfYarns(listOfYarnSet: OverviewListYarnSet[]): string[] {
    return listOfYarnSet?.map((yarnSet: OverviewListYarnSet) => this.getNumberOfYarns(yarnSet).toString());
  }

  private getNumberOfYarns(yarnSet: OverviewListYarnSet): number {
    return yarnSet.creelPositions.flatMap((creelPosition: OverviewListCreelPosition) => creelPosition.items).length;
  }
}
