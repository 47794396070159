import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
  selector: 'vdw-split-button',
  templateUrl: './split-button.component.html'
})
export class SplitButtonComponent {
  @ViewChild('otherOptionsTrigger', {read: MatMenuTrigger}) public otherOptionsTrigger: MatMenuTrigger;
  @Input() public label: string;
  @Input() public stroked = false;
  @Input() public disabled = false;
  @Input() public compact = false;
  @Input() public inProgress = false;
  @Input() public withOpenDropdown = false;
  @Output() public buttonClicked = new EventEmitter<void>();

  public onButonClicked(): void {
    if (!this.inProgress) {
      this.withOpenDropdown ? this.otherOptionsTrigger.openMenu() : this.buttonClicked.emit();
    }
  }
}
