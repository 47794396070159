import {Injectable} from '@angular/core';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AssertionUtils} from '@vdw/angular-component-library';
import {IconPermission} from './icon-permission.interface';

@Injectable({
  providedIn: 'root'
})
export class IconPermissionsService {
  private permissionIcons: IconPermission[];

  public constructor() {
    this.permissionIcons = this.composeIcons();
  }

  public getPermissionIcons(isMobile: boolean, subscription: Subscription): IconPermission[] {
    const filteredIcons = isMobile ? this.permissionIcons.filter((icon: IconPermission) => icon.mobileCompatible) : this.permissionIcons;

    return !AssertionUtils.isNullOrUndefined(subscription) ? this.permissionIcons.filter((icon: IconPermission) => subscription.hasPermission(icon.permission)) : filteredIcons;
  }

  private composeIcons(): IconPermission[] {
    return [
      {
        name: 'profile',
        mobileCompatible: true,
        permission: RouteUtils.paths.profile.requiredPermission
      },
      {
        mobileCompatible: true,
        name: 'machine-overview',
        permission: RouteUtils.paths.texState.requiredPermission
      },
      {
        name: 'bot',
        mobileCompatible: true,
        permission: RouteUtils.paths.texBot.requiredPermission
      },
      {
        name: 'library',
        mobileCompatible: false,
        permission: RouteUtils.paths.texEdit.requiredPermission
      },
      {
        name: 'textile',
        mobileCompatible: false,
        permission: RouteUtils.paths.texStyle.requiredPermission
      },
      {
        name: 'texfab',
        mobileCompatible: false,
        permission: NavigationUtils.getNavigationData(NavigationId.TEXFAB).requiredPermission
      },
      {
        name: 'reports',
        mobileCompatible: false,
        permission: RouteUtils.paths.texBi.requiredPermission
      },
      {
        name: 'process',
        mobileCompatible: false,
        permission: Permission.JOB_VIEW
      },
      {
        name: 'credits',
        mobileCompatible: false,
        permission: Permission.PROFILE_CREDITS
      }
    ];
  }
}
