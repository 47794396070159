import {Inject, Injectable} from '@angular/core';
import {PathLayoutTemplate} from '@domain/path-layout-template/path-layout-template';
import {PathLayoutTemplatePath} from '@domain/path-layout-template/path-layout-template-path';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {ColoredYarnSetPosition} from '@domain/textile-data/creel/colored-yarn-set-position';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {ProductionSchedulePlan, PRODUCTION_SCHEDULE_PLAN} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan';
import {AssertionUtils, convertCommercialUnit, convertWidthInMeterToDents, NumberUtils, Unit} from '@vdw/angular-component-library';

@Injectable({providedIn: 'root'})
export class PathLayoutTemplateService {
  public constructor(@Inject(PRODUCTION_SCHEDULE_PLAN) private readonly productionSchedulePlan: ProductionSchedulePlan) {}

  public checkIfTemplateHasTooMuchWidth(
    productionSchedule: ProductionSchedule,
    pathLayoutTemplate: PathLayoutTemplate,
    coloredYarnSetPosition: number,
    reedDensityInDentsPerMeter: number,
    pathWidths: PathWidth[]
  ): boolean {
    const totalWidthOfPathsInDents = pathLayoutTemplate.pathLayoutTemplatePaths.reduce((totalWidth: number, pathLayoutTemplatePath: PathLayoutTemplatePath) => {
      const widthOfPathInDents = !AssertionUtils.isNullOrUndefined(this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, pathWidths))
        ? this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, pathWidths)
        : convertWidthInMeterToDents(convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: pathLayoutTemplatePath.widthInMM}, to: Unit.METER}), reedDensityInDentsPerMeter, 0);

      return totalWidth + widthOfPathInDents;
    }, 0);

    const totalWidthOfPathsWithAddedRestAndFreeZones = this.addFreeZonesAndRestZonesToTotalWidthOfPathTemplate(productionSchedule, totalWidthOfPathsInDents, pathLayoutTemplate);

    const coloredYarnSets = productionSchedule.productionSchedulePathsOfColoredYarnSets.map((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      return productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet;
    });

    if (productionSchedule.machine.jacquard.isSisal) {
      if (coloredYarnSetPosition === ColoredYarnSetPosition.BOTTOM) {
        const bottomColoredYarnSet = coloredYarnSets.filter((coloredYarnSet: OverviewListColoredYarnSetWithStartDent) => coloredYarnSet.isBottomPosition());
        return bottomColoredYarnSet[0].technicalWidthInDents < totalWidthOfPathsWithAddedRestAndFreeZones;
      } else if (coloredYarnSetPosition === ColoredYarnSetPosition.TOP) {
        const topColoredYarnSet = coloredYarnSets.filter((coloredYarnSet: OverviewListColoredYarnSetWithStartDent) => !coloredYarnSet.isBottomPosition());
        return topColoredYarnSet[0].technicalWidthInDents < totalWidthOfPathsWithAddedRestAndFreeZones;
      }
    } else {
      return productionSchedule.machine.dentsForFabric < totalWidthOfPathsWithAddedRestAndFreeZones;
    }
  }

  public checkIfTemplateHasOverlappingPaths(productionSchedule: ProductionSchedule, pathLayoutTemplate: PathLayoutTemplate, reedDensityInDentsPerMeter: number, pathWidths: PathWidth[]): boolean {
    const coloredYarnSetWidthsInDents = productionSchedule.productionSchedulePathsOfColoredYarnSets.map((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      return NumberUtils.roundAwayFromZero(productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.technicalWidthInDents);
    });

    let widthInDentsCounter = 0;
    let result: boolean;

    pathLayoutTemplate.pathLayoutTemplatePaths.forEach((pathLayoutTemplatePath: PathLayoutTemplatePath) => {
      const pathWidthInMeter = convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: pathLayoutTemplatePath.widthInMM}, to: Unit.METER});
      widthInDentsCounter += convertWidthInMeterToDents(pathWidthInMeter, reedDensityInDentsPerMeter, 0);

      if (widthInDentsCounter <= coloredYarnSetWidthsInDents[0]) {
        result = false;
      } else if (widthInDentsCounter > coloredYarnSetWidthsInDents[0]) {
        result = true;
      }
    });

    return result;
  }

  private addFreeZonesAndRestZonesToTotalWidthOfPathTemplate(productionSchedule: ProductionSchedule, totalWidthOfPathsInDents: number, pathLayoutTemplate: PathLayoutTemplate): number {
    const totalWidthOfPathsInDentsWithAddedInBetweenFreeZones = !productionSchedule.initialFreeZones.inBetween.useAsMaximum
      ? totalWidthOfPathsInDents + productionSchedule.initialFreeZones.inBetween.technicalWidthInDents * (pathLayoutTemplate.pathLayoutTemplatePaths.length - 1)
      : totalWidthOfPathsInDents;
    const totalWidthOfPathsInDentsWithAddedLeftFreeZones = !productionSchedule.initialFreeZones.left.useAsMaximum
      ? totalWidthOfPathsInDentsWithAddedInBetweenFreeZones + productionSchedule.initialFreeZones.left.technicalWidthInDents
      : totalWidthOfPathsInDentsWithAddedInBetweenFreeZones;
    const totalWidthOfPathsInDentsWithAddedRightFreeZones = !productionSchedule.initialFreeZones.right.useAsMaximum
      ? totalWidthOfPathsInDentsWithAddedLeftFreeZones + productionSchedule.initialFreeZones.right.technicalWidthInDents
      : totalWidthOfPathsInDentsWithAddedLeftFreeZones;

    return AssertionUtils.isNullOrUndefined(productionSchedule.initialRestZones?.userDefinedRest?.technicalWidthInDents)
      ? totalWidthOfPathsInDentsWithAddedRightFreeZones
      : totalWidthOfPathsInDentsWithAddedRightFreeZones + productionSchedule.initialRestZones.userDefinedRest.technicalWidthInDents;
  }
}
