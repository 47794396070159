import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {DataUnitSetup} from '@domain/data-unit-setup/data-unit-setup';
import {Language} from '@domain/data-unit-setup/stop-reason-text/language';
import {StopReasonText} from '@domain/data-unit-setup/stop-reason-text/stop-reason-text';
import {OverviewListDataUnitSetup} from '@presentation/pages/settings/data-unit-setup/overview/overview-list-data-unit-setup';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpDataUnitSetupService implements Repository<DataUnitSetup, OverviewListDataUnitSetup>, UniqueIdentifierObjectRepository {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<OverviewListDataUnitSetup[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getIotConfigEndpoint()}data-unit-setups`)
      .pipe(rxjsMap((dataUnitSetupsJSON: any) => dataUnitSetupsJSON.map((dataUnitSetupJSON: any) => OverviewListDataUnitSetup.fromJSON(dataUnitSetupJSON))));
  }

  public save(item: DataUnitSetup): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getIotConfigEndpoint()}data-unit-setups`, item.toJSON()).pipe(rxjsMap((id: number) => id));
  }

  public update(item: DataUnitSetup): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getIotConfigEndpoint()}data-unit-setups/${item.id}`, item.toJSON());
  }

  public getById(id: string | number): Observable<DataUnitSetup> {
    return this.httpClient.get(`${this.backendConfiguration.getIotConfigEndpoint()}data-unit-setups/${id}`).pipe(rxjsMap((dataUnitSetupJSON: any) => DataUnitSetup.fromJSON(dataUnitSetupJSON)));
  }

  public delete(id: string | number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getIotConfigEndpoint()}data-unit-setups/${id}`);
  }

  public isIdentifierAvailable(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getIotConfigEndpoint()}data-unit-setups/check-name`, {params}).pipe(
      rxjsMap((response: {dataUnitSetupNameAvailable: boolean}) => {
        return response.dataUnitSetupNameAvailable;
      })
    );
  }

  public getAllFromCatalog(): Observable<OverviewListDataUnitSetup[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getIotConfigEndpoint()}data-unit-setups/catalog`)
      .pipe(rxjsMap((dataUnitSetupsJSON: any) => dataUnitSetupsJSON.map((dataUnitSetupJSON: any) => OverviewListDataUnitSetup.fromJSON(dataUnitSetupJSON))));
  }

  public getAllStopReasonTexts(): Observable<StopReasonText[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getIotConfigEndpoint()}stop-reason-texts`)
      .pipe(rxjsMap((stopReasonTextCatalogsJSON: any) => stopReasonTextCatalogsJSON.map((stopReasonTextCatalogJSON: any) => StopReasonText.fromJSON(stopReasonTextCatalogJSON))));
  }

  public saveStopReasonTexts(item: StopReasonText): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getIotConfigEndpoint()}stop-reason-texts`, item.toJSON()).pipe(rxjsMap((id: number) => id));
  }

  public updateStopReasonTexts(item: StopReasonText): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getIotConfigEndpoint()}stop-reason-texts/${item.id}`, item.toJSON());
  }

  public deleteStopReasonTexts(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getIotConfigEndpoint()}stop-reason-texts/${id}`);
  }

  public getAllLanguages(): Observable<Language[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getIotConfigEndpoint()}languages`)
      .pipe(rxjsMap((languagesJSON: any) => languagesJSON.map((languageJSON: any) => Language.fromJSON(languageJSON))));
  }
}
