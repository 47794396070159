<section fxLayout="column" fxFlex>
  <vdw-header [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: getEntityTitle() | lowercase}" svgIcon="select-blue" [canShowClose]="true"></vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button
      *ngIf="canShowCreateNewButton()"
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate"
      (click)="navigateToAddRuleEntity()"
    >
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #ruleEntitiesGrid class="ag-theme-vdw" [gridOptions]="gridOptions" [rowData]="listOfObjects"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectEntity()" [centered]="true" (chooseEvent)="selectEntities()"></vdw-footer>
</section>
