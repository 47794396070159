<section fxFlex="100" class="subscription-selection" fxLayout="column">
  <vdw-mobile-page-header [title]="'AUTHENTICATION.SUBSCRIPTIONS.TITLE' | bmsTranslate" (backClicked)="chooseSubscription()"></vdw-mobile-page-header>

  <article class="subscriptions-container" fxFlex fxLayout="row">
    <app-select-subscription
      fxFlex
      [companies]="companies"
      [subscriptions]="companySubscriptions.get(selectedCompany.id)"
      [selectedCompany]="selectedCompany"
      [selectedSubscription]="selectedSubscription"
      (selectedCompanyChange)="selectedCompanyChanged($event)"
      (selectedSubscriptionChange)="selectedSubscriptionChanged($event)"
    ></app-select-subscription>
  </article>

  <mat-toolbar fxLayoutAlign="center center" fxLayout="row">
    <button fxFlex="100" mat-flat-button color="primary" (click)="chooseSubscription(selectedSubscription)">
      {{ 'GENERAL.ACTIONS.CHOOSE' | bmsTranslate }}
    </button>
  </mat-toolbar>
</section>
