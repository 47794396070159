import {DataUnitSetupStopInputOptions} from './stop-input-options.enum';
import {DataUnitSetupStopModeOptions} from './stop-mode-options.enum';

export class DigitalInputConfig {
  private _stopInput: DataUnitSetupStopInputOptions;
  private _stopMode: DataUnitSetupStopModeOptions;
  private _minimalAmountOfPulses: number;
  private _pulseTimeFrame: number;
  private _maximumPulseInterval: number;
  private _stopWindowSamples: number;
  private _stopWindowSampleRate: number;
  private _inputMask: string;
  private _invertMask: string;

  public constructor(
    stopInput: DataUnitSetupStopInputOptions,
    stopMode: DataUnitSetupStopModeOptions,
    minimalAmountOfPulses: number,
    pulseTimeFrame: number,
    maximumPulseInterval: number,
    stopWindowSamples: number,
    stopWindowSampleRate: number,
    inputMask: string,
    invertMask: string
  ) {
    this._stopInput = stopInput;
    this._stopMode = stopMode;
    this._minimalAmountOfPulses = minimalAmountOfPulses;
    this._pulseTimeFrame = pulseTimeFrame;
    this._maximumPulseInterval = maximumPulseInterval;
    this._stopWindowSamples = stopWindowSamples;
    this._stopWindowSampleRate = stopWindowSampleRate;
    this._inputMask = inputMask;
    this._invertMask = invertMask;
  }

  public get stopInput(): DataUnitSetupStopInputOptions {
    return this._stopInput;
  }

  public set stopInput(value: DataUnitSetupStopInputOptions) {
    this._stopInput = value;
  }

  public get stopMode(): DataUnitSetupStopModeOptions {
    return this._stopMode;
  }

  public set stopMode(value: DataUnitSetupStopModeOptions) {
    this._stopMode = value;
  }

  public get minimalAmountOfPulses(): number {
    return this._minimalAmountOfPulses;
  }

  public set minimalAmountOfPulses(value: number) {
    this._minimalAmountOfPulses = value;
  }

  public get pulseTimeFrame(): number {
    return this._pulseTimeFrame;
  }

  public set pulseTimeFrame(value: number) {
    this._pulseTimeFrame = value;
  }

  public get maximumPulseInterval(): number {
    return this._maximumPulseInterval;
  }

  public set maximumPulseInterval(value: number) {
    this._maximumPulseInterval = value;
  }

  public get stopWindowSamples(): number {
    return this._stopWindowSamples;
  }

  public set stopWindowSamples(value: number) {
    this._stopWindowSamples = value;
  }

  public get stopWindowSampleRate(): number {
    return this._stopWindowSampleRate;
  }

  public set stopWindowSampleRate(value: number) {
    this._stopWindowSampleRate = value;
  }

  public get inputMask(): string {
    return this._inputMask;
  }

  public set inputMask(value: string) {
    this._inputMask = value;
  }

  public get invertMask(): string {
    return this._invertMask;
  }

  public set invertMask(value: string) {
    this._invertMask = value;
  }

  public static fromJSON(digitalInputConfigJSON: any): DigitalInputConfig {
    return new DigitalInputConfig(
      digitalInputConfigJSON?.stopInput,
      digitalInputConfigJSON?.stopMode,
      digitalInputConfigJSON?.minimalAmountOfPulses,
      digitalInputConfigJSON?.pulseTimeFrame,
      digitalInputConfigJSON?.maximumPulseInterval,
      digitalInputConfigJSON?.stopWindowSamples,
      digitalInputConfigJSON?.stopWindowSampleRate,
      digitalInputConfigJSON?.inputMask,
      digitalInputConfigJSON?.invertMask
    );
  }

  public toJSON(): JSON {
    return {
      stopInput: this._stopInput,
      stopMode: this._stopMode,
      minimalAmountOfPulses: this._minimalAmountOfPulses,
      pulseTimeFrame: this._pulseTimeFrame,
      maximumPulseInterval: this._maximumPulseInterval,
      stopWindowSamples: this._stopWindowSamples,
      stopWindowSampleRate: this._stopWindowSampleRate,
      inputMask: this._inputMask,
      invertMask: this._invertMask
    } as any as JSON;
  }
}
