<div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
  <div class="b2 regular">{{ labelTranslationKey | bmsTranslate }}</div>

  <div fxLayout="column" fxLayoutAlign="start start">
    <mat-radio-group vdwRadioToggleButton fxLayout="row" [value]="selectedUnitType" (change)="onUnitTypeChanged($event.value)">
      <mat-radio-button [value]="unitTypes.TECHNICAL">{{ technicalDimensionsTranslationKey | bmsTranslate }}</mat-radio-button>
      <mat-radio-button [value]="unitTypes.COMMERCIAL">{{ commercialDimensionsTranslationKey | bmsTranslate }}</mat-radio-button>
    </mat-radio-group>

    <button mat-stroked-button (click)="viewAllSettings()">{{ 'GENERAL.ACTIONS.VIEW_ALL_SETTINGS' | bmsTranslate }}</button>
  </div>
</div>
