import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNumberOfCreelPositionsData} from '@application/helper/navigation-helper/navigation-number-of-creel-positions-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {WeaveStructureColumnPipe} from '@application/pipes/weave-structure-column.pipe';
import {PropertyValue} from '@domain/property-value';
import {CarpetWeaveQuality} from '@domain/textile-data/machine-quality/carpet-weave-quality';
import {GetAllMachineQualitiesParameters, MACHINE_QUALITIES, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {NavigationMachineOverviewData} from '@presentation/pages/machine-overview/navigation-machine-overview-data.interface';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {
  AgGridRowSelection,
  AgGridUtils,
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  SelectGridDialog,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  ICellRendererParams,
  ITooltipParams,
  RowDataUpdatedEvent,
  RowDoubleClickedEvent,
  RowNode,
  RowSelectedEvent,
  ValueGetterParams
} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {isEmpty, isNil, toLower} from 'lodash-es';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map as rxjsMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-select-machine-quality',
  templateUrl: './select-machine-quality.component.html',
  styleUrls: ['./select-machine-quality.component.scss']
})
export class SelectMachineQualityComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('machineQualitiesGrid') public machineQualitiesGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfMachineQualities: OverviewListMachineQuality[];
  public title: string;
  public selectedMachineQualities: OverviewListMachineQuality[];

  private readonly rowSelection = AgGridRowSelection.MULTIPLE;
  private readonly numberOfCreelPositions: number;
  private readonly allowEmptySelection: boolean;
  private readonly listOfCustomSettings = new BehaviorSubject<PropertyValue[]>([]);
  private readonly machineId: number;
  private readonly machineQualityTranslationKey = 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY';
  private readonly filterItems: (overview: OverviewListMachineQuality[]) => OverviewListMachineQuality[];

  public constructor(
    @Inject(MACHINE_QUALITIES) public readonly machineQualities: MachineQualities,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SelectMachineQualityComponent>,
    private readonly l10nIntlService: L10nIntlService,
    private readonly translate: TranslateService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly weaveStructureColumnPipe: WeaveStructureColumnPipe,
    private readonly navigationHelperService: NavigationHelperService<NavigationSelectMachineQualityData>
  ) {
    super();
    this.selectedMachineQualities = data.selectedQualities ?? [];
    this.listOfMachineQualities = data.machineQualities;
    this.rowSelection = data.rowSelection;
    this.numberOfCreelPositions = data.numberOfCreelPositions;
    this.title = data.title;
    this.allowEmptySelection = data.allowEmptySelection;
    this.machineId = data.machineId;
    this.filterItems = data.filterItems;
  }

  public get gridOptionsForListOfMachineQualities(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfMachineQualities();
    this.listOfCustomSettings
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        filter((propertyValues: PropertyValue[]) => !AssertionUtils.isEmpty(propertyValues))
      )
      .subscribe(() => {
        this.getMachineQualities();
      });
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.machineQualitiesGrid?.api];
  }

  public canSelectMachineQualities(): boolean {
    let canSelectMachineQualities = false;

    if (!isEmpty(this.listOfMachineQualities)) {
      if (this.allowEmptySelection) {
        canSelectMachineQualities = true;
      } else if (!AssertionUtils.isNullOrUndefined(this.machineQualitiesGrid?.api)) {
        canSelectMachineQualities = this.machineQualitiesGrid.api.getSelectedRows().length > 0;
      }
    }

    return canSelectMachineQualities;
  }

  public selectMachineQualities(): void {
    this.dialogRef.close(this.isMultiRowSelectionAllowed() ? this.selectedMachineQualities : this.machineQualitiesGrid.api.getSelectedRows());
  }

  public isMultiRowSelectionAllowed(): boolean {
    return this.rowSelection === AgGridRowSelection.MULTIPLE;
  }

  public showOnlyLatestVersionChanged(change: boolean): void {
    this.getMachineQualities(change);
  }

  public navigateToAddQuality(): void {
    const data = {
      dialogComponent: SelectMachineQualityComponent,
      ...(this.machineId ? ({equipmentId: this.machineId} as NavigationMachineOverviewData) : ({numberOfCreelPositions: this.numberOfCreelPositions} as NavigationNumberOfCreelPositionsData))
    };
    this.navigationHelperService.navigateToNextRouteWithPartialState(data, RouteUtils.paths.texStyle.machineQuality.addMachineQuality.absolutePath);
  }

  private setGridOptionsForListOfMachineQualities(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfMachineQualities(), GridIdentifier.SELECT_MACHINE_QUALITY)
        .withRowSelection(this.isMultiRowSelectionAllowed(), true, this.isMultiRowSelectionAllowed())
        .withOnRowSelected((event: RowSelectedEvent) => this.updateSelectedMachineQualities(event))
        .withOnGridReady(() => {
          this.getListOfCustomSettings().subscribe((listOfCustomSettings: PropertyValue[]) => {
            this.listOfCustomSettings.next(listOfCustomSettings);
          });
        })
        .withOnRowDoubleClicked((row: RowDoubleClickedEvent) => {
          if (!this.isMultiRowSelectionAllowed()) {
            if (!row.node.isSelected()) {
              row.node.setSelected(true);
            }
            this.selectMachineQualities();
          }
        })
        .withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => this.selectSelectedMachineQualities(api))
        .withLoadingOverlay({
          scale: 0.7
        })
        .withNoRowsOverlay({
          scale: 0.7,
          ...this.getNoRowsOverlayTitleParameters(),
          hideDescription: true
        })
        .withRememberStateForNavigationHelper()
        .withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => {
          this.selectSelectedMachineQualities(api);
        })
        .build()
    ];
  }

  private getColumnDefsForListOfMachineQualities(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('technicalNameWithVersion', true).withHeaderName('GENERAL.TECHNICAL_NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('weaveStructure')
        .withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams) => this.getValueForWeaveStructureCell(params.data), true)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('reedDensityInDentsPerMM')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.REEDDENSITY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.DENTS_PER_MILLIMETER, 'reedDensityUnit'))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.DENTS_PER_MILLIMETER, 'reedDensityUnit'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('pickDensityInPicksPerMM')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.PICKDENSITY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.PICKS_PER_MILLIMETER, 'pickDensityUnit'))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.PICKS_PER_MILLIMETER, 'pickDensityUnit'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('practicalPickDensityInPicksPerMM')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.PRACTICALPICKDENSITY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.PICKS_PER_MILLIMETER, 'pickDensityUnit'))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.PICKS_PER_MILLIMETER, 'pickDensityUnit'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('weftDensityInDentsPerMM')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.WEFTDENSITY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.WEFTS_PER_MILLIMETER, 'weftDensityUnit'))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.WEFTS_PER_MILLIMETER, 'weftDensityUnit'))
        .build()
    ];
  }

  private hasNumberOfCreelPositions(): boolean {
    return !isNil(this.numberOfCreelPositions);
  }

  private getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.machineQualities.getListOfCustomSettings().pipe(takeUntil(this.unSubscribeOnViewDestroy));
  }

  private buildAgGridCellTextWithUnit(value: number, defaultUnit: Unit, customSettingsUnitPropertyName: string): string {
    const customSettingsUnitProperty: PropertyValue = this.listOfCustomSettings.value.find((propertyValue: PropertyValue) => propertyValue.propertyName === customSettingsUnitPropertyName);
    let customSettingsUnit: Unit = defaultUnit;
    if (!isNil(customSettingsUnitProperty)) {
      customSettingsUnit = Unit[customSettingsUnitProperty.propertyValue as string];
    }
    return AgGridUtils.buildAgGridCellTextWithUnitConversion(value, defaultUnit, customSettingsUnit, this.l10nIntlService);
  }

  private buildAgGridCellTooltipWithUnit(value: number, defaultUnit: Unit, customSettingsUnitPropertyName: string): string {
    const customSettingsUnitProperty: PropertyValue = this.listOfCustomSettings.value.find((propertyValue: PropertyValue) => propertyValue.propertyName === customSettingsUnitPropertyName);
    let customSettingsUnit: Unit = defaultUnit;
    if (!isNil(customSettingsUnitProperty)) {
      customSettingsUnit = Unit[customSettingsUnitProperty.propertyValue as string];
    }
    return AgGridUtils.buildAgGridCellTooltipWithUnitConversion(value, defaultUnit, customSettingsUnit, this.l10nIntlService);
  }

  private getNoRowsOverlayTitleParameters(): Partial<NoDataOverlayComponentParams> {
    const parameters: Partial<NoDataOverlayComponentParams> = {titleKey: null, titleParam: null};

    if (!AssertionUtils.isNullOrUndefined(this.machineId)) {
      parameters.titleKey = 'PLANNING.ADD_ITEM.MACHINE_NO_OBJECT_SELECTED';
      parameters.titleParam = {object: toLower(this.translate.instant('GENERAL.POSSIBLE_OBJECTS', {objects: this.translate.instant(this.machineQualityTranslationKey, {count: 2})}))};
    } else if (this.hasNumberOfCreelPositions()) {
      parameters.titleKey = 'TEXTILE_DATA.MACHINE_QUALITY.OVERLAY.NO_MACHINE_QUALITIES_OF_POSITIONS';
      parameters.titleParam = {numberOfCreelPositions: this.numberOfCreelPositions};
    } else {
      parameters.titleParam = this.machineQualityTranslationKey;
    }

    return parameters;
  }

  private getValueForWeaveStructureCell(machineQuality: OverviewListMachineQuality): string {
    return this.weaveStructureColumnPipe.transform(machineQuality);
  }

  private getMachineQualities(showOnlyLatestVersion: boolean = true): void {
    let request: Observable<OverviewListMachineQuality[]>;

    if (!AssertionUtils.isNullOrUndefined(this.machineId)) {
      request = this.machineQualities.getPossibleMachineQualitiesForMachine(this.machineId, {showOnlyLatestVersion}).pipe(
        rxjsMap((carpetWeaveQualities: CarpetWeaveQuality[]) => {
          return carpetWeaveQualities.map((carpetWeaveQuality: CarpetWeaveQuality) => {
            return OverviewListMachineQuality.fromCarpetWeaveQuality(carpetWeaveQuality);
          });
        })
      );
    } else if (this.hasNumberOfCreelPositions()) {
      const parameters: Partial<GetAllMachineQualitiesParameters> = {numberOfCreelPositions: this.numberOfCreelPositions, showOnlyLatestVersion};

      request = this.machineQualities.getAll(parameters);
    } else {
      const parameters: Partial<GetAllMachineQualitiesParameters> = {showOnlyLatestVersion};

      request = this.machineQualities.getAll(parameters);
    }

    request.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((machineQualities: OverviewListMachineQuality[]) => {
      this.listOfMachineQualities = this.filterItems ? this.filterItems(machineQualities) : machineQualities;
    });
  }

  private selectSelectedMachineQualities(gridApi: GridApi): void {
    gridApi.forEachNode((node: RowNode) => {
      node.setSelected(this.isMachineQualitySelected(node));
    });
  }

  private updateSelectedMachineQualities(event: RowSelectedEvent): void {
    if (!event.node.isSelected() && this.listOfMachineQualities.some((machineQuality: OverviewListMachineQuality) => machineQuality.id === event.data.id)) {
      this.selectedMachineQualities = this.selectedMachineQualities.filter((currentSelectedMachineQuality: OverviewListMachineQuality) => currentSelectedMachineQuality?.id !== event.data.id);
    } else {
      if (!this.isMachineQualitySelected(event)) {
        this.selectedMachineQualities.push(event.data);
      }
    }
    if (this.rowSelection === AgGridRowSelection.MULTIPLE) {
      this.selectSelectedMachineQualities(event.api);
    }
  }

  private isMachineQualitySelected(data: RowSelectedEvent | RowNode): boolean {
    return this.selectedMachineQualities.some((selectedMachineQuality: OverviewListMachineQuality) => {
      return selectedMachineQuality?.id === data.data.id;
    });
  }
}

interface NavigationSelectMachineQualityData {
  dialogComponent: typeof SelectMachineQualityComponent;
}
