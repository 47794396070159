import {IdName} from '@domain/id-name';
import {NumberingMode} from '@domain/machine/numbering-mode.enum';
import {StandardSpeed} from '@domain/machine/standard-speed.enum';
import {ToolStatus} from '@domain/machine/tool-status.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {MatchingCriteria} from './matching-criteria';
import {ToolType} from './tool-type.enum';

export class Tool {
  private _id: number;
  private _name: string;
  private _toolType: ToolType;
  private _weightInKg: number;
  private _speed: number;
  private _speedUnit: StandardSpeed;
  private _description: string;
  private _cavities: number;
  private _usedCavities: number;
  private _numberingMode: NumberingMode;
  private _cavitiesAcross: number;
  private _cavitiesDown: number;
  private _pieceCycles: number;
  private _waiveCycles: number;
  private _mountTimeInMinutes: number;
  private _dismountTimeInMinutes: number;
  private _status: ToolStatus;
  private _stockLocation: IdName;
  private _matchingCriteria: MatchingCriteria[];

  public constructor(
    id: number,
    name: string,
    toolType: ToolType,
    weightInKg: number,
    speed: number,
    speedUnit: StandardSpeed,
    description: string,
    cavities: number,
    usedCavities: number,
    numberingMode: NumberingMode,
    cavitiesAcross: number,
    cavitiesDown: number,
    pieceCycles: number,
    waiveCycles: number,
    mountTimeInMinutes: number,
    dismountTimeInMinutes: number,
    status: ToolStatus,
    stockLocation: IdName,
    matchingCriteria: MatchingCriteria[] = []
  ) {
    this._id = id;
    this._name = name;
    this._toolType = toolType;
    this._weightInKg = weightInKg;
    this._speed = speed;
    this._speedUnit = speedUnit;
    this._description = description;
    this._cavities = cavities;
    this._usedCavities = usedCavities;
    this._numberingMode = numberingMode;
    this._cavitiesAcross = cavitiesAcross;
    this._cavitiesDown = cavitiesDown;
    this._pieceCycles = pieceCycles;
    this._waiveCycles = waiveCycles;
    this._mountTimeInMinutes = mountTimeInMinutes;
    this._dismountTimeInMinutes = dismountTimeInMinutes;
    this._status = status;
    this._stockLocation = stockLocation;
    this._matchingCriteria = matchingCriteria;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get toolType(): ToolType {
    return this._toolType;
  }

  public set toolType(value: ToolType) {
    this._toolType = value;
  }

  public get weightInKg(): number {
    return this._weightInKg;
  }

  public set weightInKg(value: number) {
    this._weightInKg = value;
  }

  public get speed(): number {
    return this._speed;
  }

  public set speed(value: number) {
    this._speed = value;
  }

  public get speedUnit(): StandardSpeed {
    return this._speedUnit;
  }

  public set speedUnit(value: StandardSpeed) {
    this._speedUnit = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get cavities(): number {
    return this._cavities;
  }

  public set cavities(value: number) {
    this._cavities = value;
  }

  public get usedCavities(): number {
    return this._usedCavities;
  }

  public set usedCavities(value: number) {
    this._usedCavities = value;
  }

  public get numberingMode(): NumberingMode {
    return this._numberingMode;
  }

  public set numberingMode(value: NumberingMode) {
    this._numberingMode = value;
  }

  public get cavitiesAcross(): number {
    return this._cavitiesAcross;
  }

  public set cavitiesAcross(value: number) {
    this._cavitiesAcross = value;
  }

  public get cavitiesDown(): number {
    return this._cavitiesDown;
  }

  public set cavitiesDown(value: number) {
    this._cavitiesDown = value;
  }

  public get pieceCycles(): number {
    return this._pieceCycles;
  }

  public set pieceCycles(value: number) {
    this._pieceCycles = value;
  }

  public get waiveCycles(): number {
    return this._waiveCycles;
  }

  public set waiveCycles(value: number) {
    this._waiveCycles = value;
  }

  public get mountTimeInMinutes(): number {
    return this._mountTimeInMinutes;
  }

  public set mountTimeInMinutes(value: number) {
    this._mountTimeInMinutes = value;
  }

  public get dismountTimeInMinutes(): number {
    return this._dismountTimeInMinutes;
  }

  public set dismountTimeInMinutes(value: number) {
    this._dismountTimeInMinutes = value;
  }

  public get status(): ToolStatus {
    return this._status;
  }

  public set status(value: ToolStatus) {
    this._status = value;
  }

  public get stockLocation(): IdName {
    return this._stockLocation;
  }

  public set stockLocation(value: IdName) {
    this._stockLocation = value;
  }

  public get matchingCriteria(): MatchingCriteria[] {
    return this._matchingCriteria;
  }

  public set matchingCriteria(value: MatchingCriteria[]) {
    this._matchingCriteria = value;
  }

  public static fromJSON(toolJSON: any): Tool {
    return new Tool(
      toolJSON.id,
      toolJSON.name,
      toolJSON.toolType,
      toolJSON.weightInKg,
      toolJSON.standardCycleTimeInSeconds,
      toolJSON.speedUnit,
      toolJSON.description,
      toolJSON.originalCavities,
      toolJSON.nrCavities,
      toolJSON.cavityNumberingMode,
      toolJSON.cavityAcross,
      toolJSON.cavityDown,
      toolJSON.pieceCycles,
      toolJSON.waiveCycles,
      toolJSON.mountTimeInMinutes,
      toolJSON.dismountTimeInMinutes,
      toolJSON.toolStatus,
      AssertionUtils.isNullOrUndefined(toolJSON.stockLocation) ? null : IdName.fromJSON(toolJSON.stockLocation),
      toolJSON.toolMatchingCriteria.map((matchingCriteriaJSON: any) => MatchingCriteria.fromJSON(matchingCriteriaJSON))
    );
  }

  public toJSON(): JSON {
    return {
      name: this._name,
      toolType: this._toolType,
      weightInKg: this._weightInKg,
      standardCycleTimeInSeconds: this._speed,
      speedUnit: this._speedUnit,
      description: this._description,
      originalCavities: this._cavities,
      nrCavities: this._usedCavities,
      cavityNumberingMode: this._numberingMode,
      cavityAcross: this._cavitiesAcross,
      cavityDown: this._cavitiesDown,
      pieceCycles: this._pieceCycles,
      waiveCycles: this._waiveCycles,
      mountTimeInMinutes: this._mountTimeInMinutes,
      dismountTimeInMinutes: this._dismountTimeInMinutes,
      toolStatus: this._status,
      stockLocationId: this._stockLocation?.id,
      toolMatchingCriteria: this._matchingCriteria.map((matchingCriteria: MatchingCriteria) => matchingCriteria.toJSON())
    } as any as JSON;
  }
}
