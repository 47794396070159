import {Component, Inject} from '@angular/core';
import {RuntimeApplicationEnvironment, RUNTIME_APPLICATION_ENVIRONMENT} from '@application/configuration/runtime-application-environment';
import {BaseSignTermsAndConditionsComponent} from '../base-sign-terms-and-conditions.component';

@Component({
  selector: 'app-sign-terms-and-conditions-desktop',
  templateUrl: './sign-terms-and-conditions-desktop.component.html',
  styleUrls: ['./sign-terms-and-conditions-desktop.component.scss']
})
export class SignTermsAndConditionsDesktopComponent extends BaseSignTermsAndConditionsComponent {
  public constructor(@Inject(RUNTIME_APPLICATION_ENVIRONMENT) protected readonly runtimeEnvironment: RuntimeApplicationEnvironment) {
    super(runtimeEnvironment);
  }
}
