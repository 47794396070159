<button type="button" mat-icon-button [class.z-index-4]="showSettingsDialog()" [class.open]="showSettingsDialog()" tabindex="-1" (click)="toggleSettingsDialog()">
  <mat-icon class="icon" svgIcon="solid-more-vert" />
</button>

@if (showSettingsDialog()) {
  <div class="invisible-overlay z-index-3" (click)="toggleSettingsDialog()"></div>
  <div class="settings-dialog z-index-4">
    <!--add extra section for extra actions in the future-->
    <section>
      <button mat-stroked-button (click)="customSettingsButtonClicked.emit(); toggleSettingsDialog()">{{ 'GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS' | bmsTranslate }}</button>
    </section>
  </div>
}
