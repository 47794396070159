<div class="full-width bms-theme flex-column grid-with-actions-container">
  @if (canShowActions) {
    <div class="actions">
      <div class="flex-row gap-8">
        <button
          type="button"
          mat-icon-button
          [matTooltip]="'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EXPORT' | bmsTranslate"
          matTooltipPosition="above"
          matTooltipClass="bms-theme"
          tabindex="-1"
          (click)="exportData()"
        >
          <mat-icon class="icon" svgIcon="solid-download"></mat-icon>
        </button>
        @if (showCustomSettingsButton) {
          <vdw-settings-icon-button [showCustomSettingsButton]="true" (customSettingsButtonClicked)="showCustumSettings.emit()" />
        }
        @if (withNewClicked) {
          <button class="action-button" mat-flat-button (click)="withNewClicked($event)">
            {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.NEW' | bmsTranslate }}
          </button>
        }
      </div>
    </div>
  }

  <div #gridContainer class="full-width auto-height-grid-container">
    <ag-grid-angular #grid class="full-width full-height ag-theme-vdw ag-theme-vdw ag-auto-grow-grid-height" [rowData]="rowData" [gridOptions]="gridOptions"></ag-grid-angular>
  </div>
</div>
