import {Location} from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {EventAlert} from '@domain/event/event-alert';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {CompanyWeaveStructure} from '@domain/textile-data/company-weave-structure/company-weave-structure';
import {Finishing} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing';
import {PageUrls} from '@domain/textile-data/page-urls';
import {OverviewListTextileData, TextileDataService} from '@domain/textile-data/textile-data';
import {Translations} from '@domain/textile-data/translations';
import {WeavePattern} from '@domain/textile-data/weave-structure/weave-pattern/weave-pattern';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {SearchFiltersComponent} from '@presentation/components/search-filters/search-filters.component';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {AssertionUtils, BaseComponent, FilterComponent, LoadingCellOverlayComponentParams, MobileColDef, RESPONSIVENESS_VIEW_MODE, ResponsivenessViewMode, Unit} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {GridApi, GridOptions, IMultiFilterModel, IServerSideDatasource, RowClassParams, RowNode} from 'ag-grid-community';
import {find, get, isEqual} from 'lodash-es';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {TextileService} from './textile.service';

@Component({
  selector: 'app-textile-data-overview',
  templateUrl: './textile-data-overview.component.html',
  styleUrls: ['./textile-data-overview.component.scss']
})
export class TextileDataOverviewComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('textileDataOverviewGrid') public textileDataOverviewGrid: AgGridAngular;
  @ViewChild('filterComponent') public filterComponent: FilterComponent;
  @ViewChild('searchFilters') public searchFilters: SearchFiltersComponent;

  @Input() public iconForSearch = 'search';
  @Input() public iconForCard: string;
  @Input() public columnDefs: MobileColDef[];
  @Input() public textileDataService: TextileDataService;
  @Input() public autoSizeAllColumns: boolean;
  @Input() public textileDataType: TextileDataType;
  @Input() public rowHeight: (params) => number = null;
  @Input() public infiniteGrid = false;
  @Input() public advancedSearchFilters: PropertyValue[] = [];
  @Input() public cacheBlockSize = 1;
  @Input() public pickDensityUnit: Unit;
  @Input() public reedDensityUnit: Unit;
  @Input() public canAddItem = true;
  @Input() public disableAddButton = false;
  @Input() public canEditItem = true;
  @Input() public disableEditButton = false;
  @Input() public canDeleteItem = true;
  @Input() public disableDeleteButton = false;
  @Input() public canDuplicateItem = true;
  @Input() public disableDuplicateButton = false;
  @Input() public canExportGrid = true;
  @Input() public isExporting = false;
  @Input() public managedRoute = false;
  @Input() public showHeader = true;
  @Input() public dataSource: IServerSideDatasource;
  @Input() public gridIdentifier: GridIdentifier;
  @Input() public canShowCustomSettings: boolean;
  @Input() public disableActionButton: boolean;
  @Input() public gridResizable = true;
  @Input() public showSearchBar = true;
  @Input() public getRowClass: (params: RowClassParams) => string | string[];
  @Input() public rememberScrollPosition = false;
  @Input() public exportGridApi: GridApi;
  @Input() public settingsTooltip = 'GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS';
  @Input() public multiSelection = false;

  @Output() public customSettingsSetup = new EventEmitter<void>();
  @Output() public rowsSelected = new EventEmitter<any>();
  @Output() public reloadData = new EventEmitter<PropertyValue[]>();
  @Output() public loadData = new EventEmitter<void>();
  @Output() public addItem = new EventEmitter<void>();
  @Output() public editItem = new EventEmitter<WeavePattern | CompanyWeaveStructure | EventAlert | Finishing>();
  @Output() public duplicateItem = new EventEmitter<EventAlert | Finishing>();
  @Output() public deleteItem = new EventEmitter<number | string>();
  @Output() public showOnlyLatestVersionChanged = new EventEmitter<boolean>();
  @Output() public getAllExportData = new EventEmitter<IMultiFilterModel>();
  @Output() public exportGridWithComplexColumns = new EventEmitter<GridApi>();

  public gridOptionsForTextileDataOverview: GridOptions;
  public permissionToModifyItems: Permission;
  public textileDataCountUpOptions = {
    duration: 1,
    formattingFn: (n: number): string => `(${n})`
  };

  public selectedTextileDataItems: OverviewListTextileData[] = [];
  public canShowBackButton = true;

  private pageUrls: PageUrls;
  private translations: Translations;
  private _listOfTextileData: any[];
  private currentSubscription: Subscription;

  private readonly filterChangeSubject = new Subject<string>();
  private readonly _amountOfTotalCreatedTextileDataItemsSubject = new BehaviorSubject(0);

  public constructor(
    protected readonly router: Router,
    protected readonly location: Location,
    protected readonly textileService: TextileService,
    @Inject(AUTHENTICATION) private authentication: Authentication,
    @Inject(RESPONSIVENESS_VIEW_MODE) protected readonly responsivenessViewMode: ResponsivenessViewMode,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  @Input() public set listOfTextileData(listOfTextileData: any[]) {
    this._listOfTextileData = listOfTextileData;
    this._amountOfTotalCreatedTextileDataItemsSubject.next(listOfTextileData?.length ?? 0);
  }

  public get listOfTextileData(): any[] {
    return this._listOfTextileData;
  }

  public get amountOfTotalCreatedTextileDataItemsSubject(): Observable<number> {
    return this._amountOfTotalCreatedTextileDataItemsSubject.asObservable();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.initialiseTextileDataOverview(this.textileDataType);
    this.gridOptionsForTextileDataOverview = this.textileService.initialiseGridOptionsForTextileDataOverview(
      this.infiniteGrid,
      this.cacheBlockSize,
      this.rowHeight,
      this.autoSizeAllColumns,
      this.selectedTextileDataItems,
      this.rowsSelected,
      this.loadData,
      this.responsivenessViewMode,
      this.columnDefs,
      this.textileDataType,
      this.advancedSearchFilters,
      this.isTextileDataTypeProductionSchedule(),
      this.changeDetectorRef,
      this.gridIdentifier,
      this.dataSource,
      this.disableActionButton,
      this.gridResizable,
      this.getRowClass,
      this.rememberScrollPosition,
      this.multiSelection
    );
    this.textileService.subscribeToDeviceChanges(this.responsivenessViewMode, this.columnDefs);
    this.canShowCustomSettingsSetup();
    this.canShowBackButton = !history?.state?.isRedirect ?? true;
  }

  public ngAfterViewInit(): void {
    this.textileService.subscribeToFilterChanges(
      this.filterChangeSubject,
      this.iconForSearch,
      this.isTextileDataTypeProductionSchedule(),
      this.advancedSearchFilters,
      this.searchFilters,
      this.reloadData,
      () => this.hideOverlay()
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.textileService.updateGrid(changes, this.rowsSelected, () => this.hideOverlay(), this.changeDetectorRef, this.selectedTextileDataItems);
  }

  public ngOnDestroy(): void {
    this.textileService.resetGridModelForTextileDataOverviewFromService();
  }

  public removeConfirmation(): void {
    const selectedRows = this.textileDataOverviewGrid.api.getSelectedRows();

    this.textileService.removeConfirmation(
      selectedRows,
      this.textileDataType,
      this.managedRoute,
      this.deleteItem,
      this.textileDataService,
      this.selectedTextileDataItems,
      this.getTranslations(),
      this.reloadData,
      this.advancedSearchFilters,
      this.infiniteGrid,
      this.rowsSelected,
      this._amountOfTotalCreatedTextileDataItemsSubject,
      this.multiSelection
    );
  }

  public isShowLatestVersionActive(): boolean {
    return this.showOnlyLatestVersionChanged.observed;
  }

  public canShowOnlyLatestVersionChanged(change: boolean): void {
    this.showOnlyLatestVersionChanged.emit(change);
  }

  public onAdd(): void {
    this.textileService.executeActionForItem('add', undefined, this.textileDataType, this.addItem, this.managedRoute);
  }

  public onEdit(): void {
    if (this.textileDataType === TextileDataType.PRODUCTION_SCHEDULE) {
      let pageUrl = LastModifiedCardUtils.getPageUrls(this.textileDataType)['edit'];
      if (this.hasPermissionToNavigateToNewBuilder()) {
        pageUrl = pageUrl + '/new';
      }
      this.router.navigate([pageUrl.replace(':id', this.getSelectedItem().id.toString())]);
    } else {
      this.textileService.executeActionForItem('edit', this.getSelectedItem().id, this.textileDataType, this.editItem);
    }
  }

  public onDuplicate(): void {
    this.textileService.executeActionForItem('duplicate', this.getSelectedItem().id, this.textileDataType, this.duplicateItem);
  }

  public hasEditPermission(): boolean {
    return this.currentSubscription?.hasPermission(this.permissionToModifyItems);
  }

  public canShowCustomSettingsSetup(): void {
    if (LastModifiedCardUtils.hasCustomSettings(this.textileDataType) && this.canShowCustomSettings !== false) {
      this.canShowCustomSettings = true;
    }
  }

  public showCustomSettingsSetup(): void {
    this.customSettingsSetup.emit(null);
  }

  public filter(event: string): void {
    this.filterChangeSubject.next(event);
  }

  public getPageUrl(type: string): string {
    return this.pageUrls[type];
  }

  public getSelectedItem(): OverviewListTextileData {
    return this.textileDataOverviewGrid.api.getSelectedRows()?.[0];
  }

  public getTranslations(): Translations {
    return this.translations;
  }

  public isSingleTextileDataRowSelected(): boolean {
    return this.textileDataOverviewGrid?.api.getSelectedRows()?.length === 1;
  }

  public canDisableDeleteButton(): boolean {
    return this.textileService.canDisableDeleteButton(this.disableDeleteButton, this.textileDataType, this.isSingleTextileDataRowSelected(), this.getSelectedItem());
  }

  public canDuplicateSelectedRow(): boolean {
    return this.isSingleTextileDataRowSelected() && this.getSelectedItem()?.canDuplicate();
  }

  public getReasonForPreventingDuplicate(): string {
    return this.isSingleTextileDataRowSelected() ? this.getSelectedItem()?.getReasonForPreventingDuplicate() : '';
  }

  public canShowTotalCreatedTextileDataItems(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.listOfTextileData);
  }

  public canShowDeleteActionButton(): boolean {
    return this.textileDataType !== TextileDataType.COMPANY_WEAVE_STRUCTURE;
  }

  public getTotalCreatedTextileDataItems(): number {
    return this.listOfTextileData?.length ?? 0;
  }

  public canShowActionButton(pageUrl: string): boolean {
    return !AssertionUtils.isNullOrUndefined(get(this.pageUrls, pageUrl));
  }

  public showAdvancedSearch(): void {
    this.textileService
      .showAdvancedSearch(
        this.iconForSearch,
        this.isTextileDataTypeProductionSchedule(),
        this.advancedSearchFilters,
        this.filterComponent,
        this.pickDensityUnit,
        this.reedDensityUnit,
        this.searchFilters
      )
      .then(() => {
        this.updateFiltered();
      });
  }

  public canShowSearchFilters(): boolean {
    return !isEqual(this.iconForSearch, 'search') && this.showSearchBar;
  }

  public updateFiltered(): void {
    this.setFilterComponentText();

    this.reloadData.emit(this.advancedSearchFilters);
  }

  public getDefaultUnit(): Unit {
    return Unit.CENTIMETER;
  }

  public ensureIndexIsVisible(index: number): void {
    this.textileService.ensureIndexVisible(index);
  }

  public showNoRowsOverlay(): void {
    this.textileService.showNoRowsOverlay();
  }

  public refreshServerSide(purge: boolean): void {
    this.textileService.refreshServerSide(purge);
  }

  public setHeaderNameForColumn(columnName: string, headerName: string): void {
    this.textileService.setHeaderNameForColumn(columnName, headerName);
  }

  public refreshHeader(): void {
    this.textileService.refreshHeader();
  }

  public redrawRows(): void {
    this.textileService.redrawRows();
  }

  public setLoadingCellRendererParams(loadingCellRendererParams: LoadingCellOverlayComponentParams): void {
    this.textileService.setLoadingCellRendererParams(loadingCellRendererParams);
  }

  public forEachNode(callback: (node: RowNode, index: number) => void): void {
    this.textileService.getGridApi().forEachNode(callback);
  }

  public getFilterModel(): any {
    return this.textileService.getGridApi().getFilterModel();
  }

  public setFilterModel(filterModel: any): void {
    this.textileService.getGridApi().setFilterModel(filterModel);
  }

  public hideOverlay(): void {
    this.textileService.hideOverlay();
  }

  public deselectRows(): void {
    this.textileService.deselectRows(this.rowsSelected, this.selectedTextileDataItems);
  }

  public initialiseTextileDataOverview(textileDataType: TextileDataType): void {
    this.pageUrls = LastModifiedCardUtils.getPageUrls(textileDataType);
    this.translations = LastModifiedCardUtils.getTranslations(textileDataType);
    this.permissionToModifyItems = LastModifiedCardUtils.getPermissionToModifyItems(textileDataType);
  }

  public isTextileDataTypeProductionSchedule(): boolean {
    return this.textileDataType === TextileDataType.PRODUCTION_SCHEDULE;
  }

  public isDeleteDisabled(): boolean {
    return (!this.isSingleTextileDataRowSelected() && !this.multiSelection) || (this.selectedTextileDataItems.length === 0 && this.multiSelection) || this.canDisableDeleteButton();
  }

  public exportGrid(): void {
    if (this.textileDataType === TextileDataType.PRODUCTION_SCHEDULE || this.textileDataType === TextileDataType.PRODUCT_CONFIGURATION) {
      this.getAllExportData.emit(this.textileService.getGridApi().getFilterModel() as IMultiFilterModel);
    }

    if (this.exportGridWithComplexColumns.observed) {
      this.exportGridWithComplexColumns.emit(this.textileService.getGridApi());
    } else if (this.textileDataType === TextileDataType.COLOR) {
      this.isExporting = true;
      this.textileService
        .exportAllData(this.textileService.getGridApi().getFilterModel() as IMultiFilterModel, this.exportGridApi, this.textileDataService, this.columnDefs)
        .then(() => (this.isExporting = false));
    } else {
      this.textileService.getGridApi().exportDataAsCsv();
    }
  }

  private setFilterComponentText(): void {
    if (this.filterComponent != null && this.isTextileDataTypeProductionSchedule()) {
      this.filterComponent.filteredText = find(this.advancedSearchFilters, ['propertyName', AdvancedSearchInput.NAME]).propertyValue;
      this.filterComponent.filteredText = this.advancedSearchFilters.find((propertyValue: PropertyValue) => propertyValue.propertyName === AdvancedSearchInput.NAME).propertyValue;
    }
  }

  private hasPermissionToNavigateToNewBuilder(): boolean {
    return this.currentSubscription?.hasPermission(Permission.TEXFAB_NEW_BUILDER);
  }
}
