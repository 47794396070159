import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {AdvancedSearchUtils} from '@application/helper/advanced-search-utils';
import {convertJSONToDrawing} from '@application/helper/drawing/convert-json-to-drawing';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingConfigurationWithColorAndYarnSet} from '@domain/production-schedule/drawing-configuration-with-color-and-yarn-set';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {DrawingInfo} from '@domain/production-schedule/drawing-info.interface';
import {DrawingRecolorInformation} from '@domain/production-schedule/drawing-recolor-information';
import {DrawingShapeAbbreviation} from '@domain/production-schedule/drawing-shape-abbreviation';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {PropertyValue} from '@domain/property-value';
import {TargetForListOfDrawingsEnum} from '@domain/target-for-list-of-drawings.enum';
import {ColoredYarnSetPosition} from '@domain/textile-data/creel/colored-yarn-set-position';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {UploadDrawingInfo} from '@domain/textile-data/drawing/upload-drawing-info';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {Drawings} from '@infrastructure/http/drawing/drawings';
import {GroupedDrawing} from '@presentation/pages/texfab/production-schedule/add/drawing-library/views/drawings-grouped-view/grouped-drawing';
import {Conflict, GridModel, NotImplementedError} from '@vdw/angular-component-library';
import {filter, includes, isNil, map} from 'lodash-es';
import {forkJoin, from, Observable, throwError} from 'rxjs';
import {retry, map as rxjsMap, switchMap} from 'rxjs/operators';

@Injectable()
export class HttpDrawingsService implements Drawings {
  public static LOAD_CONFIGURATIONS_HTTP_PARAMETER_NAME = 'load-configurations';
  public static VIEW_MODE_HTTP_PARAMETER_NAME = 'view-mode';
  private httpClient: HttpClient;
  private _uploadQueue: DrawingImage[] = [];
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public getAll(): Observable<Drawing[]> {
    return throwError(() => new NotImplementedError());
  }

  public getAllWithoutImageData(target: TargetForListOfDrawingsEnum, filters: PropertyValue[] = []): Observable<Drawing[]> {
    const params = new HttpParams().set(HttpDrawingsService.VIEW_MODE_HTTP_PARAMETER_NAME, 'list').set(HttpDrawingsService.LOAD_CONFIGURATIONS_HTTP_PARAMETER_NAME, false).set('target', target);
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}drawings/get`, AdvancedSearchUtils.getJsonBodyForAdvancedSearchFilters(filters), {params}).pipe(
      rxjsMap((drawingsJSON: any[]) => {
        return drawingsJSON.map(convertJSONToDrawing);
      })
    );
  }

  public getAllWithoutImageDataFromGridModel(
    target: TargetForListOfDrawingsEnum,
    gridModel: GridModel,
    loadConfigurations: boolean = false,
    viewMode: string = 'list',
    exclude?: string
  ): Observable<Drawing[]> {
    const params = new HttpParams()
      .set(HttpDrawingsService.VIEW_MODE_HTTP_PARAMETER_NAME, viewMode)
      .set(HttpDrawingsService.LOAD_CONFIGURATIONS_HTTP_PARAMETER_NAME, loadConfigurations)
      .set('target', target)
      .set('exclude', exclude ?? '');
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}drawings/get`, gridModel.toJSON(), {params}).pipe(
      rxjsMap((drawingsJSON: any[]) => {
        return drawingsJSON.map(convertJSONToDrawing);
      })
    );
  }

  public getSlice(
    startIndex: number,
    count: number,
    viewMode: string,
    loadConfiguration: boolean,
    target: TargetForListOfDrawingsEnum,
    filters: PropertyValue[] = [],
    sort?: {sort: string; colId: string}
  ): Observable<Drawing[]> {
    const params = new HttpParams()
      .set(HttpDrawingsService.VIEW_MODE_HTTP_PARAMETER_NAME, viewMode)
      .set(HttpDrawingsService.LOAD_CONFIGURATIONS_HTTP_PARAMETER_NAME, loadConfiguration)
      .set('target', target)
      .set('start', startIndex)
      .set('count', count)
      .set('sort', sort ? sort.colId : 'dateModified')
      .set('direction', sort ? sort.sort : 'DESC');

    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}drawings/get`, AdvancedSearchUtils.getJsonBodyForAdvancedSearchFilters(filters), {params}).pipe(
      rxjsMap((drawingsJSON: any) => {
        return drawingsJSON.map(convertJSONToDrawing);
      })
    );
  }

  public getById(id: number): Observable<Drawing> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}drawings/${id}`).pipe(rxjsMap((drawingJSON: any) => convertJSONToDrawing(drawingJSON)));
  }

  public getDrawing(drawingImageId: string, drawingImageSignature: string): Observable<Blob> {
    const params = new HttpParams().set('id', drawingImageId).set('s', drawingImageSignature);
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}drawings/image`, {params, responseType: 'blob'}).pipe(retry(2));
  }

  public save(drawing: Drawing): Observable<number> {
    return throwError(() => new NotImplementedError());
  }

  public update(drawing: Drawing): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}drawings/${drawing.id}`, drawing.toJSON());
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}drawings/${id}`);
  }

  public deleteMultiple(drawingIds: number[]): Observable<number[]> {
    return this.httpClient.request('delete', `${this.backendConfiguration.getEndpoint()}drawings`, {body: drawingIds}).pipe(
      rxjsMap((response: number[]) => {
        return response;
      })
    );
  }

  public getPossibleValues(columnIdentifier: string): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}drawings/${columnIdentifier}/possible-values`).pipe(rxjsMap((response: string[]) => response));
  }

  public getDrawingShapes(): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}drawings/shapes`).pipe(
      rxjsMap((response: string[]) => {
        return response;
      })
    );
  }

  public getListOfCustomSettings(): Observable<PropertyValue[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint(2)}drawings/custom-settings`).pipe(
      rxjsMap((customSettings: PropertyValue[]) => {
        customSettings.forEach((property: PropertyValue) => {
          if (property.propertyName === 'placeholderPartsForDrawingName') {
            property.propertyValue = property.propertyValue.map((placeholderPartJSON: any) => {
              return PlaceholderComponent.fromJSON(placeholderPartJSON);
            });
          } else if (property.propertyName === 'shapeAbbreviations') {
            property.possiblePropertyValues = property.propertyValue.map((propertyValue: any) => {
              return DrawingShapeAbbreviation.fromJSON(propertyValue);
            });
          }
        });

        return customSettings;
      })
    );
  }

  public saveCustomSettings(customSettings: PropertyValue[]): Observable<void> {
    const body = customSettings.map((propertyValue: PropertyValue) => {
      const result: PropertyValue = {
        propertyName: propertyValue.propertyName,
        propertyValue: propertyValue.propertyValue,
        possiblePropertyValues: undefined
      };

      if (propertyValue.propertyName === 'placeholderPartsForDrawingName') {
        result.propertyValue = propertyValue.propertyValue.map((placeholderComponent: PlaceholderComponent) => {
          return placeholderComponent.toFullJSON();
        });
      } else if (propertyValue.propertyName === 'shapeAbbreviations') {
        result.possiblePropertyValues = propertyValue.possiblePropertyValues.map((shapeAbbreviation: DrawingShapeAbbreviation) => {
          return shapeAbbreviation.toJSON();
        });
      } else if (propertyValue.propertyName === 'customShape') {
        result.possiblePropertyValues = propertyValue.possiblePropertyValues.map((customShape: DrawingShapeAbbreviation) => {
          return customShape.toJSON();
        });
      }

      return result;
    });

    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint(2)}drawings/custom-settings`, body);
  }

  public addImageToUploadQueue(drawingImage: DrawingImage): void {
    this._uploadQueue.push(drawingImage);
  }

  public clearUploadQueue(): void {
    this._uploadQueue = [];
  }

  public removeDrawingImagesFromUploadQueue(namesOfDrawingImagesToRemove: string[]): DrawingImage[] {
    this._uploadQueue = filter(this._uploadQueue, (drawingImage: DrawingImage) => !includes(namesOfDrawingImagesToRemove, drawingImage.name));
    return this._uploadQueue;
  }

  public getUploadQueue(): DrawingImage[] {
    return this._uploadQueue;
  }

  public uploadDrawingImages(drawingImages: DrawingImage[]): Observable<DrawingImage[]> {
    const pvdFiles = drawingImages.filter((drawingImage: DrawingImage) => drawingImage.type === DrawingType.PVD);
    drawingImages = drawingImages.filter((drawingImage: DrawingImage) => drawingImage.type !== DrawingType.PVD);

    return forkJoin(
      map(drawingImages, (drawingImage: DrawingImage) => {
        const pvdFile = pvdFiles.find((pvd: DrawingImage) => pvd.name === drawingImage.name);
        const formDataToUpload = new FormData();
        formDataToUpload.append('file', drawingImage.file, drawingImage.file.name);
        if (pvdFile) {
          formDataToUpload.append('pvd', pvdFile.file, pvdFile.file.name);
        }
        return this.httpClient.post(`${this.backendConfiguration.getEndpoint(2)}drawings/images`, formDataToUpload).pipe(
          rxjsMap((response: any[]) => {
            drawingImage.uploadDrawingInfo = response.map((responseElement: any) => UploadDrawingInfo.fromJSON(responseElement))[0];
            drawingImage.id = drawingImage.uploadDrawingInfo.id;
            return drawingImage;
          })
        );
      })
    );
  }

  public deleteDrawingImages(drawingImagesIds: string[]): Observable<void> {
    return this.httpClient.request<void>('delete', `${this.backendConfiguration.getEndpoint()}drawings/images`, {body: drawingImagesIds});
  }

  public getRecoloredImageConfiguration(
    imageId: string,
    machineQualityId: number,
    colorSetId: number,
    coloredYarnSetId: number,
    mappingForCurrentPositionOnMachine: CreelMap,
    target: TargetForListOfDrawingsEnum,
    dimensions: {
      input: DimensionsInPx;
      output?: DimensionsInPx;
    },
    orderLineId?: number,
    position?: ColoredYarnSetPosition,
    finishingId?: number,
    numberOfWeftSelectionColumns?: number,
    threadByThread?: boolean
  ): Observable<DrawingRecolorInformation> {
    if (isNil(dimensions.output)) {
      dimensions.output = dimensions.input;
    }
    return this.httpClient
      .put(`${this.backendConfiguration.getEndpoint()}drawings/images/${imageId}/recolored`, {
        qualityId: machineQualityId,
        colorSetId,
        coloredYarnSetId,
        target,
        mappingForCurrentPositionOnMachine: !isNil(mappingForCurrentPositionOnMachine) ? mappingForCurrentPositionOnMachine.creelPositions : null,
        heightInPx: dimensions.input.heightInPx,
        widthInPx: dimensions.input.widthInPx,
        outputHeightInPx: dimensions.output.heightInPx,
        outputWidthInPx: dimensions.output.widthInPx,
        orderLineId,
        position: !isNil(position) ? ColoredYarnSetPosition[position] : undefined,
        finishingId,
        nrColumnsForWeftSelection: numberOfWeftSelectionColumns,
        processThreadByThread: threadByThread
      })
      .pipe(
        rxjsMap((drawingRecolorInformationJSON: any) => {
          return DrawingRecolorInformation.fromJSON(drawingRecolorInformationJSON);
        })
      );
  }

  public getRecoloredImage(imageId: string, machineQualityId: number, colorSetId: number, dimensionsInPx: DimensionsInPx): Observable<Blob> {
    return this.httpClient.put(
      `${this.backendConfiguration.getEndpoint()}drawings/images/${imageId}/recolored/image`,
      {
        qualityId: machineQualityId,
        colorSetId,
        heightInPx: dimensionsInPx.heightInPx,
        widthInPx: dimensionsInPx.widthInPx
      },
      {responseType: 'blob'}
    );
  }

  public getRecoloredPathLabelImage(
    imageId: string,
    machineQualityId: number,
    colorSetId: number,
    mappingForCurrentPositionOnMachine: CreelMap,
    {widthInPx, heightInPx}: DimensionsInPx,
    position: ColoredYarnSetPosition,
    creelName: string,
    machineId: number,
    freePatternId: number
  ): Observable<string> {
    return this.httpClient
      .put(
        `${this.backendConfiguration.getEndpoint()}drawings/pathlabel/${imageId}`,
        {
          qualityId: machineQualityId,
          colorSetId,
          mappingForCurrentPositionOnMachine: !isNil(mappingForCurrentPositionOnMachine) ? mappingForCurrentPositionOnMachine.creelPositions : null,
          position: ColoredYarnSetPosition[position],
          heightInPx,
          widthInPx,
          creelName,
          machineId,
          freePatternId
        },
        {responseType: 'blob'}
      )
      .pipe(
        switchMap((blob: Blob) => {
          return from(
            new Promise((resolve: (value: string) => void) => {
              const reader = new FileReader();
              reader.onloadend = (): void => resolve(reader.result as string);
              reader.readAsDataURL(blob);
            })
          );
        })
      );
  }

  public hasAlreadyAdjustedCustomSettings(): Observable<boolean> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}drawings/custom-settings/already-adjusted`).pipe(
      rxjsMap((response: {alreadyAdjustedCustomSettings: boolean}) => {
        return response.alreadyAdjustedCustomSettings;
      })
    );
  }

  public isIdentifierAvailable(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}drawings/check-name`, {params}).pipe(
      rxjsMap((response: {drawingNameAvailable: boolean}) => {
        return response.drawingNameAvailable;
      })
    );
  }

  public saveAll(drawings: Drawing[], overwriteExistingDrawings: boolean): Observable<number[]> {
    const drawingsJSON: JSON[] = map(drawings, (drawing: Drawing) => drawing.toJSON());
    const endpoint: string = overwriteExistingDrawings ? '/overwrite' : '';
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}drawings${endpoint}`, drawingsJSON).pipe(rxjsMap((response: {ids: number[]}) => response.ids));
  }

  public processDrawingConfigurationIntoEpDrawing(
    imageId: string,
    drawingName: string,
    machineQualityId: number,
    colorSetId: number,
    dimensionsInPx: DimensionsInPx,
    finishingId: number,
    machineId: number
  ): Observable<void> {
    const body = {
      imageId,
      machineQualityId,
      colorSetId,
      ...dimensionsInPx,
      name: drawingName,
      finishingId,
      machineId
    };

    return this.httpClient.post<void>(`${this.backendConfiguration.getAggregatorEndpoint()}processing`, body);
  }

  public downloadFile(imageId: string): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}drawings/download?id=${imageId}`, {responseType: 'arraybuffer'});
  }

  public downloadPVDFile(yarnConsumptionId: number): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.backendConfiguration.getProductionOrderEndpoint()}smart-creel-files/${yarnConsumptionId}/file`, {responseType: 'arraybuffer'});
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getAggregatorEndpoint()}drawings/${id}/conflicts`)
      .pipe(rxjsMap((conflictsJSON: any) => map(conflictsJSON, (conflictJSON: any) => Conflict.fromJSON(conflictJSON))));
  }

  public getCategoriesWithComponents(): Observable<PlaceholderCategory[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}drawings/custom-settings/categories-with-components`)
      .pipe(rxjsMap((placeholderCategoriesJSON: any) => map(placeholderCategoriesJSON, (placeholderCategoryJSON: any) => PlaceholderCategory.fromJSON(placeholderCategoryJSON))));
  }

  public getDrawingInfoByName(dimensionsInPx: DimensionsInPx, id: string, drawingName: string): Observable<DrawingInfo> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}drawings/configuration-suggestion/${dimensionsInPx.widthInPx}/${dimensionsInPx.heightInPx}/${id}/${drawingName}`).pipe(
      rxjsMap((drawingInfoJSON: any) => {
        const configurations = map(drawingInfoJSON.configurations, (configuration: any) => DrawingConfigurationWithColorAndYarnSet.fromJSON(configuration));

        return {
          shape: drawingInfoJSON.shape,
          configurations,
          code: drawingInfoJSON.drawingCode
        };
      })
    );
  }

  public getGroupedDrawings(gridModel: GridModel): Observable<GroupedDrawing[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}drawings/summary`, {
        groupedBy: ['QUALITY', 'COLOR_SET', 'TECHNICAL_WIDTH_IN_DENTS', 'COMMERCIAL_WIDTH_IN_CM'],
        sortModel: gridModel.sortModel,
        filterModel: gridModel.filterModel
      })
      .pipe(rxjsMap((groupedDrawingsJSON: any) => groupedDrawingsJSON.map((groupedDrawingJSON: any) => GroupedDrawing.fromJSON(groupedDrawingJSON))));
  }
}
