import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {DialogData, UnitType} from '@vdw/angular-component-library';
import {Observable, Subject} from 'rxjs';

@Component({
  templateUrl: './switch-commercial-technical-units.component.html'
})
export class SwitchCommercialTechnicalUnitsCustomSettingsDialogComponent implements DialogData<UnitType> {
  public labelTranslationKey: string;
  public technicalDimensionsTranslationKey: string;
  public commercialDimensionsTranslationKey: string;
  public selectedUnitType: UnitType;
  public readonly unitTypes = UnitType;

  private readonly selectedUnitTypeChanged = new Subject<UnitType>();

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.labelTranslationKey = data.labelTranslationKey;
    this.technicalDimensionsTranslationKey = data.technicalDimensionsTranslationKey;
    this.commercialDimensionsTranslationKey = data.commercialDimensionsTranslationKey;
    this.selectedUnitType = data.selectedUnitType;
  }

  public onUnitTypeChanged(value: UnitType): void {
    this.selectedUnitType = value;
    this.selectedUnitTypeChanged.next(value);
  }

  public getValue(): Observable<UnitType> {
    return this.selectedUnitTypeChanged.asObservable();
  }

  public viewAllSettings(): void {
    this.router.navigateByUrl(NavigationUtils.getAbsolutePath(NavigationId.CARPET_PRODUCTION_SCHEDULE_SETTINGS));
  }
}
