import {CountConfiguration} from '@domain/data-unit-setup/count-configuration';

export class OverviewListDataUnitSetup {
  private _id: number;
  private _name: string;
  private _countConfiguration: CountConfiguration;

  public constructor(id: number, name: string, countConfiguration: CountConfiguration) {
    this._id = id;
    this._name = name;
    this._countConfiguration = countConfiguration;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get countConfiguration(): CountConfiguration {
    return this._countConfiguration;
  }

  public set countConfiguration(countConfiguration: CountConfiguration) {
    this._countConfiguration = countConfiguration;
  }

  public static fromJSON(dataUnitSetupJSON: any): OverviewListDataUnitSetup {
    return new OverviewListDataUnitSetup(dataUnitSetupJSON.id, dataUnitSetupJSON.name, CountConfiguration.fromJSON(dataUnitSetupJSON.countConfiguration));
  }
}
