import {Inject, Injectable} from '@angular/core';
import {Pattern} from '@domain/pattern';
import {CutbarPattern} from '@domain/textile-data/finishing-and-finishing-template/finishing/cutbar-pattern';
import {FinishingTemplates, FINISHING_TEMPLATES} from '@infrastructure/http/finishing-template/finishing-templates';
import {Finishings, FINISHINGS, GetAllFinishingsParameters} from '@infrastructure/http/finishing/finishings';
import {Patterns, PATTERNS} from '@infrastructure/http/patterns/patterns';
import {Observable} from 'rxjs';
import {PatternType} from './pattern-type.enum';

@Injectable({
  providedIn: 'root'
})
export class GetPatternsService {
  public constructor(
    @Inject(PATTERNS) private readonly patterns: Patterns,
    @Inject(FINISHINGS) private readonly finishings: Finishings,
    @Inject(FINISHING_TEMPLATES) private readonly finishingTemplates: FinishingTemplates
  ) {}

  public getPatterns(
    patternType: PatternType,
    machineQualityId?: number,
    colorSetId?: number,
    weaveStructureId?: number,
    exludeColors?: boolean,
    getAllPatternsParameters?: Partial<GetAllFinishingsParameters>
  ): Observable<Pattern[]> {
    switch (patternType) {
      case PatternType.REST_ZONE_PATTERNS:
        return this.getRestZonesPatterns(machineQualityId, colorSetId);
      case PatternType.FREE_ZONE_PATTERNS:
        return this.getFreeZonesPatterns(machineQualityId);
      case PatternType.JUTE_PATTERNS:
        return this.getJutePatterns(machineQualityId, colorSetId);
      case PatternType.JUTE_PATTERNS_OR_DRAWINGS:
        return this.getJutePatternsOrDrawing(weaveStructureId);
      case PatternType.WEAVE_PATTERNS_OR_DRAWINGS:
        return this.getWeavePatternsOrDrawing(weaveStructureId, exludeColors);
      case PatternType.CUTBAR_PATTERNS:
        return this.getCutbarPatterns(getAllPatternsParameters);
      case PatternType.FOLDING_TYPES_PATTERNS:
        return this.getFoldingTypesPatterns(weaveStructureId);
    }
  }

  private getRestZonesPatterns(machineQualityId: number, colorSetId: number): Observable<Pattern[]> {
    return this.patterns.getAllRestZonePatterns(machineQualityId, colorSetId);
  }

  private getFreeZonesPatterns(machineQualityId: number): Observable<Pattern[]> {
    return this.patterns.getAllFreeZonePatterns(machineQualityId);
  }

  private getJutePatterns(machineQualityId: number, colorSetId: number): Observable<Pattern[]> {
    return this.patterns.getAllJutePatterns(machineQualityId, colorSetId);
  }

  private getJutePatternsOrDrawing(weaveStructureId: number): Observable<Pattern[]> {
    return this.patterns.getJutePatternsOrDrawings(weaveStructureId);
  }

  private getWeavePatternsOrDrawing(weaveStructureId: number, excludeColors: boolean): Observable<Pattern[]> {
    return this.patterns.getWeavePatternsOrDrawings(weaveStructureId, excludeColors);
  }

  private getCutbarPatterns(getAllPatternsParameters: Partial<GetAllFinishingsParameters>): Observable<CutbarPattern[]> {
    return this.finishings.getPatternsForCutbarForMachineQuality(getAllPatternsParameters);
  }

  private getFoldingTypesPatterns(weaveStructureId: number): Observable<Pattern[]> {
    return weaveStructureId ? this.finishings.getFoldingTypes(weaveStructureId) : this.finishingTemplates.getFoldingTypes();
  }
}
