import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MobileNavigationBarItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-item';
import {MobileNavigationService} from '@application/services/navigation/mobile-navigation.service';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {
  ArrowPosition,
  BaseComponent,
  DialogBuilder,
  DialogBuilderFactoryService,
  MenuItemEntry,
  RESPONSIVENESS_VIEW_MODE,
  ResponsivenessViewMode,
  TranslateService
} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs/operators';
import {AccountSwitcherDialogComponent} from '../account-switcher-dialog/account-switcher-dialog.component';
import {SideNavigationStateService} from '../side-navigation-state.service';
import {MobileNavigationComponentService} from './mobile-navigation-component.service';

@Component({
  selector: 'app-mobile-navigation-sidebar',
  templateUrl: './mobile-navigation-sidebar.component.html',
  styleUrls: ['./mobile-navigation-sidebar.component.scss']
})
export class MobileNavigationSidebarComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('accountSwitch') private readonly accountSwitcher: ElementRef<HTMLElement>;

  public navigationBarItems: MobileNavigationBarItem[];
  public accountSwitcherActive = false;
  public subscriptionId: string;
  public subscriptionName: string;

  private dialogBuilder: DialogBuilder;

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode,
    private readonly sideNavigationStateService: SideNavigationStateService,
    private readonly navigationComponentService: MobileNavigationComponentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly mobileNavigationService: MobileNavigationService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    const startSubscription = this.authentication.getCurrentSubscription();
    this.subscriptionId = startSubscription.id;
    this.subscriptionName = startSubscription.name;

    this.responsivenessViewMode.observeDeviceChanges().subscribe(() => (this.navigationBarItems = this.mobileNavigationService.getNavigationBarItems(this.authentication.getCurrentSubscription())));

    this.authentication
      .currentSubscriptionChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((subscription: Subscription) => {
        this.subscriptionId = subscription.id;
        this.subscriptionName = subscription.name;
        this.navigationBarItems = this.mobileNavigationService.getNavigationBarItems(subscription);
      });
  }

  public ngOnDestroy(): void {
    this.dialogBuilder?.close();
    super.ngOnDestroy();
  }

  public accountSwitcherClicked(): void {
    if (this.accountSwitcherActive) {
      this.dialogBuilder?.close();
      return;
    }

    this.accountSwitcherActive = true;
    this.dialogBuilder = this.dialogBuilderFactoryService
      .getBuilder()
      .withCustomBackdrop('no-opacity')
      .withWidth(`${this.accountSwitcher.nativeElement.clientWidth}px`)
      .withMaxWidth('100vw')
      .withClass('bms-theme');

    this.dialogBuilder
      .openAtElement(this.accountSwitcher.nativeElement, ArrowPosition.TOP, AccountSwitcherDialogComponent, {}, this.changeDetectorRef, false, null, 0)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => (this.accountSwitcherActive = false));
  }

  public getMenuItemEntryFromNavigationBarItem(navigationBarItem: MobileNavigationBarItem): MenuItemEntry {
    return {title: this.translate.instant(navigationBarItem.name), icon: navigationBarItem.icon};
  }

  public chooseMenuItem(event: MouseEvent, navigationBarItem: MobileNavigationBarItem): void {
    this.navigationComponentService.chooseMenuItem(event, navigationBarItem, this.changeDetectorRef, this.unSubscribeOnViewDestroy);
  }

  public closeNavigation(): void {
    this.sideNavigationStateService.changeNavigationStateToClosed();
  }
}
