import {ComponentType} from '@angular/cdk/portal';
import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {switchMap, takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {DialogBuilderFactoryService} from '../../dialogs/dialog-builder/dialog-builder-factory.service';
import {ArrowPosition} from '../../dialogs/dialog-reposition/arrow-position.enum';
import {DialogData} from './dialog-data.interface';

@Component({
  selector: 'vdw-dialog-button',
  templateUrl: './dialog-button.component.html',
  styleUrls: ['./dialog-button.component.scss']
})
export class DialogButtonComponent<T> extends BaseComponent {
  @Input() public componentType: ComponentType<DialogData<T>>;
  @Input() public dialogData: any;

  @Input() public dialogClass: string | string[];
  @Input() public iconName = 'settings';
  @Input() public tooltip: string;
  @Input() public verticaloffset = 4;
  @Input() public horizontalOffset = 0;
  @Input() public dialogHeight: string;
  @Input() public showArrow: boolean = false;
  @Input() public align: 'left' | 'right' = 'right';

  @Output() public dialogDataChanged = new EventEmitter<T>();

  public open = false;

  public constructor(
    private readonly dialog: DialogBuilderFactoryService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public toggleDialog(): void {
    if (!this.open) {
      this.dialog
        .getBuilder()
        .withCustomBackdrop('transparent-backdrop')
        .withClass(
          AssertionUtils.isNullOrUndefined(this.dialogClass) ? ['icon-button-dialog-panel'] : ['icon-button-dialog-panel', ...(Array.isArray(this.dialogClass) ? this.dialogClass : [this.dialogClass])]
        )
        .withHeight(this.dialogHeight)
        .withOnDialogOpened((dialogRef: MatDialogRef<DialogData<T>, any>) => this.subscribeToAfterOpened(dialogRef))
        .openAtElement(
          this.viewContainerRef.element.nativeElement,
          ArrowPosition.TOP,
          this.componentType,
          this.dialogData,
          this.changeDetectorRef,
          this.showArrow,
          this.align,
          this.verticaloffset,
          this.horizontalOffset
        )
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(() => (this.open = false));
    }

    this.open = !this.open;
  }

  private subscribeToAfterOpened(dialogRef: MatDialogRef<DialogData<T>, any>): void {
    dialogRef
      .afterOpened()
      .pipe(
        switchMap(() => dialogRef.componentInstance.getValue()),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((dialogData: T) => this.dialogDataChanged.next(dialogData));
  }
}
