import {Injectable} from '@angular/core';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {BuggyForProductionSchedule} from '@domain/production-schedule/buggy-for-production-schedule';
import {
  AgGridUtils,
  ColDefBuilderFactoryService,
  GridOptionsBuilder,
  LinkIconRendererComponent,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  StringUtils,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams, ValueGetterParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';

@Injectable({providedIn: 'root'})
export class BuggyOverviewService {
  private readonly pageUrls = LastModifiedCardUtils.getPageUrls('buggy');

  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly l10nIntlService: L10nIntlService
  ) {}

  public getColumnDefs(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.NAME')
        .withColIdAndField('name', true)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('amountPlannedInPicks')
        .withNumericMultiFilter()
        .withHeaderName('ORDERBOOK.PLANNED_PICKS')
        .withValueGetter((params: ValueGetterParams<BuggyForProductionSchedule>) => +params.data.amountPlannedInPicks.toFixed(2))
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(params.value, Unit.PICKS, this.l10nIntlService))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('amountPlannedInMeter')
        .withNumericMultiFilter()
        .withHeaderName('ORDERBOOK.PLANNED_METERS')
        .withValueGetter((params: ValueGetterParams<BuggyForProductionSchedule>) => +params.data.amountPlannedInMeter.toFixed(2))
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(params.value, Unit.METER, this.l10nIntlService))
        .build()
    ];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams)
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant('BUGGY.BUGGY', {count: 1}),
        hideDescription: true
      } as NoDataOverlayComponentParams);
  }
}
