import {Component} from '@angular/core';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';

@Component({
  templateUrl: './grid-color-list-preview.component.html',
  styleUrls: ['./grid-color-list-preview.component.scss']
})
export class GridColorListPreviewComponent {
  public overviewListColorSet: OverviewListColorSet;

  public agInit(params: any): void {
    this.overviewListColorSet = params.value;
  }
}
