import {Injectable} from '@angular/core';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {MenuItemEntry, TranslateService} from '@vdw/angular-component-library';
import {BehaviorSubject, Subject} from 'rxjs';
import {NavigationService} from '../navigation/navigation.service';

@Injectable({providedIn: 'root'})
export class NavigationBreadcrumbsService {
  public onNameChangedSubject: Subject<string> = new Subject<string>();
  public isUsingBreadcrumbFormLinkerSubject = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly translate: TranslateService,
    private readonly navigationService: NavigationService
  ) {}

  public getAllMenuItemsDetails(): MenuItemEntry[] {
    const defaultMenuItem = this.navigationService.composeMenuItems(null);
    this.setMachineQualityChildEntries(defaultMenuItem);
    this.setMachineChildEntries(defaultMenuItem);

    return [
      ...defaultMenuItem,
      {title: this.translate.instant('PROFILE.PROFILE'), link: RouteUtils.paths.profile.absolutePath},
      {
        title: this.translate.instant('SETTINGS.MENU_NAME'),
        link: RouteUtils.paths.settings.absolutePath,
        childEntries: [
          {
            title: this.translate.instant('BMSCONFIG.AUTOMATIC_STOP_GROUPS.CARD_TITLE'),
            link: RouteUtils.paths.settings.automaticStopGroup.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.CARD_TITLE'),
            link: RouteUtils.paths.settings.deviceTemplate.absolutePath,
            childEntries: [
              {
                title: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.COUNTERS', {count: 2}),
                link: RouteUtils.paths.settings.deviceTemplate.addCounter.absolutePath.replace('/add', '')
              },
              {
                title: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.CONFIGURATION.CONFIGURATIONS', {count: 1}),
                link: RouteUtils.paths.settings.deviceTemplate.addConfiguration.absolutePath.replace('/add', '')
              }
            ]
          },
          {
            title: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES_CATALOG.DEVICE_TEMPLATES_CATALOG'),
            link: RouteUtils.paths.settings.deviceTemplateCatalog.absolutePath,
            childEntries: [
              {
                title: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.COUNTERS', {count: 2}),
                link: RouteUtils.paths.settings.deviceTemplateCatalog.addCounterCatalog.absolutePath.replace('/add', '')
              },
              {
                title: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.CONFIGURATION.CONFIGURATIONS', {count: 1}),
                link: RouteUtils.paths.settings.deviceTemplateCatalog.addConfigurationCatalog.absolutePath.replace('/add', '')
              }
            ]
          },
          {
            title: this.translate.instant('BMSCONFIG.PHYSICAL_QUANTITIES.CARD_TITLE'),
            link: RouteUtils.paths.settings.physicalQuantity.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.DECLARATIONS.CARD_TITLE'),
            link: RouteUtils.paths.settings.declaration.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.REJECT_REASON.REJECT_REASON', {count: 2}),
            link: RouteUtils.paths.settings.rejectReason.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.REPORT_ITEMS.CARD_TITLE'),
            link: RouteUtils.paths.settings.reportItem.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.OPERATORS.CARD_TITLE'),
            link: RouteUtils.paths.settings.operator.absolutePath,
            childEntries: [
              {
                title: this.translate.instant('MACHINE.OPERATOR_TYPES.OPERATOR_TYPE', {count: 1}),
                link: RouteUtils.paths.settings.operator.addOperatorType.absolutePath.replace('/add', '')
              }
            ]
          },
          {
            title: this.translate.instant('BMSCONFIG.RESOURCE_TYPES.CARD_TITLE'),
            link: RouteUtils.paths.settings.resourceType.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.DATA_UNIT_SETUP.CARD_TITLE'),
            link: RouteUtils.paths.settings.dataUnitSetup.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.DATA_UNIT_SETUP_CATALOG.CARD_TITLE'),
            link: RouteUtils.paths.settings.dataUnitSetupCatalog.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.MACHINE_DATA_SOURCES.CARD_TITLE'),
            link: RouteUtils.paths.settings.machineDataSources.absolutePath
          },
          {
            title: this.translate.instant('BMSCONFIG.EVENTS.CARD_TITLE'),
            link: RouteUtils.paths.settings.event.absolutePath
          }
        ]
      },
      {title: this.translate.instant('ONBOARDING.NAVIGATION.HELP.TITLE'), link: RouteUtils.paths.help.absolutePath}
    ];
  }

  public onNameChanged(name: string): void {
    this.onNameChangedSubject.next(name);
  }

  public updateBreadcrumbFormLinkerUsed(isUsingBreadcrumbFormLinker: boolean): void {
    this.isUsingBreadcrumbFormLinkerSubject.next(isUsingBreadcrumbFormLinker);
  }

  public findChildEntryByPath(menuItems: MenuItemEntry[], path: string, currentActiveMenuItems: MenuItemEntry[] = []): MenuItemEntry[] {
    let result: MenuItemEntry[] = null;

    menuItems.forEach((menuItem: MenuItemEntry) => {
      const activeMenuItems = [...currentActiveMenuItems, menuItem];

      if (menuItem.link === path) {
        result = activeMenuItems;
      }

      if (!result && menuItem.childEntries) {
        result = this.findChildEntryByPath(menuItem.childEntries, path, activeMenuItems);
      }
    });

    return result;
  }

  private setMachineQualityChildEntries(menuItems: MenuItemEntry[]): void {
    const childEntries = [
      {
        title: this.translate.instant('TEXTILE_DATA.FINISHING.FINISHING', {count: 1}),
        link: RouteUtils.paths.texStyle.machineQuality.addFinishing.absolutePath.replace('/add', '')
      }
    ];
    const machineQuality = this.findChildEntryByPath(menuItems, RouteUtils.paths.texStyle.machineQuality.absolutePath);
    machineQuality[machineQuality.length - 1].childEntries = childEntries;
  }

  private setMachineChildEntries(menuItems: MenuItemEntry[]): void {
    const childEntries = [
      {
        title: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.WEAVING'),
        link: RouteUtils.paths.texState.addWeavingMachineType.absolutePath.replace('/add', '')
      },
      {
        title: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.PLASTIC'),
        link: RouteUtils.paths.texState.addPlasticMachineType.absolutePath.replace('/add', '')
      },
      {
        title: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.TUFTING'),
        link: RouteUtils.paths.texState.addTuftingMachineType.absolutePath.replace('/add', '')
      },
      {
        title: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.WINDER'),
        link: RouteUtils.paths.texState.addWinderMachineType.absolutePath.replace('/add', '')
      },
      {
        title: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.DYEING'),
        link: RouteUtils.paths.texState.addDyeingMachineType.absolutePath.replace('/add', '')
      },
      {
        title: this.translate.instant('SETTINGS.MENU_NAME'),
        link: RouteUtils.paths.texState.settings.absolutePath,
        childEntries: [
          {
            title: this.translate.instant('MACHINE.SETTINGS.REPORT_TEMPLATE'),
            link: RouteUtils.paths.texState.addTemplate.absolutePath.replace('/add', '')
          }
        ]
      }
    ];
    const machine = this.findChildEntryByPath(menuItems, RouteUtils.paths.texState.absolutePath);
    machine[machine.length - 1].childEntries = childEntries;
  }
}
