import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {User} from '@domain/alert/user';
import {StringIdName} from '@domain/string-id-name';
import {AssertionUtils, ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, NoDataOverlayComponentParams, SelectGridDialog, TranslateService} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, FirstDataRenderedEvent, GridApi, GridOptions, RowNode, ValueGetterParams} from 'ag-grid-community';

@Component({
  templateUrl: './select-recipients.component.html',
  styleUrls: ['./select-recipients.component.scss']
})
export class SelectRecipientsComponent implements AfterViewInit, SelectGridDialog {
  @ViewChild('recipientsGrid') public recipientsGrid: AgGridAngular;
  public listOfRecipients: User[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  private selectedEntities: User[];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SelectRecipientsComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    const ungroupedTranslation = this.translate.instant(`BMSCONFIG.ALERTS.UNGROUPED`);

    this.listOfRecipients = data.listOfRecipients.map((recipient: User) => {
      if (AssertionUtils.isNullOrUndefined(recipient.group)) {
        recipient.group = new StringIdName('', ungroupedTranslation);
      }
      return recipient;
    });

    this.setGridOptionsForListOfGroupedRecipients();
    this.selectedEntities = data?.selectedEntities;
  }

  public get gridOptionsForListOfGroupedRecipients(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.recipientsGrid.api];
  }

  public filterRecipients(event: string): void {
    this.recipientsGrid.api.setGridOption('quickFilterText', event);
  }

  public selectRecipients(): void {
    this.dialogRef.close(this.recipientsGrid.api.getSelectedRows());
  }

  public canSelectRecipient(): boolean {
    return this.recipientsGrid?.api && this.recipientsGrid.api.getSelectedRows().length > 0;
  }

  private setGridOptionsForListOfGroupedRecipients(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfGroupedRecipients(), GridIdentifier.SELECT_RECIPIENTS)
        .withRowSelection(true, true, true, false, true)
        .withNoRowsOverlay({
          titleParam: 'BMSCONFIG.ALERTS.RECIPIENTS',
          hideDescription: true,
          scale: 0.7
        } as Partial<NoDataOverlayComponentParams>)
        .withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => this.selectSelectedNodes(api))
        .withAutoGroupColumnDef(this.getAutoGroupColumnDef())
        .withGroupDisplayType('singleColumn')
        .withLoadingOverlay({
          scale: 0.7
        })
        .build()
    ];
  }

  private getColumnDefsForListOfGroupedRecipients(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('group.name').withRowGroup().withHide(true).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('emailAddress', true).withHeaderName('BMSCONFIG.ALERTS.EMAIL_ADDRESS').build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('phoneNumber', true).withHeaderName('BMSCONFIG.ALERTS.PHONE').build()
    ];
  }

  private getAutoGroupColumnDef(): ColDef {
    return this.colDefBuilderFactoryService
      .getBuilder()
      .withHeaderName('BMSCONFIG.ALERTS.RECIPIENTS')
      .withField('firstName')
      .withValueGetter((params: ValueGetterParams) => {
        if (params.data) {
          return `${params.data.firstName} ${params.data.lastName}`;
        }
      })
      .withCellClass('multiple-select-checkbox-row-group')
      .withSuppressMovable()
      .build();
  }

  private selectSelectedNodes(gridApi: GridApi): void {
    gridApi.forEachNode((node: RowNode) => {
      if (this.selectedEntities?.some((recipient: User) => recipient.id === node.data?.id)) {
        node.setSelected(true);
      }
    });
  }
}
