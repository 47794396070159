export enum NumberingMode {
  BOTTOM_TOP_LEFT_RIGHT = 'BOTTOM_TOP_LEFT_RIGHT',
  BOTTOM_TOP_RIGHT_LEFT = 'BOTTOM_TOP_RIGHT_LEFT',
  LEFT_RIGHT_BOTTOM_TOP = 'LEFT_RIGHT_BOTTOM_TOP',
  LEFT_RIGHT_TOP_BOTTOM = 'LEFT_RIGHT_TOP_BOTTOM',
  RIGHT_LEFT_BOTTOM_TOP = 'RIGHT_LEFT_BOTTOM_TOP',
  RIGHT_LEFT_TOP_BOTTOM = 'RIGHT_LEFT_TOP_BOTTOM',
  TOP_BOTTOM_LEFT_RIGHT = 'TOP_BOTTOM_LEFT_RIGHT',
  TOP_BOTTOM_RIGHT_LEFT = 'TOP_BOTTOM_RIGHT_LEFT'
}
