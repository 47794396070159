<section fxLayout="column" fxFlex>
  <vdw-header
    [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'BMSCONFIG.DATA_UNIT_SETUP.DATA_UNIT_SETUP' | bmsTranslate: {count: 1} | lowercase}"
    svgIcon="select-blue"
    [canShowClose]="true"
  ></vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToAddDataUnitSetup()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #dataUnitSetupsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForDataUnitSetups" [rowData]="listDataUnitSetups"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectDataUnitSetup()" [centered]="true" (chooseEvent)="selectDataUnitSetup()"></vdw-footer>
</section>
