import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {MimeType} from '../../common/mime-type.enum';

@Directive({
  selector: '[vdwInputCounter]'
})
export class InputCounterDirective implements AfterViewInit {
  private hintElement: HTMLElement | null = null;

  public constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    this.setCounter();
  }

  @HostListener('ngModelChange')
  public onNgModelChange(): void {
    this.setCounter();
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData(MimeType.PLAIN_TEXT);
    this.elementRef.nativeElement.value += pastedInput;
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
    setTimeout(() => this.setCounter());
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    event.preventDefault();
    const droppedInput: string = event.dataTransfer.getData(MimeType.TEXT);
    this.elementRef.nativeElement.value += droppedInput;
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
    setTimeout(() => this.setCounter());
  }

  private setCounter(): void {
    const parent = this.elementRef.nativeElement.closest('mat-form-field');
    const matHintElement = parent.querySelector('.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper');

    if (matHintElement) {
      this.hintElement = matHintElement.querySelector('mat-hint');

      if (!this.hintElement) {
        this.hintElement = this.renderer.createElement('mat-hint');
        this.renderer.addClass(this.hintElement, 'mat-mdc-form-field-hint');
        this.renderer.addClass(this.hintElement, 'mat-mdc-form-field-bottom-align');
        this.renderer.appendChild(matHintElement, this.hintElement);
      }

      const inputLength = this.elementRef.nativeElement.value.length;
      const maxLength = this.elementRef.nativeElement.attributes.getNamedItem('maxLength').value;
      this.renderer.setProperty(this.hintElement, 'innerText', `${inputLength}/${maxLength}`);
    }
  }
}
