export class Language {
  private _code: string;
  private _language: string;

  public constructor(code: string, language: string) {
    this._code = code;
    this._language = language;
  }

  public get code(): string {
    return this._code;
  }

  public set code(code: string) {
    this._code = code;
  }

  public get language(): string {
    return this._language;
  }

  public set language(language: string) {
    this._language = language;
  }

  public static fromJSON(languageJSON: any): Language {
    return new Language(languageJSON.code, languageJSON.language);
  }
}
