import {Directive, TemplateRef} from '@angular/core';
import {DndDraggableDropListComponent} from './dnd-draggable-drop-list/dnd-draggable-drop-list.component';

@Directive({
  selector: '[vdwDndDragPreviewContent]'
})
export class DndDragPreviewContentDirective {
  public constructor(
    template: TemplateRef<any>,
    private readonly dropList: DndDraggableDropListComponent
  ) {
    this.dropList.dragPreviewContent = template;
  }
}
