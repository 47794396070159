import {InjectionToken} from '@angular/core';
import {PropertyValue} from '@domain/property-value';
import {TechnicalDatasheet} from '@domain/technical-datasheet/technical-datasheet';
import {TuftTechnicalDatasheet} from '@domain/technical-datasheet/tuft-technical-datasheet';
import {Observable} from 'rxjs';

export const TECHNICAL_DATASHEETS = new InjectionToken<TechnicalDatasheets>('Datasheets');

export interface TechnicalDatasheets {
  get(start: number, count: number, advancedSearchFilters: PropertyValue[]): Observable<TechnicalDatasheet[]>;
  getThumbnail(id: number): Observable<Blob>;
  getById(id: number): Observable<TuftTechnicalDatasheet>;
  getXmlForDatasheet(id: number): Observable<string>;
  getXSLTForDatasheet(id: number): Observable<string>;
  delete(id: number): Observable<void>;
}
