export enum GridIdentifier {
  ORDERBOOK = 'Orderbook',
  ORDER_ITEMS = 'OrderItems',
  ORDER_ITEMS_NEW_BUILDER = 'OrderItemsNewBuilder',
  ORDER_ITEMS_PRESELECTION = 'OrderItemsPreselection',
  ORDER_ITEMS_NEW_PRESELECTION = 'OrderItemsNewPreselection',
  GROUPED_ORDER_ITEMS = 'GroupedOrderItems',
  STOP_REASON_TEXTS = 'StopReasonTexts',
  MANAGED_WEAVE_STRUCTURE_OVERVIEW = 'managedWeaveStructureOverview',
  WEAVE_STRUCTURE_OVERVIEW = 'weaveStructureOverview',
  EVENT_ALERT_OVERVIEW = 'eventAlertOverview',
  EVENT_OVERVIEW = 'eventOverview',
  PRODUCT_CONFIGURATION_OVERVIEW = 'productConfigurationOverview',
  ARTICLE_OVERVIEW = 'articleOverview',
  LIST_OF_CREEL_POSITIONS_FOR_WEFT_COLORED_YARN_SET = 'listOfCreelPositionsForWeftColoredYarnSet',
  LIST_OF_CREEL_POSITIONS_FOR_COLORED_YARN_SET = 'listOfCreelPositionsForColoredYarnSet',
  LIST_OF_CREEL_POSITIONS_FOR_COLORED_YARN_SET_PREVIEW = 'listOfCreelPositionsForColoredYarnSetPreview',
  LIST_OF_CREEL_POSITIONS_FOR_TEXTILE_DATA_DETAIL = 'listOfCreelPositionsForTextileDataDetail',
  CUSTOMER_OVERVIEW = 'customerOverview',
  PRODUCTION_ORDER_LITE_OVERVIEW = 'productionOrderLiteOverview',
  PRODUCTION_ORDER_WEAVING_OVERVIEW = 'productionOrderWeavingOverview',
  TUFT_PRODUCTION_ORDER_OVERVIEW = 'tuftProductionOrderOverview',
  PRODUCTION_SCHEDULE_OVERVIEW = 'productionScheduleOverview',
  SALES_ORDER_OVERVIEW = 'salesOrderOverview',
  COLOR_OVERVIEW = 'colorOverview',
  COLOR_SET_OVERVIEW = 'colorSetOverview',
  COLORED_YARN_OVERVIEW = 'coloredYarnOverview',
  COLORED_YARN_SET_OVERVIEW = 'coloredYarnSetOverview',
  CREEL_OVERVIEW = 'creelOverview',
  FINISHING_OVERVIEW = 'finishingOverview',
  FINISHING_TEMPLATE_OVERVIEW = 'finishingTemplateOverview',
  GROUPED_WEAVE_STRUCTURE_OVERVIEW = 'groupedWeaveStructureOverview',
  MACHINE_QUALITY_FINISHING_OVERVIEW = 'qualityFinishingOverview',
  MACHINE_QUALITY_OVERVIEW = 'machineQualityOverview',
  WEAVE_PRODUCT_OVERVIEW = 'weaveProductOverview',
  COMPANY_WEAVE_STRUCTURE_OVERVIEW = 'companyWeaveStructureOverview',
  ADD_WEAVE_STRUCTURE = 'weaveStructureAdd',
  WEFT_COLORED_YARN_SET_OVERVIEW = 'weftColoredYarnSetOverview',
  YARN_SET_OVERVIEW = 'yarnSetOverview',
  YARN_TYPE_OVERVIEW = 'yarnTypeOverview',
  MACHINE_LIST = 'machineList',
  KIOSK_MACHINE_LIST = 'kioskMachineList',
  DATA_UNIT_SETUP_CATALOG = 'dataUnitSetupCatalog',
  DATA_UNIT_SETUP = 'dataUnitSetup',
  BACKGROUND_JOBS = 'backgroundJobs',
  SELECT_YARN_TYPE = 'selectYarnType',
  SELECT_YARN_TYPE_FOR_MATERIAL_SET = 'selectYarnTypeForMaterialSet',
  SELECT_WEAVE_STRUCTURE = 'selectWeaveStructure',
  SELECT_PATH_LAYOUT_TEMPLATE = 'selectPathLayoutTemplate',
  SELECT_MACHINES = 'selectMachines',
  SELECT_IMAGE = 'selectImage',
  ALTERNATIVE_MACHINE_QUALITIES = 'alternativeMachineQualities',
  SELECT_MACHINE_QUALITY_FOR_FINISHING = 'selectMachineQualityForFinishing',
  SELECT_FINISHING_TEMPLATE = 'selectFinishingTemplate',
  SELECT_COLORED_YARN_SET = 'selectColoredYarnSet',
  SELECT_WEAVE_STRUCTURES = 'selectWeaveStructures',
  SELECT_YARN_SET = 'selectYarnSet',
  SELECT_COLOR_SET = 'selectColorSet',
  ALTERNATIVE_COLORED_YARN_SETS = 'alternativeColoredYarnSets',
  SELECT_SINGLE_COLOR = 'selectSingleColor',
  SELECT_CUSTOMER = 'selectCustomer',
  SELECT_ARTICLE = 'selectArticle',
  ORDER_LINES_CONFLICTS = 'orderLinesConflicts',
  PRODUCTION_SCHEDULE_COLORED_YARN_SET_PATHS = 'productionScheduleColoredYarnSetPaths',
  SELECT_PRODUCT_CONFIGURATION = 'selectProductConfiguration',
  SELECT_PRODUCT_CONFIGURATION_VERSION_HISTORY = 'selectProductConfigurationVersionHistory',
  SELECT_MACHINE = 'selectMachine',
  SELECT_WEAVING_MACHINE = 'selectWeavingMachine',
  SELECT_WEAVE_PRODUCT = 'selectWeaveProduct',
  SELECT_TUFT_PRODUCT = 'selectTuftProduct',
  SELECT_PRODUCTION_ORDER = 'selectProductionOrder',
  PRODUCTION_ORDERS_LIST = 'productionOrdersList',
  SELECT_WEFT_COLORED_YARN_SET = 'selectWeftColoredYarnSet',
  PRODUCT_CONFIGURATION_DESIGNS_LIST = 'productConfigurationDesignsList',
  VERSION_HISTORIES_LIST = 'versionHistoriesList',
  DESIGNS_LIST = 'designsList',
  MANAGE_TRAINING_FILES = 'manageTrainingFiles',
  TRAINING_MACHINE_LIST = 'trainingMachineList',
  DOCUMENTATION_MACHINE_LIST = 'documentationMachineList',
  MACHINE_DATA_SOURCES = 'machineDataSources',
  SELECT_EVENT_TYPE = 'selectEventType',
  SELECT_EVENT_SOURCES = 'selectEventSources',
  SELECT_DATA_UNIT_SETUP_CATALOG = 'selectDataUnitSetupCatalog',
  SELECT_RECIPIENTS = 'selectRecipients',
  DECLARATION_OVERVIEW = 'declarationOverview',
  SELECT_WEAVING_MACHINE_TYPE = 'selectWeavingMachineType',
  SELECT_TUFT_MACHINE_TYPE = 'selectTuftMachineType',
  SELECT_TUFT_MACHINE = 'selectTuftMachine',
  SELECT_WINDER_MACHINE_TYPE = 'selectWinderMachineType',
  SELECT_DYEING_MACHINE_TYPE = 'selectDyeingMachineType',
  SELECT_PLASTICS_MACHINE_TYPE = 'selectPlasticsMachineType',
  SELECT_FINISHINGS = 'selectFinishings',
  SELECT_POSSIBLE_MACHINES = 'selectPossibleMachines',
  SELECT_COLOR_PATTERN = 'selectColorPattern',
  SELECT_DRAWING_PATTERN = 'selectDrawingPattern',
  SELECT_MACHINE_QUALITY = 'selectMachineQuality',
  SELECT_FINISHING = 'selectFinishing',
  SELECT_DATA_UNIT_SETUP = 'selectDataUnitSetup',
  SELECT_CREEL = 'selectCreel',
  SELECT_WEAVE_PATTERN_TEMPLATE = 'selectWeavePatternTemplate',
  ADVANCED_SEARCH_MACHINE_QUALITY = 'advancedSearchMachineQuality',
  ADVANCED_SEARCH_COLORED_YARN_SET = 'advancedSearchColoredYarnSet',
  ADVANCED_SEARCH_MACHINE = 'advancedSearchMachine',
  ADVANCED_SEARCH_COLOR_SET = 'advancedSearchColorSet',
  ADVANCED_SEARCH_MAIN_COLOR = 'advancedSearchMainColor',
  ADVANCED_SEARCH_BORDER_COLOR = 'advancedSearchBorderColor',
  ADVANCED_SEARCH_ARTICLE = 'advancedSearchArticle',
  ADVANCED_SEARCH_CREEL = 'advancedSearchCreel',
  ADVANCED_SEARCH_WEAVE_STRUCTURE = 'advancedSearchWeaveStructure',
  ADVANCED_SEARCH_MAIN_DESIGN = 'advancedSearchMainDesign',
  ADVANCED_SEARCH_WEFT_COLORED_YARN_SET = 'advancedSearchWeftColoredYarnSet',
  ADVANCED_SEARCH_WEAVE_PRODUCT = 'advancedSearchWeaveProduct',
  ADVANCED_SEARCH_TUFT_PRODUCT = 'advancedSearchTuftProduct',
  ADVANCED_SEARCH_PLASTIC_PRODUCT = 'advancedSearchPlasticProduct',
  ADVANCED_SEARCH_TOOL = 'advancedSearchTool',
  TUFT_PRODUCT_OVERVIEW = 'tuftProductOverview',
  SELECT_ALERT_TYPES = 'selectAlertTypes',
  RESOURCE_TYPE_OVERVIEW = 'resourceTypeOverview',
  PHYSICAL_QUANITTY_OVERVIEW = 'physicalQuantityOverview',
  SELECT_PHYSICAL_QUANITTY_CATALOG = 'selectPhysicalQuantityCatalog',
  DEVICE_TEMPLATE_CATALOG_OVERVIEW = 'deviceTemplateCatalogOverview',
  DEVICE_TEMPLATE_COUNTER_CATALOG_OVERVIEW = 'deviceTemplateCounterCatalogOverview',
  DEVICE_TEMPLATE_CONFIGURATION_CATALOG_OVERVIEW = 'deviceTemplateConfigurationCatalogOverview',
  DEVICE_TEMPLATE_OVERVIEW = 'deviceTemplateOverview',
  SELECT_RESOURCE_TYPES = 'selectResourceTypes',
  SELECT_REPORT_ITEM = 'selectReportItems',
  SELECT_REPORT_ITEM_CATALOG = 'selectReportItemCatalog',
  SELECT_UNIT = 'selectUnits',
  DEVICE_TEMPLATE_COUNTER_OVERVIEW = 'deviceTemplateCounterOverview',
  DEVICE_TEMPLATE_CONFIGURATION_OVERVIEW = 'deviceTemplateConfigurationOverview',
  SELECT_DEVICE_TEMPLATE_CATALOG = 'selectDeviceTemplateCatalog',
  SELECT_COUNTER_CATALOG = 'selectCounterCatalog',
  REPORT_ITEM_OVERVIEW = 'reportItemOverview',
  OPERATORS_OVERVIEW = 'operatorsOverview',
  OPERATOR_TYPES_OVERVIEW = 'operatorTypesOverview',
  PATH_LAYOUT_TEMPLATE_OVERVIEW = 'pathLayoutTemplateOverview',
  STOP_GROUPS_PREVIEW = 'stopGroupsPreview',
  SELECT_RESOURCE_TYPE_BREAKDOWN = 'selectResourceTypeBreakdown',
  SELECT_DATA_UNIT = 'selectDataUnit',
  SELECT_EDGE_DEVICE = 'selectEdgeDevice',
  SELECT_REPORT_ITEMS_BY_CATEGORY = 'reportItemsByCategory',
  PRODUCTION_SCHEDULE_COLORED_YARN_SETS = 'productionScheduleColoredYarnSets',
  BUGGY = 'buggy',
  SELECT_OBJECTS_DIALOG = 'selectObjectsDialog',
  LIST_OF_STOP_REASON_TEXT_CATALOG = 'listOfStopReasonTextCatalog',
  SELECT_COLOR = 'selectColor',
  CREEL_PREVIEW = 'creelPreview',
  LIST_OF_MACHINES_FOR_ADD_COMPANY_WEAVE_STRUCTURE = 'listOfMachinesForAddCompanyWeaveStructure',
  PART_TAGS_FOR_TRAINING_MANAGE_TAGS = 'partTagsForTrainingManageTags',
  SELECT_RULE_ENTITY = 'selectRuleEntity',
  AUTOMATIC_STOP_MAPPING = 'automaticStopMapping',
  LIST_OF_PATTERN_FIELDS = 'listOfPatternFields',
  PLACEHOLDER_PARTS = 'placeholderParts',
  SELECT_RAW_MATERIAL_FOR_YARN = 'selectRawMaterialForYarn',
  SELECT_RAW_MATERIAL_FOR_COLORED_YARN = 'selectRawMaterialForColoredYarn',
  DESIGN_LIST_NEW = 'designListNew',
  LIST_OF_PATH_CONTENTS = 'listOfPathContents',
  GROUPED_DESIGNS = 'groupedDesigns',
  GROUPED_DESIGNS_DETAIL_PRESELECTION = 'groupedDesignsPreselection',
  GROUPED_DESIGNS_DETAIL_BUILDER = 'groupedDesignsBuilder',
  PRODUCTION_SCHEDULE_PATH_LIST_VIEW = 'productionSchedulePathListView',
  GROUPED_DESIGN_THUMBNAILS = 'groupedDesignThumbnails',
  PLASTIC_PRODUCT_OVERVIEW = 'plasticOverview',
  PLASTIC_PRODUCTION_ORDER_OVERVIEW = 'plasticProductionOrderOverview',
  TOOL_OVERVIEW = 'toolOverview',
  SELECT_REJECT_REASON_GROUP = 'selectRejectReasonGroup',
  REJECT_REASON_OVERVIEW = 'rejectReasonOverview',
  PLASTIC_PRODUCT_GROUP_OVERVIEW = 'plasticProductGroupOverview',
  SELECT_MACHINE_GROUP = 'selectMachineGroup',
  FIXED_SCHEDULE_OVERVIEW = 'fixedScheduleOverview',
  SHIFT_OVERVIEW = 'shiftOverview'
}
