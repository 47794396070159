import {BreakpointState} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Device} from './device.enum';
import {ResponsivenessViewMode} from './responsiveness-view.mode';

@Injectable({
  providedIn: 'root'
})
export class StubResponsivenessViewModeService implements ResponsivenessViewMode {
  public get isDesktop(): boolean {
    return true;
  }

  public get isPhone(): boolean {
    return false;
  }

  public get isTablet(): boolean {
    return false;
  }

  public matchesBreakpoint(mediaQuery: string): boolean {
    return false;
  }

  public observeBreakpointChanges(mediaQuery: string | string[]): Observable<BreakpointState> {
    return of({
      matches: true,
      breakpoints: {}
    });
  }

  public observeDeviceChanges(): Observable<Device> {
    return of(Device.DESKTOP);
  }
}
