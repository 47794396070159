import {InjectionToken} from '@angular/core';
import {OrderLinesTarget} from '@domain/order-lines-target.enum';
import {OrderLine} from '@domain/orderbook/order-line';
import {Repository} from '@infrastructure/http/repository';
import {OverviewListOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-order-line';
import {GroupedOrderLine} from '@presentation/pages/texfab/production-schedule/add/drawing-library/views/order-lines-grouped-view/grouped-order-line';
import {GridModel} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {CustomSettingsObjectRepository} from '../custom-settings-object-repository';

export const ORDERBOOK = new InjectionToken<Orderbook>('Orderbook');

export interface Orderbook extends Omit<Repository<OrderLine, OverviewListOrderLine>, 'getAll' | 'getById'>, Omit<CustomSettingsObjectRepository, 'hasAlreadyAdjustedCustomSettings'> {
  getSlice(target: OrderLinesTarget, gridModel: GridModel, productionScheduleId?: string): Observable<OverviewListOrderLine[]>;

  getGroupedOrderlines(gridModel: GridModel): Observable<GroupedOrderLine[]>;

  getById(id: number | string, target: OrderLinesTarget): Observable<OrderLine>;

  getPossibleValues(columnIdentifier: string): Observable<string[]>;
}
