import {Injectable, Injector} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Route, Routes} from '@angular/router';
import {cloneDeep} from 'lodash-es';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {PrototypeRouteUtils} from '../../common/utils/prototype-route-utils';

@Injectable({providedIn: 'root'})
export class ContentSwitcherDialogService<T = unknown> {
  public activeRoute: Route;
  public headerTitle: string;
  public parentInjector: Injector;
  public dialog: MatDialogRef<any>;
  public navigationHistory: {route: Route; inputs: Record<string, any>}[] = [];

  private routes: Routes = [];
  private activeRouteData: T;
  private cachedPreviousRoute: Route;
  private inputs: Record<string, unknown>;
  private routeData: Record<string, T> = {};

  public addRouting(routes: Routes): void {
    this.routes.push(...routes);
  }

  public setHeaderTitle(title: string): void {
    this.headerTitle = title;
  }

  public reset(): void {
    this.routes = [];
    this.dialog = null;
    this.routeData = {};
    this.activeRoute = null;
    this.activeRouteData = null;
    this.navigationHistory = [];
  }

  public getInputs(): Record<string, unknown> {
    return this.inputs;
  }

  public getRouteData(route: Route): T {
    return this.routeData[route.path];
  }

  public isAddRoute(): boolean {
    return this.activeRoute?.path?.endsWith('add') ?? false;
  }

  public isEditRoute(): boolean {
    return this.activeRoute?.path?.endsWith('edit/:id') ?? false;
  }

  public isDuplicateRoute(): boolean {
    return this.activeRoute?.path?.endsWith('add/:id') ?? false;
  }

  public getRouteDataWithId(id: number): T {
    return this.routeData[PrototypeRouteUtils.getAbsolutePath(this.routes, id)];
  }

  public resetRouteData(route: Route): void {
    this.routeData[route.path] = null;
  }

  public getActiveRouteData(): T {
    return this.activeRoute ? this.routeData[PrototypeRouteUtils.getAbsolutePath(this.routes, this.activeRoute.data?.id)] : null;
  }

  public getCachedPreviousRoute(): Route {
    return this.cachedPreviousRoute;
  }

  public getPreviousRouteData(): any {
    if (AssertionUtils.isEmpty(this.navigationHistory)) {
      return null;
    }

    const route = this.navigationHistory[this.navigationHistory.length - 1].route;
    return this.routeData[PrototypeRouteUtils.getAbsolutePath(this.routes, route.data?.id)];
  }

  public navigateBack(data?: T): void {
    const previous = this.navigationHistory.pop();
    this.cachedPreviousRoute = cloneDeep(this.activeRoute);

    if (!AssertionUtils.isNullOrUndefined(data)) {
      this.setActiveRouteData(data);
    }

    if (!AssertionUtils.isNullOrUndefined(previous)) {
      this.inputs = previous.inputs;
      this.activeRoute = previous.route;
    } else {
      this.dialog.close();
    }
  }

  public navigateForward(route: Route, data?: T, newInputs?: Record<string, unknown>): void {
    if (!AssertionUtils.isNullOrUndefined(this.dialog)) {
      this.cachedPreviousRoute = cloneDeep(this.activeRoute);
      this.navigationHistory.push({route: this.activeRoute, inputs: this.inputs});
    }

    if (!AssertionUtils.isNullOrUndefined(data) && !AssertionUtils.isNullOrUndefined(this.activeRoute)) {
      this.setActiveRouteData(data);
    }

    this.activeRoute = route;
    this.setActiveRouteData(null);

    if (!AssertionUtils.isNullOrUndefined(newInputs)) {
      this.inputs = newInputs;
    }
  }

  private setActiveRouteData(routeData: T): void {
    this.activeRouteData = routeData;
    this.routeData[PrototypeRouteUtils.getAbsolutePath(this.routes, this.activeRoute.data?.id)] = this.activeRouteData;
  }
}
