import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, WritableSignal} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslationModule} from '../../translation/translation.module';

@Component({
  selector: 'vdw-settings-icon-button',
  templateUrl: 'settings-icon-button.component.html',
  styleUrl: 'settings-icon-button.component.scss',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatTooltipModule, TranslationModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsIconButtonComponent {
  @Input() public showCustomSettingsButton: boolean;
  @Output() public customSettingsButtonClicked: EventEmitter<void> = new EventEmitter();

  protected showSettingsDialog: WritableSignal<boolean> = signal(false);

  protected toggleSettingsDialog(): void {
    this.showSettingsDialog.update((show: boolean) => !show);
  }
}
