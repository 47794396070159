import {AssertionUtils, Color, Pattern} from '@vdw/angular-component-library';
import {AutomaticStopGroupRule} from './automatic-stop-group-rule';
import {MachineType} from './machine-type.enum';
import {OverallEquipmentEffectivenessType} from './overall-equipment-effectiveness-type.enum';

export class AutomaticStopGroup {
  private _id: number;
  private _name: string;
  private _color: Color;
  private _pattern: Pattern;
  private _abbreviation: string;
  private _isDefaultGroup: boolean;
  private _operatorRelated: boolean;
  private _rules: AutomaticStopGroupRule[];
  private _childGroups: AutomaticStopGroup[];
  private _oeeType: OverallEquipmentEffectivenessType;

  public constructor(
    id: number,
    name: string,
    color: Color,
    pattern: Pattern,
    abbreviation: string,
    isDefaultGroup: boolean,
    operatorRelated: boolean,
    rules: AutomaticStopGroupRule[],
    childGroups: AutomaticStopGroup[],
    oeeType: OverallEquipmentEffectivenessType
  ) {
    this._id = id;
    this._name = name;
    this._rules = rules;
    this._color = color;
    this._pattern = pattern;
    this._oeeType = oeeType;
    this._childGroups = childGroups;
    this._abbreviation = abbreviation;
    this._isDefaultGroup = isDefaultGroup;
    this._operatorRelated = operatorRelated;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get color(): Color {
    return this._color;
  }

  public set color(value: Color) {
    this._color = value;
  }

  public get pattern(): Pattern {
    return this._pattern;
  }

  public set pattern(value: Pattern) {
    this._pattern = value;
  }

  public get abbreviation(): string {
    return this._abbreviation;
  }

  public set abbreviation(value: string) {
    this._abbreviation = value;
  }

  public get isDefaultGroup(): boolean {
    return this._isDefaultGroup;
  }

  public get operatorRelated(): boolean {
    return this._operatorRelated;
  }

  public set operatorRelated(value: boolean) {
    this._operatorRelated = value;
  }

  public get rules(): AutomaticStopGroupRule[] {
    return this._rules;
  }

  public set rules(value: AutomaticStopGroupRule[]) {
    this._rules = value;
  }

  public get childGroups(): AutomaticStopGroup[] {
    return this._childGroups;
  }

  public set childGroups(childGroups: AutomaticStopGroup[]) {
    this._childGroups = childGroups;
  }

  public get oeeType(): OverallEquipmentEffectivenessType {
    return this._oeeType;
  }

  public set oeeType(value: OverallEquipmentEffectivenessType) {
    this._oeeType = value;
  }

  public static fromJSON(json: any): AutomaticStopGroup {
    return new AutomaticStopGroup(
      json.id,
      json.name,
      json.color,
      AssertionUtils.isNullOrUndefined(json.patternId) ? Pattern.NONE : json.patternId,
      json.abbreviation,
      json.isDefaultGroup,
      json.operatorRelated,
      json.rules.map((rule: any) => AutomaticStopGroupRule.fromJSON(rule)),
      json.childGroups.map((childGroup: any) => this.fromJSON(childGroup)),
      json.oeeType
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      colorId: this._color?.id,
      pattern: this._pattern,
      abbreviation: this._abbreviation,
      isDefaultGroup: this._isDefaultGroup,
      oeeType: this._oeeType,
      operatorRelated: this._operatorRelated,
      rules: this._rules.map((rule: AutomaticStopGroupRule) => rule.toJSON()),
      childGroups: this._childGroups.map((childGroup: AutomaticStopGroup) => childGroup.toJSON())
    } as any as JSON;
  }

  public update(updatedGroup: AutomaticStopGroup): void {
    this._name = updatedGroup.name;
    this._color = updatedGroup.color;
    this._oeeType = updatedGroup.oeeType;
    this._pattern = updatedGroup.pattern;
    this._abbreviation = updatedGroup.abbreviation;
    this._operatorRelated = updatedGroup.operatorRelated;
  }

  public static getNestedChildGroupMachineTypes(group: AutomaticStopGroup): MachineType[] {
    const nestedChildGroupMachineTypes: MachineType[] = [];

    if (!AssertionUtils.isNullOrUndefined(group.rules)) {
      nestedChildGroupMachineTypes.push(...group.rules.flatMap((rule: AutomaticStopGroupRule) => rule.machineTypes));
    }

    if (!AssertionUtils.isNullOrUndefined(group.childGroups)) {
      nestedChildGroupMachineTypes.push(...group.childGroups.flatMap((childGroup: AutomaticStopGroup) => AutomaticStopGroup.getNestedChildGroupMachineTypes(childGroup)));
    }

    return nestedChildGroupMachineTypes;
  }
}
