import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {RuntimeApplicationEnvironment, RUNTIME_APPLICATION_ENVIRONMENT} from '@application/configuration/runtime-application-environment';
import {BaseSignTermsAndConditionsComponent} from '../base-sign-terms-and-conditions.component';

@Component({
  selector: 'app-sign-terms-and-conditions-mobile',
  templateUrl: './sign-terms-and-conditions-mobile.component.html',
  styleUrls: ['./sign-terms-and-conditions-mobile.component.scss']
})
export class SignTermsAndConditionsMobileComponent extends BaseSignTermsAndConditionsComponent {
  @Output() public signTermsAndConditions: EventEmitter<void> = new EventEmitter<void>();

  public constructor(@Inject(RUNTIME_APPLICATION_ENVIRONMENT) protected readonly runtimeEnvironment: RuntimeApplicationEnvironment) {
    super(runtimeEnvironment);
  }

  public onSignTermsAndConditons(): void {
    this.signTermsAndConditions.emit();
  }
}
