import {Component, Inject} from '@angular/core';
import {PathLayoutTemplate} from '@domain/path-layout-template/path-layout-template';
import {PathLayoutTemplatePath} from '@domain/path-layout-template/path-layout-template-path';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {ProductionSchedulePlan, PRODUCTION_SCHEDULE_PLAN} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan';
import {AssertionUtils, convertCommercialUnit, convertWidthInMeterToDents, NumberUtils, TranslateService, Unit} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  templateUrl: './path-layout-template-mismatch.component.html',
  styleUrls: ['./path-layout-template-mismatch.component.scss']
})
export class PathLayoutTemplateMismatchComponent implements ICellRendererAngularComp {
  private productionSchedule: ProductionSchedule;
  private pathLayoutTemplate: PathLayoutTemplate;
  private pathWidths: PathWidth[];
  private reedDensityInDentsPerMeter: number = 0;

  public constructor(
    @Inject(PRODUCTION_SCHEDULE_PLAN) private readonly productionSchedulePlan: ProductionSchedulePlan,
    private readonly translate: TranslateService
  ) {
    this.translate = translate;
  }

  public agInit(params: any): void {
    this.pathLayoutTemplate = params.data;
    this.productionSchedule = params.productionSchedule;
    this.pathWidths = params.pathWidths;
    this.reedDensityInDentsPerMeter = params.reedDensityInDentsPerMeter;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public getMismatchIcon(): string {
    if (AssertionUtils.isNullOrUndefined(this.pathLayoutTemplate) || this.checkIfTemplateHasTooMuchWidth(this.pathLayoutTemplate) || this.checkIfTemplateHasOverlappingPaths()) {
      return 'error';
    } else {
      return 'confirm';
    }
  }

  public getMismatchTooltip(): string {
    if (this.checkIfTemplateHasTooMuchWidth(this.pathLayoutTemplate)) {
      return this.translate.instant('PATH_LAYOUT_TEMPLATE.TOO_MUCH_WIDTH_ERROR');
    } else if (this.checkIfTemplateHasOverlappingPaths()) {
      return this.translate.instant('PATH_LAYOUT_TEMPLATE.OVERLAPPING_COLORSETS_ERROR');
    } else {
      return this.translate.instant('PATH_LAYOUT_TEMPLATE.TEMPLATE_COMPATIBLE');
    }
  }

  private checkIfTemplateHasTooMuchWidth(pathLayoutTemplate: PathLayoutTemplate): boolean {
    const totalWidthOfPathsInDents = pathLayoutTemplate.pathLayoutTemplatePaths.reduce((totalWidth: number, pathLayoutTemplatePath: PathLayoutTemplatePath) => {
      const widthOfPathInDents = !AssertionUtils.isNullOrUndefined(this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, this.pathWidths))
        ? this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, this.pathWidths)
        : convertWidthInMeterToDents(convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: pathLayoutTemplatePath.widthInMM}, to: Unit.METER}), this.reedDensityInDentsPerMeter, 0);

      return totalWidth + widthOfPathInDents;
    }, 0);

    const totalWidthOfPathsInDentsWithAddedInBetweenFreeZones = !this.productionSchedule.initialFreeZones.inBetween.useAsMaximum
      ? totalWidthOfPathsInDents + this.productionSchedule.initialFreeZones.inBetween.technicalWidthInDents * (pathLayoutTemplate.pathLayoutTemplatePaths.length - 1)
      : totalWidthOfPathsInDents;
    const totalWidthOfPathsInDentsWithAddedLeftFreeZones = !this.productionSchedule.initialFreeZones.inBetween.useAsMaximum
      ? totalWidthOfPathsInDentsWithAddedInBetweenFreeZones + this.productionSchedule.initialFreeZones.left.technicalWidthInDents
      : totalWidthOfPathsInDentsWithAddedInBetweenFreeZones;
    const totalWidthOfPathsInDentsWithAddedRightFreeZones = !this.productionSchedule.initialFreeZones.inBetween.useAsMaximum
      ? totalWidthOfPathsInDentsWithAddedLeftFreeZones + this.productionSchedule.initialFreeZones.right.technicalWidthInDents
      : totalWidthOfPathsInDentsWithAddedLeftFreeZones;

    const totalWidthOfPathsWithAddedRestAndFreeZones = AssertionUtils.isNullOrUndefined(this.productionSchedule?.initialRestZones.userDefinedRest?.technicalWidthInDents)
      ? totalWidthOfPathsInDentsWithAddedRightFreeZones
      : totalWidthOfPathsInDentsWithAddedRightFreeZones + this.productionSchedule.initialRestZones.userDefinedRest.technicalWidthInDents;

    return this.productionSchedule.machine.dentsForFabric < totalWidthOfPathsWithAddedRestAndFreeZones ? true : false;
  }

  private checkIfTemplateHasOverlappingPaths(): boolean {
    const coloredYarnSetWidthsInDents = this.productionSchedule.productionSchedulePathsOfColoredYarnSets.map((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      return NumberUtils.roundAwayFromZero(productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.technicalWidthInDents);
    });

    let widthInDentsCounter = 0;
    let result: boolean;

    this.pathLayoutTemplate.pathLayoutTemplatePaths.forEach((pathLayoutTemplatePath: PathLayoutTemplatePath) => {
      widthInDentsCounter += !AssertionUtils.isNullOrUndefined(this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, this.pathWidths))
        ? this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, this.pathWidths)
        : convertWidthInMeterToDents(convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: pathLayoutTemplatePath.widthInMM}, to: Unit.METER}), this.reedDensityInDentsPerMeter, 0);

      if (widthInDentsCounter === coloredYarnSetWidthsInDents[0]) {
        result = false;
      } else if (widthInDentsCounter > coloredYarnSetWidthsInDents[0]) {
        result = true;
      }
    });

    return result;
  }
}
