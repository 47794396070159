import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import moment from 'moment';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  selector: 'vdw-time-picker-form-input',
  templateUrl: 'time-picker-form-input.component.html',
  styleUrl: 'time-picker-form-input.component.scss'
})
export class TimePickerFormInputComponent implements OnInit, OnChanges {
  @Input() public label: string = '';
  @Input() public form: FormGroup;
  @Input() public formControlName: string;
  @Input() public notation: 12 | 24;

  public canShowMenu = false;
  public fieldFocused = false;
  public menuFocused = false;
  public get timeControl(): AbstractControl {
    return this.form.controls[this.formControlName];
  }

  @HostListener('document:click') public onClick(): void {
    this.updateCanShowMenu();
  }

  @HostListener('document:keyup', ['$event']) public onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.updateCanShowMenu();
    }
  }

  public ngOnInit(): void {
    this.timeControl.addValidators(this.timeValidator());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('notation' in changes && !AssertionUtils.isNullOrWhitespace(this.timeControl.value)) {
      this.timeControl.setValue(moment(this.timeControl.value, ['HH:mm', 'hh:mm A']).format(changes.notation.currentValue === 12 ? 'hh:mm A' : 'HH:mm'));
    }
  }

  public getSelectedTime(): string {
    return this.timeControl.valid ? this.timeControl.value : null;
  }

  private updateCanShowMenu(): void {
    if (!this.fieldFocused && !this.menuFocused) {
      this.canShowMenu = false;
    }
    this.fieldFocused = false;
    this.menuFocused = false;
  }

  private timeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (AssertionUtils.isNullOrWhitespace(control.value)) {
        return null;
      }

      const pattern = this.notation === 12 ? /^(1[0-2]|0?[1-9]):[0-5][0-9]\s?(AM|PM)$/ : /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
      return pattern.test(control.value) ? null : {invalidTimeFormat: true};
    };
  }
}
