export class EventDefinition {
  private _equipmentKind: string;
  private _reason: string;
  private _group: string;
  private _subGroup: string;

  public constructor(equipmentKind: string, reason: string, group: string, subGroup: string) {
    this._equipmentKind = equipmentKind;
    this._reason = reason;
    this._group = group;
    this._subGroup = subGroup;
  }

  public get equipmentKind(): string {
    return this._equipmentKind;
  }

  public set equipmentKind(value: string) {
    this._equipmentKind = value;
  }

  public get reason(): string {
    return this._reason;
  }

  public set reason(value: string) {
    this._reason = value;
  }

  public get group(): string {
    return this._group;
  }

  public set group(value: string) {
    this._group = value;
  }

  public get subGroup(): string {
    return this._subGroup;
  }

  public set subGroup(value: string) {
    this._subGroup = value;
  }

  public static fromJSON(json: any): EventDefinition {
    return new EventDefinition(json.equipmentKind, json.reason, json.group, json.subGroup);
  }
}
