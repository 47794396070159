import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {CREELS, Creels} from '@infrastructure/http/creel/creels';
import {NavigationMachineOverviewData} from '@presentation/pages/machine-overview/navigation-machine-overview-data.interface';
import {NavigationCreelData} from '@presentation/pages/textile-data/creel/add/navigation-creel-data.interface';
import {GridCreelPreviewComponent} from '@presentation/pages/textile-data/creel/overview/grid-creel-preview/grid-creel-preview.component';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {
  AgGridRowSelection,
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  SelectGridDialog,
  TranslateService
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, FirstDataRenderedEvent, GridApi, GridOptions, RowDataUpdatedEvent, RowDoubleClickedEvent, RowHeightParams, RowNode, ValueGetterParams} from 'ag-grid-community';
import {cloneDeep} from 'lodash-es';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {getRowHeightForCreelPreview} from './get-row-height-for-creel-preview/get-row-height-for-creel-preview';

@Component({
  selector: 'app-select-creel',
  templateUrl: './select-creel.component.html',
  styleUrls: ['./select-creel.component.scss']
})
export class SelectCreelComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('creelsGrid') public creelsGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public creels: OverviewListCreel[];
  public title: string;
  private selectedCreels: OverviewListCreel[];
  private rowSelection: AgGridRowSelection;
  private numberOfCreelPositions: number;
  private numberOfDentsForFabric: number;
  private machineId: number;
  private allowEmptySelection = true;

  public constructor(
    @Inject(CREELS) private readonly creelsService: Creels,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SelectCreelComponent>,
    private readonly translate: TranslateService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectCreelComponent>>
  ) {
    super();
    this.selectedCreels = data.selectedCreels ?? [];
    this.creels = data.creels;
    this.rowSelection = data.rowSelection ?? AgGridRowSelection.SINGLE;
    this.numberOfCreelPositions = data.numberOfCreelPositions;
    this.numberOfDentsForFabric = data.numberOfDentsForFabric;
    this.machineId = data.machineId;
    this.title = data.title;
    this.allowEmptySelection = data.allowEmptySelection ?? this.allowEmptySelection;
  }

  public get gridOptionsForListOfCreels(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfCreels();

    if (AssertionUtils.isNullOrUndefined(this.creels)) {
      const request: Observable<OverviewListCreel[]> = !AssertionUtils.isNullOrUndefined(this.machineId)
        ? this.creelsService.getPossibleCreelsForMachine(this.machineId)
        : this.creelsService.getAllWithNumberOfCreelPositionsAndNumberOfDents(this.numberOfCreelPositions, this.numberOfDentsForFabric);

      request.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((creels: OverviewListCreel[]) => {
        this.creels = creels;
      });
    }
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.creelsGrid?.api];
  }

  public selectCreels(): void {
    this.dialogRef.close(cloneDeep(this.creelsGrid.api.getSelectedRows()));
  }

  public canSelectCreels(): boolean {
    return this.allowEmptySelection ? this.creels?.length > 0 : this.creelsGrid?.api?.getSelectedRows()?.length > 0;
  }

  public isMultiRowSelectionAllowed(): boolean {
    return this.rowSelection === AgGridRowSelection.MULTIPLE;
  }

  public navigateToAddCreel(): void {
    const data = {
      dialogComponent: SelectCreelComponent,
      ...(this.machineId
        ? ({equipmentId: this.machineId} as NavigationMachineOverviewData)
        : ({fixedAmountOfCreelPositions: this.numberOfCreelPositions ?? null, numberOfDentsForFabric: this.numberOfDentsForFabric ?? null} as NavigationCreelData))
    };
    this.navigationHelperService.navigateToNextRouteWithPartialState(data, RouteUtils.paths.texStyle.creel.addCreel.absolutePath);
  }

  private setGridOptionsForListOfCreels(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfCreels(), GridIdentifier.SELECT_CREEL)
        .withGetRowHeight((params: RowHeightParams) => this.getRowHeight(params))
        .withRowSelection(this.isMultiRowSelectionAllowed(), true, this.isMultiRowSelectionAllowed())
        .withOnRowDoubleClicked((event: RowDoubleClickedEvent) => {
          if (!this.isMultiRowSelectionAllowed()) {
            event.node.setSelected(true);
            this.selectCreels();
          }
        })
        .withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => this.setSelectedCreels(api))
        .withLoadingOverlay({
          scale: 0.7
        })
        .withNoRowsOverlay({
          scale: 0.7,
          ...this.getNoRowsOverlayTitleParameters(),
          hideDescription: true
        })
        .withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => {
          this.setSelectedCreels(api);
        })
        .withRememberStateForNavigationHelper()
        .build()
    ];
  }

  private getColumnDefsForListOfCreels(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withValueGetter((params: ValueGetterParams) => `${this.getNumberOfCreelPositionsForCreel(params.data)}`, true)
        .withHeaderName('MACHINE.DETAILS.NR_CREEL_POSITIONS')
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withValueGetter((params: ValueGetterParams) => params.data.getColoredYarnSetsName(), true)
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET')
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withValueGetter('data.coloredYarnSetsWithStartDent')
        .withCellRenderer(GridCreelPreviewComponent)
        .withHeaderName('TEXTILE_DATA.COLOR_SET.COLOR_SET')
        .withSortable(false)
        .withoutFilter()
        .build()
    ];
  }

  private getNumberOfCreelPositionsForCreel(creel: OverviewListCreel): number {
    return creel.coloredYarnSetsWithStartDent[0].coloredYarnSet.overviewListColorSet.creelPositions.length;
  }

  private setSelectedCreels(gridApi: GridApi): void {
    gridApi.forEachNode((node: RowNode) => {
      if (!AssertionUtils.isNullOrUndefined(this.selectedCreels)) {
        node.setSelected(!AssertionUtils.isNullOrUndefined(this.selectedCreels.find((creel: OverviewListCreel) => creel.id === node.data.id)));
      }
    });
  }

  private getRowHeight(params: RowHeightParams): number {
    const creel: OverviewListCreel = params.data;
    return getRowHeightForCreelPreview(creel.coloredYarnSetsWithStartDent);
  }

  private getNoRowsOverlayTitleParameters(): Partial<NoDataOverlayComponentParams> {
    const parameters: Partial<NoDataOverlayComponentParams> = {titleKey: null, titleParam: null};

    if (!AssertionUtils.isNullOrUndefined(this.machineId)) {
      parameters.titleKey = 'PLANNING.ADD_ITEM.MACHINE_NO_OBJECT_SELECTED';
      parameters.titleParam = {object: this.translate.instant('GENERAL.POSSIBLE_OBJECTS', {objects: this.translate.instant('TEXTILE_DATA.CREEL.CREEL', {count: 2})}).toLowerCase()};
    } else {
      parameters.titleKey = 'TEXTILE_DATA.CREEL.OVERLAY.NO_CREELS_OF_POSITIONS_DENTS';
      parameters.titleParam = {numberOfCreelPositions: this.numberOfCreelPositions, numberOfDents: this.numberOfDentsForFabric};
    }

    return parameters;
  }
}
