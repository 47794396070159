<mat-card fxLayout="column" [class.cursor-pointer]="compact" (click)="cardClicked()">
  <mat-card-header fxLayout="row" fxLayoutAlign="start center" [class.border-none]="compact">
    <div mat-card-avatar>
      <mat-icon [svgIcon]="iconName"></mat-icon>
    </div>
    <mat-card-title class="antialiased" fxLayout="column">
      <h3 class="font-weight-bold">{{ cardTitle | bmsTranslate: {count: 1} }}</h3>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="!compact" fxLayout="column" fxLayoutAlign="center center" fxFlex="124px">
    <button mat-flat-button class="action-button" color="primary" (click)="buttonClicked()">
      {{ buttonText | bmsTranslate }}
    </button>
  </mat-card-content>
</mat-card>
