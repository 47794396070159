import {Directive, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {DrawingConfigurationWeaveQuality} from '@domain/production-schedule/drawing-configuration-weave-quality';
import {DrawingRecolorInformation} from '@domain/production-schedule/drawing-recolor-information';
import {TargetForListOfDrawingsEnum} from '@domain/target-for-list-of-drawings.enum';
import {Drawings} from '@infrastructure/http/drawing/drawings';
import {GetAllMachineQualitiesParameters, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {SelectMachineQualityComponent} from '@presentation/components/select-machine-quality/select-machine-quality.component';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {SelectColorSetComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/select-color-set/select-color-set.component';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {
  AgGridRowSelection,
  AlertDialogResult,
  AssertionUtils,
  BackendError,
  BaseComponent,
  canShowErrorForErrorCodeAndFormControlForFormGroup,
  DialogBuilder,
  DialogBuilderFactoryService,
  DialogType,
  TranslateService
} from '@vdw/angular-component-library';
import {get, isEmpty, isEqual, isNil, lowerCase} from 'lodash-es';
import {Subscription} from 'rxjs';
import {retry, takeUntil} from 'rxjs/operators';

@Directive()
export abstract class BaseDrawingConfigurationComponent extends BaseComponent {
  @Input() public drawingDimensionsInPx: DimensionsInPx;
  @Input() public existingQualitiesAndColorSets: {[qualityId: string]: number[]} = {};
  public recoloringImage = false;
  protected drawingsSubscription: Subscription;
  public abstract form: UntypedFormGroup;
  protected abstract readonly WARNING_REASON: string;

  protected constructor(
    protected readonly qualities: MachineQualities,
    protected readonly drawings: Drawings,
    protected readonly translate: TranslateService,
    protected readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();
  }

  public canShowErrorForFormControl(errorCode: string, formControlName: string): boolean {
    return canShowErrorForErrorCodeAndFormControlForFormGroup(errorCode, formControlName, this.form);
  }

  protected getDialogBuiderForBasicDialogPanel(): DialogBuilder {
    return this.dialogBuilderFactoryService.getBuilder().withClass('basic-dialog-panel');
  }

  public isQualitySelected(): boolean {
    return !isNil(this.form.get('quality').value);
  }

  public isColorSetSelected(): boolean {
    return !isNil(this.form.get('colorSet').value);
  }

  public isMachineSelected(): boolean {
    return !isNil(this.form.get('machine').value);
  }

  public getQualityId(): number {
    return get(this.form.get('quality').value, 'id', null);
  }

  public getMachineId(): number {
    return get(this.form.get('machine').value, 'id', null);
  }

  public getColorSetId(): number {
    return get(this.form.get('colorSet').value, 'id', null);
  }

  public selectQuality(): void {
    this.getDialogBuiderForBasicDialogPanel()
      .openSelectGridDialog(SelectMachineQualityComponent, {
        title: this.translate.instant('GENERAL.ACTIONS.SELECT_OBJECT', {object: lowerCase(this.translate.instant('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY', {count: 1}))}),
        selectedQualities: [this.form.get('quality').value],
        rowSelection: AgGridRowSelection.SINGLE,
        allowEmptySelection: false
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((machineQualities: OverviewListMachineQuality[]) => {
        if (machineQualities?.length) {
          const oldMachineQuality: DrawingConfigurationWeaveQuality = this.form.get('quality').value;
          const chosenMachineQuality: OverviewListMachineQuality = machineQualities[0];
          if (AssertionUtils.isNullOrUndefined(oldMachineQuality) || oldMachineQuality.id !== chosenMachineQuality.id) {
            this.setQuality(chosenMachineQuality);
          }
        }
      });
  }

  public getQuality(id: number): void {
    const parameters: Partial<GetAllMachineQualitiesParameters> = {showOnlyLatestVersion: true};
    this.qualities
      .getAll(parameters)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((qualities: OverviewListMachineQuality[]) => {
        const newQuality = qualities.find((quality: OverviewListMachineQuality) => quality.id === id);
        this.setQuality(newQuality);
      });
  }

  public selectColorSet(): void {
    const qualityId = this.getQualityId();

    this.getDialogBuiderForBasicDialogPanel()
      .openSelectGridDialog(SelectColorSetComponent, {
        qualityId: this.getQualityId(),
        existingColorSets: !isNil(this.existingQualitiesAndColorSets[qualityId]) ? this.existingQualitiesAndColorSets[qualityId] : []
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((colorSet: OverviewListColorSet) => {
        const currentColorSet: OverviewListColorSet = this.form.get('colorSet').value;
        if (!isEmpty(colorSet) && (isNil(currentColorSet) || !isEqual(currentColorSet.id, colorSet.id))) {
          this.getRecoloredImage(colorSet.id);
        }
      });
  }

  protected confirmMachineQualitySelection(machineQuality: DrawingConfigurationWeaveQuality): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openAlertDialog({
        titleText: this.translate.instant('GENERAL.ACTIONS.SELECT_OBJECT', {object: lowerCase(this.translate.instant('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY', {count: 1}))}),
        messageText: this.translate.instant('GENERAL.WARNINGS.CONFIRMATION', {reasonForWarning: this.translate.instant(this.WARNING_REASON)}),
        type: DialogType.CONFIRM
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((result: AlertDialogResult) => {
        if (isEqual(result, AlertDialogResult.CONFIRM)) {
          this.onConfirmMachineQualitySelection(machineQuality);
        }
      });
  }

  protected getRecoloredImage(id: number): void {
    this.recoloringImage = true;
    this.drawingsSubscription = this.drawings
      .getRecoloredImageConfiguration(
        this.getDrawingImageId(),
        this.getQualityId(),
        id,
        null,
        null,
        TargetForListOfDrawingsEnum.DRAWING_LIBRARY,
        {
          output: {widthInPx: 200, heightInPx: 200},
          input: this.drawingDimensionsInPx
        },
        null,
        null,
        null,
        this.getDrawingNumberOfSelectionColumns(),
        this.getDrawingThreadByThread()
      )
      .pipe(retry(2))
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (drawingRecolorInformation: DrawingRecolorInformation) => {
          this.handleColorSetSelection(drawingRecolorInformation);
        },
        error: (error: BackendError) => {
          this.recoloringImage = false;

          this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
            titleText: this.translate.instant('TEXTILE_DATA.COLOR_SET.COLOR_SET', {count: 1}),
            messageText: error.message ?? this.translate.instant('PRODUCTION_ORDER.WEAVE_PLAN.WARNINGS.COULD_NOT_RECOLOR'),
            type: DialogType.INFORMATION
          });
        }
      });
  }

  protected abstract handleMachineQualitySelection(chosenMachineQuality: DrawingConfigurationWeaveQuality): void;

  protected abstract onConfirmMachineQualitySelection(machineQuality: DrawingConfigurationWeaveQuality): void;

  protected abstract handleColorSetSelection(drawingRecolorInformation: DrawingRecolorInformation): void;

  protected abstract getDrawingImageId(): string;
  protected abstract getDrawingNumberOfSelectionColumns(): number;
  protected abstract getDrawingThreadByThread(): boolean;

  private setQuality(quality: OverviewListMachineQuality): void {
    if (!AssertionUtils.isNullOrUndefined(this.drawingsSubscription)) {
      this.recoloringImage = false;
      this.drawingsSubscription.unsubscribe();
    }
    this.handleMachineQualitySelection(DrawingConfigurationWeaveQuality.fromOverviewListMachineQuality(quality));
  }
}
