import {moment} from '../moment';
import {Message} from './message';
import {MessageType} from './message-type.enum';

export class MessageStubFactory {
  public static getRandomMessageWithIdAndType(type: MessageType): Message {
    return MessageStubFactory.getRandomMessageWithTypeAndTimestamp(type, moment().toISOString());
  }

  public static getRandomMessageWithTypeAndTimestamp(type: MessageType, timestamp: string): Message {
    return new Message(`Message_example`, `Description_example`, type, timestamp);
  }

  public static getRandomMessageWithTitleAndDescription(title: string, description: string): Message {
    return new Message(title, description, MessageType.INFO, moment().toISOString());
  }
}
