import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {MachineType} from '@domain/machine/machine-type.enum';
import {Conflict, EnumUtils} from '@vdw/angular-component-library';

export enum ConflictType {
  MACHINE = 'MACHINE',
  COLOR_SET = 'COLOR_SET',
  YARN_SET = 'YARN_SET',
  COLORED_YARN_SET = 'COLORED_YARN_SET',
  CREEL = 'CREEL',
  ARTICLE = 'ARTICLE',
  PRODUCTION_SCHEDULE = 'PRODUCTION_SCHEDULE',
  PRODUCTION_ORDER_LITE = 'PRODUCTION_ORDER_LITE',
  DESIGN = 'DESIGN',
  MACHINE_QUALITY = 'MACHINE_QUALITY',
  PRODUCT_CONFIGURATION = 'PRODUCT_CONFIGURATION',
  SALES_ORDER = 'SALES_ORDER',
  ORDER_LINE = 'ORDER_LINE',
  OPERATOR = 'OPERATOR',
  TUFT_PRODUCT = 'TUFT_PRODUCT',
  PRODUCTION_ORDER_TUFT = 'PRODUCTION_ORDER_TUFT',
  PRODUCTION_ORDER_WEAVING = 'PRODUCTION_ORDER_WEAVING',
  YARN_TYPE = 'YARN_TYPE'
}

export namespace ConflictType {
  const PRODUCTION_ORDER_TRANSLATION_KEY = 'PRODUCTION_ORDER.PRODUCTION_ORDER';

  const translationKeysForConflictType: Map<ConflictType, string> = new Map<ConflictType, string>([
    [ConflictType.MACHINE, 'MACHINE.MACHINE'],
    [ConflictType.COLOR_SET, 'TEXTILE_DATA.COLOR_SET.COLOR_SET'],
    [ConflictType.YARN_SET, 'TEXTILE_DATA.YARN_SET.YARN_SET'],
    [ConflictType.COLORED_YARN_SET, 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET'],
    [ConflictType.CREEL, 'TEXTILE_DATA.CREEL.CREEL'],
    [ConflictType.ARTICLE, 'ARTICLES.ARTICLE'],
    [ConflictType.DESIGN, 'DESIGN_LIBRARY.DESIGN'],
    [ConflictType.MACHINE_QUALITY, 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY'],
    [ConflictType.PRODUCT_CONFIGURATION, 'PRODUCT_CONFIGURATION.PRODUCT_CONFIGURATION'],
    [ConflictType.SALES_ORDER, 'SALES_ORDERS.SALES_ORDER'],
    [ConflictType.ORDER_LINE, 'SALES_ORDERS.ORDER_LINES.ORDER_LINE'],
    [ConflictType.OPERATOR, 'MACHINE.OPERATORS.OPERATOR'],
    [ConflictType.TUFT_PRODUCT, 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT'],
    [ConflictType.PRODUCTION_SCHEDULE, PRODUCTION_ORDER_TRANSLATION_KEY],
    [ConflictType.PRODUCTION_ORDER_LITE, PRODUCTION_ORDER_TRANSLATION_KEY],
    [ConflictType.PRODUCTION_ORDER_TUFT, PRODUCTION_ORDER_TRANSLATION_KEY],
    [ConflictType.PRODUCTION_ORDER_WEAVING, PRODUCTION_ORDER_TRANSLATION_KEY],
    [ConflictType.YARN_TYPE, 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE']
  ]);

  const pathsForConflictType: Map<string, string> = new Map<ConflictType, string>([
    [ConflictType.MACHINE, RouteUtils.paths.texState.editMachine.absolutePath],
    [ConflictType.COLOR_SET, RouteUtils.paths.texStyle.colorSet.editColorSet.absolutePath],
    [ConflictType.YARN_SET, RouteUtils.paths.texStyle.yarnSet.editYarnSet.absolutePath],
    [ConflictType.COLORED_YARN_SET, RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet.absolutePath],
    [ConflictType.CREEL, RouteUtils.paths.texStyle.creel.editCreel.absolutePath],
    [ConflictType.ARTICLE, RouteUtils.paths.texFab.article.editArticle.absolutePath],
    [ConflictType.PRODUCTION_SCHEDULE, RouteUtils.paths.texFab.productionOrder.editProductionOrder.absolutePath],
    [ConflictType.PRODUCTION_ORDER_LITE, RouteUtils.paths.texFab.weaving.editProductionOrder.absolutePath],
    [ConflictType.DESIGN, RouteUtils.paths.texEdit.designLibrary.absolutePath],
    [ConflictType.MACHINE_QUALITY, RouteUtils.paths.texStyle.machineQuality.editMachineQuality.absolutePath],
    [ConflictType.PRODUCT_CONFIGURATION, RouteUtils.paths.texEdit.editProductConfiguration.absolutePath],
    [ConflictType.SALES_ORDER, RouteUtils.paths.texFab.salesOrder.editSalesOrder.absolutePath],
    [ConflictType.ORDER_LINE, RouteUtils.paths.texFab.orderbook.absolutePath],
    [ConflictType.OPERATOR, RouteUtils.paths.settings.operator.editOperator.absolutePath],
    [ConflictType.TUFT_PRODUCT, RouteUtils.paths.texStyle.tuftProduct.editTuftProduct.absolutePath],
    [ConflictType.PRODUCTION_ORDER_TUFT, RouteUtils.paths.texFab.tufting.editProductionOrder.absolutePath],
    [ConflictType.PRODUCTION_ORDER_WEAVING, RouteUtils.paths.texFab.weaving.editProductionOrder.absolutePath],
    [ConflictType.YARN_TYPE, RouteUtils.paths.texStyle.yarn.editYarn.absolutePath]
  ]);

  export function getTranslationKeyForConflictType(conflictType: ConflictType): string {
    return translationKeysForConflictType.get(conflictType);
  }

  export function getPathForConflictType(conflict: Conflict, router: Router): string {
    const path = pathsForConflictType.get(conflict.type);
    let conflictUrl = '';

    if (conflict.type === ConflictType.MACHINE) {
      conflictUrl = router.serializeUrl(router.createUrlTree([path.replace(':machineType', EnumUtils.getKeyFromValue(MachineType, MachineType.CARPET_LOOM).toLowerCase())]));
    } else if (conflict.type === ConflictType.DESIGN) {
      conflictUrl = router.serializeUrl(
        router.createUrlTree([path], {
          queryParams: {
            id: conflict.id
          }
        })
      );
    } else {
      conflictUrl = router.serializeUrl(router.createUrlTree([path.replace(':id', `${conflict.id}`)]));
    }

    return conflictUrl;
  }
}
