<section class="subscription-container">
  <vdw-header [title]="'AUTHENTICATION.SUBSCRIPTIONS.TITLE' | bmsTranslate" svgIcon="select-blue"></vdw-header>
  <ng-container *ngIf="hasSubscriptions()">
    <article class="dialog-content" fxLayout="row">
      <app-select-subscription
        [companies]="companies"
        [subscriptions]="companySubscriptions.get(selectedCompany.id)"
        [selectedCompany]="selectedCompany"
        [selectedSubscription]="selectedSubscription"
        (selectedCompanyChange)="selectedCompanyChanged($event)"
        (selectedSubscriptionChange)="selectedSubscriptionChanged($event)"
      ></app-select-subscription>
    </article>
    <vdw-footer [centered]="true" [withCancelButton]="false" (chooseEvent)="chooseSubscription()"></vdw-footer>
  </ng-container>

  <article class="dialog-content" *ngIf="!hasSubscriptions()">
    <p class="b1">{{ 'AUTHENTICATION.SUBSCRIPTIONS.NO_SUBSCRIPTIONS' | bmsTranslate }}</p>
  </article>
</section>
