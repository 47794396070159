import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Subject, takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Component({
  selector: 'vdw-text-area-with-chips',
  templateUrl: './text-area-with-chips.component.html',
  styleUrls: ['./text-area-with-chips.component.scss']
})
export class TextAreaWithChipsComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public inputText: string;
  @Input() public variableAdded = new Subject<string>();
  @Input() public listOfVariables: {id: number; name: string}[];
  @Input() public disabled = false;

  @Output() public valueChange = new EventEmitter<string>();
  @Output() public caretPositionChange = new EventEmitter<number>();

  @ViewChild('editable', {static: true})
  public readonly divElement: ElementRef<HTMLDivElement>;

  private readonly SPAN_ELEMENT_END = '</span>';

  private cachedCaretPosition = 0;
  private afterViewInitSubject = new BehaviorSubject<boolean>(false);
  private variablesLoadedSubject = new BehaviorSubject<boolean>(false);

  public ngOnInit(): void {
    this.variableAdded.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((variable: string) => this.addVariable(variable));

    if (this.listOfVariables) {
      this.variablesLoadedSubject.next(true);
    }

    combineLatest([this.variablesLoadedSubject, this.afterViewInitSubject])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([isVariableLoaded, isAfterViewInit]: [boolean, boolean]) => {
        if (isVariableLoaded && isAfterViewInit) {
          this.initializeText();
          this.updateInputText(false);
        }
      });
  }

  public ngAfterViewInit(): void {
    this.afterViewInitSubject.next(true);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('listOfVariables' in changes && !changes.listOfVariables.isFirstChange() && this.listOfVariables) {
      this.variablesLoadedSubject.next(true);
    }
  }

  public onInput(caretPosition?: number): void {
    setTimeout(() => {
      this.cachedCaretPosition = caretPosition ?? this.getCaretPosition();
      this.caretPositionChange.emit(this.cachedCaretPosition);
    }, 0);
  }

  public onTextInput(event: InputEvent): void {
    this.updateInputText();

    if (event.inputType !== 'deleteContentBackward') {
      return;
    }

    const textElement = this.divElement.nativeElement;

    setTimeout(() => {
      if (textElement.innerHTML.slice(this.cachedCaretPosition - this.SPAN_ELEMENT_END.length, this.cachedCaretPosition) !== this.SPAN_ELEMENT_END) {
        return;
      }

      const childNodes = Array.from(textElement.childNodes);

      const chipNodes = childNodes.filter((childElement: HTMLElement) => childElement.classList?.contains('chip'));
      const foundChipNode = chipNodes.find((node: HTMLElement) => textElement.innerHTML.slice(this.cachedCaretPosition - node.outerHTML.length, this.cachedCaretPosition) === node.outerHTML);

      if (!AssertionUtils.isNullOrUndefined(foundChipNode)) {
        this.deleteChipElement(foundChipNode as HTMLElement, childNodes.indexOf(foundChipNode));

        this.updateInputText();
        this.onInput(this.getCaretPosition());
      }
    }, 0);
  }

  public onMouseInput(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    const targetElement = event.target as HTMLElement;
    const parentTargetElement = targetElement?.parentElement;

    if (parentTargetElement?.classList.contains('chip-button')) {
      const caretPosition = this.divElement.nativeElement.innerHTML.indexOf(parentTargetElement.parentElement.outerHTML);
      this.divElement.nativeElement.innerHTML = this.divElement.nativeElement.innerHTML.replace(parentTargetElement.parentElement.outerHTML, '');
      this.updateInputText();

      this.onInput(caretPosition);
    } else if (!targetElement.classList.contains('chip')) {
      this.onInput();
    }
  }

  private deleteChipElement(chipElement: HTMLElement, chipIndex: number): void {
    if (chipIndex === 0) {
      this.divElement.nativeElement.innerHTML = this.divElement.nativeElement.innerHTML.replace(chipElement.outerHTML, '');
      return;
    }

    const caretOffset = (this.divElement.nativeElement.childNodes[chipIndex - 1] as any as string).length;
    this.divElement.nativeElement.innerHTML = this.divElement.nativeElement.innerHTML.replace(chipElement.outerHTML, '');

    this.setCaretPosition(this.divElement.nativeElement.childNodes[chipIndex - 1], caretOffset);
  }

  private getCaretPosition(): number {
    const selection = window.getSelection();

    if (AssertionUtils.isNullOrUndefined(selection) || selection.rangeCount === 0) {
      return 0;
    }

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    const tempElement = document.createElement('div');

    preCaretRange.selectNodeContents(this.divElement.nativeElement);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    tempElement.appendChild(preCaretRange.cloneContents());

    return tempElement.innerHTML.length;
  }

  private setCaretPosition(element: Node, caretOffset: number): void {
    const selection = window.getSelection();

    if (AssertionUtils.isNullOrUndefined(selection) || selection.rangeCount === 0) {
      return;
    }

    const selectedRange = document.createRange();
    selectedRange.setStart(element, caretOffset);
    selectedRange.collapse(true);

    selection.removeAllRanges();
    selection.addRange(selectedRange);

    setTimeout(() => {
      this.divElement.nativeElement.focus({focusVisible: true} as FocusOptions);
    }, 0);
  }

  private addVariable(name: string): void {
    const chipSpan = this.createChipSpan(name);

    this.divElement.nativeElement.innerHTML =
      this.divElement.nativeElement.innerHTML.slice(0, this.cachedCaretPosition) + chipSpan.outerHTML + '&nbsp;' + this.divElement.nativeElement.innerHTML.slice(this.cachedCaretPosition);

    this.updateInputText();

    const childNodes = Array.from(this.divElement.nativeElement.childNodes);
    const chipIndex = childNodes.findIndex((node: HTMLElement) => node.outerHTML === chipSpan.outerHTML);

    this.setCaretPosition(this.divElement.nativeElement.childNodes[chipIndex + 1], 1);
    this.onInput(this.cachedCaretPosition + chipSpan.outerHTML.length + '&nbsp;'.length);
  }

  private initializeText(): void {
    const variables = this.inputText?.match(/(%\{-?\d+\}%)/g);

    if (AssertionUtils.isNullOrUndefined(variables)) {
      this.divElement.nativeElement.innerHTML = this.inputText ?? '';
      return;
    }

    for (const variable of variables) {
      const variableId = parseInt(/%\{(-?\d+)\}%/.exec(variable)[1], 10);
      const foundVariable = this.listOfVariables?.find((availableVariable: {id: number; name: string}) => availableVariable.id === variableId);

      if (!AssertionUtils.isNullOrUndefined(foundVariable)) {
        this.inputText = this.inputText.replace(`%{${variableId}}%`, `${this.createChipSpan(foundVariable.name).outerHTML}&nbsp;`);
      }
    }

    this.divElement.nativeElement.innerHTML = this.inputText ?? '';
  }

  private createChipSpan(name: string): HTMLSpanElement {
    const span = document.createElement('span');
    span.id = Math.random().toString();
    span.textContent = name;

    span.classList.add('chip');
    span.contentEditable = 'false';

    const button = document.createElement('button');
    button.classList.add('chip-button');

    const image = document.createElement('img');
    image.src = '../../../assets/icons/16px/erase.svg';
    image.classList.add('cursor-pointer');

    button.appendChild(image);
    span.appendChild(button);

    return span;
  }

  private updateInputText(shouldEmit: boolean = true): void {
    this.inputText = this.divElement.nativeElement.innerHTML;

    const htmlMatches = this.inputText?.match(/<span.*?<\/span>/g);

    if (!AssertionUtils.isNullOrUndefined(htmlMatches)) {
      for (const htmlMatch of htmlMatches) {
        const variableName = /class="chip".*?>(.*?)<button/.exec(htmlMatch)[1];
        this.inputText = this.inputText.replace(variableName, `%{${this.getVariableIdByName(variableName)}}%`);
      }
    }

    if (shouldEmit) {
      this.valueChange.emit(this.inputText);
    }
  }

  private getVariableIdByName(name: string): number | undefined {
    return this.listOfVariables.find((variable: {id: number; name: string}) => variable.name === name)?.id;
  }
}
