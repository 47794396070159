import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {HttpDataUnitSetupService} from '@infrastructure/http/data-unit-setup/http-data-unit-setup.service';
import {OverviewListDataUnitSetup} from '@presentation/pages/settings/data-unit-setup/overview/overview-list-data-unit-setup';
import {
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, FirstDataRenderedEvent, GridApi, GridOptions} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-data-unit-setup.component.html'
})
export class SelectDataUnitSetupComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('dataUnitSetupsGrid') public dataUnitSetupsGrid: AgGridAngular;
  public listDataUnitSetups: OverviewListDataUnitSetup[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public selectedDataUnitSetup: OverviewListDataUnitSetup;

  public constructor(
    private readonly dataUnitSetups: HttpDataUnitSetupService,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly dialogRef: MatDialogRef<SelectDataUnitSetupComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFacotryService: ColDefBuilderFactoryService,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectDataUnitSetupComponent>>
  ) {
    super();
    this.selectedDataUnitSetup = data.selectedDataUnitSetup;
  }

  public get gridOptionsForDataUnitSetups(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.getDataUnitSetups();
    this.initialiseGridOptions();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.dataUnitSetupsGrid?.api];
  }

  public selectDataUnitSetup(): void {
    this.dialogRef.close(this.dataUnitSetupsGrid.api.getSelectedRows()[0]);
  }

  public canSelectDataUnitSetup(): boolean {
    return !!this.dataUnitSetupsGrid?.api?.getSelectedRows()[0];
  }

  public navigateToAddDataUnitSetup(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState({dialogComponent: SelectDataUnitSetupComponent}, RouteUtils.paths.settings.dataUnitSetup.addDataUnitSetup.absolutePath);
  }

  private setSelectedDataUnitSetup(gridApi: GridApi): void {
    if (this.selectedDataUnitSetup) {
      gridApi.getRowNode(this.selectedDataUnitSetup.id.toString())?.setSelected(true);
    }
  }

  private getDataUnitSetups(): void {
    this.dataUnitSetups
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((setups: OverviewListDataUnitSetup[]) => {
        this.listDataUnitSetups = setups;
      });
  }

  private initialiseGridOptions(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefs(), GridIdentifier.SELECT_DATA_UNIT_SETUP)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'BMSCONFIG.DATA_UNIT_SETUP.DATA_UNIT_SETUP',
          hideDescription: true,
          isAnyFilterPresent: () => this.dataUnitSetupsGrid?.api?.isAnyFilterPresent()
        } as NoDataOverlayComponentParams)
        .withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => this.setSelectedDataUnitSetup(api))
        .build()
    ];
  }

  private getColumnDefs(): ColDef[] {
    return [
      this.colDefBuilderFacotryService.getBuilder().withField('name').withHeaderName('GENERAL.NAME').build(),
      this.colDefBuilderFacotryService.getBuilder().withField('countConfiguration.productionCounter').withHeaderName('BMSCONFIG.DATA_UNIT_SETUP_CATALOG.COUNTER').build()
    ];
  }
}
