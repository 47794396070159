import {Injectable, Injector} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BaseRouteNode} from '../../common/interfaces/base-route-node.interface';
import {AssertionUtils} from '../../common/utils/assertion-utils';

@Injectable({providedIn: 'root'})
export class ContentSwitcherDialogService<T = unknown> {
  public parentInjector: Injector;
  public dialog: MatDialogRef<any>;
  public activeRouteNode: BaseRouteNode;
  public navigationHistory: {route: BaseRouteNode; inputs: Record<string, any>}[] = [];

  private activeRouteData: T;
  private inputs: Record<string, unknown>;
  private routeData: Record<string, T> = {};

  public init(parentInjector: Injector): void {
    this.parentInjector = parentInjector;
  }

  public reset(): void {
    this.dialog = null;
    this.routeData = {};
    this.activeRouteData = null;
    this.navigationHistory = [];
  }

  public getInputs(): Record<string, unknown> {
    return this.inputs;
  }

  public getRouteData(route: BaseRouteNode): T {
    return this.routeData[route.url];
  }

  public resetRouteData(route: BaseRouteNode): void {
    this.routeData[route.url] = null;
  }

  public getActiveRouteData(): T {
    return this.routeData[this.activeRouteNode?.url];
  }

  public navigateBack(data?: T): void {
    const previous = this.navigationHistory.pop();

    if (!AssertionUtils.isNullOrUndefined(data)) {
      this.setActiveRouteData(data);
    }

    if (!AssertionUtils.isNullOrUndefined(previous)) {
      this.inputs = previous.inputs;
      this.activeRouteNode = previous.route;
    } else {
      this.dialog.close();
    }
  }

  public navigateForward(route: BaseRouteNode, data?: T, newInputs?: Record<string, unknown>): void {
    if (!AssertionUtils.isNullOrUndefined(this.dialog)) {
      this.navigationHistory.push({route: this.activeRouteNode, inputs: this.inputs});
    }

    if (!AssertionUtils.isNullOrUndefined(data)) {
      this.setActiveRouteData(data);
    }

    this.activeRouteNode = route;

    if (!AssertionUtils.isNullOrUndefined(newInputs)) {
      this.inputs = newInputs;
    }
  }

  private setActiveRouteData(routeData: T): void {
    this.activeRouteData = routeData;
    this.routeData[this.activeRouteNode?.url] = this.activeRouteData;
  }
}
