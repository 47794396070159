import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthorizedRouteFactory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {NotFoundPageComponent} from '../status-codes/404/not-found-page.component';

export const utilitiesRoutes: Routes = [
  {path: '', component: NotFoundPageComponent},
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      route: 'tool',
      parentId: NavigationId.UTILITIES,
      id: NavigationId.TOOL_MODULE,
      navigationKey: 'UTILITIES.TOOL.TOOL',
      requiredPermission: Permission.TEXSTYLE_PLASTIC_TOOL_VIEW
    },
    import('@presentation/pages/utilities/tool/tool.module')
  )
];

export const utilitiesRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(utilitiesRoutes);
