import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {DataUnitDataSource} from '@domain/settings/data-unit-data-source';
import {DataUnitType} from '@domain/settings/data-unit-type.enum';
import {BaseComponent, ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, NoDataOverlayComponentParams, OverlayComponentParams, SelectGridDialog} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ValueGetterParams} from 'ag-grid-community';

@Component({
  templateUrl: './select-data-unit.component.html'
})
export class SelectDataUnitComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('dataUnitsGrid') public dataUnitsGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfDataUnits: DataUnitDataSource[];

  public constructor(
    private readonly dialogRef: MatDialogRef<SelectDataUnitComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super();

    this.listOfDataUnits = data.dataUnitDataSources;
  }

  public get gridOptionsForListOfDataUnits(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfDataUnits();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.dataUnitsGrid?.api];
  }

  public selectDataUnit(): void {
    this.dialogRef.close(this.dataUnitsGrid.api.getSelectedRows()[0]);
  }

  public canSelectDataUnit(): boolean {
    return this.dataUnitsGrid?.api?.getSelectedRows().length > 0;
  }

  private setGridOptionsForListOfDataUnits(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfDataUnits(), GridIdentifier.SELECT_DATA_UNIT)
        .withoutColumnMenu()
        .withOnCellDoubleClicked(() => this.selectDataUnit())
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'BMSCONFIG.MACHINE_DATA_SOURCES.DATA_SOURCE_TYPE.DATA_UNIT',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .build()
    ];
  }

  private getColumnDefsForListOfDataUnits(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('cimNetAddress', true).withHeaderName('BMSCONFIG.MACHINE_DATA_SOURCES.CIMNET_ADDRESS').build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withColIdAndField('dataUnitType', true)
        .withHeaderName('BMSCONFIG.MACHINE_DATA_SOURCES.TYPE')
        .withValueGetter((params: ValueGetterParams) => DataUnitType[params.data.dataUnitType], true)
        .build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('dataUnitSetup.name', true).withHeaderName('BMSCONFIG.MACHINE_DATA_SOURCES.SETUP').build()
    ];
  }
}
