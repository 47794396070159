import {Component} from '@angular/core';
import {GridModelRepository} from '@infrastructure/http/grid-model-repository';
import {GridSelectPreviewCellRendererParams} from '@presentation/pages/textile-data/material-set/add/grid-select-preview/grid-select-preview-cell-renderer-params';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {AgGridUtils, Color, convertRGBToHexadecimalColorCode, GridModel} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, SortModelItem} from 'ag-grid-community';
import {join, map} from 'lodash-es';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-select-grid-preview',
  templateUrl: './grid-select-preview.component.html',
  styleUrls: ['./grid-select-preview.component.scss']
})
export class GridSelectPreviewComponent<TEntity extends Color | OverviewListYarnType> implements ICellRendererAngularComp {
  public item: TEntity;
  public isColorPreview: boolean;

  private params: GridSelectPreviewCellRendererParams<TEntity>;

  public agInit(params: GridSelectPreviewCellRendererParams<TEntity>): void {
    this.item = params.data as TEntity;
    this.isColorPreview = params.isColor;
    this.params = params;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public getAltLabel(): string {
    if (this?.item instanceof Color) {
      const color: Color = this.item;
      return Object.values(color.rgb).join(', ');
    } else if (this?.item instanceof OverviewListYarnType) {
      const yarnType: OverviewListYarnType = this.item;
      return join(
        map(yarnType.threads, (thread: {rawMaterial: string}) => thread.rawMaterial),
        ', '
      );
    }
  }

  public canShowPreview(): boolean {
    return this.item !== undefined;
  }

  public getPreviewBoxShadow(color: Color): string {
    return `0 3px 6px -2px ${convertRGBToHexadecimalColorCode(color.rgb)}`;
  }

  public hasAlreadyAddedItem(item: TEntity): boolean {
    return this.params.hasAlreadyAddedItem(item);
  }

  public canShowControls(): boolean {
    return this.params.canShowControls();
  }

  public clickSubtract(item: TEntity): void {
    this.params.subtractClick(item);
  }

  public clickAdd(item: TEntity): void {
    this.params.addClick(item);
  }

  public getLabelName(): string {
    return this.item.name;
  }

  public getColorHexCode(): string {
    if (this?.item instanceof Color) {
      const color: Color = this.item;
      return color.hexadecimalColorCode;
    }
  }

  public static getServerSideDataSource<TItem>(
    items: GridModelRepository<TItem>,
    unsub: Observable<boolean>,
    maxFetchPerSlice: number = 20,
    onRequestCompleted: () => void = null
  ): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams): void => {
        const sortModel: SortModelItem[] = params.request.sortModel;
        const startRow: number = params.request.startRow;
        const endRow: number = params.request.endRow;
        const filterModel = params.request.filterModel;
        const gridModel: GridModel = new GridModel(startRow, endRow, sortModel, filterModel);
        const gridApi: GridApi = params.api;

        items
          .get(gridModel)
          .pipe(takeUntil(unsub))
          .subscribe((item: TItem[]) => {
            if (startRow === 0 && item.length === 0) {
              gridApi?.showNoRowsOverlay();
            }
            if (item.length > 0) {
              gridApi?.hideOverlay();
            }

            let er: number = -1;
            if (item.length < maxFetchPerSlice) {
              er = startRow + item.length;
            }

            params.success({
              rowData: item,
              rowCount: er
            });

            gridApi?.sizeColumnsToFit();
            onRequestCompleted();
          });
      }
    };
  }

  public static onGridReady(param: GridReadyEvent<any>, gridApi: GridApi, search: Subject<string>, unsub: Subject<boolean>): void {
    if (param.api) {
      gridApi = param.api;
    }
    AgGridUtils.initSimpleSearchSubject(gridApi, search, unsub);
  }
}
