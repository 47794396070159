<section class="flex-column full-height">
  <vdw-header class="dialog-header" [title]="title" [svgIcon]="'select-blue'">
    <button mat-icon-button mat-dialog-close class="mat-icon-action-button-sm-without-border">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </vdw-header>
  <article class="dialog-content full-height">
    <ag-grid-angular #reportItemsGrid class="ag-theme-vdw ag-grid-compact-view" [gridOptions]="gridOptionsReportItems" [rowData]="listOfReportItems"></ag-grid-angular>
  </article>
  <vdw-footer [locked]="!isMinAmountOfReportItemsSelected()" [centered]="true" (chooseEvent)="chooseReportItems()"></vdw-footer>
</section>
