import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BackendErrorCodeEnum} from '@application/helper/backend-error-code.enum';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {DrawingPatternVisualisation} from '@domain/drawing-pattern-visualisation';
import {Pattern} from '@domain/pattern';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {PATTERNS, Patterns} from '@infrastructure/http/patterns/patterns';
import {BaseComponent, canShowErrorForErrorCodeAndFormControlForFormGroup, DialogBuilderFactoryService, DialogType, TranslateService, UnhandledBackendError} from '@vdw/angular-component-library';
import {isEqual} from 'lodash-es';
import {Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-add-pattern',
  templateUrl: './add-pattern.component.html',
  styleUrls: ['./add-pattern.component.scss']
})
export class AddPatternComponent extends BaseComponent implements OnInit {
  public static readonly CLASS_NAME_DIALOG = 'add-pattern-dialog';

  @Input() public image: DrawingImage;
  @Output() public closeAddPattern: EventEmitter<void> = new EventEmitter<void>();
  @Output() public addedPattern: EventEmitter<Pattern> = new EventEmitter<Pattern>();

  public addPatternForm: UntypedFormGroup;
  public patternTranslationKey: string;

  private readonly addPatternCommand: (name: string, imageData: string) => Observable<number>;

  public constructor(
    @Inject(PATTERNS) private readonly patterns: Patterns,
    @Inject(MAT_DIALOG_DATA) data: {patternTranslationKey: string; addPatternCommand: (name: string, imageData: string) => Observable<number>},
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    super();

    this.patternTranslationKey = data.patternTranslationKey;
    this.addPatternCommand = data.addPatternCommand;
  }

  public ngOnInit(): void {
    this.addPatternForm = this.formBuilder.group({
      name: [this.image.name, Validators.required, AsyncUniqueValidator.createValidator(this.patterns, null)]
    });

    this.addPatternForm.get('name').markAsTouched();
  }

  public canShowErrorForFormControl(error: string, formControlName: string): boolean {
    return canShowErrorForErrorCodeAndFormControlForFormGroup(error, formControlName, this.addPatternForm);
  }

  public add(): void {
    this.saving = true;

    const patternName = this.addPatternForm.get('name').value;

    this.addPatternCommand(patternName, this.image.data)
      .pipe(finalize(this.finalizeSaving()), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (id: number) => {
          this.addedPattern.emit(new Pattern(id, patternName, [new DrawingPatternVisualisation(this.image.data)]));
        },
        error: (error: UnhandledBackendError) => {
          if (isEqual(BackendErrorCodeEnum[error?.errorContext?.errorCode], BackendErrorCodeEnum.MISSING_COLORS)) {
            this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
              titleText: this.translate.instant('TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE', {count: 1}),
              messageText: error.message,
              type: DialogType.INFORMATION
            });
          } else {
            throw error;
          }
        }
      });
  }

  public close(): void {
    this.closeAddPattern.emit();
  }
}
