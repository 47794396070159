<mat-form-field class="bms-theme full-width full-height overflow-hidden no-subscript flex-center" (click)="stopClickEvent($event)">
  @if (hasUnit()) {
    <input
      #inputElement
      matInput
      vdwAutoFocus
      [autoSelect]="autoSelect"
      type="number"
      autocomplete="off"
      vdwL10nDecimal
      [decimals]="allowDecimals"
      [maximumFractionDigits]="maximumFractionDigits"
      [formControl]="formControl"
      [min]="min"
      [max]="max"
    />
  } @else {
    <input #inputElement matInput vdwAutoFocus [autoSelect]="autoSelect" type="text" [formControl]="formControl" [maxlength]="max" />
  }

  <button *ngIf="canShowClearInputButton()" class="clear-input-button" mat-icon-button type="button" (click)="reset($event)">
    <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
  </button>

  @if (hasUnit()) {
    <span matSuffix>{{ unit }}</span>
  }

  @if (canShowErrorMessage) {
    <mat-icon
      matSuffix
      class="icon-18 cursor-pointer"
      svgIcon="solid-error-circle-rounded"
      [matTooltip]="getErrorMessage() | bmsTranslate"
      matTooltipPosition="above"
      matTooltipClass="unset-max-width error"
    ></mat-icon>
  }
</mat-form-field>
