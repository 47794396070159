<section fxLayout="column" fxFlex>
  <vdw-header [title]="objectTranslationKey | bmsTranslate" svgIcon="info-blue" [canShowClose]="true"></vdw-header>
  <article fxFlex fxLayout="column" fxLayoutAlign="center center" class="dialog-content">
    <div fxLayout="row">
      <img src="{{ illustrationSource }}" class="large-image" alt="download image illustration" />
    </div>
    <h3 class="font-weight-semibold title">{{ header | bmsTranslate }}</h3>
    <p class="description" *ngIf="showWaitingMessage">{{ 'DESIGN_LIBRARY.SMART_CREEL.KEEP_THIS_WINDOW_OPEN' | bmsTranslate }}</p>
    <p *ngIf="showSuccessMessage" class="description">
      {{ successMessage | bmsTranslate: {object: 'x'} }}
      <span *ngIf="objectTranslationKey === smartCreelTranslationKey" (click)="downloadImageManually()" class="download-link">{{
        'DESIGN_LIBRARY.SMART_CREEL.DOWNLOAD_FILES_MANUALLY' | bmsTranslate: {object: 'x'}
      }}</span>
    </p>
    <p class="description" *ngIf="showErrorMessage">{{ 'DESIGN_LIBRARY.SMART_CREEL.ERROR_MESSAGE' | bmsTranslate }}</p>
    <p class="description" *ngIf="showErrorMessage">{{ 'DESIGN_LIBRARY.SMART_CREEL.ERROR_DESCRIPTION' | bmsTranslate: {error: errorMessage} }}</p>
  </article>
  <vdw-footer
    chooseEventName="DESIGN_LIBRARY.SMART_CREEL.TRY_AGAIN"
    [centered]="true"
    [cancelEventName]="getCancelEventName()"
    [withChooseButton]="showErrorMessage"
    [canShowBMSTheme]="true"
    (cancelEvent)="cancelEvent()"
    (chooseEvent)="retryDownload()"
  >
    <button mat-stroked-button *ngIf="showSuccessMessage && objectTranslationKey !== smartCreelTranslationKey" (click)="reloadPage()">
      {{ 'GENERAL.ACTIONS.RELOAD' | bmsTranslate }}
    </button>
  </vdw-footer>
</section>
