<article fxLayout="column" fxLayoutGap="32px" class="wrapper">
  <section fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="end center">
    <div fxLayout="row" fxLayoutGap="16px" *ngIf="pattern.visualisations?.length > 1">
      <div
        *ngFor="let patternVisualisation of pattern.visualisations; let patternIndex = index"
        (click)="setSelectedPatternVisualisation(patternIndex)"
        [class.is-active]="indexOfSelectedPatternVisualisation === patternIndex"
        class="pattern-visualisation-thumbnail-wrapper"
      >
        <app-pattern-preview [patternVisualisation]="patternVisualisation" [viewMode]="'thumbnail'"></app-pattern-preview>
      </div>
    </div>
    <div>
      <button mat-icon-button mat-dialog-close class="mat-icon-action-button-lg-with-border">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </section>
  <section fxLayout="row" fxLayoutAlign="center center" class="full">
    <app-pattern-preview [patternVisualisation]="selectedPatternVisualisation" [viewMode]="'full'"></app-pattern-preview>
  </section>
</article>
