import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNumberOfCreelPositionsData} from '@application/helper/navigation-helper/navigation-number-of-creel-positions-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {YARN_SETS, YarnSets} from '@infrastructure/http/yarn-set/yarn-sets';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {OverviewListYarnSetService} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set.service';
import {AssertionUtils, BaseComponent, DialogComponentData, GridOptionsBuilderFactoryService, OverlayComponentParams, SelectGridDialog} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {GridApi, GridOptions} from 'ag-grid-community';
import {filter, has, isEqual, isNil, size} from 'lodash-es';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-select-yarn-set',
  templateUrl: './select-yarn-set.component.html'
})
export class SelectYarnSetComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('yarnSetsGrid') public yarnSetsGrid: AgGridAngular;
  public listOfYarnSets: OverviewListYarnSet[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public readonly YARN_SET_EDIT_PERMISSION = LastModifiedCardUtils.getPermissionToModifyItems('yarnSet');

  private numberOfCreelPositions: number;
  private readonly possibleValuesSubject = new ReplaySubject<OverviewListYarnSet[]>();

  public constructor(
    @Inject(YARN_SETS) private readonly yarnSets: YarnSets,
    private readonly dialogRef: MatDialogRef<SelectYarnSetComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly overviewListYarnSetService: OverviewListYarnSetService,
    @Inject(MAT_DIALOG_DATA) public data: {numberOfCreelPositions: number},
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectYarnSetComponent>>
  ) {
    super();

    this.numberOfCreelPositions = has(data, 'numberOfCreelPositions') ? data.numberOfCreelPositions : null;
  }

  public get gridOptionsForListOfYarnSets(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfYarnSets();
    this.getYarnSets();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.yarnSetsGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<DialogComponentData<typeof SelectYarnSetComponent>>({dialogComponent: SelectYarnSetComponent});
  }

  public selectYarnSet(): void {
    this.dialogRef.close(this.yarnSetsGrid.api.getSelectedRows()[0]);
  }

  public canSelectYarnSet(): boolean {
    return !AssertionUtils.isEmpty(this.yarnSetsGrid?.api?.getSelectedRows());
  }

  public navigateToAddYarnSet(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState<NavigationNumberOfCreelPositionsData>(
      {numberOfCreelPositions: this.numberOfCreelPositions},
      RouteUtils.paths.texStyle.yarnSet.addYarnSet.absolutePath
    );
  }

  private getYarnSets(): void {
    this.yarnSets
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfYarnSets: OverviewListYarnSet[]) => {
        this.listOfYarnSets = !isNil(this.numberOfCreelPositions)
          ? filter(listOfYarnSets, (yarnSet: OverviewListYarnSet) => {
              return isEqual(this.numberOfCreelPositions, size(yarnSet.creelPositions));
            })
          : listOfYarnSets;
        this.possibleValuesSubject.next(this.listOfYarnSets);
      });
  }

  private setGridOptionsForListOfYarnSets(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.overviewListYarnSetService.getColumnDefsForListOfYarnSets(this.possibleValuesSubject, false), GridIdentifier.SELECT_YARN_SET)
        .withOnCellDoubleClicked(() => this.selectYarnSet())
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'TEXTILE_DATA.YARN_SET.YARN_SET',
          hideDescription: true
        })
        .withRememberStateForNavigationHelper()
        .build()
    ];
  }
}
