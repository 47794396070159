import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Company} from '@domain/profile/company';
import {Subscription} from '@domain/profile/subscription';
import {BaseChooseSubscription} from '@presentation/components/choose-subscription/base-choose-subscription';
import {first, isNil} from 'lodash-es';

@Component({
  selector: 'app-choose-subscription-desktop',
  templateUrl: './choose-subscription-desktop.component.html',
  styleUrls: ['./choose-subscription-desktop.component.scss']
})
export class ChooseSubscriptionDesktopComponent extends BaseChooseSubscription {
  private dialogRef: MatDialogRef<ChooseSubscriptionDesktopComponent>;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subscriptions: Subscription[]; currentSubscription: Subscription; companies: Company[]; currentCompany: Company},
    dialogRef: MatDialogRef<ChooseSubscriptionDesktopComponent>
  ) {
    super();

    this.subscriptions = data.subscriptions;
    this.companies = data.companies;
    this.dialogRef = dialogRef;

    this.initializeCompanySubscriptions();

    this.selectedCompany = isNil(data.currentCompany) ? first(this.companies) : data.currentCompany;
    this.selectedSubscription = isNil(data.currentSubscription) ? first(this.companySubscriptions.get(this.selectedCompany.id)) : data.currentSubscription;
  }

  public chooseSubscription(): void {
    this.dialogRef.close(this.selectedSubscription);
  }
}
