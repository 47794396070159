import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {noop} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';

const PAD_CHAR_CODE = '&ZeroWidthSpace;';

@Component({
  selector: 'vdw-text-area-with-chips-prototype',
  templateUrl: './text-area-with-chips-prototype.component.html',
  styleUrls: ['./text-area-with-chips-prototype.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TextAreaWithChipsPrototypeComponent}]
})
export class TextAreaWithChipsPrototypeComponent extends BaseComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  public set listOfVariables(value: {id: number; name: string}[]) {
    if (this._listOfVariables === value) {
      return;
    }
    this._listOfVariables = value;
    this.redrawElement();
  }

  @Input() public inputText: string;
  @Input() public disabled = false;

  public get listOfVariables(): {id: number; name: string}[] {
    return this._listOfVariables;
  }

  @ViewChild('editable', {static: true})
  public readonly divElement: ElementRef<HTMLDivElement>;

  public caretPositionInValue = 0;
  private caretPositionInDisplay: [number, number] = [0, 0];
  private _listOfVariables: {id: number; name: string}[];
  private internalValue = '';
  private touched = false;
  private onTouched = noop;
  private onChange = (_: string): void => noop();

  public ngAfterViewInit(): void {
    this.setValue(this.inputText, false);
    this.redrawElement();
  }

  public writeValue(value: string): void {
    this.setValue(value, false);
    this.redrawElement();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public addChip(tagId: number): void {
    this.markAsTouched();
    const placeHolder = ` %{${tagId}}% `;

    let preValue = this.internalValue.slice(0, this.caretPositionInValue);
    preValue = preValue.replace(/&+$/, ' ');

    let postValue = this.internalValue.slice(this.caretPositionInValue);
    postValue = postValue.replace(/^nbsp;/, ' ');

    this.setValue(preValue + placeHolder + postValue);
    this.redrawElement();
    this.setCachedPosition();
  }

  public onTextInput(event: InputEvent): void {
    this.markAsTouched();

    const currentHtml = this.divElement.nativeElement.innerHTML;
    const [result, redrawRequired] = this.parseTextFromHtml(currentHtml);

    const prevResult = this.parseTextFromHtml(this.internalValue)[0];
    const [updatedResult, redrawRequiredDoubleLineBreak] = this.replaceDoubleLineBreak(result, prevResult);
    const [finalResult, redrawRequiredZeroWidthSpace] = this.insertZeroWidthSpaces(updatedResult);

    this.setValue(finalResult);

    if (redrawRequired || redrawRequiredDoubleLineBreak || redrawRequiredZeroWidthSpace) {
      this.redrawElement();
      this.setCachedPosition();
    }
  }

  public onInput(): void {
    this.markAsTouched();
    this.getCaretPosition();
  }

  public onMouseInput(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    this.getCaretPosition();
    const targetElement = event.target as HTMLElement;
    const parentTargetElement = targetElement?.parentElement;

    if (parentTargetElement?.classList.contains('chip-button')) {
      this.divElement.nativeElement.innerHTML = this.divElement.nativeElement.innerHTML.replace(parentTargetElement.parentElement.outerHTML, '');
      const [result] = this.parseTextFromHtml(this.divElement.nativeElement.innerHTML);
      this.setValue(result);
      this.redrawElement();
    } else if (!targetElement.classList.contains('chip')) {
      this.onInput();
    } else {
      this.divElement.nativeElement.focus();
    }
  }

  private parseTextFromHtml(rawHtml: string): [string, boolean] {
    let result = rawHtml;
    result = result.replace(/​/g, '');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = rawHtml;
    const matchesToShorten = Array.from(tempElement.querySelectorAll<HTMLSpanElement>('span[data-variable-id]'));

    if (!AssertionUtils.isNullOrUndefined(matchesToShorten)) {
      for (const matchToShorten of matchesToShorten) {
        const variableId = Number(matchToShorten.getAttribute('data-variable-id'));
        result = result.replace(matchToShorten.outerHTML, `%{${variableId}}%`);
      }
    }

    const matchesToRemove = Array.from(tempElement.querySelectorAll<HTMLElement>(':not(span[data-variable-id], br):not(span[data-variable-id] *)'));
    const lineBreaksToClean = Array.from(tempElement.querySelectorAll<HTMLBRElement>('br')).filter((element: HTMLBRElement) => element.attributes.length > 0);
    const cleanupRequired = matchesToRemove?.length > 0 || lineBreaksToClean?.length > 0;

    if (cleanupRequired) {
      for (const matchToRemove of matchesToRemove) {
        const x = document.createElement('div');
        x.textContent = matchToRemove.textContent;

        result = result.replace(matchToRemove.outerHTML, x.innerHTML);
      }
      for (const lineBreakToClean of lineBreaksToClean) {
        result = result.replace(lineBreakToClean.outerHTML, '<br>');
      }
    }

    return [result, cleanupRequired];
  }

  private redrawElement(): void {
    let innerHtml = this.internalValue;

    const variables = innerHtml?.match(/(%\{-?\d+\}%)/g);
    if (AssertionUtils.isNullOrUndefined(variables)) {
      this.divElement.nativeElement.innerHTML = innerHtml;
      return;
    }

    for (const variable of variables) {
      const variableId = parseInt(/%\{(-?\d+)\}%/.exec(variable)[1], 10);
      const foundVariable = this.listOfVariables?.find((availableVariable: {id: number; name: string}) => availableVariable.id === variableId);
      if (!AssertionUtils.isNullOrUndefined(foundVariable)) {
        innerHtml = innerHtml.replace(`%{${variableId}}%`, `${PAD_CHAR_CODE}${this.createChipSpan(foundVariable).outerHTML}${PAD_CHAR_CODE}`);
      }
    }
    this.divElement.nativeElement.innerHTML = innerHtml;

    this.insertPaddingCharacters();
  }

  private insertPaddingCharacters(): void {
    if (!this.divElement.nativeElement.childNodes) {
      return;
    }

    let prevNode: ChildNode;
    for (const childNode of Array.from(this.divElement.nativeElement.childNodes)) {
      if (childNode instanceof Element && !(prevNode instanceof Text)) {
        childNode.outerHTML = PAD_CHAR_CODE + childNode.outerHTML;
      }
      prevNode = childNode;
    }

    if (this.divElement.nativeElement.lastChild instanceof Element) {
      this.divElement.nativeElement.innerHTML += PAD_CHAR_CODE;
    }

    const brSpanRegex = /<br><span/g;
    this.divElement.nativeElement.innerHTML = this.divElement.nativeElement.innerHTML.replace(brSpanRegex, '<br>' + PAD_CHAR_CODE + '<span');
  }

  private markAsTouched(): void {
    if (this.touched) {
      return;
    }
    this.onTouched();
    this.touched = true;
  }

  private setValue(value: string, emitEvent: boolean = true): void {
    this.internalValue = value;
    if (emitEvent) {
      this.onChange(value);
    }
  }

  private createChipSpan(availableVariable: {id: number; name: string}): HTMLSpanElement {
    const span = document.createElement('span');
    span.id = Math.random().toString();
    span.textContent = availableVariable.name;
    span.classList.add('chip');
    span.contentEditable = 'false';
    span.setAttribute('data-variable-id', availableVariable.id.toString());

    const button = document.createElement('button');
    button.classList.add('chip-button');

    const image = document.createElement('img');
    image.src = '../../../assets/icons/16px/erase.svg';
    image.classList.add('cursor-pointer');

    button.appendChild(image);
    span.appendChild(button);

    return span;
  }

  private getCaretPosition(): void {
    if (window.getSelection().rangeCount === 0) {
      this.caretPositionInValue = 0;
      return;
    }
    const range = window.getSelection().getRangeAt(0);
    const preCaretRange = range.cloneRange();
    const divElementNode: Node = this.divElement.nativeElement as Node;
    preCaretRange.selectNodeContents(divElementNode);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const preCaretSelection = preCaretRange.cloneContents();

    const endNodeIndex = (Array.from(this.divElement.nativeElement.childNodes) as Node[]).indexOf(preCaretRange.endContainer);
    this.caretPositionInDisplay = [endNodeIndex, preCaretRange.endOffset];

    const selectionInnerHTML = Array.from(preCaretSelection.childNodes)
      .map((node: ChildNode) => {
        if (node instanceof Element) {
          return node.outerHTML;
        }
        return node.textContent;
      })
      .join('');
    const [selectionText] = this.parseTextFromHtml(selectionInnerHTML);
    this.caretPositionInValue = selectionText.length;
  }

  private setCachedPosition(): void {
    const selection = window.getSelection();
    const range = document.createRange();
    const [childIndex, offset] = this.caretPositionInDisplay;
    const targetNode = this.divElement.nativeElement.childNodes[childIndex];

    if (!targetNode) {
      return;
    }

    const maxOffset = targetNode.nodeType === Node.TEXT_NODE ? (targetNode as Text).textContent?.length || 0 : 0;
    const validOffset = Math.min(offset, maxOffset);

    range.setStart(targetNode, validOffset);
    range.setEnd(targetNode, validOffset);
    selection.removeAllRanges();
    selection.addRange(range);

    requestAnimationFrame(() => {
      this.divElement.nativeElement.focus();
    });
  }

  private replaceDoubleLineBreak(newString: string, prevString: string): [string, boolean] {
    const minLength = Math.min(newString.length, prevString.length);
    let diffIndex = -1;

    for (let i = 0; i < minLength; i++) {
      if (newString[i] !== prevString[i]) {
        diffIndex = i;
        break;
      }
    }

    if (diffIndex === -1 && newString.length !== prevString.length) {
      diffIndex = minLength;
    }

    const part1 = newString.substring(0, diffIndex);
    const part2 = newString.substring(diffIndex);
    const replacedPart2 = part2.replace(/^<br><br>/, '<br>');

    const redrawRequired = part2 !== replacedPart2;

    return [part1 + replacedPart2, redrawRequired];
  }

  private insertZeroWidthSpaces(input: string): [string, boolean] {
    const modifiedString = input.replace(/%%/g, `%${PAD_CHAR_CODE}%`);

    const isModified = input !== modifiedString;

    return [modifiedString, isModified];
  }
}
