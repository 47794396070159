import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationService} from '@application/services/navigation/navigation.service';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION} from '@infrastructure/http/authentication/authentication';

export const canActivatePermissionGuard = (route: ActivatedRouteSnapshot): boolean => {
  const router = inject(Router);
  const authentication = inject(AUTHENTICATION);
  const navigationService = inject(NavigationService);

  const currentSubscription: Subscription = authentication.getCurrentSubscription();

  let requiredPermission: Permission = route.data.requiredPermission;
  if (route.data?.requiredPermissionFactory) {
    requiredPermission = route.data.requiredPermissionFactory(route);
  }

  const hasRequiredPermission = currentSubscription.hasPermission(requiredPermission);

  if (!hasRequiredPermission && !navigationService.checkIsSwitchingSubscription(currentSubscription)) {
    router.navigateByUrl(RouteUtils.paths.unauthorized.absolutePath);
  }

  return hasRequiredPermission;
};
