<section fxLayout="column" fxFlex>
  <vdw-header [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'PLANNING.BUGGY.BUGGY' | bmsTranslate | lowercase}" svgIcon="select-blue" [canShowClose]="true"></vdw-header>
  <article class="dialog-content" fxLayout="row">
    <button
      mat-icon-button
      appDataCy="create-new"
      class="mat-icon-action-button-lg-with-border"
      [disabled]="!addButtonEnabled()"
      (click)="addBuggy()"
      [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate"
    >
      <mat-icon svgIcon="add"></mat-icon>
    </button>
  </article>
  <article fxFlex appDataCy="grid" fxLayout="column" class="dialog-content">
    <ag-grid-angular #buggiesGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfBuggies" [rowData]="listOfBuggies"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectBuggy()" [centered]="true" (chooseEvent)="selectBuggy()" (cancelEvent)="cancel()"></vdw-footer>
</section>
