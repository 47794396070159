<mat-form-field [formGroup]="form" class="bms-theme full-width" disabled>
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControlName]="formControlName" (click)="fieldFocused = true; canShowMenu = true" (focus)="fieldFocused = true; canShowMenu = true" />
  @if (form.value[formControlName]) {
    <button class="clear-input-button" tabindex="-1" mat-icon-button type="button" (click)="timeControl.reset()">
      <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
    </button>
  }
  <mat-error>
    <vdw-invalid-form-message
      *vdwCanShowErrorForFormControl="formControlName; error: 'invalidTimeFormat'"
      [message]="'ANGULAR_COMPONENT_LIBRARY.TIME_PICKER.INVALID_FORMAT' | bmsTranslate"
      [compact]="true"
    ></vdw-invalid-form-message>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  <mat-hint>
    <ng-content select="[hint]"></ng-content>
  </mat-hint>
  <mat-icon matSuffix svgIcon="solid-nest-clock-farsight-analog"></mat-icon>
</mat-form-field>

@if (canShowMenu) {
  <vdw-time-picker-menu [notation]="notation" [selectedTime]="getSelectedTime()" (selectedTimeChange)="timeControl.setValue($event)" (menuFocused)="menuFocused = true"></vdw-time-picker-menu>
}
