import {DOCUMENT} from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {NameGeneratorSeparator} from '@domain/name-generator/enums/name-generator-separator.enum';
import {NameGenerator} from '@domain/name-generator/name-generator';
import {RestZonePosition} from '@domain/production-schedule/rest-zone/rest-zone-position.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription as ProfileSubscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {PlaceholderComponent} from '@domain/textile-data/finishing-and-finishing-template/placeholder-component';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {PRODUCTION_ORDERS, ProductionOrders} from '@infrastructure/http/production-order/production-orders';
import {BackendLimitsConstants} from '@shared/constants/backend-limits.constants';
import {AssertionUtils, BaseComponent, canShowErrorForErrorCodeAndFormControlForFormGroup, EnumUtils, FormValidationHelper, ToastService, TranslateService} from '@vdw/angular-component-library';
import {some, sortBy} from 'lodash-es';
import {forkJoin} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {ProductionOrdersPlaceholderService} from '../shared/production-orders-placeholder.service';

@Component({
  selector: 'app-production-schedule-custom-settings',
  templateUrl: './production-schedule-custom-settings.component.html',
  styleUrls: ['./production-schedule-custom-settings.component.scss']
})
export class ProductionScheduleCustomSettingsComponent extends BaseComponent implements OnInit, AfterViewInit {
  private readonly CUSTOM_TEXT_ID = 27;
  private readonly VIEW_LOSS_PERMISSION: Permission = Permission.VIEW_LOSS;
  private static readonly REST_ZONES_POSITION_FORM_CONTROL_NAME = 'restZones.position';
  private static readonly LOOM_DELETE_POLICY_OPTION_FORM_CONTROL_NAME = 'loomDeletePolicy.loomDeletePolicyOption';
  private readonly PRODUCTION_SCHEDULE_OVERVIEW_URL = NavigationUtils.getAbsolutePath(NavigationId.CARPET_PRODUCTION_ORDER);

  public readonly INT_32_MAXIMUM_VALUE = BackendLimitsConstants.INT32_MAX;
  public readonly ACCESS_ALL_PERMISSION: Permission = Permission.ACCESS_ALL;

  public listOfCustomSettings: PropertyValue[] = [];
  public productionScheduleCustomSettingsForm: UntypedFormGroup;
  public placeholderPartsForPathLabel: PlaceholderComponent[] = [];
  public placeholderPartsForBuggyName: PlaceholderComponent[] = [];
  public possibleRestZonePositions: RestZonePosition[];
  public possibleRestZoneUserDefinedPositions: RestZonePosition[];
  public possibleLoomDeletePolicies: string[];
  public possibleTextPlaceholderSeparatorForPathLabel: string[];
  public possibleTextPlaceholderSeparatorForBuggyName: string[];
  public listOfPlaceholderCategoriesForBuggyName: PlaceholderCategory[];
  public listOfPlaceholderCategoriesForPathLabel: PlaceholderCategory[];
  public textPlaceholderSeparatorForBuggyName: string;
  public textPlaceholderSeparatorForPathLabel: string;
  public nameGenerationProperties: string[];
  public generateNameConfiguration: NameGenerator;

  private alreadyAdjustedCustomSettings = false;
  private saveButtonTouched = false;
  private showCustomSettings = false;

  private currentSubscription: ProfileSubscription;

  // TODO BMSMC-6924: remove all buggy settings from po settings
  public constructor(
    private readonly toastService: ToastService,
    @Inject(PRODUCTION_ORDERS) private readonly productionOrders: ProductionOrders,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly productionOrdersPlaceholderService: ProductionOrdersPlaceholderService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly navigationHelperService: NavigationHelperService<PropertyValue[]>
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.getPlaceholderCategoriesWithComponents();
    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.initialiseCustomSettings();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.onDestroy();
  }

  public canShowErrorForFormControl(errorCode: string, formControlName: string): boolean {
    return canShowErrorForErrorCodeAndFormControlForFormGroup(errorCode, formControlName, this.productionScheduleCustomSettingsForm);
  }

  public placeholderPartsForPathLabelChanged(placeholderPartsForPathLabel: PlaceholderComponent[]): void {
    this.placeholderPartsForPathLabel = placeholderPartsForPathLabel;
  }

  public placeholderPartsForBuggyNameChanged(placeholderPartsForBuggyName: PlaceholderComponent[]): void {
    this.placeholderPartsForBuggyName = placeholderPartsForBuggyName;
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = some(this.productionScheduleCustomSettingsForm?.controls, (control: UntypedFormControl) => control.invalid && control.touched);
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public saveCustomSettings(): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForms([this.productionScheduleCustomSettingsForm], this.document);
    if (isValid) {
      this.saving = true;

      this.generateNameConfiguration.version = this.generateNameConfiguration.version ?? 1;
      this.generateNameConfiguration.separator = this.productionScheduleCustomSettingsForm.get('nameGeneration.namePlaceholderSeparator').value;
      this.getCustomSettingForPropertyName('generateNameConfiguration').propertyValue = JSON.stringify(this.generateNameConfiguration);

      this.getCustomSettingForPropertyName('textPlaceholderSeparatorForBuggyName').propertyValue = this.productionScheduleCustomSettingsForm.get(
        'buggySelection.textPlaceholderSeparatorForBuggyName'
      ).value;

      this.getCustomSettingForPropertyName('placeholderPartsForBuggyName').propertyValue = this.placeholderPartsForBuggyName.map((placeholderPart: PlaceholderComponent) =>
        this.getPlaceholderPart(placeholderPart)
      );
      this.getCustomSettingForPropertyName('maximumLossInPercent').propertyValue = this.productionScheduleCustomSettingsForm.get('maximumLossInPercent').value;
      this.getCustomSettingForPropertyName('allowExceedingRequestedAmountOfOrderLineItems').propertyValue =
        this.productionScheduleCustomSettingsForm.get('allowExceedingRequestedAmountOfOrderLineItems').value;
      this.getCustomSettingForPropertyName('allowPreFilterOnQualitiesAndColoredYarnSets').propertyValue =
        this.productionScheduleCustomSettingsForm.get('allowPreFilterOnQualitiesAndColoredYarnSets').value;

      this.getCustomSettingForPropertyName('onPlanningBoard').propertyValue = this.productionScheduleCustomSettingsForm.get('onPlanningBoard').value;

      this.getCustomSettingForPropertyName('restZones').propertyValue = this.productionScheduleCustomSettingsForm.get(
        ProductionScheduleCustomSettingsComponent.REST_ZONES_POSITION_FORM_CONTROL_NAME
      ).value;
      this.getCustomSettingForPropertyName('maxDentsRestToDivideOverFree').propertyValue = this.productionScheduleCustomSettingsForm.get('restZones.minimumWidth').value;
      if (this.canShowUserDefinedPositionForRestZone()) {
        this.getCustomSettingForPropertyName('userDefinedRestZonePosition').propertyValue = this.productionScheduleCustomSettingsForm.get('restZones.userDefinedRestZonePosition').value;
      }
      this.getCustomSettingForPropertyName('freeZoneLeftWidthInMillimeters').propertyValue = this.productionScheduleCustomSettingsForm.get('freeZones.leftCommercialWidth').value;
      this.getCustomSettingForPropertyName('freeZoneLeftUseAsMaximum').propertyValue = this.productionScheduleCustomSettingsForm.get('freeZones.leftUseAsMaximum').value;
      this.getCustomSettingForPropertyName('freeZoneRightWidthInMillimeters').propertyValue = this.productionScheduleCustomSettingsForm.get('freeZones.rightCommercialWidth').value;
      this.getCustomSettingForPropertyName('freeZoneRightUseAsMaximum').propertyValue = this.productionScheduleCustomSettingsForm.get('freeZones.rightUseAsMaximum').value;
      this.getCustomSettingForPropertyName('freeZoneInBetweenWidthInMillimeters').propertyValue = this.productionScheduleCustomSettingsForm.get('freeZones.inBetweenCommercialWidth').value;
      this.getCustomSettingForPropertyName('freeZoneInBetweenUseAsMaximum').propertyValue = this.productionScheduleCustomSettingsForm.get('freeZones.inBetweenUseAsMaximum').value;
      this.getCustomSettingForPropertyName('maxDifferenceInPathLengthInMillimeters').propertyValue = this.productionScheduleCustomSettingsForm.get('maxDifferenceInPathCommercialLength').value;
      this.getCustomSettingForPropertyName('textPlaceholderSeparatorForPathLabel').propertyValue =
        this.productionScheduleCustomSettingsForm.get('pathLabel.textPlaceholderSeparatorForPathLabel').value;
      this.getCustomSettingForPropertyName('placeholderPartsForPathLabel').propertyValue = this.placeholderPartsForPathLabel.map((placeholderPart: PlaceholderComponent) => ({
        id: this.getPlaceholderId(placeholderPart.id),
        name: placeholderPart.name,
        maxLength: placeholderPart.maxLength
      }));
      this.getCustomSettingForPropertyName('commercialLabelFontSizeInMM').propertyValue = this.productionScheduleCustomSettingsForm.get('labels.commercialLabelFontSize').value;
      this.getCustomSettingForPropertyName('commercialLabelHeightInMM').propertyValue = this.productionScheduleCustomSettingsForm.get('labels.commercialLabelHeightInMM').value;
      this.getCustomSettingForPropertyName('labelFontFamily').propertyValue = this.productionScheduleCustomSettingsForm.get('labels.labelFontFamily').value;
      this.getCustomSettingForPropertyName('labelFontWeight').propertyValue = this.productionScheduleCustomSettingsForm.get('labels.labelFontWeight').value;
      this.getCustomSettingForPropertyName('labelFontStyle').propertyValue = this.productionScheduleCustomSettingsForm.get('labels.labelFontStyle').value;

      this.getCustomSettingForPropertyName('loomDeletePolicy').propertyValue = this.productionScheduleCustomSettingsForm.get(
        ProductionScheduleCustomSettingsComponent.LOOM_DELETE_POLICY_OPTION_FORM_CONTROL_NAME
      ).value;
      if (this.hasChosenTimeBasedDeletePolicyForLoomFiles()) {
        this.getCustomSettingForPropertyName('loomDeletePolicyTimePeriodInDays').propertyValue = this.productionScheduleCustomSettingsForm.get(
          'loomDeletePolicy.loomDeletePolicyTimePeriodInDays'
        ).value;
      } else {
        this.getCustomSettingForPropertyName('loomDeletePolicyTimePeriodInDays').propertyValue = null;
      }
      this.getCustomSettingForPropertyName('scheduleFileAutoDeleteAfterStartOnLoom').propertyValue = this.productionScheduleCustomSettingsForm.get(
        'loomDeletePolicy.scheduleFileAutoDeleteAfterStartOnLoom'
      ).value;
      this.getCustomSettingForPropertyName('scheduleOnlyStartFromQueue').propertyValue = this.productionScheduleCustomSettingsForm.get('loomDeletePolicy.scheduleOnlyStartFromQueue').value;

      this.productionOrders
        .saveCustomSettings(this.listOfCustomSettings)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving()))
        .subscribe(() => {
          this.toastService.showInfo({
            tapToDismiss: false,
            timeOut: 2000,
            message: this.translate.instant('GENERAL.ACTIONS.SAVED_OBJECT', {
              object: this.translate.instant(this.translate.instant('GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS').toLowerCase())
            })
          });

          this.navigateBack();
        });
    }
  }

  public canShowCancelButton(): boolean {
    return this.alreadyAdjustedCustomSettings;
  }

  public navigateBack(): void {
    this.navigationHelperService.navigateToPreviousRoute(this.PRODUCTION_SCHEDULE_OVERVIEW_URL);
  }

  public canShowUserDefinedPositionForRestZone(): boolean {
    return (
      !AssertionUtils.isNullOrUndefined(this.productionScheduleCustomSettingsForm) &&
      this.productionScheduleCustomSettingsForm.get(ProductionScheduleCustomSettingsComponent.REST_ZONES_POSITION_FORM_CONTROL_NAME).value === RestZonePosition[RestZonePosition.USER_DEFINED]
    );
  }

  public hasChosenTimeBasedDeletePolicyForLoomFiles(): boolean {
    return (
      !AssertionUtils.isNullOrUndefined(this.productionScheduleCustomSettingsForm) &&
      this.productionScheduleCustomSettingsForm.get(ProductionScheduleCustomSettingsComponent.LOOM_DELETE_POLICY_OPTION_FORM_CONTROL_NAME).value === this.possibleLoomDeletePolicies[2]
    );
  }

  public getPossiblePropertyValuesForCustomSetting(propertyName: string): string[] {
    return this.getCustomSettingForPropertyName(propertyName).possiblePropertyValues;
  }

  public hasPlaceholderPartsForPathLabel(): boolean {
    return this.placeholderPartsForPathLabel.length > 0;
  }

  public canShowCustomSettings(): boolean {
    return this.showCustomSettings;
  }

  public hasViewLossPermission(): boolean {
    return this.currentSubscription?.hasPermission(this.VIEW_LOSS_PERMISSION);
  }

  public textPlaceholderSeparatorForBuggyNameChanged(event: MatSelectChange): void {
    this.textPlaceholderSeparatorForBuggyName = event.value;
  }

  public textPlaceholderSeparatorForProductionPathLabelChanged(event: MatSelectChange): void {
    this.textPlaceholderSeparatorForPathLabel = event.value;
  }

  public getKeysOfNameGeneratorSeparator(): string[] {
    return EnumUtils.getEnumNames(NameGeneratorSeparator);
  }

  private sortPossibleTextPlaceholderSeparators(textPlaceholderSeparators: string[]): string[] {
    return sortBy(textPlaceholderSeparators, (separator: string) => separator !== 'NO_SEPARATOR');
  }

  private getPlaceholderId(id: number): number {
    return id === this.CUSTOM_TEXT_ID ? null : id;
  }

  private setFormFields(): void {
    this.generateNameConfiguration = JSON.parse(this.getCustomSettingForPropertyName('generateNameConfiguration').propertyValue);

    this.textPlaceholderSeparatorForBuggyName = this.getCustomSettingForPropertyName('textPlaceholderSeparatorForBuggyName').propertyValue;
    this.possibleTextPlaceholderSeparatorForBuggyName = this.sortPossibleTextPlaceholderSeparators(this.getPossiblePropertyValuesForCustomSetting('textPlaceholderSeparatorForBuggyName'));

    this.placeholderPartsForBuggyName = this.getCustomSettingForPropertyName('placeholderPartsForBuggyName').propertyValue.map(
      (placeholderPartForBuggyName: {id: number; name: string; maxLength: number; value: number | string; configurableValue: boolean}) => PlaceholderComponent.fromJSON(placeholderPartForBuggyName)
    );

    const allowExceedingRequestedAmountOfOrderLineItems = this.getCustomSettingForPropertyName('allowExceedingRequestedAmountOfOrderLineItems').propertyValue;
    const allowPreFilterOnQualitiesAndColoredYarnSets = this.getCustomSettingForPropertyName('allowPreFilterOnQualitiesAndColoredYarnSets').propertyValue;
    const addToPlanningBoard = this.getCustomSettingForPropertyName('onPlanningBoard').propertyValue;
    const maximumLossInPercent = this.getCustomSettingForPropertyName('maximumLossInPercent').propertyValue;
    const restZonePosition = this.getCustomSettingForPropertyName('restZones').propertyValue;
    const restZoneMinimumWidth = this.getCustomSettingForPropertyName('maxDentsRestToDivideOverFree').propertyValue;

    const freeZoneLeftCommercialWidth = Math.round(this.getCustomSettingForPropertyName('freeZoneLeftWidthInMillimeters').propertyValue);
    const freeZoneLeftUseAsMaximum = this.getCustomSettingForPropertyName('freeZoneLeftUseAsMaximum').propertyValue;
    const freeZoneRightCommercialWidth = Math.round(this.getCustomSettingForPropertyName('freeZoneRightWidthInMillimeters').propertyValue);
    const freeZoneRightUseAsMaximum = this.getCustomSettingForPropertyName('freeZoneRightUseAsMaximum').propertyValue;
    const freeZoneInBetweenCommercialWidth = Math.round(this.getCustomSettingForPropertyName('freeZoneInBetweenWidthInMillimeters').propertyValue);
    const freeZoneInBetweenUseAsMaximum = this.getCustomSettingForPropertyName('freeZoneInBetweenUseAsMaximum').propertyValue;
    const maxDifferenceInPathCommercialLength = this.getCustomSettingForPropertyName('maxDifferenceInPathLengthInMillimeters').propertyValue;

    this.textPlaceholderSeparatorForPathLabel = this.getCustomSettingForPropertyName('textPlaceholderSeparatorForPathLabel').propertyValue;
    this.possibleTextPlaceholderSeparatorForPathLabel = this.sortPossibleTextPlaceholderSeparators(this.getPossiblePropertyValuesForCustomSetting('textPlaceholderSeparatorForPathLabel'));

    const commercialLabelFontSize = Math.round(this.getCustomSettingForPropertyName('commercialLabelFontSizeInMM').propertyValue);
    const commercialLabelHeightInMM = this.getCustomSettingForPropertyName('commercialLabelHeightInMM').propertyValue;
    const labelFontFamily = this.getCustomSettingForPropertyName('labelFontFamily').propertyValue;
    const labelFontWeight = this.getCustomSettingForPropertyName('labelFontWeight').propertyValue;
    const labelFontStyle: string = this.getCustomSettingForPropertyName('labelFontStyle').propertyValue;
    const loomDeletePolicyOption = this.getCustomSettingForPropertyName('loomDeletePolicy').propertyValue;
    const scheduleFileAutoDeleteAfterStartOnLoom = this.getCustomSettingForPropertyName('scheduleFileAutoDeleteAfterStartOnLoom').propertyValue;
    const scheduleOnlyStartFromQueue = this.getCustomSettingForPropertyName('scheduleOnlyStartFromQueue').propertyValue;

    this.productionScheduleCustomSettingsForm = this.formBuilder.group({
      nameGeneration: this.formBuilder.group({
        namePlaceholderSeparator: [this.generateNameConfiguration.separator ?? NameGeneratorSeparator[NameGeneratorSeparator.NO_SEPARATOR]]
      }),
      buggySelection: this.formBuilder.group({
        textPlaceholderSeparatorForBuggyName: [this.textPlaceholderSeparatorForBuggyName]
      }),
      maximumLossInPercent: [
        {
          value: maximumLossInPercent,
          disabled: !this.hasViewLossPermission()
        },
        Validators.required
      ],
      maxDifferenceInPathCommercialLength: [maxDifferenceInPathCommercialLength, Validators.required],
      allowExceedingRequestedAmountOfOrderLineItems: [allowExceedingRequestedAmountOfOrderLineItems],
      onPlanningBoard: [addToPlanningBoard],
      allowPreFilterOnQualitiesAndColoredYarnSets: [allowPreFilterOnQualitiesAndColoredYarnSets],
      restZones: this.formBuilder.group({
        position: [restZonePosition],
        minimumWidth: [restZoneMinimumWidth, [Validators.required, Validators.max(this.INT_32_MAXIMUM_VALUE), Validators.min(0)]]
      }),
      freeZones: this.formBuilder.group({
        leftCommercialWidth: [freeZoneLeftCommercialWidth, Validators.required],
        leftUseAsMaximum: [freeZoneLeftUseAsMaximum],
        rightCommercialWidth: [freeZoneRightCommercialWidth, Validators.required],
        rightUseAsMaximum: [freeZoneRightUseAsMaximum],
        inBetweenCommercialWidth: [freeZoneInBetweenCommercialWidth, Validators.required],
        inBetweenUseAsMaximum: [freeZoneInBetweenUseAsMaximum]
      }),
      labels: this.formBuilder.group({
        commercialLabelFontSize: [commercialLabelFontSize, Validators.required],
        commercialLabelHeightInMM: [commercialLabelHeightInMM, Validators.required],
        labelFontFamily: [labelFontFamily],
        labelFontWeight: [labelFontWeight],
        labelFontStyle: [labelFontStyle]
      }),
      pathLabel: this.formBuilder.group({
        textPlaceholderSeparatorForPathLabel: [this.textPlaceholderSeparatorForPathLabel]
      }),
      loomDeletePolicy: this.formBuilder.group({
        loomDeletePolicyOption: [loomDeletePolicyOption],
        scheduleFileAutoDeleteAfterStartOnLoom: [scheduleFileAutoDeleteAfterStartOnLoom],
        scheduleOnlyStartFromQueue: [scheduleOnlyStartFromQueue]
      })
    });

    this.placeholderPartsForPathLabel = this.getCustomSettingForPropertyName('placeholderPartsForPathLabel').propertyValue.map(
      (placeholderPartForPathLabel: {id: number; name: string; maxLength: number; value: string | number; configurableValue: boolean}) => PlaceholderComponent.fromJSON(placeholderPartForPathLabel)
    );

    this.possibleRestZonePositions = this.getCustomSettingForPropertyName('restZones').possiblePropertyValues;
    this.possibleRestZoneUserDefinedPositions = this.getCustomSettingForPropertyName('userDefinedRestZonePosition').possiblePropertyValues;

    if (restZonePosition === RestZonePosition[RestZonePosition.USER_DEFINED]) {
      const restZoneFormGroup = this.productionScheduleCustomSettingsForm.get('restZones') as UntypedFormGroup;
      restZoneFormGroup.addControl('userDefinedRestZonePosition', new UntypedFormControl(this.getCustomSettingForPropertyName('userDefinedRestZonePosition').propertyValue, Validators.required));
    }

    this.possibleLoomDeletePolicies = this.getCustomSettingForPropertyName('loomDeletePolicy').possiblePropertyValues;

    if (loomDeletePolicyOption === this.possibleLoomDeletePolicies[2]) {
      const loomDeletePolicyFormGroup = this.productionScheduleCustomSettingsForm.get('loomDeletePolicy') as UntypedFormGroup;
      loomDeletePolicyFormGroup.addControl('loomDeletePolicyTimePeriodInDays', new UntypedFormControl(this.getCustomSettingForPropertyName('loomDeletePolicyTimePeriodInDays').propertyValue));
    }

    this.setValueWatcherForRestZonePosition();
    this.setValueWatcherForLoomDeletePolicy();
  }

  private getPlaceholderPart(placeholderPart: PlaceholderComponent): any {
    return {id: this.getPlaceholderId(placeholderPart.id), name: placeholderPart.name, maxLength: placeholderPart.maxLength, value: placeholderPart.value};
  }

  private getCustomSettingForPropertyName(propertyName: string): PropertyValue {
    return this.listOfCustomSettings.find((value: PropertyValue) => value.propertyName === propertyName);
  }

  private setValueWatcherForRestZonePosition(): void {
    this.productionScheduleCustomSettingsForm
      .get(ProductionScheduleCustomSettingsComponent.REST_ZONES_POSITION_FORM_CONTROL_NAME)
      .valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((restZonePosition: RestZonePosition) => {
        const restZoneFormGroup = this.productionScheduleCustomSettingsForm.get('restZones') as UntypedFormGroup;

        if (RestZonePosition[restZonePosition] === (RestZonePosition.USER_DEFINED as any)) {
          restZoneFormGroup.addControl('userDefinedRestZonePosition', new UntypedFormControl(this.getCustomSettingForPropertyName('userDefinedRestZonePosition').propertyValue));
        } else {
          restZoneFormGroup.removeControl('userDefinedRestZonePosition');
        }
      });
  }

  private setValueWatcherForLoomDeletePolicy(): void {
    this.productionScheduleCustomSettingsForm
      .get(ProductionScheduleCustomSettingsComponent.LOOM_DELETE_POLICY_OPTION_FORM_CONTROL_NAME)
      .valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((loomDeletePolicy: string) => {
        const loomDeletePolicyFormGroup = this.productionScheduleCustomSettingsForm.get('loomDeletePolicy') as UntypedFormGroup;

        if (loomDeletePolicy === this.possibleLoomDeletePolicies[2]) {
          loomDeletePolicyFormGroup.addControl(
            'loomDeletePolicyTimePeriodInDays',
            new UntypedFormControl(this.getCustomSettingForPropertyName('loomDeletePolicyTimePeriodInDays').propertyValue, [Validators.required, Validators.min(1)])
          );
        } else {
          loomDeletePolicyFormGroup.removeControl('loomDeletePolicyTimePeriodInDays');
        }
      });
  }

  private initialiseCustomSettings(): void {
    forkJoin([this.productionOrders.getListOfCustomSettings(), this.productionOrders.hasAlreadyAdjustedCustomSettings(), this.productionOrders.getNameGenerationProperties()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([listOfCustomSettings, alreadyAdjustedCustomSettings, nameGenerationProperties]: [PropertyValue[], boolean, string[]]) => {
        this.alreadyAdjustedCustomSettings = alreadyAdjustedCustomSettings;
        this.listOfCustomSettings = listOfCustomSettings;
        this.nameGenerationProperties = nameGenerationProperties;
        this.showCustomSettings = true;
        this.setFormFields();
      });
  }

  private getPlaceholderCategoriesWithComponents(): void {
    forkJoin(this.productionOrdersPlaceholderService.getPlaceholderCategories(true, true))
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfPlaceHolderCategories: PlaceholderCategory[][]) => {
        this.listOfPlaceholderCategoriesForPathLabel = listOfPlaceHolderCategories[0];
        this.listOfPlaceholderCategoriesForBuggyName = listOfPlaceHolderCategories[2];
      });
  }
}
