import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[vdwSwipeDirective]'
})
export class SwipeDirective {
  @Output() public swipeRight: EventEmitter<void> = new EventEmitter();
  @Output() public swipeLeft: EventEmitter<void> = new EventEmitter();
  @Output() public swipeUp: EventEmitter<void> = new EventEmitter();
  @Output() public swipeDown: EventEmitter<void> = new EventEmitter();

  public defaultTouch = {x: 0, y: 0, time: 0};

  public constructor(private readonly swipeableElement: ElementRef) {}

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  public handleTouch(event: TouchEvent): void {
    const touch = event.touches[0] || event.changedTouches[0];

    if (event.type === 'touchstart') {
      this.setDefaultTouchValues(touch.pageX, touch.pageY, event.timeStamp);
    } else if (event.type === 'touchend') {
      const {deltaX, deltaY, deltaTime} = this.getTouchDeltas(touch, event);
      this.handleTouchEnd(deltaX, deltaY, deltaTime);
    }
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('mouseup', ['$event'])
  public handleMouse(event: MouseEvent): void {
    if (event.type === 'mousedown') {
      this.setDefaultTouchValues(event.pageX, event.pageY, event.timeStamp);
    } else if (event.type === 'mouseup') {
      const {deltaX, deltaY, deltaTime} = this.getMouseDeltas(event);
      this.handleTouchEnd(deltaX, deltaY, deltaTime);
    }
  }

  private setDefaultTouchValues(x: number, y: number, time: number): void {
    this.defaultTouch = {x, y, time};
  }

  private getTouchDeltas(touch: Touch, event: TouchEvent): {deltaX: number; deltaY: number; deltaTime: number} {
    const deltaX = touch.pageX - this.defaultTouch.x;
    const deltaY = touch.pageY - this.defaultTouch.y;
    const deltaTime = event.timeStamp - this.defaultTouch.time;
    return {deltaX, deltaY, deltaTime};
  }

  private getMouseDeltas(event: MouseEvent): {deltaX: number; deltaY: number; deltaTime: number} {
    const deltaX = event.pageX - this.defaultTouch.x;
    const deltaY = event.pageY - this.defaultTouch.y;
    const deltaTime = event.timeStamp - this.defaultTouch.time;
    return {deltaX, deltaY, deltaTime};
  }

  private handleTouchEnd(deltaX: number, deltaY: number, deltaTime: number): void {
    if (deltaTime < 500) {
      if (Math.abs(deltaX) > this.swipeableElement.nativeElement.offsetWidth / 4) {
        if (deltaX > 0) {
          this.swipeRight.emit();
        } else {
          this.swipeLeft.emit();
        }
      }

      if (Math.abs(deltaY) > this.swipeableElement.nativeElement.offsetWidth / 4) {
        if (deltaY > 0) {
          this.swipeDown.emit();
        } else {
          this.swipeUp.emit();
        }
      }
    }
  }
}
