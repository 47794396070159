<div
  [dndDraggable]
  [dndDragDisabled]="dropListEntry.isDragDisabled"
  [dndObject]="dropListEntry"
  (dndMoved)="removeGroup()"
  (drop)="dragDrop(dropListEntry)"
  class="dnd-list dnd-drag-element"
  [class.hidden]="dropListEntry.isHidden"
  (dndDragStart)="dragActive = true"
  (dndDragEnd)="dragActive = false"
  [dndType]="'allowed'"
  (dndCanceled)="dragActive = false"
>
  <div [class]="'template-container ' + parentOnlyClass">
    <ng-container [ngTemplateOutlet]="dropListContent" [ngTemplateOutletContext]="{data: dropListEntry}"> </ng-container>
    <ng-container *ngIf="dragActive && dndListSettings.allowedTypes.includes('allowed')">
      <div #overlayPlaceholder class="overlay-placeholder"></div>
      <div class="drop-zone-overlay" [dndList]="dndListSettings" [dndModel]="dropListEntry?.childEntries" [dndPlaceholder]="overlayPlaceholder"></div>
    </ng-container>
  </div>

  <mat-expansion-panel
    *ngIf="!isEmpty"
    class="full-width expansion-panel"
    hideToggle="true"
    disabled="true"
    [expanded]="dropListEntry.isExpanded"
    [class.empty-expansion-panel-body]="!dropListEntry.isExpanded"
  >
    <ng-template matExpansionPanelContent>
      <div [dndList]="dndListSettings" [dndModel]="dropListEntry?.childEntries" [dndPlaceholder]="placeholder" class="drop-zone">
        <div #placeholder class="dndPlaceholder" [style.height.px]="56" [style.background-color]="'transparent'"></div>
        <ng-container *ngFor="let entry of dropListEntry.childEntries">
          <vdw-dnd-draggable-drop-list
            [parentOnlyClass]="childOnlyClass"
            [dropListEntry]="entry"
            [parentEntry]="dropListEntry"
            [dropListContent]="dropListContent"
            [dragPreviewContent]="dragPreviewContent"
            [isDropAllowed]="isDropAllowed"
            (drop)="dragDrop(entry)"
          >
          </vdw-dnd-draggable-drop-list>
        </ng-container>
      </div>
    </ng-template>
  </mat-expansion-panel>
  <div class="drag-preview">
    <div #dragPreview [ngTemplateOutlet]="dragPreviewContent" [ngTemplateOutletContext]="{data: dropListEntry}"></div>
  </div>
</div>
