import {AutomaticStopGroupOperator} from './automatic-stop-group-operator.enum';
import {MachineType} from './machine-type.enum';

export class AutomaticStopGroupRule {
  private _id: number;
  private _machineTypes: MachineType[];
  private _operator: AutomaticStopGroupOperator;
  private _value: string;

  public constructor(id: number, machineTypes: MachineType[], operator: AutomaticStopGroupOperator, value: string) {
    this._id = id;
    this._machineTypes = machineTypes;
    this._operator = operator;
    this._value = value;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get machineTypes(): MachineType[] {
    return this._machineTypes;
  }

  public set machineTypes(machineTypes: MachineType[]) {
    this._machineTypes = machineTypes;
  }

  public get operator(): AutomaticStopGroupOperator {
    return this._operator;
  }

  public set operator(operator: AutomaticStopGroupOperator) {
    this._operator = operator;
  }

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this._value = value;
  }

  public static fromJSON(json: any): AutomaticStopGroupRule {
    return new AutomaticStopGroupRule(json.id, json.machineTypes, json.operator, json.value);
  }

  public toJSON(): JSON {
    return {id: this._id, value: this._value, machineTypes: this._machineTypes, operator: this._operator} as any as JSON;
  }
}
