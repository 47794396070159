import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, NoDataOverlayComponentParams, TranslateService} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {CellClassParams, ColDef, FilterChangedEvent, FirstDataRenderedEvent, GridApi, GridOptions, RowNode, RowSelectedEvent} from 'ag-grid-community';

@Component({
  selector: 'app-select-possible-machines',
  templateUrl: './select-possible-machines.component.html',
  styleUrls: ['./select-possible-machines.component.scss']
})
export class SelectPossibleMachinesComponent implements OnInit {
  @HostBinding('class.full-height') public readonly fullHeight = true;
  @HostBinding('class.full-width') public readonly fullWidth = true;
  @ViewChild('machinesGrid') public machinesGrid: AgGridAngular;
  @Input() public selectedMachines: MachineOverviewList[] = [];
  @Input() public fixedSelectedMachineId: number;
  @Input() public machines: MachineOverviewList[];
  @Input() public selectable: boolean;
  @Input() public showSearchBar = true;
  @Output() public selectedMachinesChange: EventEmitter<MachineOverviewList[]> = new EventEmitter<MachineOverviewList[]>();

  public gridOptions: GridOptions;
  public showSelected = false;
  public filteredText: string;

  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.setGridOptions();
  }

  public filterMachineGroupsAndMachines(filteredText: string): void {
    this.filteredText = filteredText;
    this.machinesGrid.api.setGridOption('quickFilterText', filteredText);
  }

  public onShowSelectedToggleChange(showSelected: boolean): void {
    this.showSelected = showSelected;
    this.machinesGrid.api.onFilterChanged();
  }

  private setGridOptions(): void {
    const gridBuilder = this.gridOptionsBuilderFactoryService
      .getBuilder(this.getColumnDefs(), GridIdentifier.SELECT_POSSIBLE_MACHINES)
      .withRowSelection(true, true, true, false, true)
      .withOnRowSelected(({api}: RowSelectedEvent) => this.onRowSelected(api))
      .withAutoGroupColumnDef(this.getAutoGroupColumnDef())
      .withGroupDisplayType('singleColumn')
      .withLoadingOverlay({
        scale: 1
      })
      .withNoRowsOverlay(this.getNoRowsOverlayTitleParameters())
      .withDoesExternalFilterPass((node: RowNode) => {
        return !this.showSelected || node.isSelected();
      })
      .withIsExternalFilterPresent(() => this.showSelected)
      .withRememberStateForNavigationHelper()
      .withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => {
        this.setFilteredText(api);

        if (this.selectedMachines.length > 0) {
          api.forEachNode((node: RowNode) => {
            node.setSelected(this.selectedMachines.some((machine: MachineOverviewList) => machine.id === node.data?.id));
          });
        }
      })
      .withRowClassRules({
        ['fixed-selected-row']: (params: CellClassParams) => this.fixedSelectedMachineId && this.fixedSelectedMachineId === params.node.data?.id
      })
      .withOnFilterChanged(({api}: FilterChangedEvent) => this.onFilterChanged(api));

    if (!this.selectable) {
      gridBuilder.withSuppressRowClickSelection();
    }

    this.gridOptions = gridBuilder.build();
  }

  private getColumnDefs(): ColDef[] {
    const manufacturerField = 'manufacturer.name';
    const typeField = 'type';

    return [
      this.colDefBuilderFactoryService.getBuilder().withHeaderName('TEXTILE_DATA.GROUPS_AND_MACHINES').withField('machineGroup.name').withRowGroup().withHide(true).build(),
      this.colDefBuilderFactoryService.getBuilder().withColId(manufacturerField).withField(manufacturerField, true).withHeaderName('MACHINE.MANUFACTURER').build(),
      this.colDefBuilderFactoryService.getBuilder().withColId(typeField).withField(typeField, true).withHeaderName('GENERAL.TYPE').build()
    ];
  }

  private getAutoGroupColumnDef(): ColDef {
    return this.colDefBuilderFactoryService
      .getBuilder()
      .withHeaderName('TEXTILE_DATA.GROUPS_AND_MACHINES')
      .withField('name')
      .withCellRenderer('agGroupCellRenderer', {
        checkbox: this.selectable
      })
      .withCellClass('machine-group')
      .withSuppressMovable()
      .build();
  }

  private onRowSelected(api: GridApi): void {
    if (this.fixedSelectedMachineId && !api.getSelectedRows().includes(this.machines.find((machine: MachineOverviewList) => machine.id === this.fixedSelectedMachineId))) {
      api.forEachNode((node: RowNode) => {
        if (node.data?.id === this.fixedSelectedMachineId) {
          node.setSelected(true);
        }
      });
    } else {
      this.selectedMachinesChange.emit(api.getSelectedRows());

      if (this.showSelected) {
        api.onFilterChanged();
      }
    }
  }

  private onFilterChanged(gridApi: GridApi): void {
    if (gridApi.getDisplayedRowCount() < 1) {
      this.gridOptions.noRowsOverlayComponentParams = this.getNoRowsOverlayTitleParameters();
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }

  private getNoRowsOverlayTitleParameters(): Partial<NoDataOverlayComponentParams> {
    const parameters: Partial<NoDataOverlayComponentParams> = {
      scale: 1,
      titleKey: null,
      titleParam: null,
      hideDescription: true,
      isAnyFilterPresent: () => {
        return ((this.showSelected && this.machinesGrid.api.getSelectedRows().length > 0) || !this.showSelected) && this.machinesGrid?.api?.isAnyFilterPresent();
      }
    };

    if (this.showSelected) {
      parameters.titleKey = 'PRODUCTION_ORDER.OVERLAY.NO_OBJECTS_SELECTED_YET';
      parameters.titleParam = {objects: this.translate.instant('MACHINE.MACHINE', {count: 2}).toLowerCase()};
    } else {
      parameters.titleParam = 'MACHINE.MACHINE';
    }

    return parameters;
  }

  private setFilteredText(gridApi: GridApi): void {
    this.filteredText = gridApi.getQuickFilter()?.toLowerCase();
  }
}
