<section class="flex-column" vdwBp.gt-md="full-height" *ngIf="canShowCustomSettings()">
  <vdw-tabs-menu-card
    icon="production-order-blue"
    [title]="'GENERAL.CUSTOM_SETTINGS.SETTINGS_FOR_OBJECT' | bmsTranslate: {object: 'PRODUCTION_ORDER.PRODUCTION_ORDER' | bmsTranslate: {count: 1}}"
    [invalidFormMessageError]="canShowInvalidFormMessageError()"
    [saving]="saving"
    [canShowCancel]="canShowCancelButton()"
    (backEvent)="navigateBack()"
    (cancelEvent)="navigateBack()"
    (saveEvent)="saveCustomSettings()"
  ></vdw-tabs-menu-card>

  <form class="production-schedule-custom-settings-form overflow-auto flex-grow flex-row-wrap" vdwBp.gt-md="gap-16" [formGroup]="productionScheduleCustomSettingsForm">
    <mat-card class="full-height overflow-auto">
      <mat-card-content>
        <article fxLayout="column" fxLayoutGap="24px">
          <section fxLayoutGap="16px" fxLayout="column">
            <h4 class="font-weight-semibold">{{ 'GENERAL.GENERAL' | bmsTranslate }}</h4>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="100">
                <div formGroupName="nameGeneration" fxLayout="column" fxFlex>
                  <app-name-generation-pattern
                    [nameGenerationProperties]="nameGenerationProperties"
                    [namePlaceholderSeparator]="productionScheduleCustomSettingsForm.value.nameGeneration.namePlaceholderSeparator"
                    [nameGeneratorParts]="generateNameConfiguration?.parts"
                    (nameGeneratorPartsChange)="generateNameConfiguration.parts = $event"
                  ></app-name-generation-pattern>
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'GENERAL.PLACEHOLDER.NAME_PLACEHOLDER_SEPARATOR' | bmsTranslate }}</mat-label>
                    <mat-select formControlName="namePlaceholderSeparator" disableOptionCentering>
                      <mat-option *ngFor="let nameGeneratorSeparator of getKeysOfNameGeneratorSeparator()" [value]="nameGeneratorSeparator">
                        {{ 'GENERAL.PLACEHOLDER.SEPARATOR.' + nameGeneratorSeparator | bmsTranslate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="16px">
                  <mat-form-field fxFlex="calc((100% - 16px) / 2)">
                    <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.MAXIMUM_LOSS' | bmsTranslate }}</mat-label>
                    <input matInput vdwL10nDecimal type="number" id="maximumLossInPercent" formControlName="maximumLossInPercent" />
                    <span matSuffix>%</span>
                    <mat-error *ngIf="canShowErrorForFormControl('required', 'maximumLossInPercent')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                    <mat-hint *ngIf="!hasViewLossPermission()"> ({{ 'GENERAL.HINTS.UNAUTHORIZED' | bmsTranslate }}) </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="max-difference-in-path-field">
                    <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.MAX_DIFFERENCE_IN_PATH' | bmsTranslate }} </mat-label>
                    <input matInput vdwL10nDecimal formControlName="maxDifferenceInPathCommercialLength" type="number" id="maxDifferenceInPathCommercialLength" />
                    <span matSuffix>{{ 'GENERAL.UNIT.MILLIMETER' | bmsTranslate }}</span>
                    <mat-error *ngIf="productionScheduleCustomSettingsForm.get('maxDifferenceInPathCommercialLength').hasError('required')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-column gap-16">
                  <mat-slide-toggle formControlName="allowExceedingRequestedAmountOfOrderLineItems">
                    {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.ALLOW_EXCEEDING_REQUESTED_AMOUNT_OF_ORDER_LINE_ITEMS' | bmsTranslate }}
                  </mat-slide-toggle>

                  <mat-slide-toggle formControlName="allowPreFilterOnQualitiesAndColoredYarnSets">
                    {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.ALLOW_PREFILTER_ON_QUALITIES_AND_COLOREDYARNSETS' | bmsTranslate }}
                  </mat-slide-toggle>

                  <mat-slide-toggle formControlName="onPlanningBoard">
                    {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.ADD_ON_PLANNING_BOARD' | bmsTranslate }}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </section>
        </article>
      </mat-card-content>
    </mat-card>
    <mat-card class="full-height">
      <mat-card-content>
        <article fxLayout="column">
          <section class="rest-zones-container" formGroupName="restZones" fxLayout="column" fxLayoutGap="16px">
            <h4 class="font-weight-semibold">{{ 'PRODUCTION_ORDER.WEAVE_PLAN.REST_ZONE.REST_ZONE' | bmsTranslate: {count: 2} }}</h4>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="100">
                <mat-radio-group fxLayout="row" fxLayoutGap="16px" formControlName="position" [class.user-defined-position]="canShowUserDefinedPositionForRestZone()">
                  <mat-radio-button color="primary" [value]="possibleRestZonePosition" *ngFor="let possibleRestZonePosition of possibleRestZonePositions">
                    {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.' + possibleRestZonePosition | bmsTranslate }}
                  </mat-radio-button>
                </mat-radio-group>

                <div fxLayout="row" fxLayoutGap="16px" *ngIf="canShowUserDefinedPositionForRestZone()">
                  <mat-form-field fxFlex="65">
                    <mat-select formControlName="userDefinedRestZonePosition" disableOptionCentering>
                      <mat-option *ngFor="let possibleRestZonePosition of possibleRestZoneUserDefinedPositions" [value]="possibleRestZonePosition">
                        {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.' + possibleRestZonePosition | bmsTranslate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxLayout="row">
                  <mat-form-field fxFlex="50">
                    <mat-label>{{ 'PRODUCTION_ORDER.WEAVE_PLAN.REST_ZONE.MINIMUM_WIDTH' | bmsTranslate }}</mat-label>
                    <input matInput vdwL10nDecimal id="maxDentsRestToDivideOverFree" formControlName="minimumWidth" type="number" />
                    <span matSuffix>
                      {{ 'GENERAL.UNIT.DENTS' | bmsTranslate }}
                    </span>
                    <mat-error *vdwCanShowErrorForFormControl="'restZones.minimumWidth'; error: 'required'">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                    <mat-error *vdwCanShowErrorForFormControl="'restZones.minimumWidth'; error: 'max'">
                      {{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: INT_32_MAXIMUM_VALUE} }}
                    </mat-error>
                    <mat-error *vdwCanShowErrorForFormControl="'restZones.minimumWidth'; error: 'min'">
                      {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: 0} }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </section>
          <section formGroupName="freeZones" fxLayout="column" fxLayoutGap="16px">
            <h4 class="font-weight-semibold">{{ 'PRODUCTION_ORDER.WEAVE_PLAN.FREE_ZONE.FREE_ZONE' | bmsTranslate: {count: 2} }}</h4>

            <div fxLayout="row">
              <div fxLayout="column" fxFlex="100">
                <div fxLayout="row" fxLayoutGap="16px">
                  <mat-form-field fxFlex="calc((100% - 32px) / 3)">
                    <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.LEFT' | bmsTranslate }}</mat-label>
                    <input matInput vdwL10nDecimal id="freeZoneLeftCommercialWidth" formControlName="leftCommercialWidth" type="number" />
                    <span matSuffix>{{ 'GENERAL.UNIT.MILLIMETER' | bmsTranslate }}</span>
                    <mat-error *ngIf="productionScheduleCustomSettingsForm.get('freeZones.leftCommercialWidth').hasError('required')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="calc((100% - 32px) / 3)">
                    <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.RIGHT' | bmsTranslate }}</mat-label>
                    <input matInput vdwL10nDecimal formControlName="rightCommercialWidth" type="number" id="freeZoneRightCommercialWidth" />
                    <span matSuffix>{{ 'GENERAL.UNIT.MILLIMETER' | bmsTranslate }}</span>
                    <mat-error *ngIf="productionScheduleCustomSettingsForm.get('freeZones.rightCommercialWidth').hasError('required')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="calc((100% - 32px) / 3)">
                    <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.IN_BETWEEN' | bmsTranslate }}</mat-label>
                    <input matInput vdwL10nDecimal formControlName="inBetweenCommercialWidth" type="number" id="freeZoneInBetweenCommercialWidth" />
                    <span matSuffix>{{ 'GENERAL.UNIT.MILLIMETER' | bmsTranslate }}</span>
                    <mat-error *ngIf="productionScheduleCustomSettingsForm.get('freeZones.inBetweenCommercialWidth').hasError('required')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                  <p class="b1 font-weight-semibold">{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.USE_AS_MAXIMUM_VALUE_FOR' | bmsTranslate }}</p>
                  <mat-checkbox color="primary" formControlName="leftUseAsMaximum">
                    {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.LEFT' | bmsTranslate }}
                  </mat-checkbox>
                  <mat-checkbox color="primary" formControlName="rightUseAsMaximum">
                    {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.RIGHT' | bmsTranslate }}
                  </mat-checkbox>
                  <mat-checkbox color="primary" formControlName="inBetweenUseAsMaximum">
                    {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.IN_BETWEEN' | bmsTranslate }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </section>
          <section formGroupName="loomDeletePolicy" class="padding-top flex-column">
            <div class="flex-column gap-16">
              <h4 class="font-weight-semibold">{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.LOOM_DELETE_POLICY_FOR_FILES' | bmsTranslate: {count: 2} }}</h4>
              <mat-radio-group class="delete-policy flex-row gap-16" formControlName="loomDeletePolicyOption">
                <mat-radio-button color="primary" [value]="possibleLoomDeletePolicy" *ngFor="let possibleLoomDeletePolicy of possibleLoomDeletePolicies">
                  {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.' + possibleLoomDeletePolicy | bmsTranslate }}
                </mat-radio-button>
              </mat-radio-group>
              <div class="flex-row" *ngIf="hasChosenTimeBasedDeletePolicyForLoomFiles()">
                <mat-form-field fxFlex="0 1 calc((100% - 16px) / 2)">
                  <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.DAY' | bmsTranslate }}</mat-label>
                  <input matInput vdwL10nDecimal type="number" id="loomDeletePolicyTimePeriodInDays" formControlName="loomDeletePolicyTimePeriodInDays" />
                  <mat-error *ngIf="productionScheduleCustomSettingsForm.get('loomDeletePolicy.loomDeletePolicyTimePeriodInDays').errors?.required">
                    {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                  </mat-error>
                  <mat-error *ngIf="productionScheduleCustomSettingsForm.get('loomDeletePolicy.loomDeletePolicyTimePeriodInDays').errors?.min">
                    {{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-column gap-16">
              <mat-slide-toggle formControlName="scheduleFileAutoDeleteAfterStartOnLoom">
                {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.SCHEDULE_FILE_AUTO_DELETE_AFTER_START_ON_LOOM' | bmsTranslate }}
              </mat-slide-toggle>

              <mat-slide-toggle formControlName="scheduleOnlyStartFromQueue">
                {{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.SCHEDULE_ONLY_START_FROM_QUEUE' | bmsTranslate }}
              </mat-slide-toggle>
            </div>
          </section>
        </article>
      </mat-card-content>
    </mat-card>
    <mat-card class="full-height overflow-auto">
      <mat-card-content>
        <article fxLayout="column" fxLayoutGap="24px">
          <section formGroupName="labels" fxLayout="column" fxLayoutGap="16px">
            <h4 class="font-weight-semibold">{{ 'GENERAL.LABEL.LABEL' | bmsTranslate: {count: 2} }}</h4>

            <div fxLayout="row">
              <div fxLayout="column" fxFlex="100">
                <mat-form-field>
                  <mat-label>
                    {{ 'GENERAL.LABEL.FONT_FAMILY' | bmsTranslate }}
                  </mat-label>
                  <mat-select formControlName="labelFontFamily" id="labelFontFamily" disableOptionCentering>
                    <mat-option *ngFor="let labelfontFamily of getPossiblePropertyValuesForCustomSetting('labelFontFamily')" [value]="labelfontFamily">
                      {{ labelfontFamily }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div fxLayout="row" fxLayoutGap="16px">
                  <mat-form-field fxFlex>
                    <mat-label>
                      {{ 'GENERAL.LABEL.FONT_WEIGHT' | bmsTranslate }}
                    </mat-label>
                    <mat-select formControlName="labelFontWeight" id="labelFontWeight" disableOptionCentering>
                      <mat-option *ngFor="let labelFontWeight of getPossiblePropertyValuesForCustomSetting('labelFontWeight')" [value]="labelFontWeight">
                        {{ labelFontWeight }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field fxFlex>
                    <mat-label>
                      {{ 'GENERAL.LABEL.FONT_STYLE' | bmsTranslate }}
                    </mat-label>
                    <mat-select formControlName="labelFontStyle" id="labelFontStyle" disableOptionCentering>
                      <mat-option *ngFor="let labelFontStyle of getPossiblePropertyValuesForCustomSetting('labelFontStyle')" [value]="labelFontStyle">
                        {{ labelFontStyle }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                  <mat-form-field fxFlex="0 1 calc((100% - 16px) / 2)">
                    <mat-label>
                      {{ 'GENERAL.LABEL.LABEL_HEIGHT' | bmsTranslate }}
                    </mat-label>
                    <input matInput vdwL10nDecimal formControlName="commercialLabelHeightInMM" type="number" min="0" id="commercialLabelHeightInMM" />
                    <span matSuffix>
                      {{ 'GENERAL.UNIT.MILLIMETER' | bmsTranslate }}
                    </span>
                    <mat-error *ngIf="productionScheduleCustomSettingsForm.get('labels.commercialLabelHeightInMM').hasError('required')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="0 1 calc((100% - 16px) / 2)">
                    <mat-label>
                      {{ 'GENERAL.LABEL.FONT_SIZE' | bmsTranslate }}
                    </mat-label>
                    <input matInput vdwL10nDecimal formControlName="commercialLabelFontSize" type="number" min="0" id="commercialLabelFontSize" />
                    <span matSuffix>
                      {{ 'GENERAL.UNIT.MILLIMETER' | bmsTranslate }}
                    </span>
                    <mat-error *ngIf="productionScheduleCustomSettingsForm.get('labels.commercialLabelFontSize').hasError('required')">
                      {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </section>
          <section formGroupName="pathLabel" fxLayout="column" fxLayoutGap="16px">
            <h4 class="font-weight-semibold">{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.PATH_LABELS.TITLE' | bmsTranslate }}</h4>

            <div fxLayout="row">
              <div fxLayout="column" fxFlex="100">
                <mat-form-field>
                  <mat-label>{{ 'GENERAL.PLACEHOLDER.TEXT_PLACEHOLDER_SEPARATOR' | bmsTranslate }}</mat-label>
                  <mat-select
                    (selectionChange)="textPlaceholderSeparatorForProductionPathLabelChanged($event)"
                    formControlName="textPlaceholderSeparatorForPathLabel"
                    id="textPlaceholderSeparatorForPathLabel"
                    disableOptionCentering
                  >
                    <mat-option *ngFor="let textPlaceholderSeparatorForProductionPathLabel of possibleTextPlaceholderSeparatorForPathLabel" [value]="textPlaceholderSeparatorForProductionPathLabel">
                      {{ 'GENERAL.PLACEHOLDER.SEPARATOR.' + textPlaceholderSeparatorForProductionPathLabel | bmsTranslate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div fxLayout="row" class="full-width">
                  <app-label-placeholder
                    class="full-width"
                    [separatorForTextPlaceholder]="textPlaceholderSeparatorForPathLabel"
                    [listOfPlaceholderCategoriesWithComponents]="listOfPlaceholderCategoriesForPathLabel"
                    [placeholderParts]="placeholderPartsForPathLabel"
                    [buttonTitle]="'GENERAL.PLACEHOLDER.PLACEHOLDER_PARTS_SPECIFY'"
                    [linkFormInputPlaceholder]="'GENERAL.PLACEHOLDER.PLACEHOLDER_PARTS'"
                    (placeholderPartsChanged)="placeholderPartsForPathLabelChanged($event)"
                  >
                  </app-label-placeholder>
                </div>
              </div>
            </div>
          </section>
        </article>
      </mat-card-content>
    </mat-card>
  </form>
</section>
