<div class="amount-specifier-container">
  <mat-form-field [draggable]="true" (dragstart)="preventDefaultEventAndStopPropagation($event)" (click)="preventDefaultEventAndStopPropagation($event)" class="amount">
    <button mat-icon-button matPrefix class="mat-icon-action-button-sm-without-border" [disabled]="!canSubtract()" (click)="subtract()" type="button">
      <mat-icon svgIcon="subtract-small"></mat-icon>
    </button>
    <input vdwL10nDecimal [(ngModel)]="listDrawing.amount" matInput (change)="amountChanged()" type="number" [disabled]="!canEdit()" />
    <button mat-icon-button matSuffix class="mat-icon-action-button-sm-without-border" type="button" (click)="add()" [disabled]="!canAdd()">
      <mat-icon svgIcon="add-small"></mat-icon>
    </button>
  </mat-form-field>
</div>
