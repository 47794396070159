import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {MenuItemEntry} from './menu-item/menu-item-entry.interface';

@Injectable()
export class MenuTreeViewContextService {
  private activeEntry: MenuItemEntry;

  public itemFavorited = new EventEmitter<MenuItemEntry>();
  public activeEntryUpdated = new EventEmitter<void>();

  public constructor(private readonly router: Router) {}

  public setActive(entry: MenuItemEntry): void {
    this.activeEntry = entry;
    this.activeEntryUpdated.emit();
  }

  public isActiveEntry(entry: MenuItemEntry): boolean {
    return this.activeEntry?.link === entry?.link && this.activeEntry?.title === entry?.title;
  }

  public menuItemClicked(entry: MenuItemEntry, event: PointerEvent): void {
    event.stopPropagation();

    if (!AssertionUtils.isEmpty(entry.childEntries)) {
      entry.isExpanded = !entry.isExpanded;
      return;
    }

    if (!AssertionUtils.isNullOrUndefined(entry.link)) {
      entry.isExternalLink ? window.open(entry.link, '_blank') : this.navigateRouter(entry);
    }
  }

  private navigateRouter(entry: MenuItemEntry): void {
    this.activeEntry = entry;
    this.router.navigateByUrl(entry.link);
  }
}
