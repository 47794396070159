import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {WINDOW} from '@vdw/angular-component-library';

@Component({
  selector: 'app-reposition-tooltip',
  templateUrl: './reposition-tooltip.component.html',
  styleUrls: ['./reposition-tooltip.component.scss']
})
export class RepositionTooltipComponent implements OnInit, AfterViewInit {
  public static readonly tooltipPaddingInPx = 19;
  public static readonly arrowHorizontalPositionInPx = 25;
  public static readonly classNameForTopPositionArrow = 'arrow-top';
  public static readonly classNameForBottomPositionArrow = 'arrow-bottom';
  public static readonly classNameForLeftPositionArrow = 'arrow-left';
  public static readonly classNameForRightPositionArrow = 'arrow-right';
  @ViewChild('container', {read: ViewContainerRef, static: true}) public container: ViewContainerRef;
  @ViewChild('arrow') public arrow: MatIcon;

  private readonly component: any;
  private readonly elementRef: ElementRef;
  private readonly dialogRef: MatDialogRef<RepositionTooltipComponent>;

  private targetDOMRect: DOMRect;
  private forceArrowCentered: boolean;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: {targetDOMRect: DOMRect; component: any; forceArrowCentered: boolean},
    @Inject(WINDOW) private readonly window: Window,
    dialogRef: MatDialogRef<RepositionTooltipComponent>,
    elementRef: ElementRef
  ) {
    this.dialogRef = dialogRef;
    this.elementRef = elementRef;
    this.component = data.component;

    this.targetDOMRect = data.targetDOMRect;
    this.forceArrowCentered = data.forceArrowCentered;
  }

  public ngOnInit(): void {
    this.addComponent();
  }

  public ngAfterViewInit(): void {
    this.setTooltipAndArrowPositioning();
  }

  private addComponent(): void {
    this.container.createComponent(this.component);
  }

  private setTooltipAndArrowPositioning(): void {
    let topPosition = this.targetDOMRect.top - this.elementRef.nativeElement.offsetHeight - RepositionTooltipComponent.tooltipPaddingInPx;
    let arrowVerticalPositioningClassName = RepositionTooltipComponent.classNameForBottomPositionArrow;

    if (topPosition < 0) {
      topPosition = this.targetDOMRect.bottom;
      arrowVerticalPositioningClassName = RepositionTooltipComponent.classNameForTopPositionArrow;
    }

    let leftPosition = this.targetDOMRect.left - RepositionTooltipComponent.arrowHorizontalPositionInPx;
    let arrowHorizontalPositioningClassName = RepositionTooltipComponent.classNameForLeftPositionArrow;

    if (leftPosition + this.elementRef.nativeElement.offsetWidth > this.window.innerWidth) {
      leftPosition = this.targetDOMRect.right + RepositionTooltipComponent.arrowHorizontalPositionInPx - this.elementRef.nativeElement.offsetWidth;
      arrowHorizontalPositioningClassName = RepositionTooltipComponent.classNameForRightPositionArrow;
    }

    this.dialogRef.updatePosition({left: `${leftPosition}px`, top: `${topPosition}px`});
    this.dialogRef.addPanelClass(arrowVerticalPositioningClassName);
    this.dialogRef.addPanelClass(arrowHorizontalPositioningClassName);

    if (this.forceArrowCentered) {
      const arrowElement = this.arrow._elementRef.nativeElement as HTMLElement;
      const arrowCenterOffset = arrowElement.offsetLeft - arrowElement.clientWidth / 2;

      if (arrowHorizontalPositioningClassName === RepositionTooltipComponent.classNameForLeftPositionArrow) {
        arrowElement.style.left = `${arrowCenterOffset + this.targetDOMRect.width / 2}px`;
      } else if (arrowHorizontalPositioningClassName === RepositionTooltipComponent.classNameForRightPositionArrow) {
        arrowElement.style.left = `${arrowCenterOffset - this.targetDOMRect.width / 2}px`;
      }
    }
  }
}
