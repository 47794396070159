<section fxLayout="column" fxFlex>
  <vdw-header
    [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'BMSCONFIG.ALERTS.ALERT_TYPE.ALERT_TYPE' | bmsTranslate | lowercase}"
    svgIcon="select-blue"
    [canShowClose]="true"
  ></vdw-header>
  <article fxFlex class="dialog-content">
    <ag-grid-angular #alertTypesGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForAlertTypes" [rowData]="listOfAlertTypes"> </ag-grid-angular>
  </article>
  <vdw-footer [centered]="true" [chooseEventName]="getButtonText()" (chooseEvent)="selectAlertTypes()" [locked]="!canSelectAlertType()"></vdw-footer>
</section>
