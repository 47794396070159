export class TimePickerConstants {
  public static readonly MIN_HOURS_FOR_12 = 1;
  public static readonly MAX_HOURS_FOR_12 = 12;
  public static readonly MIN_HOURS_FOR_24 = 0;
  public static readonly MAX_HOURS_FOR_24 = 23;
  public static readonly MIN_MINUTES = 0;
  public static readonly MAX_MINUTES = 59;
  public static readonly AM = 'AM';
  public static readonly PM = 'PM';
}
