import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MatRadioChange} from '@angular/material/radio';
import {MatSelectChange} from '@angular/material/select';
import {IconPermission} from '@application/services/icons-permissions/icon-permission.interface';
import {IconPermissionsService} from '@application/services/icons-permissions/icon-permissions.service';
import {Company} from '@domain/profile/company';
import {Subscription} from '@domain/profile/subscription';
import {BaseComponent, Device, ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-select-subscription',
  templateUrl: './select-subscription.component.html',
  styleUrls: ['./select-subscription.component.scss']
})
export class SelectSubscriptionComponent extends BaseComponent implements OnInit {
  @Input() public companies: Company[] = [];
  @Input() public subscriptions: Subscription[] = [];
  @Input() public selectedCompany: Company;
  @Input() public selectedSubscription: Subscription;
  @Output() public selectedCompanyChange: EventEmitter<Company> = new EventEmitter<Company>();
  @Output() public selectedSubscriptionChange: EventEmitter<Subscription> = new EventEmitter<Subscription>();

  private isMobile = false;

  public constructor(
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode,
    private readonly iconPermissions: IconPermissionsService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.responsivenessViewMode
      .observeDeviceChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((device: Device) => (this.isMobile = device === Device.PHONE));
  }

  public canSelectCompany(): boolean {
    return this.companies?.length > 1;
  }

  public getPermissionIconsForSubscription(subscription: Subscription): string[] {
    return this.iconPermissions.getPermissionIcons(this.isMobile, subscription).map((item: IconPermission) => `${item.name}-blue`);
  }

  public selectedCompanyChanged(event: MatSelectChange): void {
    this.selectedCompanyChange.emit(this.companies.find((company: Company) => company.id === event.value));
  }

  public selectedSubscriptionChanged(event: MatRadioChange): void {
    this.selectedSubscriptionChange.emit(this.subscriptions.find((subscription: Subscription) => subscription.id === event.value));
  }
}
