// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "19e8c3967d-dirty",
    "hash": "19e8c3967d",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "19e8c3967d-dirty",
    "semverString": null,
    "version": "5.34.1"
};
