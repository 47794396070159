<div fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" *ngIf="canSelectCompany()">
    <div fxLayout="column" fxFlex="100" fxLayoutGap="8px">
      <h4 fxLayout="row">{{ 'AUTHENTICATION.SUBSCRIPTIONS.YOUR_COMPANIES' | bmsTranslate }}</h4>
      <div fxLayout="row">
        <mat-form-field fxFlex fxFlex.gt-xs="280px">
          <mat-select [value]="selectedCompany.id" (selectionChange)="selectedCompanyChanged($event)" disableOptionCentering>
            <mat-option *ngFor="let company of companies" [value]="company.id">
              {{ company.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="100" fxLayoutGap="8px">
      <h4 fxLayout="row">{{ 'AUTHENTICATION.SUBSCRIPTIONS.YOUR_SUBSCRIPTIONS' | bmsTranslate }}</h4>
      <div fxLayout="row">
        <mat-radio-group [value]="selectedSubscription.id" fxLayout="column" fxLayoutGap="8px" (change)="selectedSubscriptionChanged($event)">
          <div fxLayout.gt-xs="row" fxLayout="column" *ngFor="let subscription of subscriptions" fxLayoutGap="24px">
            <mat-radio-button [value]="subscription.id" fxFlex.gt-xs="0 0 155px">
              <vdw-ellipsis-label [text]="subscription.name" matTooltipPosition="above"></vdw-ellipsis-label>
            </mat-radio-button>
            <div class="permissions-container" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px">
              <div fxLayout="row wrap">
                <div class="permission-item" *ngFor="let permissionIcon of getPermissionIconsForSubscription(subscription)">
                  <mat-icon class="permission-icon" [svgIcon]="permissionIcon"> </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>
