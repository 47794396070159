import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNumberOfCreelPositionsData} from '@application/helper/navigation-helper/navigation-number-of-creel-positions-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {ColoredYarnSets, COLORED_YARN_SETS} from '@infrastructure/http/colored-yarn-set/colored-yarn-sets';
import {GridColoredYarnSetPreviewComponent} from '@presentation/pages/textile-data/colored-yarn-set/overview/grid-colored-yarn-set-preview/grid-colored-yarn-set-preview.component';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {AssertionUtils, BaseComponent, ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, SelectGridDialog} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ICellRendererParams, ITooltipParams, RowNode} from 'ag-grid-community';
import {isNil, remove} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-colored-yarn-set.component.html'
})
export class SelectColoredYarnSetComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('coloredYarnSetsGrid') public coloredYarnSetsGrid: AgGridAngular;
  public listOfColoredYarnSets: OverviewListColoredYarnSet[];
  public numberOfCreelPositions: number;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public readonly COLORED_YARN_SET_EDIT_PERMISSION = LastModifiedCardUtils.getPermissionToModifyItems('coloredYarnSet');

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(COLORED_YARN_SETS) public coloredYarnSets: ColoredYarnSets,
    private readonly dialogRef: MatDialogRef<SelectColoredYarnSetComponent>,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly navigationHelperService: NavigationHelperService<NavigationSelectColoredYarnSetData>
  ) {
    super();
    this.numberOfCreelPositions = data?.numberOfCreelPositions;
  }

  public get gridOptionsForListOfColoredYarnSets(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.initializeGridOptionsForListOfColoredYarnSets();
    this.getColoredYarnSets();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.coloredYarnSetsGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<NavigationSelectColoredYarnSetData>({dialogComponent: SelectColoredYarnSetComponent});
  }

  public selectColoredYarnSet(): void {
    this.dialogRef.close(this.coloredYarnSetsGrid.api.getSelectedRows()[0]);
  }

  public canSelectColoredYarnSet(): boolean {
    return !AssertionUtils.isEmpty(this.coloredYarnSetsGrid?.api?.getSelectedRows());
  }

  public navigateToAddColoredYarnSet(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState<NavigationNumberOfCreelPositionsData>(
      {numberOfCreelPositions: this.numberOfCreelPositions},
      RouteUtils.paths.texStyle.coloredYarnSet.addColoredYarnSet.absolutePath
    );
  }

  private initializeGridOptionsForListOfColoredYarnSets(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfColoredYarnSets(), GridIdentifier.SELECT_COLORED_YARN_SET)
        .withLoadingOverlay({
          scale: 0.7
        })
        .withNoRowsOverlay({
          scale: 0.7,
          titleKey: 'TEXTILE_DATA.COLORED_YARN_SET.OVERLAY.NO_COLORED_YARN_SETS_OF_POSITIONS',
          titleParam: {numberOfCreelPositions: this.numberOfCreelPositions},
          hideDescription: true
        })
        .withOnCellDoubleClicked(() => this.selectColoredYarnSet())
        .withRememberStateForNavigationHelper()
        .build()
    ];
  }

  private getColoredYarnSets(): void {
    this.coloredYarnSets
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfColoredYarnSets: OverviewListColoredYarnSet[]) => {
        this.listOfColoredYarnSets = listOfColoredYarnSets;
        if (!isNil(this.numberOfCreelPositions)) {
          remove(this.listOfColoredYarnSets, (coloredYarnSet: OverviewListColoredYarnSet) => {
            return this.numberOfCreelPositions !== coloredYarnSet.overviewListColorSet.creelPositions.length;
          });
        }
      });
  }

  private getColumnDefsForListOfColoredYarnSets(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.MATERIAL_SET.NUMBER_OF_CREEL_POSITIONS')
        .withCellRenderer((params: ICellRendererParams) => this.getCreelPositions(params))
        .withTooltipValueGetter((params: ITooltipParams) => this.getCreelPositions(params))
        .withComparator((valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode) => {
          return (nodeA.data as OverviewListColoredYarnSet).overviewListColorSet.creelPositions.length - (nodeB.data as OverviewListColoredYarnSet).overviewListColorSet.creelPositions.length;
        })
        .withoutFilter()
        .build(),
      this.colDefBuilderFactory.getBuilder().withField('overviewListColorSet.name', true).withHeaderName('TEXTILE_DATA.COLOR_SET.COLOR_SET').build(),
      this.colDefBuilderFactory.getBuilder().withField('overviewListYarnSet.name', true).withHeaderName('TEXTILE_DATA.YARN_SET.YARN_SET').build(),
      this.colDefBuilderFactory.getBuilder().withHeaderName('GENERAL.PREVIEW').withCellRenderer(GridColoredYarnSetPreviewComponent).withSortable(false).withValueGetter('data').withoutFilter().build()
    ];
  }

  private getCreelPositions(params: any): string {
    return `${(params.data as OverviewListColoredYarnSet).overviewListColorSet.creelPositions.length}`;
  }
}

interface NavigationSelectColoredYarnSetData {
  dialogComponent: typeof SelectColoredYarnSetComponent;
}
