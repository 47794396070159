import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AssertionUtils, LocalStorageService, MenuItemEntry, TranslateService} from '@vdw/angular-component-library';

@Injectable({providedIn: 'root'})
export class NavigationService {
  public cachedMenuItems: MenuItemEntry[];
  private readonly IS_SWITCHING_SUBSCRIPTION_KEY = 'isSwitchingSubscription';

  public constructor(
    private readonly router: Router,
    private readonly localStorage: LocalStorageService,
    private readonly translate: TranslateService
  ) {
    this.cachedMenuItems = this.composeMenuItems(null);
  }

  public getDefaultRoute(subscription: Subscription): string {
    const navigationBarRouteLinkItems = this.cachedMenuItems
      .flatMap((item: MenuItemEntry) => item.childEntries)
      .filter((item: MenuItemEntry) => this.hasRequiredPermission(item, subscription) && !AssertionUtils.isNullOrUndefined(item.link));

    const texStateNavigationItem = navigationBarRouteLinkItems.find((item: MenuItemEntry) => item.link === RouteUtils.paths.texState.absolutePath);

    return texStateNavigationItem ? texStateNavigationItem.link : navigationBarRouteLinkItems[0]?.link;
  }

  public composeMenuItems(subscription: Subscription): MenuItemEntry[] {
    return [
      {title: this.translate.instant('GENERAL.NAVIGATION.FAVOURITES'), childEntries: [], isExpanded: true},
      {
        title: this.translate.instant('GENERAL.NAVIGATION.MONITORING'),
        childEntries: [
          {
            title: this.translate.instant('GENERAL.NAVIGATION.PLANT_VIEW'),
            icon: 'solid-monitoring',
            link: RouteUtils.paths.texState.absolutePath,
            permissionNumber: RouteUtils.paths.texState.requiredPermission
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.EVENT_BOARD'),
            icon: 'solid-analytics',
            link: RouteUtils.paths.texBi.machineEventBoard.absolutePath,
            permissionNumber: RouteUtils.paths.texBi.machineEventBoard.requiredPermission
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.TRENDS_REPORTS'),
            icon: 'solid-insights',
            childEntries: [
              {title: this.translate.instant('TEXBI.REPORTS'), link: RouteUtils.paths.texBi.reports.absolutePath, permissionNumber: RouteUtils.paths.texBi.reports.requiredPermission},
              {
                title: this.translate.instant('TEXBI.WINDERVIBRATIONREPORT'),
                link: RouteUtils.paths.texBi.windervibrationreport.absolutePath,
                permissionNumber: RouteUtils.paths.texBi.windervibrationreport.requiredPermission
              },
              {
                title: this.translate.instant('TEXBI.WMREPORTS'),
                link: RouteUtils.paths.texBi.wmreports.absolutePath,
                permissionNumber: RouteUtils.paths.texBi.wmreports.requiredPermission
              },
              {
                title: this.translate.instant('TEXBI.QMREPORTS'),
                link: RouteUtils.paths.texBi.qmreports.absolutePath,
                permissionNumber: RouteUtils.paths.texBi.qmreports.requiredPermission
              },
              {
                title: this.translate.instant('TEXBI.EMREPORTS'),
                link: RouteUtils.paths.texBi.emreports.absolutePath,
                permissionNumber: RouteUtils.paths.texBi.emreports.requiredPermission
              },
              {
                title: this.translate.instant('MACHINE.WINDER_VIBRATION_FORM.TITLE'),
                link: RouteUtils.paths.texBi.winderVibrationForm.absolutePath,
                permissionNumber: RouteUtils.paths.texBi.winderVibrationForm.requiredPermission
              }
            ]
          }
        ]
      },
      {
        title: this.translate.instant('GENERAL.NAVIGATION.SCHEDULING'),
        childEntries: [
          {
            title: this.translate.instant('GENERAL.NAVIGATION.PLANNING_BOARD'),
            icon: 'solid-schedule',
            link: RouteUtils.paths.texFab.planning.absolutePath,
            permissionNumber: RouteUtils.paths.texFab.planning.requiredPermission,
            shouldHide: subscription?.hasPermission(RouteUtils.paths.texFab.planningPrototype.requiredPermission)
              ? true
              : !subscription?.hasPermission(RouteUtils.paths.texFab.planning.requiredPermission)
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.PLANNING_BOARD'),
            icon: 'solid-schedule',
            link: RouteUtils.paths.texFab.planningPrototype.absolutePath,
            permissionNumber: RouteUtils.paths.texFab.planningPrototype.requiredPermission
          },
          {
            title: this.translate.instant('PRODUCTION_ORDER.PRODUCTION_ORDER', {count: 2}) as string,
            icon: 'solid-list-alt',
            shouldHide: !subscription?.hasAtLeastOneOfThePermissions([
              RouteUtils.paths.texFab.productionOrder.requiredPermission,
              RouteUtils.paths.texFab.weaving.productionOrder.requiredPermission,
              RouteUtils.paths.texFab.tufting.productionOrder.requiredPermission,
              RouteUtils.paths.texFab.plastic.productionOrder.requiredPermission
            ]),
            childEntries: [
              {
                title: this.translate.instant('PRODUCTION_ORDER.CARPET'),
                link: RouteUtils.paths.texFab.productionOrder.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.productionOrder.requiredPermission
              },
              {
                title: this.translate.instant('PRODUCTION_ORDER.WEAVING'),
                link: RouteUtils.paths.texFab.weaving.productionOrder.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.weaving.productionOrder.requiredPermission
              },
              {
                title: this.translate.instant('PRODUCTION_ORDER.TUFTING'),
                link: RouteUtils.paths.texFab.tufting.productionOrder.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.tufting.productionOrder.requiredPermission
              },
              {
                title: this.translate.instant('PRODUCTION_ORDER.PLASTIC'),
                link: RouteUtils.paths.texFab.plastic.productionOrder.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.plastic.productionOrder.requiredPermission
              }
            ]
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.DEFINITIONS'),
            icon: 'solid-edit-document',
            childEntries: [
              {
                title: this.translate.instant('GENERAL.NAVIGATION.PRODUCT_RELATED'),
                childEntries: [
                  {
                    title: this.translate.instant('TEXTILE_DATA.CREEL.CREEL', {count: 1}),
                    link: RouteUtils.paths.texStyle.creel.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.creel.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT', {count: 1}),
                    link: RouteUtils.paths.texStyle.weaveProduct.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.weaveProduct.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT', {count: 1}),
                    link: RouteUtils.paths.texStyle.tuftProduct.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.tuftProduct.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT', {count: 1}),
                    link: RouteUtils.paths.texStyle.plasticProduct.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.plasticProduct.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.FIXED_ORDER.FIXED_ORDER', {count: 1}),
                    link: RouteUtils.paths.texStyle.fixedSchedule.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.fixedSchedule.requiredPermission
                  }
                ]
              },
              {
                title: this.translate.instant('GENERAL.NAVIGATION.QUALITY_RELATED'),
                childEntries: [
                  {
                    title: this.translate.instant('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY', {count: 1}),
                    link: RouteUtils.paths.texStyle.machineQuality.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.machineQuality.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE', {count: 1}),
                    link: RouteUtils.paths.texStyle.weaveStructure.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.weaveStructure.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.COMPANY_WEAVE_STRUCTURE.COMPANY_WEAVE_STRUCTURE', {count: 1}),
                    link: RouteUtils.paths.texStyle.companyWeaveStructure.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.companyWeaveStructure.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.WEAVE_STRUCTURE.MANAGE_WEAVE_STRUCTURE'),
                    link: RouteUtils.paths.texStyle.weaveStructure.manageOverviewWeaveStructure.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.weaveStructure.manageOverviewWeaveStructure.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.GROUPED_WEAVESTRUCTURE.GROUPED_WEAVESTRUCTURE', {count: 1}),
                    link: RouteUtils.paths.texStyle.groupedWeaveStructure.overviewGroupedWeaveStructure.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.groupedWeaveStructure.overviewGroupedWeaveStructure.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE', {count: 1}),
                    link: RouteUtils.paths.texStyle.finishingTemplate.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.finishingTemplate.requiredPermission
                  },
                  {
                    title: this.translate.instant('PATH_LAYOUT_TEMPLATE.CARD_TITLE'),
                    link: RouteUtils.paths.texFab.pathLayoutTemplate.absolutePath,
                    permissionNumber: RouteUtils.paths.texFab.pathLayoutTemplate.requiredPermission
                  },
                  {
                    title: this.translate.instant('PLANNING.BUGGY.BUGGY'),
                    link: RouteUtils.paths.texStyle.buggy.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.buggy.requiredPermission
                  }
                ]
              },
              {
                title: this.translate.instant('GENERAL.NAVIGATION.YARN_RELATED'),
                childEntries: [
                  {
                    title: this.translate.instant('TEXTILE_DATA.COLOR.COLOR', {count: 1}),
                    link: RouteUtils.paths.texStyle.color.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.color.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.COLOR_SET.COLOR_SET', {count: 1}),
                    link: RouteUtils.paths.texStyle.colorSet.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.colorSet.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.YARN_TYPE.YARN_TYPE', {count: 1}),
                    link: RouteUtils.paths.texStyle.yarn.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.yarn.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.YARN_SET.YARN_SET', {count: 1}),
                    link: RouteUtils.paths.texStyle.yarnSet.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.yarnSet.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.COLORED_YARN.COLORED_YARN', {count: 1}),
                    link: RouteUtils.paths.texStyle.coloredYarn.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.coloredYarn.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET', {count: 1}),
                    link: RouteUtils.paths.texStyle.coloredYarnSet.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.coloredYarnSet.requiredPermission
                  },
                  {
                    title: this.translate.instant('TEXTILE_DATA.WEFT_COLORED_YARN_SET.WEFT_COLORED_YARN_SET', {count: 1}),
                    link: RouteUtils.paths.texStyle.weftColoredYarnSet.absolutePath,
                    permissionNumber: RouteUtils.paths.texStyle.weftColoredYarnSet.requiredPermission
                  }
                ]
              }
            ]
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.DESIGNS'),
            icon: 'solid-image',
            shouldHide: !subscription?.hasPermission(Permission.DESIGNS_VIEW),
            childEntries: [
              {
                title: this.translate.instant('DESIGN_LIBRARY.DESIGN_LIBRARY'),
                link: RouteUtils.paths.texEdit.designLibrary.absolutePath,
                permissionNumber: RouteUtils.paths.texEdit.designLibrary.requiredPermission
              },
              {
                title: this.translate.instant('TECHNICAL_DATASHEETS.DATASHEET', {count: 2}),
                link: RouteUtils.paths.texEdit.dataSheets.absolutePath,
                permissionNumber: RouteUtils.paths.texEdit.dataSheets.requiredPermission
              },
              {
                title: this.translate.instant('TEXEDIT_CARDS.EXTERNAL_APPLICATIONS.TUFTLINK.TITLE'),
                link: RouteUtils.paths.texEdit.tuftLink.absolutePath,
                permissionNumber: RouteUtils.paths.texEdit.tuftLink.requiredPermission
              },
              {
                title: this.translate.instant('PRODUCT_CONFIGURATION.PRODUCT_CONFIGURATION', {count: 2}),
                link: RouteUtils.paths.texEdit.productConfiguration.absolutePath,
                permissionNumber: RouteUtils.paths.texEdit.productConfiguration.requiredPermission
              }
            ]
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.SALES'),
            icon: 'solid-storefront',
            shouldHide: !subscription?.hasPermission(Permission.SALES_VIEW),
            childEntries: [
              {
                title: this.translate.instant('CUSTOMERS.CUSTOMER', {count: 2}),
                link: RouteUtils.paths.texFab.customer.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.customer.requiredPermission
              },
              {
                title: this.translate.instant('ARTICLES.ARTICLE', {count: 2}),
                link: RouteUtils.paths.texFab.article.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.article.requiredPermission
              },
              {
                title: this.translate.instant('GENERAL.NAVIGATION.ORDERS'),
                link: RouteUtils.paths.texFab.salesOrder.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.salesOrder.requiredPermission
              },
              {
                title: this.translate.instant('ORDERBOOK.ORDERBOOK'),
                link: RouteUtils.paths.texFab.orderbook.absolutePath,
                permissionNumber: RouteUtils.paths.texFab.orderbook.requiredPermission
              }
            ]
          }
        ]
      },
      {
        title: this.translate.instant('GENERAL.NAVIGATION.RESOURCES'),
        childEntries: [
          {
            title: this.translate.instant('GENERAL.NAVIGATION.CALENDAR'),
            icon: 'solid-calendar-month',
            childEntries: [
              {
                title: this.translate.instant('CALENDAR.SHIFT.SHIFT', {count: 2}),
                link: RouteUtils.paths.calendar.shift.absolutePath,
                permissionNumber: RouteUtils.paths.calendar.shift.requiredPermission
              }
            ]
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.DOCUMENTS_AND_TRAINING'),
            icon: 'solid-article',
            permissionNumber: RouteUtils.paths.texBot.requiredPermission,
            childEntries: [
              {
                title: this.translate.instant('MACHINE_DOCUMENTATION.DOCUMENTATION'),
                link: RouteUtils.paths.texBot.machineDocumentation.absolutePath,
                permissionNumber: RouteUtils.paths.texBot.machineDocumentation.requiredPermission
              },
              {
                title: this.translate.instant('TRAINING.TITLE'),
                link: RouteUtils.paths.texBot.training.absolutePath,
                permissionNumber: RouteUtils.paths.texBot.training.requiredPermission
              },
              {
                title: this.translate.instant('GENERAL.ACTIONS.MANAGE_OBJECT', {object: 'TRAINING.TITLE'}),
                link: RouteUtils.paths.texBot.training.manage.absolutePath,
                permissionNumber: RouteUtils.paths.texBot.training.manage.requiredPermission
              }
            ]
          },
          {
            title: this.translate.instant('GENERAL.NAVIGATION.UTILITIES'),
            icon: 'solid-build',
            permissionNumber: RouteUtils.paths.utilities.requiredPermission,
            childEntries: [
              {
                title: this.translate.instant('UTILITIES.TOOL.TOOL', {count: 2}),
                link: RouteUtils.paths.utilities.tool.absolutePath,
                permissionNumber: RouteUtils.paths.utilities.tool.requiredPermission
              }
            ]
          }
        ]
      }
    ];
  }

  public composeProcessItem(): MenuItemEntry {
    return {title: this.translate.instant('BACKGROUND_JOBS.PROCESSES'), permissionNumber: Permission.JOB_VIEW};
  }

  public setIsSwitchingSubscription(): void {
    this.localStorage.set(this.IS_SWITCHING_SUBSCRIPTION_KEY, true);
  }

  public removeIsSwitchingSubscription(): void {
    this.localStorage.remove(this.IS_SWITCHING_SUBSCRIPTION_KEY);
  }

  public checkIsSwitchingSubscription(subscription: Subscription): boolean {
    if (this.localStorage.get(this.IS_SWITCHING_SUBSCRIPTION_KEY)) {
      this.removeIsSwitchingSubscription();
      this.router.navigateByUrl(this.getDefaultRoute(subscription));
      return true;
    }
    return false;
  }

  private hasRequiredPermission(item: MenuItemEntry, subscription: Subscription): boolean {
    return AssertionUtils.isNullOrUndefined(item.permissionNumber) || subscription.hasPermission(item.permissionNumber);
  }
}
