import {Component, Input, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../base-component';

@Component({
  selector: 'vdw-paginator',
  styleUrls: ['./paginator.component.scss'],
  templateUrl: './paginator.component.html'
})
export class PaginatorComponent extends BaseComponent {
  @Input() public currentPageIndex: number;
  @Input() public totalPages: number;
  @Output() public currentPageIndexChange: Observable<number>;

  public get pages(): number[] {
    let pages: number[];

    if (this.totalPages <= 6) {
      pages = [...new Array(this.totalPages).keys()];
    } else if (this.totalPages === 7) {
      if (this.currentPageIndex <= 4) {
        pages = [0, 1, 2, 3, 4, 6];
      } else {
        pages = [0, 2, 3, 4, 5, 6];
      }
    } else {
      if (this.currentPageIndex <= 3) {
        pages = [0, 1, 2, 3, 4, this.totalPages - 1];
      } else if (this.currentPageIndex >= this.totalPages - 3) {
        pages = [0, this.totalPages - 5, this.totalPages - 4, this.totalPages - 3, this.totalPages - 2, this.totalPages - 1];
      } else {
        pages = [0, this.currentPageIndex - 1, this.currentPageIndex, this.currentPageIndex + 1, this.totalPages - 1];
      }
    }

    if (this.canShowMore(pages[0], pages[1]) && pages[1] - pages[0] === 2) {
      pages.splice(1, 0, pages[0] + 1);
    }

    if (this.canShowMore(pages[pages.length - 2], pages[pages.length - 1]) && pages[pages.length - 1] - pages[pages.length - 2] === 2) {
      pages.splice(pages.length - 1, 0, pages[pages.length - 2] + 1);
    }

    return pages;
  }

  private readonly currentPageChangeSubject = new Subject<number>();

  public constructor() {
    super();
    this.currentPageIndexChange = this.currentPageChangeSubject.asObservable().pipe(distinctUntilChanged(), takeUntil(this.unSubscribeOnViewDestroy));
  }

  public isCurrent(page: number): boolean {
    return page === this.currentPageIndex;
  }

  public canGoToPrevious(): boolean {
    return this.currentPageIndex > 0;
  }

  public canGoToNext(): boolean {
    return this.currentPageIndex < this.totalPages - 1;
  }

  public canShowMore(previousPage: number, nextPage: number): boolean {
    return Math.abs(previousPage - nextPage) > 1;
  }

  public previous(): void {
    if (this.canGoToPrevious()) {
      this.goTo(this.currentPageIndex - 1);
    }
  }

  public next(): void {
    if (this.canGoToNext()) {
      this.goTo(this.currentPageIndex + 1);
    }
  }

  public goTo(page: number): void {
    this.currentPageIndex = page;
    this.currentPageChangeSubject.next(this.currentPageIndex);
  }
}
