<section fxFlex="100" class="terms-and-conditions" fxLayout="column">
  <vdw-mobile-page-header [title]="'TERMS_AND_CONDITIONS.GENERAL_TERMS_AND_CONDITIONS' | bmsTranslate" [showBackButton]="false"></vdw-mobile-page-header>

  <article fxFlex fxLayout="row" class="terms-and-conditions-container">
    <mat-checkbox [(ngModel)]="agreesWithTermsAndConditions">
      <span class="b1" [innerHTML]="'TERMS_AND_CONDITIONS.AGREE' | bmsTranslate: {url: termsAndConditionsUrl}"></span>
    </mat-checkbox>
  </article>

  <mat-toolbar fxLayoutAlign="center center" fxLayout="row">
    <button fxFlex="100" mat-flat-button color="primary" (click)="onSignTermsAndConditons()">
      {{ 'TERMS_AND_CONDITIONS.SIGN' | bmsTranslate }}
    </button>
  </mat-toolbar>
</section>
