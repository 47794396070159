import {Component, Input} from '@angular/core';
import {Color, convertRGBToHexadecimalColorCode, Pattern} from '@vdw/angular-component-library';

@Component({
  selector: 'app-declaration-preview',
  templateUrl: './declaration-preview.component.html',
  styleUrls: ['./declaration-preview.component.scss']
})
export class DeclarationPreviewComponent {
  @Input() public set color(color: Color) {
    if (color?.rgb) {
      this.hexColor = convertRGBToHexadecimalColorCode(color.rgb);
    }
  }

  @Input() public pattern: Pattern;
  @Input() public abbreviation: string;

  public hexColor: string;
}
