import {InjectionToken} from '@angular/core';
import {Declaration} from '@domain/machine/declaration';
import {DeclarationGroup} from '@domain/machine/declaration-group';
import {MachineType} from '@domain/machine/machine-type.enum';
import {Color} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {Repository} from '../repository';

export const DECLARATIONS = new InjectionToken<Declarations>('Declarations');

export interface Declarations extends Repository<Declaration, Declaration> {
  getColors(): Observable<Color[]>;
  getDeclarationGroups(): Observable<DeclarationGroup[]>;
  saveDeclarationGroups(declarations: DeclarationGroup[]): Observable<void>;
  getDeclarationGroupsForMachineTypes(machineTypes: MachineType[]): Observable<DeclarationGroup[]>;
  isCodeAvailable(code: number, machineType: MachineType): Observable<boolean>;
  getSuggestionCode(code: number, machineType: MachineType): Observable<number>;
  getListOfActions(): Observable<string[]>;
}
