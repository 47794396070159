import {ChangeDetectorRef} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {noop} from 'lodash-es';
import {ArrowPosition} from './arrow-position.enum';
import {RepositionWatchDialog} from './reposition-watch-dialog.interface';

export class StubRepositionWatchDialogService implements RepositionWatchDialog {
  public initWithPosition(_dialogRef: MatDialogRef<any, any>, _changeDetectorRef: ChangeDetectorRef, _arrowPosition: ArrowPosition, _x: number, _y: number): void {
    noop();
  }

  public initWithElement(_dialogRef: MatDialogRef<any, any>, _changeDetectorRef: ChangeDetectorRef, _arrowPosition: ArrowPosition, _element: Element): void {
    noop();
  }

  public destroy(): void {
    noop();
  }
}
