<section fxLayout="column" fxFlex>
  <vdw-header
    [title]="'GENERAL.ACTIONS.CHOOSE_OBJECT' | bmsTranslate: {object: 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET' | bmsTranslate: {count: 1} | lowercase}"
    svgIcon="select-blue"
  ></vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button
      *appHasPermission="COLORED_YARN_SET_EDIT_PERMISSION"
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate"
      (click)="navigateToAddColoredYarnSet()"
    >
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #coloredYarnSetsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfColoredYarnSets" [rowData]="listOfColoredYarnSets"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectColoredYarnSet()" [centered]="true" (chooseEvent)="selectColoredYarnSet()"></vdw-footer>
</section>
