import {ComponentType} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {GridApi} from 'ag-grid-community';
import {finalize, Observable, Subject} from 'rxjs';
import {FooterConstants} from '../../custom-components/footer/footer.constants';
import {GridOptionsBuilderFactoryService} from '../../grids/grid-options-builder-factory.service';
import {AlertDialogData, AlertDialogResult} from '../alert-dialog/alert-dialog-data';
import {AlertDialogComponent} from '../alert-dialog/alert-dialog.component';
import {SelectGridDialog} from '../select-grid-dialog.interface';

@Injectable()
export class DialogOpenerService {
  public constructor(
    private readonly dialog: MatDialog,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService
  ) {}

  public open<T, S>(component: ComponentType<T>, config: MatDialogConfig<S>): MatDialogRef<T> {
    return this.dialog.open(component, config);
  }

  public openDialog<T, S, R>(component: ComponentType<T>, config: MatDialogConfig<S>): Observable<R> {
    return this.dialog.open(component, config)?.afterClosed();
  }

  public openCrudDialog<T, S, R>(component: ComponentType<T>, config: MatDialogConfig<S>): Observable<R> {
    const dialogRef = this.dialog.open(component, config);

    dialogRef.afterOpened().subscribe(() => {
      this.withExistingGridRowSelectionAndButtonFocusOnSelection(dialogRef.componentInstance['grid']?.api, dialogRef.id);
    });

    return dialogRef?.afterClosed();
  }

  public openAlertDialog(config: MatDialogConfig<AlertDialogData>): Observable<AlertDialogResult> {
    return this.openDialog<AlertDialogComponent, AlertDialogData, AlertDialogResult>(AlertDialogComponent, config);
  }

  public openSelectGridDialog<T, R>(component: ComponentType<SelectGridDialog>, config: MatDialogConfig<T>): Observable<R> {
    const dialogRef = this.dialog.open(component, config);
    const resultSubject = new Subject<R>();

    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.listOfGridApis?.forEach((gridApi: GridApi) => {
        this.withExistingGridRowSelectionAndButtonFocusOnSelection(gridApi, dialogRef.id);
      });
    });

    dialogRef
      .afterClosed()
      .pipe(
        finalize(() => {
          resultSubject.complete();
        })
      )
      .subscribe((result: R) => resultSubject.next(result));

    return resultSubject.asObservable();
  }

  public close(id: string, value: any): void {
    this.dialog.getDialogById(id)?.close(value);
  }

  private withExistingGridRowSelectionAndButtonFocusOnSelection(gridApi: GridApi, dialogId: string): void {
    this.gridOptionsBuilderFactoryService
      .getBuilderFromExistingGridApi(gridApi)
      .withAutomaticRowSelection()
      .withAutomaticButtonFocusOnRowSelection(`[id="${dialogId}"] .${FooterConstants.CHOOSE_BUTTON_CLASS_NAME}`)
      .build();
  }
}
