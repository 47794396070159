// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "ba0e0c5159-dirty",
    "hash": "ba0e0c5159",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "ba0e0c5159-dirty",
    "semverString": null,
    "version": "5.36.0"
};
