import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-link-form-textarea',
  templateUrl: './link-form-textarea.component.html',
  styleUrls: ['./link-form-textarea.component.scss']
})
export class LinkFormTextareaComponent {
  @Input() public value: string;
  @Output() public inputClick: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();

  public click(): void {
    this.inputClick.emit();
  }

  public getValue(): string {
    return this.value ?? '';
  }
}
