import {Injectable} from '@angular/core';
import {InterfaceOf} from './../../common/types/interface-of';
import {RepositionWatchDialogFactoryService} from './reposition-watch-dialog-factory.service';
import {RepositionWatchDialog} from './reposition-watch-dialog.interface';
import {StubRepositionWatchDialogService} from './stub-reposition-watch-dialog.service';

@Injectable()
export class StubRepositionWatchDialogFactoryService implements InterfaceOf<RepositionWatchDialogFactoryService> {
  public getService(): RepositionWatchDialog {
    return new StubRepositionWatchDialogService();
  }
}
