import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationSelectedMenuItemData} from '@application/helper/navigation-helper/navigation-selected-menu-item-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {PathLayoutTemplate} from '@domain/path-layout-template/path-layout-template';
import {PathLayoutTemplatePath} from '@domain/path-layout-template/path-layout-template-path';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {ColoredYarnSetPosition} from '@domain/textile-data/creel/colored-yarn-set-position';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {HttpPathLayoutTemplatesService} from '@infrastructure/http/path-layout-template/http-path-layout-templates.service';
import {PRODUCTION_SCHEDULE_PLAN, ProductionSchedulePlan} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan';
import {
  AgGridUtils,
  AlertDialogResult,
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  convertCommercialUnit,
  convertToCommercialUnitCentimeter,
  convertWidthInMeterToDents,
  DialogBuilderFactoryService,
  DialogComponentData,
  DialogType,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog,
  StringUtils,
  TabsMenuItem,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ICellRendererParams, ITooltipParams, RowNode, ValueGetterParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {takeUntil} from 'rxjs';
import {PathLayoutTemplateMismatchComponent} from './path-layout-template-mismatch/path-layout-template-mismatch.component';
import {PathLayoutTemplateService} from './path-layout-template.service';

@Component({
  templateUrl: './select-path-layout-template.component.html',
  styleUrls: ['./select-path-layout-template.component.scss']
})
export class SelectPathLayoutTemplateComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  public pathLayoutTemplateTranslationKey = 'PATH_LAYOUT_TEMPLATE.PATH_LAYOUT_TEMPLATE';
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfPathLayoutTemplates: PathLayoutTemplate[];
  @ViewChild('selectPathLayoutTemplatesGrid')
  public selectPathLayoutTemplatesGrid: AgGridAngular;

  public pathWidths: PathWidth[];
  public productionSchedule: ProductionSchedule;

  public selectedMenuItem: TabsMenuItem;
  public menuItems: TabsMenuItem[] = [
    {
      value: ColoredYarnSetPosition.BOTTOM,
      translationKey: 'TEXTILE_DATA.MACHINE_QUALITY.POST_PROCESSING.CLOTH_TYPE_OPTIONS.BOTTOM'
    },
    {
      value: ColoredYarnSetPosition.TOP,
      translationKey: 'TEXTILE_DATA.MACHINE_QUALITY.POST_PROCESSING.CLOTH_TYPE_OPTIONS.TOP'
    }
  ];

  private reedDensityInDentsPerMeter: number;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PRODUCTION_SCHEDULE_PLAN) private readonly productionSchedulePlan: ProductionSchedulePlan,
    private readonly dialogRef: MatDialogRef<SelectPathLayoutTemplateComponent>,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly pathLayoutTemplates: HttpPathLayoutTemplatesService,
    private readonly router: Router,
    private readonly pathLayoutTemplateService: PathLayoutTemplateService,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectPathLayoutTemplateComponent>>
  ) {
    super();
    this.productionSchedule = data?.productionSchedule;
    this.pathWidths = data?.pathWidths;
    this.reedDensityInDentsPerMeter = data?.reedDensityInDentsPerMeter;
    this.selectedMenuItem = this.menuItems[0];
  }

  public ngOnInit(): void {
    this.setGridOptions();
    this.getPathLayoutTemplates();
  }

  public get gridOptionsForListOfPathLayoutTemplates(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.selectPathLayoutTemplatesGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<DialogComponentData<typeof SelectPathLayoutTemplateComponent>>({dialogComponent: SelectPathLayoutTemplateComponent});
    this.navigationHelperService.savePartialState<NavigationSelectedMenuItemData>({menuItem: this.selectedMenuItem});
  }

  public isProductionScheduleDoubleWidth(): boolean {
    if (AssertionUtils.isNullOrUndefined(this.productionSchedule)) {
      return false;
    } else {
      return this.productionSchedule.machine.jacquard.isSisal;
    }
  }

  public confirmOverride(): void {
    if (this.productionSchedule.machine.jacquard.isSisal) {
      this.confirmOverrideForDoubleWidth();
    } else if (
      AssertionUtils.isNullOrUndefined(this.productionSchedule.productionSchedulePathsOfColoredYarnSets[0].productionSchedulePaths) ||
      AssertionUtils.isEmpty(this.productionSchedule.productionSchedulePathsOfColoredYarnSets[0].productionSchedulePaths)
    ) {
      this.selectPathLayoutTemplate();
    } else {
      this.openConfirmDialog();
    }
  }

  public confirmOverrideForDoubleWidth(): void {
    if (this.selectedMenuItem.value === ColoredYarnSetPosition.BOTTOM) {
      const bottomColoredYarnSet = this.productionSchedule.productionSchedulePathsOfColoredYarnSets.filter((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
        productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.isBottomPosition()
      );
      if (AssertionUtils.isNullOrUndefined(bottomColoredYarnSet[0].productionSchedulePaths) || AssertionUtils.isEmpty(bottomColoredYarnSet[0].productionSchedulePaths)) {
        this.selectPathLayoutTemplate();
      } else {
        this.openConfirmDialog();
      }
    } else if (this.selectedMenuItem.value === ColoredYarnSetPosition.TOP) {
      const topColoredYarnSet = this.productionSchedule.productionSchedulePathsOfColoredYarnSets.filter(
        (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => !productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.isBottomPosition()
      );
      if (AssertionUtils.isNullOrUndefined(topColoredYarnSet[0].productionSchedulePaths) || AssertionUtils.isEmpty(topColoredYarnSet[0].productionSchedulePaths)) {
        this.selectPathLayoutTemplate();
      } else {
        this.openConfirmDialog();
      }
    }
  }

  public navigateToAddPathLayoutTemplatePage(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.pathLayoutTemplate.addPathLayoutTemplate.absolutePath);
  }

  public selectPathLayoutTemplate(): void {
    this.dialogRef.close([this.selectPathLayoutTemplatesGrid?.api?.getSelectedRows()[0], this.selectedMenuItem.value]);
  }

  public onSelectedMenuItemChange(menuItem: TabsMenuItem): void {
    this.selectedMenuItem = menuItem;
  }

  private openConfirmDialog(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openAlertDialog({
        titleText: this.translate.instant('GENERAL.ACTIONS.IMPORT_OBJECT', {object: this.selectPathLayoutTemplatesGrid?.api?.getSelectedRows()?.[0]?.name}),
        messageText: this.translate.instant('GENERAL.ACTIONS.CONFIRM_IMPORT_DESCRIPTION'),
        type: DialogType.OVERRIDE
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((result: AlertDialogResult) => {
        if (result === AlertDialogResult.CONFIRM) {
          this.selectPathLayoutTemplate();
        }
      });
  }

  private setGridOptions(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfPathLayoutTemplates(), GridIdentifier.SELECT_PATH_LAYOUT_TEMPLATE)
        .withOnCellDoubleClicked(() => this.confirmOverride())
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: this.pathLayoutTemplateTranslationKey,
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withIsRowSelectable((rowNode: RowNode) => !this.checkIfTemplateHasTooMuchWidth(rowNode.data) && !this.checkIfTemplateHasOverlappingPaths(rowNode.data))
        .withRememberStateForNavigationHelper()
        .build()
    ];
  }

  private getColumnDefsForListOfPathLayoutTemplates(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('loomGroup', true).withHeaderName('PATH_LAYOUT_TEMPLATE.LOOM_TYPE').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('paths')
        .withHeaderName('PATH_LAYOUT_TEMPLATE.PATHS')
        .withValueGetter((params: ValueGetterParams) => (params.data as PathLayoutTemplate).pathLayoutTemplatePaths.length, true)
        .withComparator(StringUtils.stringComparator)
        .withCellClass('right')
        .withNumericMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('totalWidth')
        .withHeaderName('PATH_LAYOUT_TEMPLATE.TOTAL_WIDTH')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(this.getTotalWidth(params, false), Unit.CENTIMETER, this.l10nIntlService))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(this.getTotalWidth(params, false), Unit.CENTIMETER, this.l10nIntlService))
        .withFilterValueGetter((params: ValueGetterParams) => this.getTotalWidth(params, false))
        .withComparator(StringUtils.stringComparator)
        .withCellClass('right')
        .withNumericMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('totalWidth')
        .withHeaderName('PATH_LAYOUT_TEMPLATE.TOTAL_WIDTH')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(this.getTotalWidth(params, true), Unit.DENTS, this.l10nIntlService))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(this.getTotalWidth(params, true), Unit.DENTS, this.l10nIntlService))
        .withFilterValueGetter((params: ValueGetterParams) => this.getTotalWidth(params, true))
        .withComparator(StringUtils.stringComparator)
        .withCellClass('right')
        .withNumericMultiFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('info')
        .withHeaderName('PATH_LAYOUT_TEMPLATE.INFO')
        .withCellRenderer(PathLayoutTemplateMismatchComponent, {
          productionSchedule: this.productionSchedule,
          pathWidths: this.pathWidths,
          reedDensityInDentsPerMeter: this.productionSchedule?.machineQuality?.reedDensity * 1000
        })
        .withoutFilter()
        .withCellClass('center')
        .withResizable(false)
        .withWidth(100)
        .build()
    ];
  }

  private getPathLayoutTemplates(): void {
    this.pathLayoutTemplates
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((pathLayoutTemplates: PathLayoutTemplate[]) => (this.listOfPathLayoutTemplates = pathLayoutTemplates));
  }

  private getTotalWidth(params: ICellRendererParams | ITooltipParams | ValueGetterParams, dents: boolean): string {
    const totalWidthOfPathsInDents = (params.data as PathLayoutTemplate).pathLayoutTemplatePaths.reduce((totalWidth: number, pathLayoutTemplatePath: PathLayoutTemplatePath) => {
      const widthOfPathInDents = !AssertionUtils.isNullOrUndefined(this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, this.pathWidths))
        ? this.productionSchedulePlan.lookupCommercialWidthInPathWidths(pathLayoutTemplatePath.widthInMM, this.pathWidths)
        : convertWidthInMeterToDents(convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: pathLayoutTemplatePath.widthInMM}, to: Unit.METER}), this.reedDensityInDentsPerMeter, 0);

      return totalWidth + widthOfPathInDents;
    }, 0);

    const totalWidthOfPathsInMilimeters = (params.data as PathLayoutTemplate).pathLayoutTemplatePaths.reduce((totalWidth: number, pathLayoutTemplatePath: PathLayoutTemplatePath) => {
      return totalWidth + pathLayoutTemplatePath.widthInMM;
    }, 0);

    const totalWidthOfPathsInDentsWithAddedRestAndFreeZones = this.addFreeZonesAndRestZonesToTotalWidthOfPathTemplate(totalWidthOfPathsInDents, params.data as PathLayoutTemplate);
    const totalWidthOfPathsInMillimetersWithAddedRestAndFreeZones = this.addFreeZonesAndRestZonesToTotalWidthOfPathTemplate(totalWidthOfPathsInMilimeters, params.data as PathLayoutTemplate);

    const totalWidthInCM = convertToCommercialUnitCentimeter({
      unit: Unit.MILLIMETER,
      value: totalWidthOfPathsInMillimetersWithAddedRestAndFreeZones
    });

    return dents ? totalWidthOfPathsInDentsWithAddedRestAndFreeZones.toFixed(0).toString() : totalWidthInCM.toFixed(2).toString();
  }

  private checkIfTemplateHasTooMuchWidth(pathLayoutTemplate: PathLayoutTemplate): boolean {
    return this.pathLayoutTemplateService.checkIfTemplateHasTooMuchWidth(
      this.productionSchedule,
      pathLayoutTemplate,
      this.selectedMenuItem.value,
      this.productionSchedule.machineQuality.reedDensity * 1000,
      this.pathWidths
    );
  }

  private checkIfTemplateHasOverlappingPaths(pathLayoutTemplate: PathLayoutTemplate): boolean {
    return this.pathLayoutTemplateService.checkIfTemplateHasOverlappingPaths(this.productionSchedule, pathLayoutTemplate, this.productionSchedule.machineQuality.reedDensity * 1000, this.pathWidths);
  }

  private addFreeZonesAndRestZonesToTotalWidthOfPathTemplate(totalWidthOfPathsInDents: number, pathLayoutTemplate: PathLayoutTemplate): number {
    const totalWidthOfPathsInDentsWithAddedInBetweenFreeZones = !this.productionSchedule.initialFreeZones.inBetween.useAsMaximum
      ? totalWidthOfPathsInDents + this.productionSchedule.initialFreeZones.inBetween.technicalWidthInDents * (pathLayoutTemplate.pathLayoutTemplatePaths.length - 1)
      : totalWidthOfPathsInDents;
    const totalWidthOfPathsInDentsWithAddedLeftFreeZones = !this.productionSchedule.initialFreeZones.left.useAsMaximum
      ? totalWidthOfPathsInDentsWithAddedInBetweenFreeZones + this.productionSchedule.initialFreeZones.left.technicalWidthInDents
      : totalWidthOfPathsInDentsWithAddedInBetweenFreeZones;
    const totalWidthOfPathsInDentsWithAddedRightFreeZones = !this.productionSchedule.initialFreeZones.right.useAsMaximum
      ? totalWidthOfPathsInDentsWithAddedLeftFreeZones + this.productionSchedule.initialFreeZones.right.technicalWidthInDents
      : totalWidthOfPathsInDentsWithAddedLeftFreeZones;

    return AssertionUtils.isNullOrUndefined(this.productionSchedule.initialRestZones?.userDefinedRest?.technicalWidthInDents)
      ? totalWidthOfPathsInDentsWithAddedRightFreeZones
      : totalWidthOfPathsInDentsWithAddedRightFreeZones + this.productionSchedule.initialRestZones.userDefinedRest.technicalWidthInDents;
  }
}
