import {Directive, ElementRef, HostListener} from '@angular/core';
import {join} from 'lodash-es';
import {MimeType} from '../../common/mime-type.enum';

@Directive({
  selector: 'input[vdwAlphabet]'
})
export class InputAlphabetDirective {
  private elementRef: ElementRef;

  public constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(keyboardEvent: KeyboardEvent): void {
    if (!this.isLetterKeyCode(keyboardEvent.key) && !this.isCommand(keyboardEvent)) {
      keyboardEvent.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData(MimeType.PLAIN_TEXT);
    this.elementRef.nativeElement.value = this.getProcessedText(pastedInput);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    event.preventDefault();
    const droppedInput: string = event.dataTransfer.getData(MimeType.TEXT);
    this.elementRef.nativeElement.value = this.getProcessedText(droppedInput);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
    this.elementRef.nativeElement.focus();
  }

  private isLetterKeyCode(key: string): boolean {
    return /[a-z]/i.test(key);
  }

  private isCommand(keyboardEvent: KeyboardEvent): boolean {
    return this.isControlCommand(keyboardEvent) || this.isStandardCommand(keyboardEvent.key);
  }

  private isControlCommand(keyboardEvent: KeyboardEvent): boolean {
    return /^[acvxyz]$/.test(keyboardEvent.key) && (keyboardEvent.ctrlKey || keyboardEvent.metaKey);
  }

  private isStandardCommand(key: string): boolean {
    const standardCommands: string[] = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Shift', 'Home', 'End'];
    return new RegExp(`^(${join(standardCommands, '|')})$`).test(key);
  }

  private getProcessedText(rawText: string): string {
    return rawText.replace(/[^A-Za-z]/g, '').replace(/\s/g, '');
  }
}
