import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  templateUrl: './product-configuration-open-in-cadcam-dialog.component.html',
  styleUrls: ['./product-configuration-open-in-cadcam-dialog.component.scss']
})
export class ProductConfigurationOpenInCadcamDialogComponent {
  public constructor(@Inject(MAT_DIALOG_DATA) public data: {name: string}) {}
}
