import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BackendErrorContext} from '@application/helper/backend-error-context';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {Drawings, DRAWINGS} from '@infrastructure/http/drawing/drawings';
import {YARN_CONSUMPTION, YarnConsumption} from '@infrastructure/http/yarn-consumption/yarn-consumption';
import {REALTIME_YARN_CONSUMPTION, RealtimeYarnConsumption} from '@infrastructure/signalr/yarn-consumption/realtime-yarn-consumption';
import {AssertionUtils, BaseComponent} from '@vdw/angular-component-library';
import {forkJoin, merge, Subject} from 'rxjs';
import {delay, filter, take, takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './download-drawing.component.html',
  styleUrls: ['./download-drawing.component.scss']
})
export class DownloadDrawingComponent extends BaseComponent implements OnInit {
  public showErrorMessage = false;
  public showSuccessMessage = false;
  public showWaitingMessage = true;
  public objectTranslationKey: string;
  public illustrationSource: string;
  public header = 'DESIGN_LIBRARY.SMART_CREEL.PREPARING_YOUR_FILES';
  public errorMessage = 'DESIGN_LIBRARY.SMART_CREEL.CHECK_YOUR_CONNECTION';
  public successMessage = 'DESIGN_LIBRARY.SMART_CREEL.SUCCESS_MESSAGE';
  public waitingMessage = 'DESIGN_LIBRARY.SMART_CREEL_KEEP_THIS_WINDOW_OPEN';
  public drawingImageId: string;
  public readonly COMPLETED_SVG = 'uploading.svg';
  public readonly FAILED_SVG = 'report-warning.svg';
  public readonly LOADING_SVG = 'img-loading.svg';
  public readonly smartCreelTranslationKey = 'DESIGN_LIBRARY.SMART_CREEL.DOWNLOAD_SMART_CREEL_FILES';
  private designId: number;
  private colorSetId: number;
  private yarnSetId: number;
  private qualityId: number;
  private machineId: number;
  private designName: string;
  private drawingDimensionsInPx: DimensionsInPx;
  private finishingId: number;
  private errorContextForMissingMachineQualityParameters = 'YARN_CONSUMPTION_INSUFFICIENT_INFORMATION';
  private readonly downloadFailedTranslation = 'DESIGN_LIBRARY.SMART_CREEL.DOWNLOAD_FAILED';
  private readonly bmpFile: ArrayBuffer;
  private readonly pvdFile: ArrayBuffer;
  private readonly cancelDownloadSubject: Subject<boolean> = new Subject();
  private readonly cancelDownload = this.cancelDownloadSubject.asObservable();

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      objectTranslationKey: string;
      drawingImageId: string;
      designId: number;
      colorSetId: number;
      yarnSetId: number;
      qualityId: number;
      finishingId: number;
      machineId: number;
      designName: string;
      drawingDimensionsInPx: DimensionsInPx;
    },
    @Inject(DRAWINGS) protected readonly drawings: Drawings,
    @Inject(YARN_CONSUMPTION) private readonly yarnConsumption: YarnConsumption,
    @Inject(REALTIME_YARN_CONSUMPTION) private realtimeYarnConsumption: RealtimeYarnConsumption,
    private readonly dialogRef: MatDialogRef<DownloadDrawingComponent>
  ) {
    super();
    this.objectTranslationKey = data?.objectTranslationKey;
    this.drawingImageId = data?.drawingImageId ?? 'image';
    this.designId = data?.designId;
    this.colorSetId = data?.colorSetId;
    this.yarnSetId = data?.yarnSetId;
    this.qualityId = data?.qualityId;
    this.finishingId = data?.finishingId;
    this.machineId = data?.machineId;
    this.drawingDimensionsInPx = data?.drawingDimensionsInPx;
    this.designName = data?.designName;
  }

  public ngOnInit(): void {
    this.setIllustration(this.LOADING_SVG);
    this.showWaitingScreen();
    this.objectTranslationKey === this.smartCreelTranslationKey ? this.calculateYarnConsumption() : this.saveAsEp();
  }

  public downloadImageManually(): void {
    const bmpBlob = new Blob([this.bmpFile]);
    const pvdBlob = new Blob([this.pvdFile]);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(bmpBlob);
    downloadLink.setAttribute('download', `${this.designName}.bmp`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.href = URL.createObjectURL(pvdBlob);
    downloadLink.setAttribute('download', `${this.designName}.pvd`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  public reloadPage(): void {
    location.reload();
  }

  public retryDownload(): void {
    this.objectTranslationKey === this.smartCreelTranslationKey ? this.calculateYarnConsumption() : this.saveAsEp();
  }

  public getCancelEventName(): string {
    return this.showSuccessMessage ? 'GENERAL.ACTIONS.CLOSE' : 'GENERAL.ACTIONS.CANCEL';
  }

  public cancelEvent(): void {
    this.dialogRef.close();
    if (!this.showSuccessMessage) {
      this.cancelDownloadSubject.next(true);
    }
  }

  private calculateYarnConsumption(): void {
    this.yarnConsumption
      .calculateYarnConsumptionForDesign(this.designId, this.colorSetId, this.yarnSetId, this.qualityId, true)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: () => {
          this.getCalculatedYarnConsumption();
        },
        error: (error: BackendErrorContext) => {
          if (error.errorCode.toString() === this.errorContextForMissingMachineQualityParameters) {
            this.errorMessage = 'TEXTILE_DATA.YARN_TYPE.CALCULATE_YARN_CONSUMPTION_ERROR_FOR_DOWNLOAD';
          }
          this.header = this.downloadFailedTranslation;
          this.showWaitingMessage = false;
          this.showErrorMessage = true;
          this.setIllustration(this.FAILED_SVG);
        }
      });
  }

  private getCalculatedYarnConsumption(): void {
    this.realtimeYarnConsumption
      .getCalculatedYarnConsumption()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (calculatedYarnConsumption: any) => {
          this.downloadFiles(calculatedYarnConsumption.yarnConsumption.id);
        },
        error: () => {
          this.showErrorScreen();
        }
      });
  }

  private setIllustration(name: string): void {
    this.illustrationSource = `/assets/icons/illustrations/${name}`;
  }

  private showErrorScreen(): void {
    this.showWaitingMessage = false;
    this.showErrorMessage = true;
    this.header = this.downloadFailedTranslation;
    this.setIllustration(this.FAILED_SVG);
  }

  private showWaitingScreen(): void {
    this.showWaitingMessage = true;
    this.waitingMessage = this.objectTranslationKey === this.smartCreelTranslationKey ? 'DESIGN_LIBRARY.SMART_CREEL_KEEP_THIS_WINDOW_OPEN' : 'DESIGN_LIBRARY.DETAILS.SAVE_AS_EP.PROCESSED_EP';
  }

  private showSuccessScreen(): void {
    this.showWaitingMessage = false;
    this.showSuccessMessage = true;
    this.header = 'DESIGN_LIBRARY.SMART_CREEL.DOWNLOAD_HAS_STARTED';
    this.setIllustration(this.COMPLETED_SVG);
    this.successMessage = this.objectTranslationKey === this.smartCreelTranslationKey ? 'DESIGN_LIBRARY.SMART_CREEL.SUCCESS_MESSAGE' : 'DESIGN_LIBRARY.DETAILS.SAVE_AS_EP.SUCCESS_MESSAGE';
  }

  private downloadFiles(yarnConsumptionId: number): void {
    forkJoin([this.drawings.downloadFile(this.drawingImageId), this.drawings.downloadPVDFile(yarnConsumptionId)])
      .pipe(delay(1500), takeUntil(merge(this.unSubscribeOnViewDestroy && this.cancelDownload)))
      .subscribe({
        next: ([bmpFile, pvdFile]: [ArrayBuffer, ArrayBuffer]) => {
          this.showSuccessScreen();
          const bmpBlob = new Blob([bmpFile]);
          const pvdBlob = new Blob([pvdFile]);
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(bmpBlob);
          downloadLink.setAttribute('download', `${this.designName}.bmp`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.href = URL.createObjectURL(pvdBlob);
          downloadLink.setAttribute('download', `${this.designName}.pvd`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error: () => {
          this.showErrorScreen();
        }
      });
  }

  private downloadEp(epId: string): void {
    forkJoin([this.drawings.downloadFile(epId)])
      .pipe(takeUntil(merge(this.unSubscribeOnViewDestroy && this.cancelDownload)))
      .subscribe({
        next: ([epFile]: [ArrayBuffer]) => {
          this.showSuccessScreen();
          const epBlob = new Blob([epFile]);
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(epBlob);
          downloadLink.setAttribute('download', `${this.designName}.ep`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error: () => {
          this.showErrorScreen();
        }
      });
  }

  private saveAsEp(): void {
    const bmpObservable = merge(
      this.realtimeYarnConsumption.getSingleBmpProcessed(),
      this.drawings.processDrawingConfigurationIntoEpDrawing(this.drawingImageId, this.designName, this.qualityId, this.colorSetId, this.drawingDimensionsInPx, this.finishingId, this.machineId)
    );

    bmpObservable
      .pipe(
        takeUntil(merge(this.unSubscribeOnViewDestroy && this.cancelDownload)),
        filter((file: any) => !AssertionUtils.isNullOrUndefined(file?.fileId)),
        take(1)
      )
      .subscribe({
        next: (file: any) => this.downloadEp(file.fileId),
        error: () => this.showErrorScreen()
      });
  }
}
