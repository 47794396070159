<section fxFlex fxLayout="column">
  <vdw-header [title]="'TERMS_AND_CONDITIONS.GENERAL_TERMS_AND_CONDITIONS' | bmsTranslate" svgIcon="terms"></vdw-header>

  <article class="dialog-content" fxLayout="row">
    <mat-checkbox [(ngModel)]="agreesWithTermsAndConditions">
      <span class="b1" [innerHTML]="'TERMS_AND_CONDITIONS.AGREE' | bmsTranslate: {url: termsAndConditionsUrl}"></span>
    </mat-checkbox>
  </article>

  <vdw-footer chooseEventName="TERMS_AND_CONDITIONS.SIGN" [locked]="!agreesWithTermsAndConditions" [centered]="true" [withCancelButton]="false"></vdw-footer>
</section>
