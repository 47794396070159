import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DateRange} from '@domain/date-range';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {AssertionUtils, BaseComponent, DatePickerComponent, DialogBuilderFactoryService, moment, TimeViewMode} from '@vdw/angular-component-library';
import {cloneDeep} from 'lodash-es';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-period-selection',
  templateUrl: './period-selection.component.html',
  styleUrls: ['./period-selection.component.scss']
})
export class PeriodSelectionComponent extends BaseComponent {
  @Input() public timeViewMode = TimeViewMode.DAY;
  @Input() public set selectedDateRange(dateRange: DateRange) {
    this._selectedDateRange = cloneDeep(dateRange);
  }

  @Output() public selectedDateRangeChanged: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  private _selectedDateRange: DateRange;

  private readonly DAY_FORMAT = 'DD';
  private readonly YEAR_FORMAT = 'YYYY';
  private readonly MONTH_FORMAT = 'MMMM';
  private readonly DAY_MONTH_FORMAT = `${this.DAY_FORMAT} ${this.MONTH_FORMAT}`;
  private readonly MONTH_YEAR_FORMAT = `${this.MONTH_FORMAT} ${this.YEAR_FORMAT}`;
  private readonly DAY_MONTH_YEAR_FORMAT = `${this.DAY_FORMAT} ${this.MONTH_YEAR_FORMAT}`;

  public constructor(private readonly dialogBuilderFactoryService: DialogBuilderFactoryService) {
    super();
  }

  public openDatePicker(event: MouseEvent): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('reposition-dialog')
      .openDialog(RepositionDialogComponent, {
        component: DatePickerComponent,
        sourceElement: event.target,
        positionOfDialog: PositionOfDialog.BOTTOM,
        date: this.getFromDate(),
        endDate: this.getToDate(),
        isRangePicker: this.canShowDateRange()
      })
      .pipe(
        filter((result: Date | DateRange) => !AssertionUtils.isNullOrUndefined(result)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((result: Date | DateRange) => {
        if (this.canShowDateRange()) {
          this._selectedDateRange = result as DateRange;
        } else {
          const date = result as Date;
          this._selectedDateRange = [date, moment(date).add(1, 'hours').toDate()];
        }

        this.emitDateRangeChanges();
      });
  }

  public selectPreviousDay(): void {
    const newStartDate = moment(this._selectedDateRange[0]).startOf('day').subtract(1, 'days').toDate();

    if (this.canShowDateRange()) {
      this._selectedDateRange = [newStartDate, moment(this._selectedDateRange[1]).endOf('day').subtract(1, 'days').toDate()];
    } else {
      this._selectedDateRange = [newStartDate, moment(newStartDate).add(1, 'hours').toDate()];
    }

    this.emitDateRangeChanges();
  }

  public selectNextDay(): void {
    const newStartDate = moment(this._selectedDateRange[0]).startOf('day').add(1, 'days').toDate();

    if (this.canShowDateRange()) {
      this._selectedDateRange = [newStartDate, moment(this._selectedDateRange[1]).endOf('day').add(1, 'days').toDate()];
    } else {
      this._selectedDateRange = [newStartDate, moment(newStartDate).add(1, 'hours').toDate()];
    }

    this.emitDateRangeChanges();
  }

  public getFromDateLabel(): string {
    const fromDate = moment(this.getFromDate());

    return fromDate.isSame(moment(this.getToDate()), 'year') ? `${fromDate.format(this.DAY_MONTH_FORMAT)}` : `${fromDate.format(this.DAY_MONTH_YEAR_FORMAT)}`;
  }

  public canShowDateRange(): boolean {
    return this.timeViewMode === TimeViewMode.DAY;
  }

  public getToDateLabel(): string {
    const toDate = moment(this.getToDate());

    return toDate.isSame(moment(this.getFromDate()), 'year') ? `${toDate.format(this.DAY_MONTH_FORMAT)}` : `${toDate.format(this.DAY_MONTH_YEAR_FORMAT)}`;
  }

  private getFromDate(): Date {
    return this._selectedDateRange[0];
  }

  private getToDate(): Date {
    return this._selectedDateRange[1];
  }

  private emitDateRangeChanges(): void {
    this.selectedDateRangeChanged.emit(this._selectedDateRange);
  }
}
