import {Component, Input} from '@angular/core';

@Component({
  selector: 'vdw-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() public progress = 0;
  @Input() public showTooltip = false;

  public getProgress(): number {
    return Math.max(0, Math.min(this.progress, 100));
  }
}
