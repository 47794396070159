<div class="grid-select-color" *ngIf="canShowPreview()">
  <div class="color-preview" *ngIf="isColorPreview" [style.box-shadow]="getPreviewBoxShadow(item)" [style.background-color]="getColorHexCode()"></div>
  <div [class.color-name-width-with-rgb-values]="isColorPreview">
    <vdw-ellipsis-label [text]="getLabelName()" class="color-name" matTooltipPosition="above"></vdw-ellipsis-label>
  </div>
  <span class="alt-label">
    <vdw-ellipsis-label [text]="getAltLabel()" matTooltipPosition="above"></vdw-ellipsis-label>
  </span>

  <div class="actions flex-end-center gap-8 color-controls" *ngIf="canShowControls()">
    <button mat-icon-button class="mat-icon-action-button-sm" *ngIf="hasAlreadyAddedItem(item)" (click)="clickSubtract(item)">
      <mat-icon svgIcon="subtract-small"></mat-icon>
    </button>

    <button mat-icon-button class="mat-icon-action-button-sm" *ngIf="!hasAlreadyAddedItem(item)" (click)="clickAdd(item)">
      <mat-icon svgIcon="add-small"></mat-icon>
    </button>
  </div>
</div>
