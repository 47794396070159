import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import moment from 'moment';
import {filter, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {TimePickerConstants} from '../time-picker.constants';

@Component({
  selector: 'vdw-time-picker-menu',
  templateUrl: 'time-picker-menu.component.html',
  styleUrl: 'time-picker-menu.component.scss'
})
export class TimePickerMenuComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() public notation: 12 | 24;
  @Input() public selectedTime: string;
  @Output() public selectedTimeChange = new EventEmitter<string>();
  @Output() public menuFocused = new EventEmitter<void>();

  public timeForm: TimeForm;

  public constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  @HostListener('click') public click(): void {
    this.menuFocused.emit();
  }

  @HostListener('keyup', ['$event']) public onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.menuFocused.emit();
    }
  }

  public ngOnInit(): void {
    this.setFormFields();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('selectedTime' in changes && !changes.selectedTime.isFirstChange()) {
      this.setFormValues();
    }
  }

  public increase(formControlName: string): void {
    let newValue = parseInt(this.timeForm.value[formControlName]) + 1;
    newValue = newValue > this.getMaxValue(formControlName) ? this.getMinValue(formControlName) : newValue;
    this.timeForm.controls[formControlName].setValue(newValue.toString());
  }

  public decrease(formControlName: string): void {
    let newValue = parseInt(this.timeForm.value[formControlName]) - 1;
    newValue = newValue < this.getMinValue(formControlName) ? this.getMaxValue(formControlName) : newValue;
    this.timeForm.controls[formControlName].setValue(newValue.toString());
  }

  public togglePeriod(): void {
    this.timeForm.controls.period.setValue(this.timeForm.value.period === TimePickerConstants.AM ? TimePickerConstants.PM : TimePickerConstants.AM);
  }

  public getMinValue(formControlName: string): number {
    if (formControlName === 'hours') {
      return this.notation === 12 ? TimePickerConstants.MIN_HOURS_FOR_12 : TimePickerConstants.MIN_HOURS_FOR_24;
    } else if (formControlName === 'minutes') {
      return TimePickerConstants.MIN_MINUTES;
    }
  }

  public getMaxValue(formControlName: string): number {
    if (formControlName === 'hours') {
      return this.notation === 12 ? TimePickerConstants.MAX_HOURS_FOR_12 : TimePickerConstants.MAX_HOURS_FOR_24;
    } else if (formControlName === 'minutes') {
      return TimePickerConstants.MAX_MINUTES;
    }
  }

  public setTime(now: boolean = false): void {
    this.timeForm.setValue(this.getTimeValues(now ? moment() : moment('00:00', 'HH:mm')));
  }

  private setFormFields(): void {
    const {hours, minutes, period} = this.getTimeValues(moment(AssertionUtils.isNullOrWhitespace(this.selectedTime) ? '00:00' : this.selectedTime, ['hh:mm A', 'HH:mm']));
    this.timeForm = this.formBuilder.group({
      hours: [hours],
      minutes: [minutes],
      period: [period]
    });

    this.timeForm.valueChanges
      .pipe(
        filter(() => this.timeForm.valid),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((time: {hours: string; minutes: string; period: typeof TimePickerConstants.AM | typeof TimePickerConstants.PM}) => {
        this.selectedTimeChange.emit(`${time.hours.padStart(2, '0')}:${time.minutes.padStart(2, '0')}${this.notation === 12 ? ' ' + time.period : ''}`);
      });

    this.timeForm.controls.hours.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((value: string) => {
      this.timeForm.controls.hours.setValue(value.toString().padStart(2, '0'), {emitEvent: false});
    });

    this.timeForm.controls.minutes.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((value: string) => {
      this.timeForm.controls.minutes.setValue(value.toString().padStart(2, '0'), {emitEvent: false});
    });
  }

  private setFormValues(): void {
    if (!AssertionUtils.isNullOrWhitespace(this.selectedTime)) {
      this.timeForm.setValue(this.getTimeValues(moment(this.selectedTime, ['hh:mm A', 'HH:mm'])), {emitEvent: false});
    }
  }

  private getTimeValues(time: moment.Moment): {hours: string; minutes: string; period: typeof TimePickerConstants.AM | typeof TimePickerConstants.PM} {
    return {hours: time.format(this.notation === 12 ? 'hh' : 'HH'), minutes: time.format('mm'), period: time.format('A') as typeof TimePickerConstants.AM | typeof TimePickerConstants.PM};
  }
}

type TimeForm = FormGroup<{hours: FormControl<string>; minutes: FormControl<string>; period: FormControl<typeof TimePickerConstants.AM | typeof TimePickerConstants.PM>}>;
