import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {BreakdownSpecification} from '@domain/resource-type/breakdown-specification';
import {
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions} from 'ag-grid-community';

@Component({
  templateUrl: './select-resource-type-breakdown.component.html'
})
export class SelectResourceTypeBreakdownComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('resourceTypeBreakdownsGrid') public resourceTypeBreakdownsGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfResourceTypeBreakdowns: BreakdownSpecification[];

  private resourceTypeId: number;

  public constructor(
    private readonly dialogRef: MatDialogRef<SelectResourceTypeBreakdownComponent>,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectResourceTypeBreakdownComponent>>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super();
    this.listOfResourceTypeBreakdowns = data.listOfResourceTypeBreakdowns;
    this.resourceTypeId = data.resourceTypeId;
  }

  public get gridOptionsForListOfResourceTypeBreakdowns(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfResourceTypeBreakdowns();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.resourceTypeBreakdownsGrid?.api];
  }

  public selectResourceTypeBreakdown(): void {
    this.dialogRef.close(this.resourceTypeBreakdownsGrid.api.getSelectedRows()[0]);
  }

  public canSelectResourceTypeBreakdown(): boolean {
    return this.resourceTypeBreakdownsGrid?.api?.getSelectedRows().length > 0;
  }

  public navigateToEditResourceType(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState(
      {dialogComponent: SelectResourceTypeBreakdownComponent},
      RouteUtils.paths.settings.resourceType.editResourceType.absolutePath.replace(':id', this.resourceTypeId.toString())
    );
  }

  private setGridOptionsForListOfResourceTypeBreakdowns(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfResourceTypeBreakdowns(), GridIdentifier.SELECT_RESOURCE_TYPE_BREAKDOWN)
        .withoutColumnMenu()
        .withOnCellDoubleClicked(() => this.selectResourceTypeBreakdown())
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'BMSCONFIG.MACHINE_DATA_SOURCES.RESOURCE_TYPE_BREAKDOWN',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .build()
    ];
  }

  private getColumnDefsForListOfResourceTypeBreakdowns(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('name', true).withHeaderName('GENERAL.NAME').build(),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('description', true).withHeaderName('GENERAL.DESCRIPTION').build()
    ];
  }
}
