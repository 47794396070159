export class CountConfiguration {
  private _productionCounter: string;
  private _pulsesPerUnit: number;

  public constructor(productionCounter: string, pulsesPerUnit: number) {
    this._productionCounter = productionCounter;
    this._pulsesPerUnit = pulsesPerUnit;
  }

  public get productionCounter(): string {
    return this._productionCounter;
  }

  public set productionCounter(productionCounter: string) {
    this._productionCounter = productionCounter;
  }

  public get pulsesPerUnit(): number {
    return this._pulsesPerUnit;
  }

  public set pulsesPerUnit(pulsesPerUnit: number) {
    this._pulsesPerUnit = pulsesPerUnit;
  }

  public static fromJSON(countConfigurationJSON: any): CountConfiguration {
    return new CountConfiguration(countConfigurationJSON.productionCounter, countConfigurationJSON.pulsesPerUnit);
  }

  public toJSON(): JSON {
    return {
      productionCounter: this._productionCounter,
      pulsesPerUnit: this._pulsesPerUnit
    } as any as JSON;
  }
}
