import {Component} from '@angular/core';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {TranslateService} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';

@Component({
  templateUrl: './finishing-cutbar-before-length.component.html'
})
export class FinishingCutbarBeforeLengthComponent implements ICellRendererAngularComp {
  public cellText: string;

  private finishing: OverviewListFinishing;
  private translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public agInit(params: ICellRendererParams): void {
    this.finishing = params.data;
    this.buildCellText();
  }

  public refresh(params: any): boolean {
    return false;
  }

  public canShowLengthOfCutbarBefore(): boolean {
    return !isNil(this.finishing.cutbarsInformation) && this.finishing.cutbarsInformation.hasAddedCutbarBefore();
  }

  private buildCellText(): void {
    if (this.canShowLengthOfCutbarBefore()) {
      this.translate.get(['GENERAL.UNIT.MILLIMETER', 'GENERAL.UNIT.PICKS']).subscribe((translations: any) => {
        const lengthOfCutbarBeforeInMillimeters = this.getLengthOfCutbarBeforeInMillimeters();
        const lengthOfCutbarBeforeInNumberOfPicks = this.getLengthOfCutbarBeforeInNumberOfPicks();
        this.cellText = `${lengthOfCutbarBeforeInMillimeters} ${translations['GENERAL.UNIT.MILLIMETER']} / ${lengthOfCutbarBeforeInNumberOfPicks} ${translations['GENERAL.UNIT.PICKS']}`;
      });
    }
  }

  private getLengthOfCutbarBeforeInMillimeters(): number {
    return this.finishing.cutbarsInformation.getLengthOfCutbarBeforeInMillimeters();
  }

  private getLengthOfCutbarBeforeInNumberOfPicks(): number {
    return this.finishing.cutbarsInformation.getLengthOfCutbarBeforeInNumberOfPicks();
  }
}
