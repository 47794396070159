import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';

@Component({
  selector: 'app-pattern-preview-dialog',
  templateUrl: './pattern-preview-dialog.component.html',
  styleUrls: ['./pattern-preview-dialog.component.scss']
})
export class PatternPreviewDialogComponent {
  public pattern: Pattern;
  public selectedPatternVisualisation: PatternVisualisation;
  public indexOfSelectedPatternVisualisation: number;
  public amountOfPatternVisualisations: number;

  public constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.pattern = data.pattern;
    this.selectedPatternVisualisation = data.pattern?.visualisations[data.index];
    this.amountOfPatternVisualisations = data.pattern?.visualisations?.length;
    this.indexOfSelectedPatternVisualisation = data.index;
  }

  @HostListener('document:keydown', ['$event'])
  public onKeyPressed(event: KeyboardEvent): void {
    if (this.amountOfPatternVisualisations <= 1) {
      return;
    }

    let index = this.indexOfSelectedPatternVisualisation;
    if (event.code === 'ArrowRight') {
      index++;
    }
    if (event.code === 'ArrowLeft') {
      index--;
    }
    index %= this.amountOfPatternVisualisations;
    index < 0 ? this.setSelectedPatternVisualisation(this.amountOfPatternVisualisations - 1) : this.setSelectedPatternVisualisation(index);
  }

  public setSelectedPatternVisualisation(indexOfSelectedPatternVisualisation: number): void {
    this.indexOfSelectedPatternVisualisation = indexOfSelectedPatternVisualisation;
    this.selectedPatternVisualisation = this.pattern.visualisations[indexOfSelectedPatternVisualisation];
  }
}
