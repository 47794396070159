import {HttpResponse} from '@angular/common/http';
import {InjectionToken} from '@angular/core';
import {DitaManualType} from '@domain/dita-manual-type.enum';
import {MachineGeneralDocumentation} from '@domain/machine/machine-general';
import {MachineManual} from '@domain/machine/machine-manual';
import {MachineSchematic} from '@domain/machine/machine-schematic';
import {MachineSparePartsCatalogue} from '@domain/machine/machine-spare-parts-catalogue';
import {MachineTechnicalWeaveSettings} from '@domain/machine/machine-technical-weave-settings';
import {MachineTechnicalWeaveSettingsRevision} from '@domain/machine/machine-technical-weave-settings-revision';
import {OverviewListMachineForDocumentation} from '@domain/machine/overview-list-machine-for-documentation';
import {Repository} from '@infrastructure/http/repository';
import {Observable} from 'rxjs';

export const MACHINES_DOCUMENTATION = new InjectionToken<MachinesDocumentation>('MachinesDocumentation');

export interface MachinesDocumentation extends Repository<OverviewListMachineForDocumentation, OverviewListMachineForDocumentation> {
  getMachineGeneralDocumentations(machineId: number): Observable<MachineGeneralDocumentation[]>;

  getMachineTechnicalWeaveSettings(machineId: number): Observable<MachineTechnicalWeaveSettings[]>;

  getMachineSparePartsCatalogue(machineId: number): Observable<MachineSparePartsCatalogue>;

  getMachineSchematics(machineId: number): Observable<MachineSchematic[]>;

  getMachineManuals(machineId: number): Observable<MachineManual[]>;

  getMachineDitaManual(machineId: number, type: DitaManualType): Observable<string>;

  downloadMachineGeneralDocumentationFile(machineId: number, number: string): Observable<HttpResponse<Blob>>;

  downloadMachineTechnicalWeaveSettingsFile(
    machineId: number,
    technicalWeaveSettingsType: string,
    technicalWeaveSettingsRevision: MachineTechnicalWeaveSettingsRevision
  ): Observable<HttpResponse<Blob>>;

  isSoftwareAvailable(machineId: number): Observable<boolean>;

  downloadMachineSetupFile(machineId: number): Observable<HttpResponse<Blob>>;

  downloadMachineSparePartsCatalogueFile(machineId: number, name: string): Observable<HttpResponse<Blob>>;

  downloadMachineSchematicFile(machineId: number, name: string): Observable<HttpResponse<Blob>>;

  downloadMachineManualFile(machineId: number, machineManual: MachineManual): Observable<HttpResponse<Blob>>;

  renameMachine(machineId: number, machineName: string): Observable<void>;
}
