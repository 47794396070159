import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-overview-button-card',
  templateUrl: './overview-button-card.component.html'
})
export class OverviewButtonCardComponent {
  @Input() public iconName: string;
  @Input() public buttonText: string;
  @Input() public cardTitle: string;
  @Input() public compact = false;
  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  public buttonClicked(): void {
    this.clicked.emit();
  }

  public cardClicked(): void {
    if (this.compact) {
      this.clicked.emit();
    }
  }
}
