<mat-button-toggle-group>
  @for (button of toggleButtons; track $index) {
    <mat-button-toggle [value]="button.value" [disabled]="button.disabled" (click)="onClick(button)">
      @if (button.icon) {
        <mat-icon [svgIcon]="button.icon" class="icon-16 toggle-icon"></mat-icon>
      }
      @if (button.label) {
        <span>{{ button.label }}</span>
      }
    </mat-button-toggle>
  }
</mat-button-toggle-group>
