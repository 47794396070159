<section fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
  <div fxLayout="column">
    <div appDataCy="date-ranger-picker" class="date-range-container" fxLayout="row" fxLayoutGap="12px" (click)="openDatePicker($event)">
      <div fxLayout="column">
        <span class="micro hint" *ngIf="canShowDateRange()"> {{ 'GENERAL.DATETIME.FROM' | bmsTranslate }}</span>
        <span class="micro hint" *ngIf="!canShowDateRange()">{{ 'GENERAL.DATETIME.DATE' | bmsTranslate }}</span>
        <h2 class="selected-date-range font-weight-semibold">
          {{ getFromDateLabel() }}
        </h2>
      </div>

      <ng-container *ngIf="canShowDateRange()">
        <span fxFlexAlign="center" class="separator"></span>

        <div fxLayout="column">
          <span class="micro hint">{{ 'GENERAL.DATETIME.TO' | bmsTranslate }} </span>
          <h2 class="selected-date-range font-weight-semibold">
            {{ getToDateLabel() }}
          </h2>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="date-controls" fxLayout="column">
    <div fxLayout="row">
      <mat-icon svgIcon="chevron-left" (click)="selectPreviousDay()"></mat-icon>
      <mat-icon svgIcon="chevron-right" (click)="selectNextDay()"></mat-icon>
    </div>
  </div>
</section>
