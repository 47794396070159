import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatExpansionPanel} from '@angular/material/expansion';
import {OpenPdfFile} from '@application/command/open-pdf-file';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {DitaManualType} from '@domain/dita-manual-type.enum';
import {MachineGeneralDocumentation} from '@domain/machine/machine-general';
import {MachineManual, MachineManualLanguage} from '@domain/machine/machine-manual';
import {MachineSchematic} from '@domain/machine/machine-schematic';
import {MachineSparePartsCatalogue} from '@domain/machine/machine-spare-parts-catalogue';
import {MachineTechnicalWeaveSettings} from '@domain/machine/machine-technical-weave-settings';
import {MachineTechnicalWeaveSettingsRevision} from '@domain/machine/machine-technical-weave-settings-revision';
import {OverviewListMachineForDocumentation} from '@domain/machine/overview-list-machine-for-documentation';
import {MACHINES_DOCUMENTATION, MachinesDocumentation} from '@infrastructure/http/machines-documentation/machines-documentation';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  DialogBuilderFactoryService,
  DialogType,
  RESPONSIVENESS_VIEW_MODE,
  ResponsivenessViewMode,
  skeletonViewAnimation,
  ToastService,
  TranslateService
} from '@vdw/angular-component-library';
import {cloneDeep} from 'lodash-es';
import {forkJoin, interval, Subject} from 'rxjs';
import {finalize, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-machine-documentation-details',
  templateUrl: './machine-documentation-details.component.html',
  styleUrls: ['./machine-documentation-details.component.scss'],
  animations: [skeletonViewAnimation('.title-skeleton-wrapper, .list-skeleton-wrapper, .form-field-skeleton-wrapper')]
})
export class MachineDocumentationDetailsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly machineTranslationKey = 'MACHINE.MACHINE';
  @Input() public machineId: number;
  @Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() public nameChangedEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('machineGeneralDocumentationsExpansionPanel') public machineGeneralDocumentationsExpansionPanel: MatExpansionPanel;
  @ViewChild('machineTechnicalWeaveSettingsExpansionPanel') public machineTechnicalWeaveSettingsExpansionPanel: MatExpansionPanel;
  @ViewChild('machineSparePartsCatalogueExpansionPanel') public machineSparePartsCatalogueExpansionPanel: MatExpansionPanel;
  @ViewChild('machineSchematicsExpansionPanel') public machineSchematicsExpansionPanel: MatExpansionPanel;
  @ViewChild('machineManualsExpansionPanel') public machineManualsExpansionPanel: MatExpansionPanel;
  @ViewChild('machineSetupsExpansionPanel') public machineSetupsExpansionPanel: MatExpansionPanel;

  public overviewListMachineForDocumentation: OverviewListMachineForDocumentation;
  public machineGeneralDocumentations: MachineGeneralDocumentation[];
  public machineTechnicalWeaveSettings: MachineTechnicalWeaveSettings[];
  public machineSparePartsCatalogue: MachineSparePartsCatalogue;
  public machineSchematics: MachineSchematic[];
  public machineManuals: MachineManual[];
  public machineDitaUserManualUrl: string;
  public machineDitaMaintenanceManualUrl: string;
  public showSkeletonViewForGeneralDocumentations = true;
  public showSkeletonViewForTechnicalWeaveSettings = true;
  public showSkeletonViewForSparePartsCatalogue = true;
  public showSkeletonViewForSchematics = true;
  public showSkeletonViewForManuals = true;
  public showSkeletonViewForSetups = true;
  public canDownloadMachineSoftware = false;
  public isEditingName = false;
  public saving = false;
  public machineNameChangeForm: FormGroup<{name: FormControl<string>}>;

  private editedName: string;
  private readonly loadingPageRoute: string = RouteUtils.paths.loadingFile.absolutePath;
  private readonly INTERVAL_TO_REFRESH_TOKEN: number = 55 * 60 * 1000;
  private machineIdUpdated = new Subject<void>();

  public constructor(
    @Inject(MACHINES_DOCUMENTATION) private readonly machinesDocumentationService: MachinesDocumentation,
    @Inject(RESPONSIVENESS_VIEW_MODE) public readonly responsivenessViewMode: ResponsivenessViewMode,
    private readonly openPdfFile: OpenPdfFile,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly formBuilder: FormBuilder
  ) {
    super();
  }

  public ngOnInit(): void {
    this.machineIdUpdated
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        switchMap(() => interval(this.INTERVAL_TO_REFRESH_TOKEN)),
        switchMap(() =>
          forkJoin([
            this.machinesDocumentationService.getMachineDitaManual(this.machineId, DitaManualType.USER),
            this.machinesDocumentationService.getMachineDitaManual(this.machineId, DitaManualType.MAINTENANCE)
          ])
        ),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(([userUrl, maintenanceUrl]: [string, string]) => {
        this.machineDitaUserManualUrl = userUrl;
        this.machineDitaMaintenanceManualUrl = maintenanceUrl;
      });
  }

  public ngOnDestroy(): void {
    this.machineIdUpdated.complete();
    super.ngOnDestroy();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('machineId' in changes) {
      if (this.canShowMachineDetails()) {
        this.isEditingName = false;
        this.canDownloadMachineSoftware = false;
        this.resetMachineGeneralDocumentations();
        this.resetMachineTechnicalWeaveSettings();
        this.resetMachineSparePartsCatalogue();
        this.resetMachineSchematics();
        this.resetMachineManuals();
        this.resetMachineSetups();
        this.machineDitaUserManualUrl = '';
        this.machineDitaMaintenanceManualUrl = '';
        this.machineIdUpdated.next();
      }
      this.getMachine();
    }
  }

  public editMachineName(): void {
    this.machineNameChangeForm = this.formBuilder.group({
      name: this.overviewListMachineForDocumentation.name
    });

    this.isEditingName = true;
  }

  public cancel(): void {
    this.isEditingName = false;
  }

  public saveMachineName(): void {
    this.saving = true;
    this.editedName = this.machineNameChangeForm.value.name;
    this.updateMachineName(this.overviewListMachineForDocumentation.id, this.editedName);
    this.overviewListMachineForDocumentation.name = this.editedName;
  }

  public canShowMachineDetails(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.overviewListMachineForDocumentation);
  }

  public downloadMachineGeneralDocumentationFile(number: string): void {
    this.openPdfFile.execute([this.machinesDocumentationService.downloadMachineGeneralDocumentationFile(this.overviewListMachineForDocumentation.id, number), number, this.loadingPageRoute]);
  }

  public downloadTechnicalWeaveSettingsRevisionFiles(technicalWeaveSettingsType: string, technicalWeaveSettingsRevision: MachineTechnicalWeaveSettingsRevision): void {
    this.openPdfFile.execute([
      this.machinesDocumentationService.downloadMachineTechnicalWeaveSettingsFile(this.overviewListMachineForDocumentation.id, technicalWeaveSettingsType, technicalWeaveSettingsRevision),
      technicalWeaveSettingsRevision.files[0]?.name ?? technicalWeaveSettingsType,
      this.loadingPageRoute
    ]);
  }

  public downloadMachineSetupFile(): void {
    this.openPdfFile.execute([this.machinesDocumentationService.downloadMachineSetupFile(this.overviewListMachineForDocumentation.id), '', this.loadingPageRoute]);
  }

  public downloadMachineSparePartsCatalogueFile(): void {
    this.openPdfFile.execute([
      this.machinesDocumentationService.downloadMachineSparePartsCatalogueFile(this.overviewListMachineForDocumentation.id, this.machineSparePartsCatalogue.name),
      this.machineSparePartsCatalogue.name,
      this.loadingPageRoute
    ]);
  }

  public getRevisionFileName(revision: MachineTechnicalWeaveSettingsRevision, isLast: boolean): string {
    let filename = this.translate.instant('MACHINE_DOCUMENTATION.REVISION_NR', {revisionNumber: revision.revisionNumber});
    if (revision.files.length > 0) {
      filename += ' - ' + revision.files[0].name;
      if (revision.files.length > 1) {
        filename += '..';
      }
    }
    if (!isLast) {
      filename += ',';
    }
    return filename;
  }

  public downloadMachineSchematicsFile(name: string): void {
    this.openPdfFile.execute([this.machinesDocumentationService.downloadMachineSchematicFile(this.overviewListMachineForDocumentation.id, name), name, this.loadingPageRoute]);
  }

  public downloadMachineManualFile(machineManual: MachineManual, language: string): void {
    const selectedMachineManual: MachineManual = cloneDeep(machineManual);
    selectedMachineManual.languages = [selectedMachineManual.languages.find((lang: MachineManualLanguage) => lang.language === language)];
    this.openPdfFile.execute([
      this.machinesDocumentationService.downloadMachineManualFile(this.overviewListMachineForDocumentation.id, selectedMachineManual),
      machineManual.name,
      this.loadingPageRoute
    ]);
  }

  public getMachineSchematic(machineSchematic: MachineSchematic): string {
    let result: string = machineSchematic.name;

    if (machineSchematic.title !== null) {
      result = machineSchematic.title;
    }

    return result;
  }

  public get machineName(): string {
    return StringUtils.getPropertyValueOrGeneralPlaceholderIfEmpty(this.overviewListMachineForDocumentation, 'name', this.translate);
  }

  public canShowMachineGeneralDocumentations(): boolean {
    return this.showSkeletonViewForGeneralDocumentations || !AssertionUtils.isEmpty(this.machineGeneralDocumentations);
  }

  public canShowMachineSparePartsCatalogue(): boolean {
    return this.showSkeletonViewForSparePartsCatalogue || (this.machineSparePartsCatalogue !== undefined && this.machineSparePartsCatalogue.name !== null);
  }

  public canShowMachineSchematics(): boolean {
    return this.showSkeletonViewForSchematics || !AssertionUtils.isEmpty(this.machineSchematics);
  }

  public canShowMachineManuals(): boolean {
    return (
      this.showSkeletonViewForManuals ||
      !AssertionUtils.isEmpty(this.machineManuals) ||
      !AssertionUtils.isEmpty(this.machineDitaUserManualUrl) ||
      !AssertionUtils.isEmpty(this.machineDitaMaintenanceManualUrl)
    );
  }

  public canShowMachineSetups(): boolean {
    return this.showSkeletonViewForSetups || this.canDownloadMachineSoftware;
  }

  public canShowMachineDitaUserManualUrl(): boolean {
    return this.showSkeletonViewForManuals || !AssertionUtils.isEmpty(this.machineDitaUserManualUrl);
  }

  public canShowMachineDitaMaintenanceManualUrl(): boolean {
    return this.showSkeletonViewForManuals || !AssertionUtils.isEmpty(this.machineDitaMaintenanceManualUrl);
  }

  public canShowMachineTechnicalWeaveSettings(): boolean {
    return this.showSkeletonViewForTechnicalWeaveSettings || !AssertionUtils.isEmpty(this.machineTechnicalWeaveSettings);
  }

  public canShowNoDataOverlay(): boolean {
    return (
      !this.canShowMachineGeneralDocumentations() &&
      !this.canShowMachineManuals() &&
      !this.canShowMachineSchematics() &&
      !this.canShowMachineSparePartsCatalogue() &&
      !this.canShowMachineTechnicalWeaveSettings() &&
      !this.canShowMachineDitaUserManualUrl() &&
      !this.canShowMachineDitaMaintenanceManualUrl() &&
      !this.canShowMachineSetups()
    );
  }

  private updateMachineName(id: number, newName: string): void {
    this.machinesDocumentationService
      .renameMachine(id, newName)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving()))
      .subscribe({
        next: () => {
          this.nameChangedEvent.emit({id, newName});
          this.saving = false;
          this.isEditingName = false;
          this.showToast(newName);
        },
        error: (errorMessage: BackendError) => this.showErrorDialogForBackendError('GENERAL.ACTIONS.EDIT_OBJECT', errorMessage.message)
      });
  }

  private showToast(newName: string): void {
    this.toastService.showInfo({
      tapToDismiss: false,
      timeOut: 2000,
      message: this.translate.instant('GENERAL.ACTIONS.EDITED_OBJECT', {
        object: this.translate.instant(MachineDocumentationDetailsComponent.machineTranslationKey, {count: 1}),
        name: newName,
        count: 1
      })
    });
  }

  private showErrorDialogForBackendError(translationKey: string, error: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {
        object: this.translate.instant(MachineDocumentationDetailsComponent.machineTranslationKey, {
          count: 1
        })
      }),
      messageText: error,
      type: DialogType.INFORMATION
    });
  }

  private getMachine(): void {
    this.machinesDocumentationService
      .getById(this.machineId)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((overviewListMachineForDocumentation: OverviewListMachineForDocumentation) => {
        this.overviewListMachineForDocumentation = overviewListMachineForDocumentation;
        this.changeDetectorRef.detectChanges();

        return forkJoin([
          this.machinesDocumentationService.getMachineGeneralDocumentations(this.overviewListMachineForDocumentation.id),
          this.machinesDocumentationService.getMachineTechnicalWeaveSettings(this.overviewListMachineForDocumentation.id),
          this.machinesDocumentationService.getMachineSparePartsCatalogue(this.overviewListMachineForDocumentation.id),
          this.machinesDocumentationService.getMachineSchematics(this.overviewListMachineForDocumentation.id),
          this.machinesDocumentationService.getMachineManuals(this.overviewListMachineForDocumentation.id),
          this.machinesDocumentationService.getMachineDitaManual(this.overviewListMachineForDocumentation.id, DitaManualType.USER),
          this.machinesDocumentationService.getMachineDitaManual(this.overviewListMachineForDocumentation.id, DitaManualType.MAINTENANCE),
          this.machinesDocumentationService.isSoftwareAvailable(this.overviewListMachineForDocumentation.id)
        ])
          .pipe(
            takeUntil(this.unSubscribeOnViewDestroy),
            finalize(() => {
              this.showSkeletonViewForGeneralDocumentations = false;
              this.showSkeletonViewForTechnicalWeaveSettings = false;
              this.showSkeletonViewForSparePartsCatalogue = false;
              this.showSkeletonViewForSchematics = false;
              this.showSkeletonViewForManuals = false;
              this.showSkeletonViewForSetups = false;
            })
          )
          .subscribe(
            ([
              machineGeneralDocumentations,
              machineTechnicalWeaveSettings,
              machineSparePartsCatalogue,
              machineSchematics,
              machineManuals,
              machineDitaUserManualUrl,
              machineDitaMaintenanceManualUrl,
              canDownloadMachineSoftware
            ]: [MachineGeneralDocumentation[], MachineTechnicalWeaveSettings[], MachineSparePartsCatalogue, MachineSchematic[], MachineManual[], string, string, boolean]) => {
              this.machineGeneralDocumentations = machineGeneralDocumentations;
              this.machineTechnicalWeaveSettings = machineTechnicalWeaveSettings;
              this.machineSparePartsCatalogue = machineSparePartsCatalogue;
              this.machineSchematics = machineSchematics;
              this.machineManuals = machineManuals;
              this.machineDitaUserManualUrl = machineDitaUserManualUrl;
              this.machineDitaMaintenanceManualUrl = machineDitaMaintenanceManualUrl;
              this.canDownloadMachineSoftware = canDownloadMachineSoftware;
            }
          );
      });
  }

  private resetMachineGeneralDocumentations(): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineGeneralDocumentationsExpansionPanel)) {
      this.machineGeneralDocumentationsExpansionPanel.close();
    }
    this.machineGeneralDocumentations = undefined;
    this.showSkeletonViewForGeneralDocumentations = true;
  }

  private resetMachineTechnicalWeaveSettings(): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineTechnicalWeaveSettingsExpansionPanel)) {
      this.machineTechnicalWeaveSettingsExpansionPanel.close();
    }
    this.machineTechnicalWeaveSettings = undefined;
    this.showSkeletonViewForTechnicalWeaveSettings = true;
  }

  private resetMachineSparePartsCatalogue(): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineSparePartsCatalogueExpansionPanel)) {
      this.machineSparePartsCatalogueExpansionPanel.close();
    }
    this.machineSparePartsCatalogue = undefined;

    this.changeDetectorRef.detectChanges();

    this.showSkeletonViewForSparePartsCatalogue = true;
  }

  private resetMachineSchematics(): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineSchematicsExpansionPanel)) {
      this.machineSchematicsExpansionPanel.close();
    }
    this.machineSchematics = undefined;
    this.showSkeletonViewForSchematics = true;
  }

  private resetMachineManuals(): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineManualsExpansionPanel)) {
      this.machineManualsExpansionPanel.close();
    }
    this.machineManuals = undefined;
    this.showSkeletonViewForManuals = true;
  }

  private resetMachineSetups(): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineSetupsExpansionPanel)) {
      this.machineSetupsExpansionPanel.close();
    }
    this.showSkeletonViewForSetups = true;
  }
}
