import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Company} from '@domain/profile/company';
import {Subscription} from '@domain/profile/subscription';
import {BaseChooseSubscription} from '@presentation/components/choose-subscription/base-choose-subscription';
import {first} from 'lodash-es';

@Component({
  selector: 'app-choose-subscription-mobile',
  templateUrl: './choose-subscription-mobile.component.html',
  styleUrls: ['./choose-subscription-mobile.component.scss']
})
export class ChooseSubscriptionMobileComponent extends BaseChooseSubscription implements OnChanges {
  @Input() public companies: Company[];
  @Input() public subscriptions: Subscription[];
  @Output() public subscriptionSelected: EventEmitter<Subscription> = new EventEmitter<Subscription>();

  public ngOnChanges(): void {
    this.initializeCompanySubscriptions();

    this.selectedCompany = first(this.companies);
    this.selectedSubscription = first(this.companySubscriptions.get(this.selectedCompany.id));
  }

  public chooseSubscription(subscription: Subscription = null): void {
    this.subscriptionSelected.emit(subscription);
  }
}
