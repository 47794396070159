<section class="full-width">
  <vdw-tabs-menu-card
    icon="creels-blue"
    [action]="getTabAction()"
    [canShowSave]="!readOnly"
    [actionText]="'GENERAL.ACTIONS.CONFIRM'"
    (cancelEvent)="cancelAction()"
    (saveEvent)="saveAction()"
    (backEvent)="cancelAction()"
  >
  </vdw-tabs-menu-card>

  <mat-card class="full-max-height-with-toolbar">
    <mat-card-content class="form-scrollable-content">
      <article fxLayout="column" fxLayoutGap="20px">
        <div class="header">
          <h3>{{ 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET' | bmsTranslate: {count: 2} }}</h3>
          <div class="toggle">
            <mat-label>{{ 'TEXTILE_DATA.COLOR.COLOR' | bmsTranslate: {count: 2} }}</mat-label>
            <mat-slide-toggle [checked]="isCurrentViewModeYarnType()" (change)="onCreelTypeViewModeChange($event)"></mat-slide-toggle>
            <mat-label>{{ 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE' | bmsTranslate: {count: 2} }}</mat-label>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="24px grid">
          <div
            fxFlex="0 1 100%"
            fxFlex.gt-md="0 1 50%"
            fxFlex.gt-lg="0 1 calc(100% / 3)"
            fxLayout="column"
            *ngFor="let coloredYarnSetWithStartDent of coloredYarnSetsWithStartDent; let index = index"
            fxLayoutGap="16px"
          >
            <p class="b1 font-weight-semibold" fxFlex fxGrow="0">{{ coloredYarnSetWithStartDent.coloredYarnSet.name }}</p>
            <app-creel-mapping-table
              [coloredYarnSetWithStartDent]="coloredYarnSetWithStartDent"
              [previewType]="creelPreviewType"
              [activeColoredYarnSetCreelMap]="getColoredYarnSetCreelMap(index)"
              [readOnly]="readOnly"
              (creelMapUpdate)="updateAction($event, index)"
            ></app-creel-mapping-table>
          </div>
        </div>
      </article>
    </mat-card-content>
  </mat-card>
</section>
