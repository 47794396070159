import {ColDef, GridOptions} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {BaseNavigationNode} from '../../common/interfaces/base-navigation-node.interface';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {GridOptionsBuilder} from '../../grids/grid-options-builder';
import {GridOptionsBuilderFactoryService} from '../../grids/grid-options-builder-factory.service';
import {ContentSwitcherDialogService} from '../content-switcher/content-switcher-dialog.service';
import {CrudOverviewButtonConfig} from './interfaces/crud-overview-button-config.interface';
import {CrudOverviewDataConfig} from './interfaces/crud-overview-data-config.interface';
import {CrudOverviewExtraActionConfig} from './interfaces/crud-overview-extra-action-config.interface';

export class CrudOverviewDataBuilder<T> {
  private readonly config: CrudOverviewDataConfig<T> = {onlyShowSelected: false, buttons: [], extraActions: [], selectedObjects: []};
  private readonly rowActionButtons: CrudOverviewButtonConfig[] = [];

  public constructor(
    private readonly gridOptionsBuilderFactory: GridOptionsBuilderFactoryService,
    private readonly contentSwitcher: ContentSwitcherDialogService
  ) {}

  public build(): CrudOverviewDataConfig<T> {
    return this.config;
  }

  public withGridOptions(colDefs: ColDef[], gridIdentifier: string, data: T[], getOptions?: (builder: GridOptionsBuilder) => GridOptions): this {
    getOptions ??= (builder: GridOptionsBuilder): GridOptions => builder.build();
    this.config.gridOptions = getOptions(this.gridOptionsBuilderFactory.getCrudBuilder(colDefs, gridIdentifier, data, this.rowActionButtons));
    this.config.rowData = data;

    return this;
  }

  public withRowData(dataObservable: Observable<T[]>): this {
    this.config.dataObservable = dataObservable;
    return this;
  }

  public withExtraActions(extraActionConfig: CrudOverviewExtraActionConfig[]): this {
    this.config.extraActions = extraActionConfig;
    return this;
  }

  public withDefaultButtons(actionButtonsForGridSelection: CrudOverviewButtonConfig[]): this {
    this.config.buttons = actionButtonsForGridSelection;
    this.withRowActionButtons(actionButtonsForGridSelection);
    return this;
  }

  public withSelectedObjects(selectedObjects: T[]): this {
    this.config.selectedObjects = selectedObjects;
    return this;
  }

  public withHeader(title: string): this {
    this.config.headerTitle = title;
    return this;
  }

  public withCustomSettingsClicked(onClick: () => void): this {
    this.config.withCustomSettingsClicked = onClick;
    return this;
  }

  public withNewClicked(onClick: (event: MouseEvent) => void): this {
    this.config.withNewClicked = onClick;
    return this;
  }

  public withRoute(node: BaseNavigationNode): this {
    if (AssertionUtils.isNullOrUndefined(this.config.withNewClicked)) {
      this.config.withNewClicked = (): void => {
        this.contentSwitcher.resetRouteData(node.add);
        this.contentSwitcher.navigateForward(node.add, this.contentSwitcher.getActiveRouteData(), {config: this.config});
      };
    }

    if (AssertionUtils.isNullOrUndefined(this.config.withBackClicked)) {
      this.config.withBackClicked = (): void => this.contentSwitcher.navigateBack(this.contentSwitcher.getActiveRouteData());
    }

    if (AssertionUtils.isNullOrUndefined(this.config.withConfirmClicked)) {
      this.config.withConfirmClicked = (data: T[]): void => {
        const shouldClose = !AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog) && AssertionUtils.isEmpty(this.contentSwitcher.navigationHistory);
        shouldClose ? this.contentSwitcher.dialog.close(data) : this.contentSwitcher.navigateBack(data);
      };
    }

    return this;
  }

  private withRowActionButtons(actionButtonsForGridSelection: CrudOverviewButtonConfig[]): void {
    actionButtonsForGridSelection?.forEach((actionButtonForGridSelection: CrudOverviewButtonConfig) => {
      if (actionButtonForGridSelection.title !== 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EXPORT') {
        this.rowActionButtons.push(actionButtonForGridSelection);
      }
    });
  }
}
