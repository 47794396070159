import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNumberOfCreelPositionsData} from '@application/helper/navigation-helper/navigation-number-of-creel-positions-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {StringUtils} from '@application/helper/string-utils';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {COLOR_SETS, ColorSets} from '@infrastructure/http/color-set/color-sets';
import {MACHINE_QUALITIES, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {GridColorListPreviewComponent} from '@presentation/components/grid/grid-color-list-preview/grid-color-list-preview.component';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  Color,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ValueGetterParams} from 'ag-grid-community';
import {filter, has, indexOf, isEqual, isNil, size} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-color-set.component.html'
})
export class SelectColorSetComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('colorSetsGrid') public colorSetsGrid: AgGridAngular;
  public listOfColorSets: OverviewListColorSet[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public readonly COLOR_SET_EDIT_PERMISSION = LastModifiedCardUtils.getPermissionToModifyItems('colorSet');

  private numberOfCreelPositions: number;
  private machineQualityId: number;
  private existingColorSets: number[];

  public constructor(
    @Inject(COLOR_SETS) private readonly colorSets: ColorSets,
    @Inject(MACHINE_QUALITIES) private readonly qualities: MachineQualities,
    private readonly dialogRef: MatDialogRef<SelectColorSetComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    @Inject(MAT_DIALOG_DATA) public data: {numberOfCreelPositions: number; qualityId: number; existingColorSets: number[]},
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectColorSetComponent> & NavigationNumberOfCreelPositionsData>,
    private readonly router: Router
  ) {
    super();
    this.numberOfCreelPositions = has(data, 'numberOfCreelPositions') ? data.numberOfCreelPositions : null;
    this.machineQualityId = has(data, 'qualityId') ? data.qualityId : null;
    this.existingColorSets = has(data, 'existingColorSets') ? data.existingColorSets : [];
  }

  public get gridOptionsForListOfColorSets(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfColorSets();
    this.getColorSets();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.colorSetsGrid?.api];
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<DialogComponentData<typeof SelectColorSetComponent>>({dialogComponent: SelectColorSetComponent});
  }

  public selectColorSet(): void {
    this.dialogRef.close(this.colorSetsGrid.api.getSelectedRows()[0]);
  }

  public canSelectColorSet(): boolean {
    return !AssertionUtils.isEmpty(this.colorSetsGrid?.api?.getSelectedRows());
  }

  public navigateToAddColorSet(): void {
    if (!AssertionUtils.isNullOrUndefined(this.machineQualityId) && this.listOfColorSets?.length < 1) {
      this.qualities
        .getById(this.machineQualityId)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((quality: WeaveQuality) => {
          this.navigationHelperService.navigateToNextRouteWithPartialState<NavigationNumberOfCreelPositionsData>(
            {numberOfCreelPositions: quality.singleWidth?.weaveStructure.numberOfCreelPositions ?? quality.doubleWidth.bottomWeaveStructure.numberOfCreelPositions},
            RouteUtils.paths.texStyle.colorSet.addColorSet.absolutePath
          );
        });
    } else {
      this.navigationHelperService.navigateToNextRouteWithPartialState<NavigationNumberOfCreelPositionsData>(
        {numberOfCreelPositions: AssertionUtils.isNullOrUndefined(this.machineQualityId) ? this.numberOfCreelPositions : this.listOfColorSets[0].creelPositions.length},
        RouteUtils.paths.texStyle.colorSet.addColorSet.absolutePath
      );
    }
  }

  private getColorSets(): void {
    const request = isNil(this.machineQualityId) ? this.colorSets.getAll() : this.colorSets.getAllByMachineQuality(this.machineQualityId);
    request.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((listOfColorSets: OverviewListColorSet[]) => {
      this.listOfColorSets = !isNil(this.numberOfCreelPositions)
        ? filter(listOfColorSets, (colorSet: OverviewListColorSet) => {
            return isEqual(this.numberOfCreelPositions, size(colorSet.creelPositions));
          })
        : listOfColorSets;

      this.listOfColorSets = filter(this.listOfColorSets, (colorSet: OverviewListColorSet) => isEqual(indexOf(this.existingColorSets, colorSet.id), -1));
    });
  }

  private setGridOptionsForListOfColorSets(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfColorSets(), GridIdentifier.SELECT_COLOR_SET)
        .withOnCellDoubleClicked(() => this.selectColorSet())
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'TEXTILE_DATA.COLOR_SET.COLOR_SET',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withRememberStateForNavigationHelper()
        .build()
    ];
  }

  private getColumnDefsForListOfColorSets(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.MATERIAL_SET.NUMBER_OF_CREEL_POSITIONS')
        .withField('creelPositions.length', true)
        .withComparator((valueA: number, valueB: number) => valueA - valueB)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.COLOR.COLOR')
        .withValueGetter((params: ValueGetterParams) => this.getColorNamesForColorSet(params.data), true)
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withValueGetter('data')
        .withHeaderName('GENERAL.PREVIEW')
        .withSortable(false)
        .withCellRenderer(GridColorListPreviewComponent)
        .withoutFilter()
        .build()
    ];
  }

  private getColorNamesForColorSet(colorSet: OverviewListColorSet): string {
    return colorSet
      .getUniqueColors()
      .map((color: Color) => color.name)
      .join(', ');
  }
}
