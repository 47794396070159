import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InputCounterDirective} from './input-counter.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [InputCounterDirective],
  exports: [InputCounterDirective]
})
export class InputCounterModule {}
