<section class="flex-column full-height">
  <vdw-header
    [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'BMSCONFIG.MACHINE_DATA_SOURCES.RESOURCE_TYPE_BREAKDOWN' | bmsTranslate | lowercase}"
    svgIcon="select-blue"
    [canShowClose]="true"
  ></vdw-header>
  <article class="dialog-content full-height flex-column flex-1 gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToEditResourceType()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #resourceTypeBreakdownsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfResourceTypeBreakdowns" [rowData]="listOfResourceTypeBreakdowns"> </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectResourceTypeBreakdown()" [centered]="true" (chooseEvent)="selectResourceTypeBreakdown()"></vdw-footer>
</section>
