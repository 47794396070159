import {ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {MatDrawer, MatDrawerMode} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {Event, NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationState} from '@domain/navigation/navigation-state.enum';
import {Company} from '@domain/profile/company';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {environment} from '@environments/environment';
import Hotjar from '@hotjar/browser';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {HttpRequestManagerService} from '@infrastructure/http/http-request-manager.service';
import {CompanySubscriptions} from '@infrastructure/http/profile/company-subscriptions';
import {PROFILE, Profile} from '@infrastructure/http/profile/profile';
import {UpdateService} from '@infrastructure/update/update.service';
import {ApplicationLayoutRegisterService} from '@presentation/navigation/application-layout-register-service/application-layout-register.service';
import {NavigationContextService} from '@presentation/navigation/navigation-context-service/navigation-context.service';
import {SideNavigationStateService} from '@presentation/navigation/side-navigation-state.service';
import {
  AppInsightsLoggingService,
  ApplicationLayoutService,
  AssertionUtils,
  BaseComponent,
  Icon,
  ICONS,
  IconSet,
  LanguageService,
  LocalStorageService,
  moment,
  ResizeElementDirective,
  RESPONSIVENESS_VIEW_MODE,
  ResponsivenessViewMode,
  SOLID_ICONS,
  SolidIcon,
  ToastService,
  TranslateService,
  UuidUtils
} from '@vdw/angular-component-library';
import {NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
  public sideNavInitialized: boolean;
  public notifications: string[] = [];
  public drawerInitialized = new EventEmitter();
  public resizeDirective: ResizeElementDirective;

  private isAuthenticated: boolean;
  private companies: Company[] = [];
  private subscriptions: Subscription[] = [];

  private readonly HOTJAR_VERSION = 6;

  @ViewChild('drawer')
  public set drawer(value: MatDrawer) {
    if (!AssertionUtils.isNullOrUndefined(value)) {
      this.applicationLayoutService.matDrawer = value;
      this.drawerInitialized.emit();
    }
  }

  public constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    @Inject(PROFILE) private readonly profile: Profile,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly dialog: MatDialog,
    private readonly sideNavigationState: SideNavigationStateService,
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode,
    private readonly languageService: LanguageService,
    private readonly updateService: UpdateService,
    private readonly httpRequestManagerService: HttpRequestManagerService,
    private readonly localStorage: LocalStorageService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly backendConfiguration: BackendConfiguration,
    private readonly logger: NGXLogger,
    private readonly toastService: ToastService,
    private matSnackBar: MatSnackBar,
    public readonly applicationLayoutService: ApplicationLayoutService,
    private readonly applicationLayoutRegistering: ApplicationLayoutRegisterService,
    public readonly appInsightsLoggingService: AppInsightsLoggingService,
    private readonly navigationContext: NavigationContextService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setUpIcons();
    this.calculateDpi();
    this.updateSideNavState();
    this.subscribeToApplicationUpdates();
    this.subscribeToApplicationUpdateApplied();
    this.subscribeToRouterEvents();
    this.subscribeToMediaChanges();
    this.validateCurrentSubscription();
    this.checkForExperimentalLanguageUsage();
    this.subscribeToAuthenticationChanges();
    this.setLoggerLevel();
    this.setBrowserInstanceId();
    this.setUpHotjar();
    this.setupApplicationInsightsLogging();

    this.applicationLayoutRegistering.registerElements();
  }

  public isSideNavCollapsed(): boolean {
    return this.sideNavigationState.getCurrentNavigationState() === NavigationState.CLOSED;
  }

  public getMode(): MatDrawerMode {
    return this.isTablet() ? 'over' : 'side';
  }

  public canShowMenu(): boolean {
    const isDefaultRoute = this.router.url === '/';
    const isLoginRoute = this.router.url.startsWith(RouteUtils.paths.login.absolutePath);
    const isLoadingMachineTechnicalWeaveSettingsFile = this.router.url.startsWith(RouteUtils.paths.loadingFile.absolutePath);

    return !isDefaultRoute && !isLoginRoute && this.isAuthenticated && !isLoadingMachineTechnicalWeaveSettingsFile;
  }

  public canShowMobileNavigation(): boolean {
    return this.isPhone() && this.isAuthenticated && !this.router.url.startsWith(RouteUtils.paths.login.absolutePath);
  }

  public toggleSideNav(): void {
    this.sideNavigationState.getCurrentNavigationState() === NavigationState.OPEN ? this.sideNavigationState.changeNavigationStateToClosed() : this.sideNavigationState.changeNavigationStateToOpen();
  }

  public getSideNavStateIcon(): string {
    return this.isSideNavCollapsed() ? 'chevron-right' : 'chevron-left';
  }

  public onOpenedChange(): void {
    this.sideNavInitialized = true;
  }

  public isTablet(): boolean {
    return this.responsivenessViewMode.isTablet;
  }

  public isPhone(): boolean {
    return this.responsivenessViewMode.isPhone;
  }

  private showUsedBackendSnackbar(): void {
    if (this.authentication.getCurrentSubscription()?.hasPermission(Permission.ACCESS_ALL)) {
      const message = `Backend URL: ${this.backendConfiguration.getEndpoint()}`;
      this.matSnackBar.open(message, 'close', {duration: 5000});
    }
  }

  private updateSideNavState(): void {
    if (this.responsivenessViewMode.isPhone && this.isSideNavOpen()) {
      this.sideNavigationState.changeNavigationStateToClosed();
    }
  }

  private setUpIcons(): void {
    SOLID_ICONS.forEach((icon: SolidIcon) => this.iconRegistry.addSvgIcon(icon.name, this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)));

    const icons: IconSet[] = ICONS;
    icons.forEach((iconSet: IconSet) => {
      iconSet.icons.forEach((icon: Icon) => {
        this.iconRegistry.addSvgIcon(icon.name, this.sanitizer.bypassSecurityTrustResourceUrl(icon.path));
      });
    });
  }

  private calculateDpi(): void {
    const div = document.createElement('div');
    div.style.height = '1in';
    div.style.width = '1in';
    div.style.top = '-100%';
    div.style.left = '-100%';
    div.style.position = 'absolute';

    document.body.appendChild(div);

    const result = div.offsetHeight;

    document.body.removeChild(div);

    this.localStorage.set('dpi', result);
  }

  private subscribeToApplicationUpdates(): void {
    this.updateService.checkForUpdates();
  }

  private subscribeToApplicationUpdateApplied(): void {
    if (this.canShowNewUpdateAppliedToast()) {
      this.translate.get(['NEW_UPDATE_APPLIED_TOAST.TITLE', 'NEW_UPDATE_APPLIED_TOAST.MESSAGE']).subscribe((translations: Record<string, string>) => {
        this.toastService.showNewUpdateApplied({
          tapToDismiss: false,
          timeOut: 10000,
          extendedTimeOut: 10000,
          title: 'NEW_UPDATE_APPLIED_TOAST.TITLE',
          message: translations['NEW_UPDATE_APPLIED_TOAST.MESSAGE']
        });
      });
    }
    this.localStorage.remove('new-update-activation-timestamp');
  }

  private canShowNewUpdateAppliedToast(): boolean {
    let result = false;

    const lastNewUpdateActivationTimestampString = this.localStorage.get('new-update-activation-timestamp');
    if (!AssertionUtils.isEmpty(lastNewUpdateActivationTimestampString)) {
      const now = moment();
      const lastUpdate = moment(Number(lastNewUpdateActivationTimestampString));
      const duration = moment.duration(now.diff(lastUpdate));
      result = duration.asSeconds() <= 30;
    }

    return result;
  }

  private isSideNavOpen(): boolean {
    return this.sideNavigationState.getCurrentNavigationState() === NavigationState.OPEN;
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event: Event | RouterEvent): boolean => event instanceof NavigationStart || event instanceof NavigationEnd),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationStart) {
          this.dialog.closeAll();
          this.httpRequestManagerService.cancelPendingRequests();
        } else if (!this.responsivenessViewMode.isDesktop && event instanceof NavigationEnd && this.isSideNavOpen()) {
          this.sideNavigationState.changeNavigationStateToClosed();
        }
      });
  }

  private subscribeToMediaChanges(): void {
    this.responsivenessViewMode
      .observeDeviceChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        this.updateSideNavState();
      });
  }

  private validateCurrentSubscription(): void {
    this.authentication.validateCurrentSubscription();
  }

  private checkForExperimentalLanguageUsage(): void {
    const activeLanguage = this.languageService.getActiveLanguage();

    if (this.languageService.isExperimentalLanguage(activeLanguage)) {
      const experimentalLanguageConfirmed = this.localStorage.get<boolean>(`experimentalLanguage-${activeLanguage}`);
      if (!experimentalLanguageConfirmed) {
        this.notifyUserAboutExperimentalLanguage(activeLanguage);
      }
    }
  }

  private subscribeToAuthenticationChanges(): void {
    this.isAuthenticated = this.authentication.isAuthenticated();

    this.authentication
      .currentAuthenticatedChanges()
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        tap((authenticated: boolean) => this.authenticatedChanges(authenticated)),
        filter(() => this.isAuthenticated),
        switchMap(() => this.profile.getSubscriptions().pipe(filter((subscriptions: CompanySubscriptions) => !AssertionUtils.isNullOrUndefined(subscriptions))))
      )
      .subscribe((companySubscriptions: CompanySubscriptions) => {
        this.companies = companySubscriptions.companies.sort((first: Company, second: Company) => first.name.localeCompare(second.name));
        this.subscriptions = companySubscriptions.subscriptions.sort((first: Subscription, second: Subscription) => first.name.localeCompare(second.name));

        this.navigationContext.companies = this.companies;
        this.navigationContext.subscriptions = this.subscriptions;
      });
  }

  private notifyUserAboutExperimentalLanguage(activeLanguage: string): void {
    const url = `${environment.helpUrl}support/tickets/new`;
    this.translate.get('PROFILE.LANGUAGE_SETTINGS.EXPERIMENTAL_LANGUAGE_CAUTION', {url}, activeLanguage).subscribe((translation: string) => {
      const toast = this.toastService.showInfo({tapToDismiss: true, timeOut: 0, message: translation});

      toast?.onTap.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
        this.localStorage.set(`experimentalLanguage-${activeLanguage}`, true);
      });
    });
  }

  private setLoggerLevel(): void {
    if (!AssertionUtils.isNullOrUndefined(this.localStorage.get('loggerLevel'))) {
      this.logger.updateConfig({level: NgxLoggerLevel[`${this.localStorage.get('loggerLevel')}`]});
    }
  }

  private setBrowserInstanceId(): void {
    const browserInstanceId = UuidUtils.generateV4Uuid();
    this.backendConfiguration.setBrowserInstanceId(browserInstanceId);
  }

  private setUpHotjar(): void {
    if (!AssertionUtils.isNullOrUndefined(environment.siteIdHotjar)) {
      Hotjar.init(environment.siteIdHotjar, this.HOTJAR_VERSION);
    }
  }

  private setupApplicationInsightsLogging(): void {
    const applicationInsightsKey = this.backendConfiguration.getApplicationInsightsInstrumentationKey();
    if (!AssertionUtils.isNullOrUndefined(applicationInsightsKey)) {
      this.appInsightsLoggingService.init(applicationInsightsKey);
    }
  }

  private authenticatedChanges(authenticated: boolean): void {
    this.isAuthenticated = authenticated;
    this.changeDetectorRef.detectChanges();
    this.showUsedBackendSnackbar();
  }
}
