import {Directive, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, Renderer2} from '@angular/core';
import {TechnicalDatasheets, TECHNICAL_DATASHEETS} from '@infrastructure/http/technical-datasheets/technical-datasheets';
import {BaseComponent, BlobUtils} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {fromEvent, noop, Observable} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[appLoadTechnicalDatasheetImage]'
})
export class LoadTechnicalDatasheetImageDirective extends BaseComponent implements OnInit, OnChanges {
  @Input() public technicalDatasheetId: number;
  @Input() public shouldDownloadImage: boolean;
  @Output() public imageDownloaded: EventEmitter<string> = new EventEmitter<string>();

  protected elementRef: ElementRef<HTMLElement>;
  private renderer: Renderer2;

  public constructor(
    @Inject(TECHNICAL_DATASHEETS) private readonly technicalDatasheets: TechnicalDatasheets,
    renderer: Renderer2,
    elementRef: ElementRef<HTMLElement>
  ) {
    super();
    this.renderer = renderer;
    this.elementRef = elementRef;
  }

  public ngOnInit(): void {
    this.setImageFromData('/assets/images/image-placeholder.svg');
  }

  public ngOnChanges(): void {
    if (!isNil(this.technicalDatasheetId) && this.shouldDownloadImage) {
      fromEvent(this.elementRef.nativeElement, 'load')
        .pipe(first(), takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(() => {
          this.downloadAndSetImageForTechnicalDatasheet(this.technicalDatasheetId);
        });
    }
  }

  public downloadAndSetImageForTechnicalDatasheet(imageId: number): void {
    this.downloadTechnicalDatasheetThumbnail(imageId)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (blob: Blob) => {
          this.setDownloadedImage(blob);
        },
        error: noop
      });
  }

  protected setDownloadedImage(blob: Blob): void {
    BlobUtils.blobToImageData(blob).then((imageData: string) => {
      this.setImageFromData(imageData);
      this.imageDownloaded.emit(imageData);
    });
  }

  protected setImageFromData(image: string): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'src', image);
  }

  private downloadTechnicalDatasheetThumbnail(imageId: number): Observable<Blob> {
    return this.technicalDatasheets.getThumbnail(imageId);
  }
}
