import {DOCUMENT} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Command} from '@application/command/command';
import {AssertionUtils, DialogBuilderFactoryService, DialogType, MimeType, WINDOW} from '@vdw/angular-component-library';
import {isEqual, isNil} from 'lodash-es';
import {Observable} from 'rxjs';
import {delay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OpenPdfFile implements Command<[Observable<HttpResponse<Blob>>, string, string], void> {
  public constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {}

  public execute([downloadFileFunction, fileName, loadingPageRoute]: [Observable<HttpResponse<Blob>>, string, string]): void {
    const isBrowserIEdge = this.isBrowserIEdge();
    let newWindow: Window;

    if (!isBrowserIEdge) {
      newWindow = this.window.open(loadingPageRoute, '_blank');
    }

    downloadFileFunction.pipe(delay(2000)).subscribe({
      next: (blobResponse: HttpResponse<Blob>) => {
        if (AssertionUtils.isEmpty(fileName)) {
          fileName = this.getFileNameFromHeaders(blobResponse);
        }

        if (isBrowserIEdge) {
          (this.window.navigator as any).msSaveOrOpenBlob(blobResponse.body, fileName);
        } else {
          if (isEqual(blobResponse.body.type, MimeType.PDF)) {
            newWindow.location.href = URL.createObjectURL(blobResponse.body);
          } else {
            newWindow.close();
            this.createAndClickAnchor(blobResponse.body, fileName);
          }
        }
      },
      error: (error: Error) => {
        if (!isBrowserIEdge) {
          newWindow.close();
        }

        this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
          titleText: error.name,
          messageText: error.message,
          type: DialogType.ERROR
        });
      }
    });
  }

  private getFileNameFromHeaders(response: HttpResponse<Blob>): string {
    const contentDisposition = response.headers.get('content-disposition');
    if (AssertionUtils.isNullOrUndefined(contentDisposition)) {
      return '';
    }

    return contentDisposition.split('filename=')[1].split(';')[0];
  }

  private isBrowserIEdge(): boolean {
    return !isNil(this.window.navigator) && !isNil((this.window.navigator as any).msSaveOrOpenBlob);
  }

  private createAndClickAnchor(blob: Blob, fileName: string): void {
    const downloadAnchor: HTMLAnchorElement = this.document.createElement('a');
    downloadAnchor.setAttribute('href', URL.createObjectURL(blob));
    downloadAnchor.setAttribute('download', fileName);
    downloadAnchor.click();
  }
}
