import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[vdwDelayedClass]'
})
export class DelayedClassDirective implements OnInit {
  @Input() public vdwDelayedClass: string;

  public constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    if (this.vdwDelayedClass) {
      setTimeout(() => {
        this.elementRef.nativeElement.classList.add(this.vdwDelayedClass);
      }, 500);
    }
  }
}
