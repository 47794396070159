<div fxLayout="row" fxLayoutGap="8px">
  <div class="paginator" fxLayout="column" fxLayoutAlign="center center" (click)="previous()" [class.disabled]="!canGoToPrevious()">
    <mat-icon class="icon-24" svgIcon="chevron-left"></mat-icon>
  </div>

  <ng-container *ngFor="let page of pages; let index = index; let first = first">
    <div class="page-item" fxLayout="column" fxLayoutAlign="center center" *ngIf="!first && canShowMore(pages[index - 1], page)">
      <mat-icon class="icon-24" svgIcon="more"></mat-icon>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" [class.page-item]="!isCurrent(page)" [class.current-page]="isCurrent(page)" (click)="goTo(page)">
      <span class="font-weight-semibold">{{ page + 1 }}</span>
    </div>
  </ng-container>

  <div class="paginator" fxLayout="column" fxLayoutAlign="center center" (click)="next()" [class.disabled]="!canGoToNext()">
    <mat-icon class="icon-24" svgIcon="chevron-right"></mat-icon>
  </div>
</div>
