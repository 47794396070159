import {createTheme} from 'ag-grid-community';
import {ColorConstants} from '../common/colors-variables.generated';

export const gridTheme = createTheme().withParams({
  spacing: 8,
  wrapperBorder: false,
  wrapperBorderRadius: 0,
  cellHorizontalPadding: 12,
  accentColor: ColorConstants.NEUTRAL_900,
  borderColor: ColorConstants.NEUTRAL_850,
  rowBorder: true,
  fontSize: 14,
  rowHeight: 32,
  pinnedColumnBorder: true,
  pinnedRowBorder: false,
  rowHoverColor: ColorConstants.NEUTRAL_900,
  popupShadow: `0px 0px 3px 0px ${ColorConstants.NEUTRAL_BLACK}33, 0px 8px 16px -4px ${ColorConstants.NEUTRAL_BLACK}26`,
  selectedRowBackgroundColor: ColorConstants.PRIMARY_900,
  focusShadow: 'none',
  rowGroupIndentSize: 0,
  fontFamily: 'Source Sans Pro',
  //header
  headerTextColor: ColorConstants.NEUTRAL_BLACK,
  headerBackgroundColor: ColorConstants.NEUTRAL_950,
  headerFontWeight: 600,
  headerVerticalPaddingScale: 0.6,
  headerColumnBorder: true,
  headerColumnResizeHandleHeight: 0,
  //icon
  iconSize: 16,
  iconButtonHoverBackgroundColor: 'tranparent',
  iconButtonHoverColor: 'transparent',
  //menu
  menuBackgroundColor: ColorConstants.NEUTRAL_WHITE,
  menuSeparatorColor: ColorConstants.NEUTRAL_900,
  menuTextColor: ColorConstants.NEUTRAL_BLACK,
  menuBorder: 'none',
  //floating filter
  widgetContainerHorizontalPadding: {ref: 'spacing'},
  widgetContainerVerticalPadding: {ref: 'spacing'},
  listItemHeight: 32,
  //panel
  dialogBorder: 'none',
  panelBackgroundColor: ColorConstants.NEUTRAL_WHITE,
  panelTitleBarBackgroundColor: ColorConstants.NEUTRAL_WHITE,
  panelTitleBarBorder: {color: ColorConstants.NEUTRAL_900}
});
