<mat-expansion-panel class="mat-elevation-z mat-expansion-panel margin-bottom" [expanded]="true" (opened)="openPanel()" (closed)="closePanel()">
  <mat-expansion-panel-header class="configuration-expansion-panel-header" expandedHeight="54px" collapsedHeight="54px">
    <mat-panel-title fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <h3 fxFlex class="font-weight-semibold">{{ 'DESIGN_LIBRARY.DETAILS.CONFIGURATION.CONFIGURATION' | bmsTranslate: {count: 1} }} {{ drawingConfigurationIndex }}</h3>
      <app-color-list-preview *ngIf="canShowColorListPreview()" fxFlex="nogrow" fxLayoutAlign="center end" [colorList]="colorSet" [maximumCreelPositionsToShow]="10" (click)="$event.stopPropagation()">
      </app-color-list-preview>
      <ng-container *ngIf="canDelete">
        <button *appHasPermission="texeditWritePermission" mat-icon-button class="mat-icon-action-button-sm-without-border delete-configuration" [disableRipple]="true" (click)="deleteConfiguration()">
          <mat-icon svgIcon="trash"></mat-icon>
        </button>
      </ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <section [formGroup]="form">
    <div class="flex-row gap-24">
      <div class="drawing-image">
        <app-drawing-library-image-preview
          draggable="false"
          [drawingImage]="getDrawingImage()"
          [qualityId]="getQualityId()"
          [colorSetId]="colorSet?.id"
          [hasMissingDesign]="hasMissingDesign"
          [drawingDimensionsInPx]="drawingDimensionsInPx"
          [areImagesUploading]="false"
          [shouldDownloadImage]="true"
          (imageDownloaded)="imageDownloaded($event)"
        >
        </app-drawing-library-image-preview>
      </div>
      <div class="configuration-form full-width">
        <div class="flex-row gap-16">
          <mat-form-field class="flex-1">
            <mat-label>{{ 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY' | bmsTranslate: {count: 1} }}</mat-label>
            <vdw-object-selection
              formControlName="quality"
              [objectName]="'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY' | bmsTranslate: {count: 1} | lowercase"
              [chipValueKey]="QUALITY_CHIP_VALUE_KEY"
              [baseRouterLink]="QUALITY_BASE_ROUTER_LINK"
              (selectClicked)="selectQuality()"
              (clearClicked)="handleMachineQualitySelection(null)"
            ></vdw-object-selection>
            <mat-error *vdwCanShowErrorForFormControl="'quality'; error: 'required'">
              {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
            </mat-error>
          </mat-form-field>
          <div *ngIf="isColorItemSelected()" class="flex-row gap-16 flex-1">
            <mat-form-field>
              <mat-label>{{ 'GENERAL.DIMENSIONS.WIDTH' | bmsTranslate }}</mat-label>
              <input matInput vdwL10nDecimal type="number" formControlName="widthInCm" />
              <span matSuffix>{{ 'GENERAL.UNIT.CENTIMETER' | bmsTranslate }}</span>
              <mat-error *ngIf="canShowErrorForFormControl('required', 'widthInCm')">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
              <mat-error *ngIf="canShowErrorForFormControl('min', 'widthInCm')">
                {{ 'GENERAL.VALIDATION.MINIMUM' | bmsTranslate }}
                : 1
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'GENERAL.DIMENSIONS.HEIGHT' | bmsTranslate }}</mat-label>
              <input matInput vdwL10nDecimal type="number" formControlName="heightInCm" />
              <span matSuffix>{{ 'GENERAL.UNIT.CENTIMETER' | bmsTranslate }}</span>
              <mat-error *ngIf="canShowErrorForFormControl('required', 'heightInCm')">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
              <mat-error *ngIf="canShowErrorForFormControl('min', 'heightInCm')">
                {{ 'GENERAL.VALIDATION.MINIMUM' | bmsTranslate }}
                : 1
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <ng-container *ngIf="isQualitySelected()">
            <div *ngIf="!useColoredYarnSet" class="flex-row gap-16">
              <mat-form-field class="full-width">
                <mat-label>{{ 'TEXTILE_DATA.COLOR_SET.COLOR_SET' | bmsTranslate: {count: 1} }}</mat-label>
                <vdw-object-selection
                  formControlName="colorSet"
                  [objectName]="'TEXTILE_DATA.COLOR_SET.COLOR_SET' | bmsTranslate: {count: 1} | lowercase"
                  [baseRouterLink]="COLOR_SET_BASE_ROUTER_LINK"
                  [inProgress]="recoloringImage"
                  (selectClicked)="selectColorSet()"
                  (clearClicked)="removeColorSet()"
                ></vdw-object-selection>
                <mat-error *vdwCanShowErrorForFormControl="'colorSet'; error: 'required'">
                  {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="isColorSetSelected()" class="full-width">
                <mat-label>
                  {{ 'TEXTILE_DATA.YARN_SET.YARN_SET' | bmsTranslate: {count: 1} }}
                  <span class="optional">({{ 'GENERAL.OPTIONAL' | bmsTranslate | lowercase }})</span>
                </mat-label>
                <vdw-object-selection
                  formControlName="yarnSet"
                  [objectName]="'TEXTILE_DATA.YARN_SET.YARN_SET' | bmsTranslate: {count: 1} | lowercase"
                  [baseRouterLink]="YARN_SET_BASE_ROUTER_LINK"
                  (selectClicked)="selectYarnSet()"
                ></vdw-object-selection>
              </mat-form-field>
            </div>
            <mat-form-field *ngIf="useColoredYarnSet" class="full-width">
              <mat-label>{{ 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET' | bmsTranslate: {count: 1} }}</mat-label>
              <vdw-object-selection
                formControlName="coloredYarnSet"
                [objectName]="'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET' | bmsTranslate: {count: 1} | lowercase"
                [baseRouterLink]="COLORED_YARN_SET_BASE_ROUTER_LINK"
                [inProgress]="recoloringImage"
                (selectClicked)="selectColoredYarnSet()"
              ></vdw-object-selection>
              <mat-error *vdwCanShowErrorForFormControl="'coloredYarnSet'; error: 'required'">
                {{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="24px" *ngIf="isColorItemSelected()">
      <div fxLayout="row wrap" class="table">
        <header class="table-header" fxLayout="row" fxFlex="100">
          <div class="table-cell" fxFlex="12" fxLayoutAlign="center center">
            <h4 class="font-weight-semibold">{{ 'DESIGN_LIBRARY.DETAILS.CONFIGURATION.MAIN_COLOR' | bmsTranslate }}</h4>
          </div>
          <div class="table-cell" fxFlex="14" fxLayoutAlign="center center">
            <h4 class="font-weight-semibold">{{ 'DESIGN_LIBRARY.DETAILS.CONFIGURATION.BORDER_COLOR' | bmsTranslate }}</h4>
          </div>
          <div class="table-cell" fxFlex="20" fxLayoutAlign="center center">
            <h4 class="font-weight-semibold">{{ 'DESIGN_LIBRARY.DETAILS.CONFIGURATION.SAME_BATH' | bmsTranslate }}</h4>
          </div>
          <div class="table-cell" fxFlex="10" fxLayoutAlign="center center" *appHasPermission="TEXEDIT_SHOW_YARN_PERCENTAGES">
            <h4 class="font-weight-semibold">%</h4>
          </div>
          <div class="table-cell" fxFlex="44" fxLayoutAlign="start center">
            <h4 class="font-weight-semibold">{{ 'DESIGN_LIBRARY.DETAILS.CONFIGURATION.COLOR_RGB' | bmsTranslate }}</h4>
          </div>
        </header>
        <article class="table-body" fxLayout="row wrap" fxFlex="100">
          <div fxLayout="row" fxFlex="100" *ngFor="let creelPosition of colorSet.creelPositions" class="table-row">
            <div class="table-cell" fxFlex="12" fxLayout="column" fxLayoutAlign="center center">
              <mat-radio-group formControlName="mainColorsCreelPosition">
                <mat-radio-button
                  color="primary"
                  [attr.checked]="isCurrentMainColorsCreelPosition(creelPosition)"
                  [checked]="isCurrentMainColorsCreelPosition(creelPosition)"
                  [value]="creelPosition"
                  (click)="deselectCurrentMainColorsCreelPosition($event, creelPosition)"
                ></mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="table-cell" fxFlex="14" fxLayout="column" fxLayoutAlign="center center">
              <mat-radio-group formControlName="borderColorsCreelPosition">
                <mat-radio-button
                  color="primary"
                  [attr.checked]="isCurrentBorderColorsCreelPosition(creelPosition)"
                  [checked]="isCurrentBorderColorsCreelPosition(creelPosition)"
                  [value]="creelPosition"
                  (click)="deselectCurrentBorderColorsCreelPosition($event, creelPosition)"
                ></mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="table-cell" fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
              <mat-checkbox color="primary" [checked]="creelPosition.needsToBeFromSameBath" (change)="updateCreelPosition(creelPosition, $event)" [disabled]="!canEditInputs"></mat-checkbox>
            </div>
            <div class="table-cell" fxFlex="10" fxLayout="column" fxLayoutAlign="center center" *appHasPermission="TEXEDIT_SHOW_YARN_PERCENTAGES">
              <span>{{ creelPosition.percentageOfColorsInDrawingImage | number: '1.0-2' }}</span>
            </div>
            <div class="table-cell" fxFlex="44" fxLayoutAlign="start center">
              <app-color-grid-preview [colors]="creelPosition.items" [showRgbValues]="true" class="full-width"></app-color-grid-preview>
            </div>
          </div>
        </article>
      </div>
      <app-colored-yarn-set-preview *ngIf="canShowColoredYarnSetPreview()" [yarnSet]="yarnSet" [colorSet]="colorSet" [designId]="drawingId" [qualityId]="getQualityId()"></app-colored-yarn-set-preview>
      <div fxFlex fxLayoutAlign="row" fxLayoutGap="16px">
        <div fxLayout="row" *ngIf="allowToSaveAsEp">
          <button *appHasPermission="requiredPermissionToSaveAsEp" mat-stroked-button type="button" (click)="onSaveAsEp()">
            {{ 'DESIGN_LIBRARY.DETAILS.SAVE_AS_EP.SAVE_AS_EP' | bmsTranslate }}
          </button>
        </div>
        <div *ngIf="canDownloadSmartCreelFiles" fxLayout="row" [matTooltip]="getInvalidSmartCreelDownloadIndication()" [matTooltipDisabled]="!isDownloadSmartCreelFilesDisabled()">
          <button *appHasPermission="requiredPermissionToDownloadPVDFile" mat-stroked-button type="button" (click)="downloadSmartCreelFiles()" [disabled]="isDownloadSmartCreelFilesDisabled()">
            {{ 'DESIGN_LIBRARY.SMART_CREEL.DOWNLOAD_SMART_CREEL_FILES' | bmsTranslate }}
          </button>
        </div>
      </div>
    </div>
  </section>
</mat-expansion-panel>
