import {IdName} from '@domain/id-name';
import {AssertionUtils} from '@vdw/angular-component-library';

export class MatchingCriteria {
  private _id: number;
  private _machine: IdName;
  private _machineType: IdName;
  private _standardSpeedInCycleTime: number;

  public constructor(id: number, machine: IdName, machineType: IdName, standardSpeedInCycleTime: number) {
    this._id = id;
    this._machine = machine;
    this._machineType = machineType;
    this._standardSpeedInCycleTime = standardSpeedInCycleTime;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get machine(): IdName {
    return this._machine;
  }

  public set machine(value: IdName) {
    this._machine = value;
  }

  public get machineType(): IdName {
    return this._machineType;
  }

  public set machineType(value: IdName) {
    this._machineType = value;
  }

  public get standardSpeedInCycleTime(): number {
    return this._standardSpeedInCycleTime;
  }

  public set standardSpeedInCycleTime(value: number) {
    this._standardSpeedInCycleTime = value;
  }

  public static fromJSON(matchingCriteriaJSON: any): MatchingCriteria {
    return new MatchingCriteria(
      matchingCriteriaJSON.id,
      AssertionUtils.isNullOrUndefined(matchingCriteriaJSON.machine) ? null : IdName.fromJSON(matchingCriteriaJSON.machine),
      AssertionUtils.isNullOrUndefined(matchingCriteriaJSON.machineType) ? null : IdName.fromJSON(matchingCriteriaJSON.machineType),
      matchingCriteriaJSON.standardSpeedInCycleTime
    );
  }

  public toJSON(): JSON {
    return {
      id: AssertionUtils.isNullOrUndefined(this._id) ? undefined : this._id,
      machineId: this._machine?.id,
      machineTypeId: this._machineType?.id,
      standardSpeedInCycleTime: this._standardSpeedInCycleTime
    } as any as JSON;
  }
}
