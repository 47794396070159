<section class="flex-column full-height bms-theme-content">
  <vdw-editor-header
    [identifier]="HEADER_IDENTIFIER"
    [menuItems]="menuItems"
    [selectedMenuItem]="selectedMenuItem"
    [isCreateNewPage]="!isEditingTool()"
    [canShowSettings]="false"
    [inProgress]="saving"
    [canShowSave]="!isDialog"
    (cancelEvent)="cancel()"
    (saveEvent)="save(SAVE_TYPE.SAVE)"
    (saveAndCreateNewEvent)="save(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
    (saveAndCloseEvent)="save(SAVE_TYPE.SAVE_AND_CLOSE)"
    (selectedMenuItemChange)="selectedMenuItem = $event"
    (duplicateEvent)="duplicateTool()"
    (deleteEvent)="deleteTool()"
  ></vdw-editor-header>

  <div class="bms-theme flex-column p-48 page-content-wrapper full-height">
    <form *ngIf="canShowFormBasedOnViewMode(VIEW_MODE.GENERAL)" [formGroup]="addToolGeneralForm" class="flex-column" vdwBp.gt-sm="pr-64 half-width" vdwBp.lt-sm="full-width" appBreadcrumbFormLinker>
      <vdw-string-input
        [label]="'GENERAL.NAME' | bmsTranslate"
        [placeholder]="'GENERAL.NAME_PLACEHOLDER' | bmsTranslate: {object: 'UTILITIES.TOOL.TOOL' | bmsTranslate: {count: 1} | lowercase}"
        formControlName="name"
      >
        <ng-container error>
          <vdw-invalid-form-message *vdwCanShowErrorForFormControl="'name'; error: 'required'" [message]="'GENERAL.ERRORS.REQUIRED' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
          <vdw-invalid-form-message
            *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'"
            [message]="'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate"
            [link]="getLoadLink()"
            [compact]="true"
          ></vdw-invalid-form-message>
        </ng-container>
      </vdw-string-input>
      <div class="flex-row gap-16">
        <mat-form-field class="full-width">
          <mat-label>{{ 'UTILITIES.TOOL.TOOL_TYPE.TOOL_TYPE' | bmsTranslate }}</mat-label>
          <mat-select
            disableOptionCentering
            formControlName="toolType"
            [placeholder]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'UTILITIES.TOOL.TOOL_TYPE.TOOL_TYPE' | bmsTranslate | lowercase}"
            panelClass="bms-theme"
          >
            <mat-option *ngFor="let toolType of toolTypes" [value]="toolType"> {{ 'UTILITIES.TOOL.TOOL_TYPE.' + toolType | bmsTranslate }} </mat-option>
          </mat-select>
          <mat-error *vdwCanShowErrorForFormControl="'toolType'; error: 'required'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.REQUIRED' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'UTILITIES.TOOL.WEIGHT' | bmsTranslate }}</mat-label>
          <input matInput type="number" vdwL10nDecimal [decimals]="true" min="0" formControlName="weight" placeholder="0" />
          <button
            *ngIf="addToolGeneralForm.controls?.weight.value !== null"
            class="clear-input-button"
            tabindex="-1"
            mat-icon-button
            type="button"
            (click)="addToolGeneralForm.controls?.weight.reset()"
          >
            <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
          </button>
          <span matSuffix>
            {{ 'GENERAL.UNIT.KILOGRAM' | bmsTranslate }}
          </span>
          <mat-error *vdwCanShowErrorForFormControl="'weight'; error: 'badInput'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.INVALID_FORMAT.INVALID_FORMAT' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
          <mat-error *vdwCanShowErrorForFormControl="'weight'; error: 'min'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: 0}" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-row gap-16">
        <mat-form-field class="full-width">
          <mat-label>{{ 'UTILITIES.TOOL.SPEED' | bmsTranslate }}</mat-label>
          <input matInput type="number" vdwL10nDecimal min="0" formControlName="speed" placeholder="0" />
          <button *ngIf="addToolGeneralForm.controls?.speed.value !== null" class="clear-input-button" tabindex="-1" mat-icon-button type="button" (click)="addToolGeneralForm.controls?.speed.reset()">
            <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
          </button>
          <mat-error *vdwCanShowErrorForFormControl="'speed'; error: 'min'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: 0}" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'UTILITIES.TOOL.SPEED_UNIT.SPEED_UNIT' | bmsTranslate }}</mat-label>
          <mat-select
            disableOptionCentering
            formControlName="speedUnit"
            [placeholder]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'UTILITIES.TOOL.SPEED_UNIT.SPEED_UNIT' | bmsTranslate | lowercase}"
            panelClass="bms-theme"
          >
            <mat-option *ngFor="let speedUnit of speedUnits" [value]="speedUnit"> {{ 'UTILITIES.TOOL.SPEED_UNIT.' + speedUnit | bmsTranslate }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <vdw-string-input
        [label]="'GENERAL.DESCRIPTION' | bmsTranslate"
        [placeholder]="'GENERAL.DESCRIPTION_PLACEHOLDER' | bmsTranslate: {object: 'UTILITIES.TOOL.TOOL' | bmsTranslate: {count: 1} | lowercase}"
        formControlName="description"
      >
      </vdw-string-input>
    </form>
    <app-cavities *ngIf="canShowFormBasedOnViewMode(VIEW_MODE.CAVITIES)" [form]="addToolCavitiesForm" vdwBp.gt-sm="pr-64 half-width" vdwBp.lt-sm="full-width"></app-cavities>
    <app-setup
      *ngIf="canShowFormBasedOnViewMode(VIEW_MODE.SETUP)"
      [form]="addToolSetupForm"
      [pieceCycles]="addToolCavitiesForm.get('pieceCycles').value"
      vdwBp.gt-sm="pr-64 half-width"
      vdwBp.lt-sm="full-width"
    ></app-setup>
    <app-tool-status *ngIf="canShowFormBasedOnViewMode(VIEW_MODE.STATUS)" [form]="addToolStatusForm" vdwBp.gt-sm="pr-64 half-width" vdwBp.lt-sm="full-width"></app-tool-status>
    <app-matching-criteria
      [hidden]="!canShowFormBasedOnViewMode(VIEW_MODE.MATCHING_CRITERIA)"
      [form]="addToolMatchingCriteriaForm"
      [matchingCriteria]="matchingCriteria"
      (matchingCriteriaChange)="matchingCriteria = $event"
      class="full-width full-height"
    ></app-matching-criteria>
  </div>
</section>
