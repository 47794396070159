<section fxLayout="column" fxFlex>
  <vdw-header [title]="title | bmsTranslate" svgIcon="select-blue"></vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToAddCreel()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #creelsGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfCreels" [rowData]="creels"></ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectCreels()" [centered]="true" (chooseEvent)="selectCreels()"></vdw-footer>
</section>
