<section class="flex-column full-width full-height">
  <vdw-header
    [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: pathLayoutTemplateTranslationKey | bmsTranslate: {count: 1} | lowercase}"
    svgIcon="select-blue"
    [canShowClose]="true"
  ></vdw-header>
  <vdw-tabs-menu *ngIf="isProductionScheduleDoubleWidth()" [menuItems]="menuItems" [selectedMenuItem]="selectedMenuItem" (selectedMenuItemChange)="onSelectedMenuItemChange($event)"></vdw-tabs-menu>

  <article class="dialog-content">
    <div>
      <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToAddPathLayoutTemplatePage()">
        <mat-icon svgIcon="add"></mat-icon>
      </button>
    </div>
  </article>
  <article class="dialog-content flex-column full-width full-height">
    <ag-grid-angular #selectPathLayoutTemplatesGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfPathLayoutTemplates" [rowData]="listOfPathLayoutTemplates"> </ag-grid-angular>
  </article>

  <vdw-footer [centered]="true" (chooseEvent)="confirmOverride()"></vdw-footer>
</section>
