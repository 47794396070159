import {ChangeDetectionStrategy, Component, effect, EventEmitter, input, InputSignal, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {NameGenerator} from '@domain/custom-settings/name-generator';
import {NameGeneratorPart} from '@domain/custom-settings/name-generator-part';
import {NameGeneratorSeparator} from '@domain/name-generator/enums/name-generator-separator.enum';
import {AssertionUtils, BaseComponent, DialogBuilderFactoryService, EnumUtils, TranslateService} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {NameGenerationPartFormType} from './name-generation-form-type';
import {getPreviewText} from './name-generation-pattern-preview';
import {SelectNameGenerationPartsData} from './select-name-generation-parts/select-name-generation-parts-data';
import {SelectNameGenerationPartsComponent} from './select-name-generation-parts/select-name-generation-parts.component';

@Component({
  selector: 'app-name-generation-pattern-new',
  templateUrl: './name-generation-pattern-new.component.html',
  styleUrl: './name-generation-pattern-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NameGenerationPatternNewComponent extends BaseComponent implements OnInit {
  public nameGenerator: InputSignal<NameGenerator> = input.required();
  public nameGenerationProperties: InputSignal<string[]> = input.required();
  @Output() public nameGeneratorChange: EventEmitter<NameGenerator> = new EventEmitter();

  protected patternPreview = new FormControl<string>(null);
  protected nameGeneratorForm: NameGenerationPartFormType = new FormGroup({
    parts: new FormControl<NameGeneratorPart[]>([]),
    separator: new FormControl<string>(NameGeneratorSeparator[NameGeneratorSeparator.NO_SEPARATOR])
  });

  // Effects should be used until angular makes a signals implementation for reactive forms (https://github.com/angular/angular/issues/53485)
  private patternPreviewEffect = effect(() => {
    if (!AssertionUtils.isNullOrUndefined(this.nameGenerator())) {
      this.nameGeneratorForm.patchValue({
        parts: this.nameGenerator().parts,
        separator: this.nameGenerator().separatorName
      });
    }
  });

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.nameGeneratorForm.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((_: any) => {
      this.updatePatternPreview();
      const nameGenerator = new NameGenerator(undefined, this.nameGeneratorForm.value.separator, undefined, this.nameGeneratorForm.value.parts);
      this.nameGeneratorChange.emit(nameGenerator);
    });
  }

  private updatePatternPreview(): void {
    const pattern = getPreviewText(this.nameGeneratorForm.value.parts, this.nameGeneratorForm.value.separator, this.translate);
    this.patternPreview.patchValue(pattern);
  }

  protected getNameGeneratorSeparators(): string[] {
    return EnumUtils.getEnumNames(NameGeneratorSeparator);
  }

  protected clearNameGeneratorParts(): void {
    this.nameGeneratorForm.patchValue({parts: []});
  }

  protected selectNameGenerationPattern(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('change-placeholder-parts')
      .withWidth('1200px')
      .withHeight('90vh')
      .withMaxHeight('688px')
      .openDialog<SelectNameGenerationPartsComponent, SelectNameGenerationPartsData, NameGeneratorPart[]>(SelectNameGenerationPartsComponent, {
        nameGenerationProperties: this.nameGenerationProperties(),
        namePlaceholderSeparator: this.nameGeneratorForm.value.separator,
        nameGeneratorParts: this.nameGeneratorForm.value.parts
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((nameGeneratorParts: NameGeneratorPart[]) => {
        if (!AssertionUtils.isNullOrUndefined(nameGeneratorParts)) {
          this.nameGeneratorForm.patchValue({
            parts: nameGeneratorParts
          });
        }
      });
  }
}
