import {convertDentsToWidthInMeter} from './convert-dents-to-width-in-meter';
import {convertPixelsToDents} from './convert-pixels-to-dents';

export function convertPixelsToWidthInMeter(
  pixels: number,
  reedDensityInPicksPerMeter: number,
  threadByThread: number,
  nrColumnsForWeftSelection: number,
  nrCloths: number,
  nrGroundYarns: number
): number {
  return convertDentsToWidthInMeter(convertPixelsToDents(pixels, threadByThread, nrColumnsForWeftSelection, nrCloths, nrGroundYarns), reedDensityInPicksPerMeter);
}
