import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {BuggyForProductionSchedule} from '@domain/production-schedule/buggy-for-production-schedule';
import {HttpBuggyService} from '@infrastructure/http/buggy/http-buggy.service';
import {BuggyOverviewService} from '@presentation/pages/textile-data/buggy/overview/buggy-overview.service';
import {
  AssertionUtils,
  BaseComponent,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {GridApi, GridOptions, IRowNode, RowDataUpdatedEvent} from 'ag-grid-community';
import {isEqual, isNil, isUndefined} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-buggy.component.html',
  styleUrls: ['./select-buggy.component.scss']
})
export class SelectBuggyComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('buggiesGrid') public buggiesGrid: AgGridAngular;
  public listOfBuggies: BuggyForProductionSchedule[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  private readonly getBuggy: BuggyForProductionSchedule;
  private readonly machineId: number;
  private readonly machineQualityId: number;
  private readonly creelId: number;
  private readonly isPropertyValueForBuggyNameEmpty: boolean;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly dialogRef: MatDialogRef<SelectBuggyComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly buggies: HttpBuggyService,
    private readonly buggyOverviewService: BuggyOverviewService,
    private readonly navigationHelperService: NavigationHelperService<DialogComponentData<typeof SelectBuggyComponent>>
  ) {
    super();

    this.dialogRef = dialogRef;
    this.gridOptionsBuilderFactoryService = gridOptionsBuilderFactoryService;
    this.getBuggy = data.getBuggy;
    this.machineId = data.machineId;
    this.machineQualityId = data.machineQualityId;
    this.creelId = data.creelId;
    this.isPropertyValueForBuggyNameEmpty = data.isPropertyValueForBuggyNameEmpty;
  }

  public get gridOptionsForListOfBuggies(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.getBuggies();
    this.initialiseGridOptions();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.buggiesGrid?.api];
  }

  public filterBuggies(event: string): void {
    this.buggiesGrid.api.setGridOption('quickFilterText', event);
  }

  public canSelectBuggy(): boolean {
    let result = false;
    if (!AssertionUtils.isNullOrUndefined(this.buggiesGrid?.api)) {
      result = this.buggiesGrid.api.getSelectedRows().length > 0;
    }
    return result;
  }

  public cancel(): void {
    this.dialogRef.close();

    if (!isEqual(this.getBuggy, this.getSelectedBuggy()) || !isNil(this.getSelectedBuggy())) {
      this.dialogRef.close(this.getBuggy);
    }
  }

  public selectBuggy(): void {
    this.dialogRef.close(this.getSelectedBuggy());
  }

  public addButtonEnabled(): boolean {
    return !isNil(this.machineId) && !isNil(this.machineQualityId) && !isNil(this.creelId) && !this.isPropertyValueForBuggyNameEmpty;
  }

  public addBuggy(): void {
    const data = {
      dialogComponent: SelectBuggyComponent
    };
    this.navigationHelperService.navigateToNextRouteWithPartialState(data, RouteUtils.paths.texStyle.buggy.addBuggy.absolutePath);
  }

  private getBuggies(): void {
    this.buggies
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((buggies: BuggyForProductionSchedule[]) => {
        this.listOfBuggies = buggies;
      });
  }

  private initialiseGridOptions(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.buggyOverviewService.getColumnDefs(), GridIdentifier.BUGGY)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'PLANNING.BUGGY.BUGGY',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => this.setBuggy(api))
        .withoutColumnMenu()
        .build()
    ];
  }

  private setBuggy(gridApi: GridApi): void {
    if (!isUndefined(this.getBuggy) && !isNil(this.getBuggy)) {
      const rowNode: IRowNode<BuggyForProductionSchedule> = gridApi.getRowNode(`${this.getBuggy.id}`);

      if (!isNil(rowNode)) {
        rowNode.setSelected(true);
      }
    }
  }

  private getSelectedBuggy(): any {
    return this.buggiesGrid.api.getSelectedRows()[0];
  }
}
