import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {FixedShape} from '@domain/drawing-library/fixed-shape.enum';
import {FinishingCutbarsInformation} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing-cutbars-information';
import {Colors, COLORS} from '@infrastructure/http/color/colors';
import {Drawings, DRAWINGS} from '@infrastructure/http/drawing/drawings';
import {FINISHINGS, Finishings} from '@infrastructure/http/finishing/finishings';
import {GridColorPreviewComponent} from '@presentation/pages/textile-data/color/overview/grid-color-preview/grid-color-preview.component';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {NavigationJuteConditionData} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-jute/jute-conditions-list-view/navigation-jute-condition-data.interface';
import {RuleType} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-jute/jute-conditions-list-view/rule-type.enum';
import {
  AgGridUtils,
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  Color,
  DialogComponentData,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog,
  StringUtils,
  TranslateService
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, FirstDataRenderedEvent, GridApi, GridOptions, RowNode, ValueGetterParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {NavigationRulesData} from '../../pages/textile-data/machine-quality/add/machine-quality-jute/jute-conditions-list-view/navigation-rules-data.interface';
import {FinishingCutbarAfterLengthComponent} from '../select-finishing/finishing-cutbar-after-length/finishing-cutbar-after-length.component';
import {FinishingCutbarBeforeLengthComponent} from '../select-finishing/finishing-cutbar-before-length/finishing-cutbar-before-length.component';
import {FinishingLabelsIndicationComponent} from '../select-finishing/finishing-labels-indication/finishing-labels-indication.component';
import {FinishingTypeComponent} from '../select-finishing/finishing-type/finishing-type.component';

@Component({
  templateUrl: './select-rule-entity.component.html',
  styleUrls: ['./select-rule-entity.component.scss']
})
export class SelectRuleEntityComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('ruleEntitiesGrid') public ruleEntitiesGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public listOfObjects: OverviewListFinishing[] | Color[] | {name: string}[];
  private rulesFormArray: UntypedFormArray;
  private ruleIndex: number;
  private ruleType: RuleType;
  private selectedEntities: OverviewListFinishing[] | Color[] | {name: string}[];
  private fixedFinishings?: OverviewListFinishing[] = null;
  private juteIndex: number;

  public constructor(
    @Inject(FINISHINGS) private readonly finishings: Finishings,
    @Inject(COLORS) private readonly colors: Colors,
    @Inject(DRAWINGS) private readonly drawings: Drawings,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SelectRuleEntityComponent>,
    private readonly translate: TranslateService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly navigationHelperService: NavigationHelperService<NavigationRulesData & NavigationJuteConditionData & DialogComponentData<typeof SelectRuleEntityComponent>>
  ) {
    super();

    this.rulesFormArray = data.rulesFormArray;
    this.ruleIndex = data.ruleIndex;
    this.ruleType = this.rulesFormArray.controls[this.ruleIndex].get('type').value;
    this.selectedEntities = this.rulesFormArray.controls[this.ruleIndex].get('entities').value;
    this.fixedFinishings = data?.fixedFinishings;
    this.juteIndex = data.juteIndex;
  }

  public get gridOptions(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    if (this.ruleType === RuleType.FINISHINGS) {
      if (this.fixedFinishings != null) {
        this.listOfObjects = this.fixedFinishings;
      } else {
        this.finishings
          .getAll()
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe((listOfFinishings: OverviewListFinishing[]) => {
            this.listOfObjects = listOfFinishings;
          });
      }
    } else if (this.ruleType === RuleType.BORDER_COLORS) {
      this.colors
        .getAll()
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((colors: Color[]) => (this.listOfObjects = colors));
    } else {
      this.drawings
        .getDrawingShapes()
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((drawingShapes: string[]) => {
          if (!isNil(drawingShapes)) {
            this.listOfObjects = drawingShapes.map((shape: string) => {
              return {name: FixedShape.getTranslationKeyForShape(shape, this.translate)};
            });
          }
        });
    }

    this.setGridOptions();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.ruleEntitiesGrid?.api];
  }

  public filter(event: string): void {
    this.ruleEntitiesGrid.api.setGridOption('quickFilterText', event);
  }

  public canSelectEntity(): boolean {
    return !AssertionUtils.isEmpty(this.getSelectedEntities());
  }

  public selectEntities(): void {
    this.dialogRef.close(this.getSelectedEntities());
  }

  public getEntityTitle(): string {
    let title = this.translate.instant('TEXTILE_DATA.MACHINE_QUALITY.JUTE_CONDITIONS.RULE_TYPES.FINISHINGS');

    if (this.ruleType === RuleType.BORDER_COLORS) {
      title = this.translate.instant('TEXTILE_DATA.MACHINE_QUALITY.JUTE_CONDITIONS.COLORS');
    } else if (this.ruleType === RuleType.SHAPES) {
      title = this.translate.instant('TEXTILE_DATA.MACHINE_QUALITY.JUTE_CONDITIONS.RULE_TYPES.SHAPES');
    }

    return title;
  }

  public canShowCreateNewButton(): boolean {
    return this.ruleType === RuleType.SHAPES || this.ruleType === RuleType.BORDER_COLORS;
  }

  public navigateToAddRuleEntity(): void {
    this.navigationHelperService.navigateToNextRouteWithPartialState<NavigationRulesData & NavigationJuteConditionData & DialogComponentData<typeof SelectRuleEntityComponent>>(
      {rulesFormArray: this.rulesFormArray, ruleIndex: this.ruleIndex, juteIndex: this.juteIndex, dialogComponent: SelectRuleEntityComponent},
      this.ruleType === RuleType.SHAPES ? RouteUtils.paths.texEdit.designLibrarySettings.absolutePath : RouteUtils.paths.texStyle.color.addColor.absolutePath
    );
  }

  private setGridOptions(): void {
    const gridBuilder = this.gridOptionsBuilderFactoryService
      .getBuilder(this.getColumnDefs(), this.ruleType === RuleType.FINISHINGS ? GridIdentifier.SELECT_FINISHINGS : GridIdentifier.SELECT_RULE_ENTITY)
      .withoutColumnMenu()
      .withoutGetRowId()
      .withRowSelection(true, true, true)
      .withOnFirstDataRendered((event: FirstDataRenderedEvent) => this.selectSelectedNodes(event))
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: 'TEXTILE_DATA.FINISHING.FINISHING',
        hideDescription: true,
        isAnyFilterPresent: () => this.ruleEntitiesGrid?.api?.isAnyFilterPresent()
      } as NoDataOverlayComponentParams)
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams);

    if (this.ruleType === RuleType.FINISHINGS) {
      gridBuilder.withColumnMenu();
    }

    this.listOfGridOptions = [gridBuilder.build()];
  }

  private getColumnDefs(): ColDef[] {
    let colDefs = this.getColumnDefsForListOfFinishings();

    if (this.ruleType === RuleType.BORDER_COLORS) {
      colDefs = this.getColumnDefsForListOfColors();
    } else if (this.ruleType === RuleType.SHAPES) {
      colDefs = this.getColumnDefsForListOfShapes();
    }

    return colDefs;
  }

  private getColumnDefsForListOfShapes(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withField('name', true)
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.JUTE_CONDITIONS.RULE_TYPES.SHAPES')
        .withComparator(StringUtils.stringComparator)
        .withCheckboxSelection()
        .withoutFilter()
        .build()
    ];
  }

  private getColumnDefsForListOfColors(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.JUTE_CONDITIONS.COLOR_RGB')
        .withCellRenderer(GridColorPreviewComponent, {
          showRGBValue: true,
          hasMultipleSelection: true,
          isRuleCondition: true
        })
        .withValueGetter('data')
        .withTooltipField('name')
        .withTextMultiFilter(this.colors.getPossibleValues('name').pipe(takeUntil(this.unSubscribeOnViewDestroy)))
        .withFilterValueGetter((params: ValueGetterParams) => params.data.name)
        .build()
    ];
  }

  private getColumnDefsForListOfFinishings(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('nameWithVersion', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.TYPE')
        .withCellRenderer(FinishingTypeComponent)
        .withComparator(StringUtils.stringComparator)
        .withFilterValueGetter((params: ValueGetterParams) => this.translate.instant('TEXTILE_DATA.FINISHING_TEMPLATE.FINISHINGTYPE_OPTIONS.' + params.data.finishingType))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.LABEL.LABEL')
        .withCellRenderer(FinishingLabelsIndicationComponent)
        .withCellClass('finishing-label-cell')
        .withoutFilter()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_BEFORE')
        .withCellRenderer(FinishingCutbarBeforeLengthComponent)
        .withComparator(StringUtils.stringComparator)
        .withFilterValueGetter((params: ValueGetterParams) => this.getFilterText(params.data.cutbarsInformation))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_AFTER')
        .withCellRenderer(FinishingCutbarAfterLengthComponent)
        .withComparator(StringUtils.stringComparator)
        .withFilterValueGetter((params: ValueGetterParams) => this.getFilterText(params.data.cutbarsInformation, false))
        .build()
    ];
  }

  private getSelectedEntities(): OverviewListFinishing[] | Color[] | {name: string}[] {
    return this.ruleEntitiesGrid?.api?.getSelectedRows();
  }

  private selectSelectedNodes(event: FirstDataRenderedEvent): void {
    AgGridUtils.onRowDataUpdated(event.api);

    event.api.getRenderedNodes().forEach((node: RowNode) => {
      if (
        this.selectedEntities?.some((entity: OverviewListFinishing | Color | {name: string}) => {
          let isSelected: boolean;

          if (this.ruleType === RuleType.BORDER_COLORS || this.ruleType === RuleType.FINISHINGS) {
            isSelected = (entity as OverviewListFinishing | Color).id === node.data.id;
          } else {
            isSelected = (entity as {name: string}).name === node.data.name;
          }

          return isSelected;
        })
      ) {
        node.setSelected(true);
      }
    });
  }

  private getFilterText(cutbarsInformation: FinishingCutbarsInformation, isBefore: boolean = true): string {
    if (cutbarsInformation && (isBefore ? cutbarsInformation.hasAddedCutbarBefore() : cutbarsInformation.hasAddedCutbarAfter())) {
      const lengthOfCutbarInMillimeters = isBefore ? cutbarsInformation.getLengthOfCutbarBeforeInMillimeters() : cutbarsInformation.getLengthOfCutbarAfterInMillimeters();
      const lengthOfCutbarInNumberOfPicks = isBefore ? cutbarsInformation.getLengthOfCutbarBeforeInNumberOfPicks() : cutbarsInformation.getLengthOfCutbarAfterInNumberOfPicks();
      return `${lengthOfCutbarInMillimeters} ${this.translate.instant('GENERAL.UNIT.MILLIMETER')} / ${lengthOfCutbarInNumberOfPicks} ${this.translate.instant('GENERAL.UNIT.PICKS')}`;
    }
  }
}
