import {AutomaticStopInput} from './automatic-stop-input';

export class AutomaticStopMapping {
  private _inputMaskFilter: string;
  private _defaultStopTextId: number;
  private _stopInputs: AutomaticStopInput[];

  public constructor(inputMaskFilter: string, defaultStopTextId: number, stopInputs: AutomaticStopInput[]) {
    this._inputMaskFilter = inputMaskFilter;
    this._defaultStopTextId = defaultStopTextId;
    this._stopInputs = stopInputs;
  }

  public get inputMaskFilter(): string {
    return this._inputMaskFilter;
  }

  public set inputMaskFilter(inputMaskFilter: string) {
    this._inputMaskFilter = inputMaskFilter;
  }

  public get defaultStopTextId(): number {
    return this._defaultStopTextId;
  }

  public set defaultStopTextId(defaultStopTextId: number) {
    this._defaultStopTextId = defaultStopTextId;
  }

  public get stopInputs(): AutomaticStopInput[] {
    return this._stopInputs;
  }

  public set stopInputs(stopInputs: AutomaticStopInput[]) {
    this._stopInputs = stopInputs;
  }

  public static fromJSON(automaticStopMappingJSON: any): AutomaticStopMapping {
    return new AutomaticStopMapping(
      automaticStopMappingJSON.inputMaskFilter,
      automaticStopMappingJSON.defaultStopTextId,
      automaticStopMappingJSON.stopInputs.map((stopInput: AutomaticStopInput) => AutomaticStopInput.fromJSON(stopInput))
    );
  }

  public toJSON(): JSON {
    return {
      inputMaskFilter: this._inputMaskFilter,
      defaultStopTextId: this._defaultStopTextId,
      stopInputs: this._stopInputs.map((stopInput: AutomaticStopInput) => stopInput.toJSON())
    } as any as JSON;
  }
}
