import {AutomaticStopMapping} from './automatic-stop-mapping';
import {CountConfiguration} from './count-configuration';
import {DigitalInputConfig} from './digital-input-config';

export class DataUnitSetup {
  private _id: number;
  private _name: string;
  private _countConfiguration: CountConfiguration;
  private _automaticStopMapping: AutomaticStopMapping;
  private _digitalInputConfig: DigitalInputConfig;

  public constructor(id: number, name: string, countConfiguration: CountConfiguration, automaticStopMapping: AutomaticStopMapping, digitalInputConfig: DigitalInputConfig) {
    this._id = id;
    this._name = name;
    this._countConfiguration = countConfiguration;
    this._automaticStopMapping = automaticStopMapping;
    this._digitalInputConfig = digitalInputConfig;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get countConfiguration(): CountConfiguration {
    return this._countConfiguration;
  }

  public set countConfiguration(countConfiguration: CountConfiguration) {
    this._countConfiguration = countConfiguration;
  }

  public get automaticStopMapping(): AutomaticStopMapping {
    return this._automaticStopMapping;
  }

  public set automaticStopMapping(automaticStopMapping: AutomaticStopMapping) {
    this._automaticStopMapping = automaticStopMapping;
  }

  public get digitalInputConfig(): DigitalInputConfig {
    return this._digitalInputConfig;
  }

  public set digitalInputConfig(digitalInputConfig: DigitalInputConfig) {
    this._digitalInputConfig = digitalInputConfig;
  }

  public static fromJSON(dataUnitSetupJSON: any): DataUnitSetup {
    return new DataUnitSetup(
      dataUnitSetupJSON.id,
      dataUnitSetupJSON.name,
      CountConfiguration.fromJSON(dataUnitSetupJSON.countConfiguration),
      AutomaticStopMapping.fromJSON(dataUnitSetupJSON.automaticStopMapping),
      DigitalInputConfig.fromJSON(dataUnitSetupJSON.digitalInputConfig)
    );
  }

  public toJSON(): JSON {
    return {
      name: this._name,
      countConfiguration: this._countConfiguration.toJSON(),
      automaticStopMapping: this._automaticStopMapping.toJSON(),
      digitalInputConfig: this._digitalInputConfig.toJSON()
    } as any as JSON;
  }
}
