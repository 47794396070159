import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {CrudActions} from '@application/helper/grid-action-buttons-helper/crud-actions.interface';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Tool} from '@domain/utilities/tool/tool';
import {HttpToolsService} from '@infrastructure/http/tool/http-tools.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {BaseComponent, CrudOverviewDataBuilderFactoryService, CrudOverviewDataConfig, GridOptionsBuilder} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, IRowNode} from 'ag-grid-community';
import {ToolOverviewService} from './tool-overview.service';

@Component({
  templateUrl: './tool-overview-page.component.html'
})
export class ToolOverviewPageComponent extends BaseComponent implements OnInit {
  public config: CrudOverviewDataConfig<Tool>;

  private readonly GRID_IDENTIFIER = GridIdentifier.TOOL_OVERVIEW;
  private readonly pageUrls = LastModifiedCardUtils.getPageUrls('tool');

  public constructor(
    private readonly tools: HttpToolsService,
    private readonly router: Router,
    private readonly textileService: TextileService,
    private readonly toolOverviewService: ToolOverviewService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<Tool>,
    private readonly gridActionButtonsHelper: GridActionButtonsHelper
  ) {
    super();
  }

  public ngOnInit(): void {
    const colDefs = this.toolOverviewService.getColumnDefs();
    this.getTools(colDefs);
  }

  private getTools(colDefs: ColDef[]): void {
    this.config = this.builderFactoryService
      .getBuilder()
      .withDefaultButtons(
        this.gridActionButtonsHelper.getActionButtonsForGridSelection({
          requiredPermissionToEdit: RouteUtils.paths.utilities.tool.editTool.requiredPermission,
          withEdit: (grid: AgGridAngular | IRowNode) => this.navigateToGivenRoute(grid, 'edit'),
          withDuplicate: (grid: AgGridAngular | IRowNode) => this.navigateToGivenRoute(grid, 'duplicate'),
          withDelete: (grid: AgGridAngular | IRowNode) => this.delete(grid),
          allowDeleteMultiple: false
        } as CrudActions)
      )
      .withGridOptions(colDefs, this.GRID_IDENTIFIER, null, (builder: GridOptionsBuilder) =>
        this.toolOverviewService.getDefaultGridOptionsBuilder(builder).withRowSelection(false, false, true).build()
      )
      .withRowData(this.tools.getAll())
      .withNewClicked(() => this.router.navigateByUrl(this.pageUrls.add))
      .build();
  }

  private navigateToGivenRoute(grid: AgGridAngular | IRowNode, action: string): void {
    this.router.navigateByUrl(this.pageUrls[action].replace(':id', grid instanceof AgGridAngular ? grid?.api?.getSelectedRows()[0].id : grid.id));
  }

  private delete(grid: AgGridAngular | IRowNode): void {
    this.textileService.removeConfirmation(grid instanceof AgGridAngular ? grid?.api?.getSelectedRows()[0] : grid.data, TextileDataType.TOOL, false, null, this.tools);
  }
}
