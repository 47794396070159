import {RouteLeaf} from './route-leaf';
import {RouteUtils} from './route-utils';

export class LinkedRouteUtils {
  private static COLORED_YARN_SET_LINKED_ROUTES = [
    RouteUtils.paths.texStyle.colorSet.addColorSet,
    RouteUtils.paths.texStyle.colorSet.editColorSet,
    RouteUtils.paths.texStyle.yarnSet.addYarnSet,
    RouteUtils.paths.texStyle.yarnSet.editYarnSet,
    RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet
  ];

  private static MACHINE_QUALITY_LINKED_ROUTES = [
    RouteUtils.paths.texStyle.weaveStructure.editWeaveStructure,
    RouteUtils.paths.texState.editMachine,
    RouteUtils.paths.texStyle.yarnSet.addYarnSet,
    RouteUtils.paths.texStyle.yarnSet.editYarnSet,
    RouteUtils.paths.texStyle.yarn.addYarn,
    RouteUtils.paths.texStyle.yarn.editYarn,
    RouteUtils.paths.texStyle.machineQuality.editMachineQuality,
    RouteUtils.paths.texEdit.designLibrarySettings,
    RouteUtils.paths.texStyle.color.addColor,
    RouteUtils.paths.texStyle.machineQuality.addFinishing,
    RouteUtils.paths.texStyle.machineQuality.editFinishing,
    RouteUtils.paths.texStyle.machineQuality.duplicateFinishing
  ];

  private static TUFT_PRODUCT_LINKED_ROUTES = [RouteUtils.paths.texStyle.weaveProduct.addWeaveProduct, RouteUtils.paths.texStyle.weaveProduct.editWeaveProduct];

  private static WEAVING_PRODUCTION_ORDER_LINKED_ROUTES = [
    RouteUtils.paths.texState.editMachine,
    RouteUtils.paths.texStyle.weaveProduct.addWeaveProduct,
    RouteUtils.paths.texStyle.weaveProduct.editWeaveProduct,
    RouteUtils.paths.texFab.weaving.settings
  ];

  private static TUFTING_PRODUCTION_ORDER_LINKED_ROUTES = [
    RouteUtils.paths.texState.editMachine,
    RouteUtils.paths.texStyle.tuftProduct.addTuftProduct,
    RouteUtils.paths.texStyle.tuftProduct.editTuftProduct,
    RouteUtils.paths.texFab.tufting.settings
  ];

  private static DESIGN_LIBRARY_LINKED_ROUTES = [
    RouteUtils.paths.texStyle.machineQuality.addMachineQuality,
    RouteUtils.paths.texStyle.machineQuality.editMachineQuality,
    RouteUtils.paths.texStyle.colorSet.addColorSet,
    RouteUtils.paths.texStyle.colorSet.editColorSet,
    RouteUtils.paths.texStyle.yarnSet.addYarnSet,
    RouteUtils.paths.texStyle.yarnSet.editYarnSet
  ];

  private static WEFT_COLORED_YARN_SET_LINKED_ROUTES = [
    RouteUtils.paths.texStyle.colorSet.addColorSet,
    RouteUtils.paths.texStyle.colorSet.editColorSet,
    RouteUtils.paths.texStyle.yarnSet.addYarnSet,
    RouteUtils.paths.texStyle.yarnSet.editYarnSet
  ];

  private static PRODUCT_CONFIGURATION_LINKED_ROUTES = [
    RouteUtils.paths.texEdit.productConfigurationSettings,
    RouteUtils.paths.texStyle.coloredYarnSet.addColoredYarnSet,
    RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet,
    RouteUtils.paths.texStyle.weftColoredYarnSet.addWeftColoredYarnSet,
    RouteUtils.paths.texStyle.weftColoredYarnSet.editWeftColoredYarnSet,
    RouteUtils.paths.texStyle.machineQuality.addMachineQuality,
    RouteUtils.paths.texStyle.machineQuality.editMachineQuality,
    RouteUtils.paths.texState.editMachine,
    RouteUtils.paths.texStyle.weaveStructure.editWeaveStructure,
    RouteUtils.paths.texStyle.weftColoredYarnSet.editWeftColoredYarnSet
  ];

  private static YARN_SET_LINKED_ROUTES = [RouteUtils.paths.texStyle.yarnSet.yarnSetSettings, RouteUtils.paths.texStyle.yarn.addYarn];

  private static CREEL_LINKED_ROUTES = [
    RouteUtils.paths.texStyle.coloredYarnSet.addColoredYarnSet,
    RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet,
    RouteUtils.paths.texStyle.yarnSet.addYarnSet,
    RouteUtils.paths.texStyle.yarnSet.editYarnSet,
    RouteUtils.paths.texStyle.colorSet.addColorSet,
    RouteUtils.paths.texStyle.colorSet.editColorSet,
    RouteUtils.paths.texState.editMachine,
    RouteUtils.paths.texStyle.creel.creelSettings
  ];

  private static ARTICLE_LINKED_ROUTES = [RouteUtils.paths.texStyle.coloredYarnSet.addColoredYarnSet, RouteUtils.paths.texEdit.addDesign, RouteUtils.paths.texStyle.machineQuality.addMachineQuality];

  private static SALES_ORDER_LINKED_ROUTES = [RouteUtils.paths.texFab.customer.addCustomer, RouteUtils.paths.texFab.article.addArticle];

  private static LINKED_PRODUCTION_ORDER_ROUTES = [
    RouteUtils.paths.texStyle.machineQuality.addMachineQuality,
    RouteUtils.paths.texStyle.machineQuality.editMachineQuality,
    RouteUtils.paths.texEdit.addProductConfiguration,
    RouteUtils.paths.texEdit.editProductConfiguration,
    RouteUtils.paths.texStyle.colorSet.editColorSet,
    RouteUtils.paths.texStyle.color.editColor,
    RouteUtils.paths.texFab.productionOrder.settings,
    RouteUtils.paths.texState.editMachine,
    RouteUtils.paths.texStyle.creel.addCreel,
    RouteUtils.paths.texStyle.creel.editCreel,
    RouteUtils.paths.texFab.pathLayoutTemplate.addPathLayoutTemplate,
    RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView,
    RouteUtils.paths.texStyle.buggy.addBuggy,
    RouteUtils.paths.texStyle.buggy.editBuggy
  ];

  private static LINKED_MACHINE_DATA_SOURCE_ROUTES = [
    RouteUtils.paths.settings.deviceTemplate.editDeviceTemplate,
    RouteUtils.paths.settings.resourceType.editResourceType,
    RouteUtils.paths.settings.dataUnitSetup.addDataUnitSetup,
    RouteUtils.paths.settings.machineDataSources.editMachineDataSource,
    RouteUtils.paths.settings.machineDataSources.settings
  ];

  private static LINKED_DEVICE_TEMPLATE = [
    RouteUtils.paths.settings.resourceType.addResourceType,
    RouteUtils.paths.settings.resourceType.editResourceType,
    RouteUtils.paths.settings.deviceTemplate.addCounter
  ];

  private static LINKED_DEVICE_TEMPLATE_COUNTER = [RouteUtils.paths.settings.reportItem.editReportItem, RouteUtils.paths.settings.physicalQuantity.editPhysicalQuantity];

  public static paths = new Map<RouteLeaf, RouteLeaf[]>([
    [
      RouteUtils.paths.texStyle.coloredYarnSet,
      [RouteUtils.paths.texStyle.coloredYarnSet.addColoredYarnSet, RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet, RouteUtils.paths.texStyle.coloredYarnSet.duplicateColoredYarnSet]
    ],
    [RouteUtils.paths.texStyle.coloredYarnSet.addColoredYarnSet, this.COLORED_YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet, this.COLORED_YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.coloredYarnSet.duplicateColoredYarnSet, this.COLORED_YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.weftColoredYarnSet.addWeftColoredYarnSet, this.WEFT_COLORED_YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.weftColoredYarnSet.editWeftColoredYarnSet, this.WEFT_COLORED_YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.weftColoredYarnSet.duplicateWeftColoredYarnSet, this.WEFT_COLORED_YARN_SET_LINKED_ROUTES],
    [
      RouteUtils.paths.texFab.planning,
      [
        RouteUtils.paths.texStyle.creel.addCreel,
        RouteUtils.paths.texStyle.creel.editCreel,
        RouteUtils.paths.texStyle.machineQuality.addMachineQuality,
        RouteUtils.paths.texStyle.machineQuality.editMachineQuality,
        RouteUtils.paths.texStyle.weaveProduct.addWeaveProduct,
        RouteUtils.paths.texStyle.weaveProduct.editWeaveProduct,
        RouteUtils.paths.texStyle.tuftProduct.addTuftProduct,
        RouteUtils.paths.texStyle.tuftProduct.editTuftProduct,
        RouteUtils.paths.texFab.productionOrder.addProductionOrderForRun,
        RouteUtils.paths.texFab.productionOrder.addProductionOrderForMachine,
        RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForRun,
        RouteUtils.paths.texFab.productionOrder.editProductionOrder,
        RouteUtils.paths.texFab.weaving.addProductionOrderForRun,
        RouteUtils.paths.texFab.weaving.addProductionOrderForMachine,
        RouteUtils.paths.texFab.weaving.editProductionOrder,
        RouteUtils.paths.texFab.tufting.addProductionOrderForRun,
        RouteUtils.paths.texFab.tufting.addProductionOrderForMachine,
        RouteUtils.paths.texFab.tufting.editProductionOrder
      ]
    ],
    [RouteUtils.paths.texFab.productionOrder.editProductionOrder, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrder, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderForMachine, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForMachine, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderForProductConfiguration, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForProductConfiguration, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderForRun, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForRun, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texFab.weaving.addProductionOrder, this.WEAVING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.weaving.editProductionOrder, this.WEAVING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.weaving.duplicateProductionOrder, this.WEAVING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.weaving.addProductionOrderForMachine, this.WEAVING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.weaving.addProductionOrderForRun, this.WEAVING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.tufting.addProductionOrder, this.TUFTING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.tufting.editProductionOrder, this.TUFTING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.tufting.duplicateProductionOrder, this.TUFTING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.tufting.addProductionOrderForMachine, this.TUFTING_PRODUCTION_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.tufting.addProductionOrderForRun, this.TUFTING_PRODUCTION_ORDER_LINKED_ROUTES],
    [
      RouteUtils.paths.texState,
      [
        RouteUtils.paths.texFab.productionOrder.addProductionOrderForMachine,
        RouteUtils.paths.texFab.productionOrder.addProductionOrderNewViewForMachine,
        RouteUtils.paths.texFab.weaving.addProductionOrderForMachine,
        RouteUtils.paths.texFab.tufting.addProductionOrderForMachine
      ]
    ],
    [
      RouteUtils.paths.texState.editMachine,
      [
        RouteUtils.paths.texStyle.machineQuality.addMachineQuality,
        RouteUtils.paths.texStyle.machineQuality.editMachineQuality,
        RouteUtils.paths.texStyle.creel.addCreel,
        RouteUtils.paths.texStyle.creel.editCreel,
        RouteUtils.paths.texState.settings
      ]
    ],
    [RouteUtils.paths.texState.settings, [RouteUtils.paths.texState.addTemplate]],
    [RouteUtils.paths.texStyle.machineQuality.addMachineQuality, this.MACHINE_QUALITY_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.machineQuality.editMachineQuality, this.MACHINE_QUALITY_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.machineQuality.duplicateMachineQuality, this.MACHINE_QUALITY_LINKED_ROUTES],
    [RouteUtils.paths.texFab.productionOrder.addProductionOrderForRun, this.LINKED_PRODUCTION_ORDER_ROUTES],
    [RouteUtils.paths.texStyle.creel.editCreel, this.CREEL_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.creel.addCreel, this.CREEL_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.creel.duplicateCreel, this.CREEL_LINKED_ROUTES],
    [RouteUtils.paths.texEdit.addProductConfiguration, this.PRODUCT_CONFIGURATION_LINKED_ROUTES],
    [RouteUtils.paths.texEdit.editProductConfiguration, [RouteUtils.paths.texFab.productionOrder.addProductionOrderForProductConfiguration, ...this.PRODUCT_CONFIGURATION_LINKED_ROUTES]],
    [RouteUtils.paths.texEdit.designLibrary, this.DESIGN_LIBRARY_LINKED_ROUTES],
    [RouteUtils.paths.texEdit.addDesign, this.DESIGN_LIBRARY_LINKED_ROUTES],
    [RouteUtils.paths.settings.operator.addOperator, [RouteUtils.paths.settings.operator.addOperatorType, RouteUtils.paths.settings.operator.editOperatorType]],
    [RouteUtils.paths.settings.operator.duplicateOperator, [RouteUtils.paths.settings.operator.addOperatorType, RouteUtils.paths.settings.operator.editOperatorType]],
    [RouteUtils.paths.settings.operator.editOperator, [RouteUtils.paths.settings.operator.addOperatorType, RouteUtils.paths.settings.operator.editOperatorType]],
    [RouteUtils.paths.settings.reportItem.editReportItem, [RouteUtils.paths.settings.physicalQuantity.editPhysicalQuantity]],
    [RouteUtils.paths.texStyle.color.addColor, [RouteUtils.paths.texStyle.color.colorSettings]],
    [RouteUtils.paths.texStyle.color.editColor, [RouteUtils.paths.texStyle.color.colorSettings]],
    [RouteUtils.paths.texStyle.color.duplicateColor, [RouteUtils.paths.texStyle.color.colorSettings]],
    [RouteUtils.paths.settings.machineDataSources.addMachineDataSource, this.LINKED_MACHINE_DATA_SOURCE_ROUTES],
    [RouteUtils.paths.settings.machineDataSources.editMachineDataSource, this.LINKED_MACHINE_DATA_SOURCE_ROUTES],
    [RouteUtils.paths.settings.machineDataSources.settings, [RouteUtils.paths.settings.resourceType.editResourceType]],
    [RouteUtils.paths.texStyle.colorSet.addColorSet, [RouteUtils.paths.texStyle.colorSet.colorSetSettings]],
    [RouteUtils.paths.texStyle.colorSet.editColorSet, [RouteUtils.paths.texStyle.colorSet.colorSetSettings]],
    [RouteUtils.paths.texStyle.colorSet.duplicateColorSet, [RouteUtils.paths.texStyle.colorSet.colorSetSettings]],
    [RouteUtils.paths.texFab.pathLayoutTemplate.addPathLayoutTemplate, [RouteUtils.paths.texFab.pathLayoutTemplate.pathLayoutTemplateSettings]],
    [RouteUtils.paths.texFab.pathLayoutTemplate.editPathLayoutTemplate, [RouteUtils.paths.texFab.pathLayoutTemplate.pathLayoutTemplateSettings]],
    [RouteUtils.paths.texFab.pathLayoutTemplate.duplicatePathLayoutTemplate, [RouteUtils.paths.texFab.pathLayoutTemplate.pathLayoutTemplateSettings]],
    [RouteUtils.paths.settings.deviceTemplate.addDeviceTemplate, this.LINKED_DEVICE_TEMPLATE],
    [RouteUtils.paths.settings.deviceTemplate.editDeviceTemplate, this.LINKED_DEVICE_TEMPLATE],
    [RouteUtils.paths.settings.deviceTemplate.addCounter, this.LINKED_DEVICE_TEMPLATE_COUNTER],
    [RouteUtils.paths.settings.deviceTemplate.editCounter, this.LINKED_DEVICE_TEMPLATE_COUNTER],
    [RouteUtils.paths.settings.deviceTemplateCatalog.addDeviceTemplateCatalog, this.LINKED_DEVICE_TEMPLATE],
    [RouteUtils.paths.settings.deviceTemplateCatalog.editDeviceTemplateCatalog, this.LINKED_DEVICE_TEMPLATE],
    [RouteUtils.paths.settings.deviceTemplateCatalog.addCounterCatalog, this.LINKED_DEVICE_TEMPLATE_COUNTER],
    [RouteUtils.paths.settings.deviceTemplateCatalog.editCounterCatalog, this.LINKED_DEVICE_TEMPLATE_COUNTER],
    [RouteUtils.paths.texStyle.tuftProduct.addTuftProduct, this.TUFT_PRODUCT_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.tuftProduct.editTuftProduct, this.TUFT_PRODUCT_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.tuftProduct.duplicateTuftProduct, this.TUFT_PRODUCT_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.yarnSet.addYarnSet, this.YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.yarnSet.editYarnSet, this.YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texStyle.yarnSet.duplicateYarnSet, this.YARN_SET_LINKED_ROUTES],
    [RouteUtils.paths.texFab.article.addArticle, this.ARTICLE_LINKED_ROUTES],
    [RouteUtils.paths.texFab.article.editArticle, this.ARTICLE_LINKED_ROUTES],
    [RouteUtils.paths.texFab.article.duplicateArticle, this.ARTICLE_LINKED_ROUTES],
    [RouteUtils.paths.texFab.salesOrder.addSalesOrder, this.SALES_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.salesOrder.editSalesOrder, this.SALES_ORDER_LINKED_ROUTES],
    [RouteUtils.paths.texFab.salesOrder.duplicateSalesOrder, this.SALES_ORDER_LINKED_ROUTES]
  ]);
}
