import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {AlertType} from '@domain/alert/alert-type.enum';
import {ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, NoDataOverlayComponentParams, SelectGridDialog, TranslateService} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GetRowIdParams, GridApi, GridOptions, RowDataUpdatedEvent, RowNode, ValueGetterParams} from 'ag-grid-community';

@Component({
  templateUrl: './select-alert-types.component.html',
  styleUrls: ['./select-alert-types.component.scss']
})
export class SelectAlertTypesComponent implements AfterViewInit, SelectGridDialog {
  @ViewChild('alertTypesGrid') public alertTypesGrid: AgGridAngular;
  public listOfAlertTypes: AlertType[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  private selectedEntities: AlertType[];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SelectAlertTypesComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    this.listOfAlertTypes = data.listOfAlertTypes;
    this.setGridOptionsForAlertTypes();

    this.selectedEntities = data?.selectedEntities ?? [];
  }

  public get gridOptionsForAlertTypes(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.alertTypesGrid.api];
  }

  public selectAlertTypes(): void {
    this.dialogRef.close(this.alertTypesGrid.api.getSelectedRows());
  }

  public canSelectAlertType(): boolean {
    return this.alertTypesGrid?.api && this.alertTypesGrid.api.getSelectedRows().length > 0;
  }

  public getButtonText(): string {
    const selectedAlertTypes = this.alertTypesGrid?.api?.getSelectedRows() ?? [];
    return (selectedAlertTypes.length === 1 && selectedAlertTypes[0] === AlertType.HMI_DU) || this.selectedEntities.length > 0 ? 'GENERAL.ACTIONS.CHOOSE' : 'GENERAL.NAVIGATION.NEXT';
  }

  private setGridOptionsForAlertTypes(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForAlertTypes(), GridIdentifier.SELECT_ALERT_TYPES)
        .withoutColumnMenu()
        .withRowSelection(true, true, true)
        .withNoRowsOverlay({
          titleParam: 'BMSCONFIG.ALERT.ALERT_TYPE.ALERT_TYPES',
          hideDescription: true,
          scale: 0.7
        } as Partial<NoDataOverlayComponentParams>)
        .withOnRowDataUpdated((event: RowDataUpdatedEvent) => this.selectSelectedNodes(event))
        .withGetRowId((params: GetRowIdParams) => params.data)
        .withLoadingOverlay({
          scale: 0.7
        })
        .build()
    ];
  }

  private getColumnDefsForAlertTypes(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('alertType')
        .withValueGetter((params: ValueGetterParams) => this.translate.instant(`BMSCONFIG.ALERTS.ALERT_TYPE.${params.data}`))
        .withHeaderName('BMSCONFIG.ALERTS.ALERT_TYPE.ALERT_TYPES')
        .withoutFilter()
        .build()
    ];
  }

  private selectSelectedNodes(event: RowDataUpdatedEvent): void {
    event.api.forEachNode((node: RowNode) => {
      if (this.selectedEntities?.some((alertType: AlertType) => alertType === node.data)) {
        node.setSelected(true);
      }
    });
  }
}
