import {Component, Inject, OnInit} from '@angular/core';
import {RuntimeApplicationEnvironment, RUNTIME_APPLICATION_ENVIRONMENT} from '@application/configuration/runtime-application-environment';

@Component({
  template: ``
})
export abstract class BaseSignTermsAndConditionsComponent implements OnInit {
  public termsAndConditionsUrl: string;
  public agreesWithTermsAndConditions = false;

  protected constructor(@Inject(RUNTIME_APPLICATION_ENVIRONMENT) protected readonly runtimeEnvironment: RuntimeApplicationEnvironment) {}

  public ngOnInit(): void {
    this.termsAndConditionsUrl = this.runtimeEnvironment.getEnvironment().termsAndConditionsUrl;
  }
}
