import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BreadcrumbFormLinkerDirective} from '@application/directives/breadcrumb-form-linker.directive';
import {DataCyDirective} from '@application/directives/data-cy.directive';
import {HasPermissionDirective} from '@application/directives/has-permission.directive';
import {InputTimeDirective} from '@application/directives/input-time.directive';
import {RunEventsOutsideAngularDirective} from '@application/directives/run-events-outside-angular.directive';
import {DrawingImageUploadHelper} from '@application/helper/drawing/drawing-image-upload-helper';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {DurationFormatPipe} from '@application/pipes/duration-format.pipe';
import {HighlightSearchPipe} from '@application/pipes/highlight-search.pipe';
import {WeaveStructureColumnPipe} from '@application/pipes/weave-structure-column.pipe';
import {VirtualScrollerModule} from '@iharbeck/ngx-virtual-scroller';
import {COLOR_SETS} from '@infrastructure/http/color-set/color-sets';
import {HttpColorSetsService} from '@infrastructure/http/color-set/http-color-sets.service';
import {COLORED_YARN_SETS} from '@infrastructure/http/colored-yarn-set/colored-yarn-sets';
import {HttpColoredYarnSetsService} from '@infrastructure/http/colored-yarn-set/http-colored-yarn-sets.service';
import {FINISHING_TEMPLATES} from '@infrastructure/http/finishing-template/finishing-templates';
import {HttpFinishingTemplatesService} from '@infrastructure/http/finishing-template/http-finishing-templates.service';
import {FINISHINGS} from '@infrastructure/http/finishing/finishings';
import {HttpFinishingsService} from '@infrastructure/http/finishing/http-finishings.service';
import {HttpMachineQualitiesService} from '@infrastructure/http/machine-quality/http-machine-qualities.service';
import {MACHINE_QUALITIES} from '@infrastructure/http/machine-quality/machine-qualities';
import {HttpMachinesService} from '@infrastructure/http/machine/http-machines.service';
import {MACHINES} from '@infrastructure/http/machine/machines.interface';
import {GetPatternVisualisationByPatternId} from '@infrastructure/http/patterns/get-pattern-visualisation-by-pattern-id';
import {HttpPatternsService} from '@infrastructure/http/patterns/http-patterns.service';
import {PATTERNS} from '@infrastructure/http/patterns/patterns';
import {HttpWeaveProductsService} from '@infrastructure/http/weave-product/http-weave-products.service';
import {WEAVE_PRODUCT} from '@infrastructure/http/weave-product/weave-products.interface';
import {HttpWeaveStructuresService} from '@infrastructure/http/weave-structure/http-weave-structures.service';
import {WEAVE_STRUCTURES} from '@infrastructure/http/weave-structure/weave-structures';
import {HttpWeftColoredYarnSetsService} from '@infrastructure/http/weft-colored-yarn-set/http-weft-colored-yarn-sets.service';
import {WEFT_COLORED_YARN_SETS} from '@infrastructure/http/weft-colored-yarn-set/weft-colored-yarn-sets';
import {HttpYarnConsumptionService} from '@infrastructure/http/yarn-consumption/http-yarn-consumption.service';
import {YARN_CONSUMPTION} from '@infrastructure/http/yarn-consumption/yarn-consumption';
import {HttpYarnSetsService} from '@infrastructure/http/yarn-set/http-yarn-sets.service';
import {YARN_SETS} from '@infrastructure/http/yarn-set/yarn-sets';
import {REALTIME_YARN_CONSUMPTION} from '@infrastructure/signalr/yarn-consumption/realtime-yarn-consumption';
import {SignalrYarnConsumptionService} from '@infrastructure/signalr/yarn-consumption/signalr-yarn-consumption.service';
import {AmountSpecifierComponent} from '@presentation/components/amount-specifier/amount-specifier.component';
import {BackgroundJobsDialogComponent} from '@presentation/components/background-jobs/background-jobs-dialog.component';
import {BackgroundJobsGridComponent} from '@presentation/components/background-jobs/background-jobs-grid/background-jobs-grid.component';
import {BackgroundJobsOverviewStatusComponent} from '@presentation/components/background-jobs/background-jobs-overview-status/background-jobs-overview-status.component';
import {DateCellRendererComponent} from '@presentation/components/background-jobs/date-cell-renderer/date-cell-renderer.component';
import {CardInfoComponent} from '@presentation/components/card-info/card-info.component';
import {ChooseSubscriptionDesktopComponent} from '@presentation/components/choose-subscription/choose-subscription-desktop/choose-subscription-desktop.component';
import {ChooseSubscriptionMobileComponent} from '@presentation/components/choose-subscription/choose-subscription-mobile/choose-subscription-mobile.component';
import {SelectSubscriptionComponent} from '@presentation/components/choose-subscription/select-subscription/select-subscription.component';
import {ColorListPreviewComponent} from '@presentation/components/color-list-preview/color-list-preview.component';
import {ColoredYarnSetPreviewComponent} from '@presentation/components/colored-yarn-set-preview/colored-yarn-set-preview.component';
import {CreelMappingComponent} from '@presentation/components/creel-mapping/creel-mapping.component';
import {CreelMappingTableComponent} from '@presentation/components/creel-tables/creel-mapping-table/creel-mapping-table.component';
import {CreelPreviewTableComponent} from '@presentation/components/creel-tables/creel-preview-table/creel-preview-table.component';
import {DeclarationPreviewComponent} from '@presentation/components/declaration-preview/declaration-preview.component';
import {OrderLinesCustomSettingsDialogComponent} from '@presentation/components/dialog-button/dialog-data/order-lines-settings/order-lines-settings.component';
import {SwitchCommercialTechnicalUnitsCustomSettingsDialogComponent} from '@presentation/components/dialog-button/dialog-data/switch-commercial-technical-units/switch-commercial-technical-units.component';
import {DisabledFormInputComponent} from '@presentation/components/disabled-form-input/disabled-form-input.component';
import {DrawingConfigurationComponent} from '@presentation/components/drawing-configuration/drawing-configuration.component';
import {LoadDrawingImageDirective} from '@presentation/components/drawing-list/load-drawing-image.directive';
import {LoadImageLabelDirective} from '@presentation/components/finishing-and-finishing-template/image-label/load-image-label.directive';
import {OverviewListFinishingAndFinishingTemplateService} from '@presentation/components/finishing-and-finishing-template/overview/overview-list-finishing-and-finishing-template.service';
import {GridProductionStatusComponent} from '@presentation/components/grid-production-status/grid-production-status.component';
import {DesignPreviewComponent} from '@presentation/components/grid/design-preview/design-preview.component';
import {GridColorListPreviewComponent} from '@presentation/components/grid/grid-color-list-preview/grid-color-list-preview.component';
import {LabelPlaceholderComponent} from '@presentation/components/label-placeholder/label-placeholder.component';
import {LinkFormTextareaComponent} from '@presentation/components/link-form-textarea/link-form-textarea.component';
import {LoadingFileComponent} from '@presentation/components/loading-file/loading-file.component';
import {MoreItemsAvailableComponent} from '@presentation/components/more-items-available/more-items-available.component';
import {NameGenerationPatternNewComponent} from '@presentation/components/name-generation-pattern-new/name-generation-pattern-new.component';
import {NameSuggestionHintComponent} from '@presentation/components/name-generation-pattern-new/name-suggestion-hint/name-suggestion-hint.component';
import {SelectNameGenerationPartsComponent} from '@presentation/components/name-generation-pattern-new/select-name-generation-parts/select-name-generation-parts.component';
import {NameGenerationPatternComponent} from '@presentation/components/name-generation-pattern/name-generation-pattern.component';
import {SelectNameGenerationPatternComponent} from '@presentation/components/name-generation-pattern/select-name-generation-pattern/select-name-generation-pattern.component';
import {OnboardingDialogComponent} from '@presentation/components/onboarding-dialog/onboarding-dialog.component';
import {GridOrderLineStatusComponent} from '@presentation/components/order-lines-grid/grid-order-line-status/grid-order-line-status.component';
import {OrderLinesGridComponent} from '@presentation/components/order-lines-grid/order-lines-grid.component';
import {OverviewButtonCardComponent} from '@presentation/components/overview-button-card/overview-button-card.component';
import {PannableAndZoomablePatternPreviewComponent} from '@presentation/components/pattern-preview/pannable-and-zoomable-pattern-preview/pannable-and-zoomable-pattern-preview.component';
import {PatternPreviewComponent} from '@presentation/components/pattern-preview/pattern-preview.component';
import {SvgLoaderDirective} from '@presentation/components/pattern-preview/svg-loader.directive';
import {ThumbnailSvgLoaderDirective} from '@presentation/components/pattern-preview/thumbnail-svg-loader.directive';
import {PeriodSelectionComponent} from '@presentation/components/period-selection/period-selection.component';
import {ProgressSpinnerComponent} from '@presentation/components/progress-spinner/progress-spinner.component';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {RepositionTooltipComponent} from '@presentation/components/reposition-tooltip/reposition-tooltip.component';
import {AdvancedSearchSelectObjectComponent} from '@presentation/components/search-filters/advanced-search/advanced-search-select-object/advanced-search-select-object.component';
import {AdvancedSearchComponent} from '@presentation/components/search-filters/advanced-search/advanced-search.component';
import {AdvancedSearchDialogComponent} from '@presentation/components/search-filters/advanced-search/dialog/advanced-search-dialog.component';
import {SearchFiltersComponent} from '@presentation/components/search-filters/search-filters.component';
import {SelectBuggyComponent} from '@presentation/components/select-buggy/select-buggy.component';
import {SelectCreelComponent} from '@presentation/components/select-creel/select-creel.component';
import {SelectDataUnitSetupComponent} from '@presentation/components/select-data-unit-setup/select-data-unit-setup.component';
import {SelectDataUnitComponent} from '@presentation/components/select-data-unit/select-data-unit.component';
import {FinishingCutbarAfterLengthComponent} from '@presentation/components/select-finishing/finishing-cutbar-after-length/finishing-cutbar-after-length.component';
import {FinishingCutbarBeforeLengthComponent} from '@presentation/components/select-finishing/finishing-cutbar-before-length/finishing-cutbar-before-length.component';
import {FinishingLabelsIndicationComponent} from '@presentation/components/select-finishing/finishing-labels-indication/finishing-labels-indication.component';
import {FinishingTypeComponent} from '@presentation/components/select-finishing/finishing-type/finishing-type.component';
import {SelectFinishingComponent} from '@presentation/components/select-finishing/select-finishing.component';
import {SelectMachineQualityComponent} from '@presentation/components/select-machine-quality/select-machine-quality.component';
import {PathLayoutTemplateMismatchComponent} from '@presentation/components/select-path-layout-template/path-layout-template-mismatch/path-layout-template-mismatch.component';
import {SelectPathLayoutTemplateComponent} from '@presentation/components/select-path-layout-template/select-path-layout-template.component';
import {SelectPatternFieldComponent} from '@presentation/components/select-pattern-field/select-pattern-field.component';
import {AddPatternComponent} from '@presentation/components/select-pattern/add-pattern/add-pattern.component';
import {ContainsVisualisationDataPipe} from '@presentation/components/select-pattern/pattern-example/contains-visualisation-data.pipe';
import {PatternExampleComponent} from '@presentation/components/select-pattern/pattern-example/pattern-example.component';
import {SelectPatternComponent} from '@presentation/components/select-pattern/select-pattern.component';
import {SelectPossibleMachinesComponent} from '@presentation/components/select-possible-machines/select-possible-machines.component';
import {SelectReportItemComponent} from '@presentation/components/select-report-item/select-report-item.component';
import {SelectResourceTypeBreakdownComponent} from '@presentation/components/select-resource-type-breakdown/select-resource-type-breakdown.component';
import {SelectRuleEntityComponent} from '@presentation/components/select-rule-entity/select-rule-entity.component';
import {SignTermsAndConditionsDesktopComponent} from '@presentation/components/sign-terms-and-conditons/sign-terms-and-conditions-desktop/sign-terms-and-conditions-desktop.component';
import {SignTermsAndConditionsMobileComponent} from '@presentation/components/sign-terms-and-conditons/sign-terms-and-conditions-mobile/sign-terms-and-conditions-mobile.component';
import {ThumbnailSelectedIndicatorComponent} from '@presentation/components/thumbnail-selected-indicator/thumbnail-selected-indicator.component';
import {UnlockInUseComponent} from '@presentation/components/unlock-in-use/unlock-in-use.component';
import {SelectAlertTypesComponent} from '@presentation/pages/settings/alerts/add/select-alert-types/select-alert-types.component';
import {SelectRecipientsComponent} from '@presentation/pages/settings/alerts/add/select-recipients/select-recipients.component';
import {MachineDocumentationDetailsComponent} from '@presentation/pages/texbot/machine-documentation/machine-documentation-details/machine-documentation-details.component';
import {DrawingLibraryImagePreviewComponent} from '@presentation/pages/texedit/drawing-library/drawing-library-image-preview/drawing-library-image-preview.component';
import {LoadTechnicalDatasheetImageDirective} from '@presentation/pages/texedit/technical-datasheet/technical-datasheet-list/load-technical-datasheet-image.directive';
import {SelectWeaveProductComponent} from '@presentation/pages/texfab/production-order-lite/add/select-weave-product/select-weave-product.component';
import {GridColorSetPreviewComponent} from '@presentation/pages/textile-data/color-set/overview/grid-color-set-preview/grid-color-set-preview.component';
import {GridColorPreviewComponent} from '@presentation/pages/textile-data/color/overview/grid-color-preview/grid-color-preview.component';
import {ColorGridPreviewComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/grid-colors-of-creel-position/color-grid-preview/color-grid-preview.component';
import {GridColorsOfCreelPositionComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/grid-colors-of-creel-position/grid-colors-of-creel-position.component';
import {GridYarnTypesOfCreelPositionComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/grid-yarn-types-of-creel-position/grid-yarn-types-of-creel-position.component';
import {YarnTypeGridPreviewComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/grid-yarn-types-of-creel-position/yarn-type-grid-preview/yarn-type-grid-preview.component';
import {GridCreelPreviewComponent} from '@presentation/pages/textile-data/creel/overview/grid-creel-preview/grid-creel-preview.component';
import {GridImageComponent} from '@presentation/pages/textile-data/machine-quality/add/machine-quality-selvedges/select-image/grid-image/grid-image.component';
import {GridSelectPreviewComponent} from '@presentation/pages/textile-data/material-set/add/grid-select-preview/grid-select-preview.component';
import {GridYarnConsumptionComponent} from '@presentation/pages/textile-data/textile-data-detail/grid-yarn-consumption/grid-yarn-consumption.component';
import {TextileDataDetailComponent} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail.component';
import {TextileDataOverviewComponent} from '@presentation/pages/textile-data/textile-data-overview/textile-data-overview.component';
import {
  AutoFocusModule,
  BreadcrumbModule,
  CanShowErrorForFormControlModule,
  CapitalFirstLetterPipeModule,
  CommonFormsModule,
  DatepickerFormInputModule,
  DialogButtonModule,
  DialogModule,
  DistanceSpecifierModule,
  FilterChipsComponent,
  FilterChipsModule,
  FilterModule,
  FooterModule,
  GridUtilsModule,
  HeaderModule,
  HexValuePipeModule,
  InputL10nDecimalModule,
  LabelModule,
  LocalStorageModule,
  MessageContainerModule,
  MinutesFromNowPipeModule,
  MobilePageHeaderModule,
  MomentDatePipeModule,
  ObjectSelectionModule,
  PatternModule,
  ProgressButtonModule,
  RadioToggleButtonModule,
  ResponsivenessModule,
  ReverseArrayPipeModule,
  SlideToggleModule,
  StatusBarModule,
  StringInputModule,
  TabsMenuCardModule,
  TabsMenuModule,
  TagModule,
  ToastModule,
  TranslationModule,
  ZoneInputModule
} from '@vdw/angular-component-library';
import {AgGridModule} from 'ag-grid-angular';
import {MaterialModule} from '../material.module';

@NgModule({
  declarations: [
    ColoredYarnSetPreviewComponent,
    GridYarnConsumptionComponent,
    CardInfoComponent,
    SelectSubscriptionComponent,
    ColorListPreviewComponent,
    MoreItemsAvailableComponent,
    GridColorListPreviewComponent,
    PatternExampleComponent,
    SvgLoaderDirective,
    LoadDrawingImageDirective,
    LoadTechnicalDatasheetImageDirective,
    HasPermissionDirective,
    ProgressSpinnerComponent,
    TextileDataOverviewComponent,
    SelectPatternComponent,
    SelectPathLayoutTemplateComponent,
    SelectPatternFieldComponent,
    SelectBuggyComponent,
    SelectDataUnitSetupComponent,
    GridCreelPreviewComponent,
    TextileDataDetailComponent,
    GridColorsOfCreelPositionComponent,
    ColorGridPreviewComponent,
    GridYarnTypesOfCreelPositionComponent,
    GridYarnConsumptionComponent,
    YarnTypeGridPreviewComponent,
    RepositionDialogComponent,
    GridColorPreviewComponent,
    GridColorSetPreviewComponent,
    SearchFiltersComponent,
    AdvancedSearchDialogComponent,
    AdvancedSearchComponent,
    AdvancedSearchSelectObjectComponent,
    DisabledFormInputComponent,
    LoadingFileComponent,
    ChooseSubscriptionDesktopComponent,
    ChooseSubscriptionMobileComponent,
    DataCyDirective,
    LoadImageLabelDirective,
    MachineDocumentationDetailsComponent,
    InputTimeDirective,
    SelectFinishingComponent,
    SelectRuleEntityComponent,
    FinishingCutbarAfterLengthComponent,
    FinishingCutbarBeforeLengthComponent,
    FinishingLabelsIndicationComponent,
    FinishingTypeComponent,
    RunEventsOutsideAngularDirective,
    PeriodSelectionComponent,
    DurationFormatPipe,
    RepositionTooltipComponent,
    HighlightSearchPipe,
    SelectMachineQualityComponent,
    DateCellRendererComponent,
    OnboardingDialogComponent,
    BackgroundJobsOverviewStatusComponent,
    PathLayoutTemplateMismatchComponent,
    UnlockInUseComponent,
    CreelMappingTableComponent,
    CreelPreviewTableComponent,
    SelectCreelComponent,
    DesignPreviewComponent,
    DrawingLibraryImagePreviewComponent,
    SignTermsAndConditionsDesktopComponent,
    SignTermsAndConditionsMobileComponent,
    BackgroundJobsDialogComponent,
    BackgroundJobsGridComponent,
    AddPatternComponent,
    OrderLinesGridComponent,
    GridOrderLineStatusComponent,
    DrawingConfigurationComponent,
    AddPatternComponent,
    PatternPreviewComponent,
    PannableAndZoomablePatternPreviewComponent,
    ThumbnailSelectedIndicatorComponent,
    LabelPlaceholderComponent,
    AmountSpecifierComponent,
    GridImageComponent,
    CreelMappingComponent,
    OverviewButtonCardComponent,
    LinkFormTextareaComponent,
    ContainsVisualisationDataPipe,
    GridProductionStatusComponent,
    OverviewButtonCardComponent,
    SwitchCommercialTechnicalUnitsCustomSettingsDialogComponent,
    OrderLinesCustomSettingsDialogComponent,
    SelectPossibleMachinesComponent,
    SelectAlertTypesComponent,
    SelectRecipientsComponent,
    DeclarationPreviewComponent,
    SelectWeaveProductComponent,
    GridSelectPreviewComponent,
    NameGenerationPatternComponent,
    SelectNameGenerationPatternComponent,
    NameGenerationPatternNewComponent,
    SelectNameGenerationPartsComponent,
    NameSuggestionHintComponent,
    ThumbnailSvgLoaderDirective,
    SelectResourceTypeBreakdownComponent,
    SelectDataUnitComponent,
    SelectReportItemComponent,
    BreadcrumbFormLinkerDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AgGridModule,
    VirtualScrollerModule,
    TranslationModule,
    CommonFormsModule,
    LabelModule,
    HeaderModule,
    GridUtilsModule,
    ToastModule,
    FilterModule,
    SlideToggleModule,
    ZoneInputModule,
    PatternModule,
    RadioToggleButtonModule,
    MomentDatePipeModule,
    MinutesFromNowPipeModule,
    ReverseArrayPipeModule,
    CanShowErrorForFormControlModule,
    CapitalFirstLetterPipeModule,
    HexValuePipeModule,
    TagModule,
    AutoFocusModule,
    DatepickerFormInputModule,
    DialogButtonModule,
    DialogModule,
    DistanceSpecifierModule,
    InputL10nDecimalModule,
    FilterChipsModule,
    MessageContainerModule,
    MobilePageHeaderModule,
    TabsMenuModule,
    TabsMenuCardModule,
    ObjectSelectionModule,
    StringInputModule,
    FooterModule,
    ProgressButtonModule,
    StatusBarModule,
    RouterModule,
    LocalStorageModule.forRoot({prefix: 'vdwt-app'}),
    BreadcrumbModule,
    ResponsivenessModule
  ],
  providers: [
    {provide: MACHINE_QUALITIES, useClass: HttpMachineQualitiesService},
    {provide: MACHINES, useClass: HttpMachinesService},
    {provide: COLOR_SETS, useClass: HttpColorSetsService},
    {provide: YARN_SETS, useClass: HttpYarnSetsService},
    {provide: YARN_CONSUMPTION, useClass: HttpYarnConsumptionService},
    {provide: REALTIME_YARN_CONSUMPTION, useClass: SignalrYarnConsumptionService},
    {provide: PATTERNS, useClass: HttpPatternsService},
    {provide: COLORED_YARN_SETS, useClass: HttpColoredYarnSetsService},
    {provide: WEFT_COLORED_YARN_SETS, useClass: HttpWeftColoredYarnSetsService},
    {provide: WEAVE_STRUCTURES, useClass: HttpWeaveStructuresService},
    {provide: FINISHINGS, useClass: HttpFinishingsService},
    {provide: FINISHING_TEMPLATES, useClass: HttpFinishingTemplatesService},
    {provide: PATTERNS, useClass: HttpPatternsService},
    {provide: WEAVE_PRODUCT, useClass: HttpWeaveProductsService},
    DurationFormatPipe,
    HighlightSearchPipe,
    DrawingImageUploadHelper,
    WeaveStructureColumnPipe,
    GetPatternVisualisationByPatternId,
    OverviewListFinishingAndFinishingTemplateService,
    GridActionButtonsHelper
  ],
  exports: [
    ColoredYarnSetPreviewComponent,
    GridYarnConsumptionComponent,
    CardInfoComponent,
    AgGridModule,
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ColorListPreviewComponent,
    MoreItemsAvailableComponent,
    HeaderModule,
    FilterModule,
    SlideToggleModule,
    ZoneInputModule,
    GridColorListPreviewComponent,
    PatternExampleComponent,
    SvgLoaderDirective,
    LoadDrawingImageDirective,
    LoadTechnicalDatasheetImageDirective,
    HasPermissionDirective,
    ProgressSpinnerComponent,
    SelectPatternComponent,
    SelectPathLayoutTemplateComponent,
    SelectBuggyComponent,
    SelectDataUnitSetupComponent,
    TextileDataOverviewComponent,
    GridCreelPreviewComponent,
    TextileDataDetailComponent,
    ColorGridPreviewComponent,
    GridYarnTypesOfCreelPositionComponent,
    GridYarnConsumptionComponent,
    YarnTypeGridPreviewComponent,
    DeclarationPreviewComponent,
    RepositionDialogComponent,
    GridColorPreviewComponent,
    GridColorSetPreviewComponent,
    SearchFiltersComponent,
    AdvancedSearchDialogComponent,
    AdvancedSearchComponent,
    AdvancedSearchSelectObjectComponent,
    DateCellRendererComponent,
    DisabledFormInputComponent,
    LoadingFileComponent,
    ChooseSubscriptionMobileComponent,
    DataCyDirective,
    LoadImageLabelDirective,
    MachineDocumentationDetailsComponent,
    InputTimeDirective,
    SelectFinishingComponent,
    SelectRuleEntityComponent,
    RunEventsOutsideAngularDirective,
    PeriodSelectionComponent,
    VirtualScrollerModule,
    DurationFormatPipe,
    RepositionTooltipComponent,
    TranslationModule,
    HighlightSearchPipe,
    ContainsVisualisationDataPipe,
    SelectMachineQualityComponent,
    OnboardingDialogComponent,
    BackgroundJobsOverviewStatusComponent,
    PathLayoutTemplateMismatchComponent,
    UnlockInUseComponent,
    CreelMappingTableComponent,
    CreelPreviewTableComponent,
    SelectCreelComponent,
    DesignPreviewComponent,
    DrawingLibraryImagePreviewComponent,
    SignTermsAndConditionsDesktopComponent,
    SignTermsAndConditionsMobileComponent,
    BackgroundJobsDialogComponent,
    BackgroundJobsGridComponent,
    OrderLinesGridComponent,
    GridOrderLineStatusComponent,
    CommonFormsModule,
    LabelModule,
    DrawingConfigurationComponent,
    GridUtilsModule,
    PatternPreviewComponent,
    ThumbnailSelectedIndicatorComponent,
    LabelPlaceholderComponent,
    AmountSpecifierComponent,
    GridImageComponent,
    CreelMappingComponent,
    OverviewButtonCardComponent,
    LinkFormTextareaComponent,
    GridProductionStatusComponent,
    OverviewButtonCardComponent,
    SwitchCommercialTechnicalUnitsCustomSettingsDialogComponent,
    OrderLinesCustomSettingsDialogComponent,
    SelectPossibleMachinesComponent,
    SelectAlertTypesComponent,
    SelectRecipientsComponent,
    MomentDatePipeModule,
    MinutesFromNowPipeModule,
    RadioToggleButtonModule,
    ReverseArrayPipeModule,
    CanShowErrorForFormControlModule,
    CapitalFirstLetterPipeModule,
    HexValuePipeModule,
    AutoFocusModule,
    DatepickerFormInputModule,
    DialogButtonModule,
    DialogModule,
    DistanceSpecifierModule,
    InputL10nDecimalModule,
    MobilePageHeaderModule,
    FilterChipsComponent,
    MessageContainerModule,
    TabsMenuModule,
    TabsMenuCardModule,
    ObjectSelectionModule,
    FooterModule,
    ProgressButtonModule,
    StatusBarModule,
    NameGenerationPatternComponent,
    NameGenerationPatternNewComponent,
    NameSuggestionHintComponent,
    SelectResourceTypeBreakdownComponent,
    SelectDataUnitComponent,
    SelectReportItemComponent,
    BreadcrumbModule,
    ResponsivenessModule,
    BreadcrumbFormLinkerDirective,
    StringInputModule
  ]
})
export class SharedModule {}
