import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Event, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationBreadcrumbsService} from '@application/services/navigation-breadcrumbs/navigation-breadcrumbs.service';
import {AssertionUtils, BaseComponent, BreadcrumbItem, MenuItemEntry, TranslateService} from '@vdw/angular-component-library';
import {filter, takeUntil} from 'rxjs';

@Component({
  selector: 'app-navigation-breadcrumbs',
  templateUrl: './navigation-breadcrumbs.component.html'
})
export class NavigationBreadcrumbsComponent extends BaseComponent implements OnInit {
  private readonly ADD_URL = '/add';
  private readonly EDIT_URL = '/edit';

  public breadcrumbItems: BreadcrumbItem[] = [];

  public constructor(
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly navigationBreadcrumbsService: NavigationBreadcrumbsService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public readonly disablePredicate = (breadcrumb: BreadcrumbItem): boolean => {
    return AssertionUtils.isNullOrUndefined(breadcrumb.data.link) || breadcrumb.data.link === this.router.url;
  };

  public ngOnInit(): void {
    this.setBreadcrumbs();
    this.subscribeToRouteEventChanges();
    this.subscribeToNameChanges();
    this.subscribeToIsUsingBreadcrumbsDirectiveChanges();
  }

  public onBreadcrumbItemsChanged(breadcrumbItems: BreadcrumbItem[]): void {
    this.router.navigateByUrl(breadcrumbItems[breadcrumbItems.length - 1].data.link);
  }

  private subscribeToRouteEventChanges(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent | Event): boolean => event instanceof NavigationEnd),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(() => this.setBreadcrumbs());
  }

  private setBreadcrumbs(): void {
    let url = this.router.url;
    const splitUrl = this.router.url.split('/');

    if (this.router.url.includes(this.ADD_URL) || this.router.url.includes(this.EDIT_URL)) {
      const urlToTrim = this.router.url.includes(this.ADD_URL) ? this.ADD_URL : this.EDIT_URL;
      url = this.router.url.substring(0, this.router.url.indexOf(urlToTrim));
    } else if (this.isCustomSettingsPage()) {
      const urlToTrim = '/settings';
      url = this.router.url.substring(0, this.router.url.startsWith(urlToTrim) ? this.router.url.lastIndexOf(urlToTrim) : this.router.url.indexOf(urlToTrim));
    } else if (Number(splitUrl[splitUrl.length - 1])) {
      url = this.router.url.substring(0, this.router.url.indexOf(`/${splitUrl[splitUrl.length - 1]}`));
    }

    let activeMenuItems = this.navigationBreadcrumbsService.findChildEntryByPath(this.navigationBreadcrumbsService.getAllMenuItemsDetails(), url);

    if (this.isCustomSettingsPage()) {
      activeMenuItems.push({title: this.translate.instant('SETTINGS.MENU_NAME'), link: this.router.url});
    }

    this.breadcrumbItems = activeMenuItems?.map((activeMenuItem: MenuItemEntry) => {
      return {key: activeMenuItem.title, data: activeMenuItem} as BreadcrumbItem;
    });
  }

  private isCustomSettingsPage(): boolean {
    return this.router.url !== RouteUtils.paths.settings.absolutePath && this.router.url.endsWith('settings');
  }

  private isAddOrEditRoute(): boolean {
    return this.router.url.includes(this.ADD_URL) || this.router.url.includes(this.EDIT_URL);
  }

  private subscribeToNameChanges(): void {
    this.navigationBreadcrumbsService.onNameChangedSubject.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((name: string) => {
      const NEW_TRANSLATION_KEY = this.translate.instant('GENERAL.NEW');
      const currentActiveMenuItem = this.getCurrentActiveBreadcrumbItem();

      if (this.router.url.includes(this.ADD_URL)) {
        currentActiveMenuItem.key = name ? `${NEW_TRANSLATION_KEY}: ` : `${NEW_TRANSLATION_KEY}`;
        currentActiveMenuItem.data.newName = name;
      } else {
        currentActiveMenuItem.key = name;
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  private subscribeToIsUsingBreadcrumbsDirectiveChanges(): void {
    this.navigationBreadcrumbsService.isUsingBreadcrumbFormLinkerSubject.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((isUsingBreadcrumbsDirective: boolean) => {
      if (isUsingBreadcrumbsDirective && this.isAddOrEditRoute() && AssertionUtils.isNullOrUndefined(this.getCurrentActiveBreadcrumbItem())) {
        this.breadcrumbItems?.push({key: this.translate.instant('GENERAL.NEW'), data: {link: this.router.url}} as BreadcrumbItem);
      }
    });
  }

  private getCurrentActiveBreadcrumbItem(): BreadcrumbItem {
    return this.breadcrumbItems.find((item: BreadcrumbItem) => item.data.link === this.router.url);
  }
}
