import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {BaseCreelTableComponent} from '@presentation/components/creel-tables/base-creel-table/base-creel-table.component';
import {PreviewType} from '@presentation/pages/textile-data/creel/add/preview-type';
import {PreviewMap} from '../preview-map';

@Component({
  selector: 'app-creel-preview-table',
  templateUrl: './creel-preview-table.component.html',
  styleUrls: ['../base-creel-table/base-creel-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreelPreviewTableComponent extends BaseCreelTableComponent implements OnChanges {
  @Input() public coloredYarnSetsWithStartDent: OverviewListColoredYarnSetWithStartDent[];
  @Input() public previewMap: PreviewMap;
  @Input() public creelMap: CreelMap[];

  public mappedColoredYarnSetsWithStartDent: OverviewListColoredYarnSetWithStartDent[];

  public trackByCreelPosition = (_: number, creelPosition: OverviewListCreelPosition): number => creelPosition.position;

  public ngOnChanges(): void {
    this.cellHeightInPx = this.getCellHeight();
    this.mappedColoredYarnSetsWithStartDent = this.getColoredYarnSetsWithStartDent();
  }

  public getCreelPositions(): number[] {
    const size = this.getSizeOfCreelPositions();
    return [...Array(size).keys()].map((index: number) => size - index);
  }

  public isCreelPositionDifferentFromTheoreticalCreel(index: number, position: number): boolean {
    if (this.previewMap !== PreviewMap.ACTIVE) {
      return false;
    }
    let coloredYarnSetCreelMap = this.creelMap[index];
    return !coloredYarnSetCreelMap.creelPositions.some(
      ({creelPosition, currentPositionOnMachine}: {creelPosition: number; currentPositionOnMachine: number}) => creelPosition === position && creelPosition === currentPositionOnMachine
    );
  }

  private getCellHeight(): number {
    const getMaxAmount = this.previewType === PreviewType.COLOR ? this.getMaximumAmountOfColorsInColoredYarnSet : this.getMaximumAmountOfYarnSetsInColoredYarnSet;
    const height = Math.max(1, ...this.coloredYarnSetsWithStartDent.map(getMaxAmount));
    return height * CreelPreviewTableComponent.colorLineHeightInPx + CreelPreviewTableComponent.cellPaddingInPx;
  }

  private getSizeOfCreelPositions(): number {
    return this.coloredYarnSetsWithStartDent[0].coloredYarnSet.overviewListColorSet.creelPositions.length;
  }

  private getColoredYarnSetsWithStartDent(): OverviewListColoredYarnSetWithStartDent[] {
    const getColoredYarnSets =
      this.previewMap === PreviewMap.THEORETICAL
        ? (coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent): OverviewListColoredYarnSetWithStartDent =>
            this.reverseColoredYarnSetWithStartDentCreelPositions(coloredYarnSetWithStartDent)
        : (coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent, index: number): OverviewListColoredYarnSetWithStartDent => {
            this.creelMap[index] ??= coloredYarnSetWithStartDent.getOriginalMappingForCurrentPositionOnMachine();
            return this.getMappedColoredYarnSetWithStartDent(coloredYarnSetWithStartDent, this.creelMap[index]);
          };

    return this.coloredYarnSetsWithStartDent.map(getColoredYarnSets);
  }
}
