import {Component, Input, OnInit} from '@angular/core';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {ListDrawingOrderLine} from '@presentation/components/drawing-list/list-drawing-order-line';

@Component({
  selector: 'app-amount-specifier',
  templateUrl: './amount-specifier.component.html',
  styleUrls: ['./amount-specifier.component.scss']
})
export class AmountSpecifierComponent implements OnInit {
  @Input() public listDrawing: ListDrawing;
  @Input() public stepSize = 1;
  @Input() public allowExceedingRequestedAmountOfOrderLineItems: boolean;

  public ngOnInit(): void {
    setTimeout(() => (this.listDrawing.amount = Math.max(this.stepSize, this.listDrawing.amount)));
  }

  public canSubtract(): boolean {
    return this.listDrawing.amount - this.stepSize !== 0 && this.canEdit();
  }

  public subtract(): void {
    this.listDrawing.amount -= this.stepSize;
    this.listDrawing.amount = Math.max(this.stepSize, this.listDrawing.amount);
  }

  public add(): void {
    this.listDrawing.amount += this.stepSize;
  }

  public amountChanged(): void {
    if (this.stepSize === 2 && this.listDrawing.amount % 2 !== 0) {
      this.listDrawing.amount--;
    }

    if (
      this.listDrawing instanceof ListDrawingOrderLine &&
      this.listDrawing.amount > this.listDrawing.orderLine.getAmountLeftForProductionSchedule(this.stepSize) &&
      !this.allowExceedingRequestedAmountOfOrderLineItems
    ) {
      this.listDrawing.amount = this.listDrawing.orderLine.getAmountLeftForProductionSchedule(this.stepSize);
    }

    this.listDrawing.amount = Math.max(this.stepSize, this.listDrawing.amount);
  }

  public canAdd(): boolean {
    if (this.listDrawing instanceof ListDrawingOrderLine && this.listDrawing.amount >= this.listDrawing.orderLine.getAmountLeftForProductionSchedule(this.stepSize)) {
      return this.allowExceedingRequestedAmountOfOrderLineItems && this.canEdit();
    }
    return this.canEdit();
  }

  public preventDefaultEventAndStopPropagation(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public canEdit(): boolean {
    return !this.listDrawing.drawing.repeated;
  }
}
