import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {ToggleButtonsComponent} from './toggle-buttons.component';

@NgModule({
  imports: [CommonModule, MatButtonToggleModule, MatButtonModule, MatIconModule],
  declarations: [ToggleButtonsComponent],
  exports: [ToggleButtonsComponent]
})
export class ToggleButtonsModule {}
