import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';

@Component({
  template: '<input #input type="number" [(ngModel)]="value"/>',
  styleUrls: ['./numeric-editor.component.scss']
})
export class NumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input') public input: ElementRef;
  public value: number;
  private params: ICellEditorParams;

  public agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = this.params.value;
  }

  public getValue(): number {
    return this.value;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
  }
}
