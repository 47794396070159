<div class="bms-theme flex-column">
  <div [formGroup]="timeForm" class="flex-row gap-4 flex-center">
    <div class="flex-column gap-4 flex-center overflow-hidden">
      <button mat-icon-button (click)="increase('hours')">
        <mat-icon svgIcon="solid-keyboard-arrow-up"></mat-icon>
      </button>
      <mat-form-field class="bms-theme no-subscript">
        <input matInput formControlName="hours" type="number" [min]="getMinValue('hours')" [max]="getMaxValue('hours')" class="text-align-center" />
      </mat-form-field>
      <button mat-icon-button (click)="decrease('hours')">
        <mat-icon svgIcon="solid-keyboard-arrow-down"></mat-icon>
      </button>
    </div>
    :
    <div class="flex-column gap-4 flex-center overflow-hidden">
      <button mat-icon-button (click)="increase('minutes')">
        <mat-icon svgIcon="solid-keyboard-arrow-up"></mat-icon>
      </button>
      <mat-form-field class="bms-theme no-subscript">
        <input matInput formControlName="minutes" type="number" [min]="getMinValue('minutes')" [max]="getMaxValue('minutes')" class="text-align-center" />
      </mat-form-field>
      <button mat-icon-button (click)="decrease('minutes')">
        <mat-icon svgIcon="solid-keyboard-arrow-down"></mat-icon>
      </button>
    </div>
    @if (notation === 12) {
      <div class="flex-column gap-4 flex-center overflow-hidden">
        <button mat-icon-button (click)="togglePeriod()">
          <mat-icon svgIcon="solid-keyboard-arrow-up"></mat-icon>
        </button>
        <mat-form-field class="bms-theme no-subscript">
          <input matInput readonly formControlName="period" type="text" class="text-align-center" />
        </mat-form-field>
        <button mat-icon-button (click)="togglePeriod()">
          <mat-icon svgIcon="solid-keyboard-arrow-down"></mat-icon>
        </button>
      </div>
    }
  </div>
  <div class="pt-8 pb-8">
    <mat-divider></mat-divider>
  </div>
  <div class="flex-row flex-space-between">
    <button mat-button class="compact" (click)="setTime()">{{ 'ANGULAR_COMPONENT_LIBRARY.TIME_PICKER.CLEAR' | bmsTranslate }}</button>
    <button mat-button class="compact" (click)="setTime(true)">{{ 'ANGULAR_COMPONENT_LIBRARY.TIME_PICKER.NOW' | bmsTranslate }}</button>
  </div>
</div>
