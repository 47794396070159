import {IdName} from './id-name';

export class IdNameDescription extends IdName {
  private readonly _description: string;

  public constructor(id: number, name: string, description: string) {
    super(id, name);
    this._description = description;
  }

  public get description(): string {
    return this._description;
  }

  public static fromJSON(object: any): IdNameDescription {
    return new IdNameDescription(object.id, object.name, object.description);
  }
}
