import {Pipe, PipeTransform} from '@angular/core';
import {PatternVisualisation} from '@domain/pattern-visualisation';

@Pipe({
  name: 'containsVisualisationData'
})
export class ContainsVisualisationDataPipe implements PipeTransform {
  public transform(patternVisualisation: PatternVisualisation): boolean {
    return patternVisualisation?.hasVisualisationData();
  }
}
