import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {AssertionUtils, DialogComponentData, ObjectUtils} from '@vdw/angular-component-library';
import {LinkedRouteUtils} from '../routing/linked-route-utils';
import {RouteLeaf} from '../routing/route-leaf';
import {NavigationNewItemData} from './navigation-new-item-data.interface';

@Injectable({providedIn: 'root'})
export class NavigationHelperService<T> {
  public static SESSION_STORAGE_KEY = 'navigationData.';

  public routingHistory: {route: string; data: T}[] = [];
  private navigatingBack = false;

  private previousRoute: string;
  private currentRoute: string;
  private initialRoute: string;
  private previousNextRoute: string;

  public constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly navigationHistory: NavigationHistoryService
  ) {
    this.location.onUrlChange((route: string) => {
      this.previousRoute = this.navigatingBack ? this.routingHistory[this.routingHistory.length - 2]?.route : this.currentRoute;
      this.currentRoute = route;
    });

    window.onbeforeunload = (): void => this.clearScrollPositionInSessionStorage();
  }

  public saveState(data: T): void {
    const currentRoute = this.getCurrentRouteForSavingState();
    const nextRoute = this.getNextRouteForSavingState();

    const currentRouteLeaf = this.getCurrentRouteLeaf(currentRoute);

    if (this.navigatingBack) {
      this.navigatingBack = false;
    } else if (currentRouteLeaf?.absolutePath && this.isNextRouteRelatedToCurrent(currentRouteLeaf, nextRoute)) {
      const lastState = this.routingHistory[this.routingHistory.length - 1];
      if (lastState?.route === currentRoute) {
        lastState.data = data;
      } else {
        this.routingHistory.push({route: currentRoute, data});
      }

      if (AssertionUtils.isNullOrUndefined(this.initialRoute)) {
        this.initialRoute = this.navigationHistory.getPreviousUrl();
      }
    } else {
      this.clearAllStates();
    }
  }

  public savePartialState<K>(data: K): void {
    let currentRoute = this.getCurrentRouteForSavingState();
    const nextRoute = this.getNextRouteForSavingState();

    if (AssertionUtils.isNullOrUndefined(currentRoute) && !AssertionUtils.isNullOrUndefined(nextRoute) && nextRoute === this.previousNextRoute && this.hasPreviousRoute()) {
      currentRoute = this.routingHistory[this.routingHistory.length - 1].route;
    }

    this.previousNextRoute = nextRoute;
    const currentRouteLeaf = this.getCurrentRouteLeaf(currentRoute);

    if (this.navigatingBack || this.currentRoute === this.getPreviousRoute()) {
      this.navigatingBack = false;
    } else if (currentRouteLeaf?.absolutePath && this.isNextRouteRelatedToCurrent(currentRouteLeaf, nextRoute)) {
      this.savePartialStateData(data, currentRoute);
    } else {
      this.clearAllStates();
    }
  }

  public onDestroy(): void {
    if (this.navigatingBack) {
      this.navigatingBack = false;
    } else {
      this.clearAllStates();
    }
  }

  public getState(): T {
    if (this.routingHistory.length > 0 && this.hasState()) {
      const state = this.routingHistory[this.routingHistory.length - 1].data;
      this.routingHistory.pop();
      return state;
    }

    return null;
  }

  public getPartialState<K>(properties: string[], fromPreviousRoute: boolean = false): K {
    if (this.routingHistory.length > 0 && (this.hasState() || fromPreviousRoute)) {
      const data = this.routingHistory[this.routingHistory.length - 1].data;
      const extractedData = {};

      if (!AssertionUtils.isNullOrUndefined(data)) {
        Object.keys(data).forEach((key: string) => {
          if (properties.includes(key)) {
            extractedData[key] = data[key];
            delete data[key];
          }
        });

        if (Object.keys(data).length === 0) {
          this.routingHistory.pop();
        }
      }

      return Object.keys(extractedData).length === 0 ? null : (extractedData as K);
    }
    return null;
  }

  public navigateToPreviousRoute(
    backRoute: string = null,
    navigationState: {
      state: {
        [property: string]: any;
      };
    } = null
  ): void {
    this.navigatingBack = true;

    if (this.routingHistory[this.routingHistory.length - 1]?.route === this.router.url) {
      this.routingHistory.pop();
    }

    Object.keys(sessionStorage).forEach((sessionStorageKey: string) => {
      if (sessionStorageKey.includes(this.router.url)) {
        sessionStorage.removeItem(sessionStorageKey);
      }
    });

    if (this.hasPreviousRoute()) {
      this.router.navigateByUrl(this.getPreviousRoute());
    } else if (backRoute) {
      navigationState ? this.router.navigate([backRoute], navigationState) : this.router.navigateByUrl(backRoute);
    } else if (this.initialRoute) {
      this.router.navigateByUrl(this.initialRoute);
      this.initialRoute = null;
    } else {
      this.location.back();
    }
  }

  public navigateToNextRouteWithPartialState<K>(data: K, nextRoute: string, queryParams: Params = null): void {
    this.savePartialStateData(data, this.router.url);

    if (queryParams) {
      this.router.navigate([nextRoute], {
        queryParams
      });
    } else {
      this.router.navigateByUrl(nextRoute);
    }
  }

  public setNewItemOrReopenDialogIfPresent(
    loadNewItem: (newItemState: NavigationNewItemData, dialogState?: DialogComponentData) => void,
    reopenDialog: (dialogState: DialogComponentData) => void
  ): void {
    const emptyNewItemState = {newItemId: null, newItemNames: null} as NavigationNewItemData;
    const newItemState = this.getPartialState<NavigationNewItemData>(Object.keys(emptyNewItemState));

    const emptyDialogState = {dialogComponent: null} as DialogComponentData;
    const dialogState = this.getPartialState<DialogComponentData>(Object.keys(emptyDialogState));
    let newItemLoaded = false;

    if (!AssertionUtils.isNullOrUndefined(newItemState) && !ObjectUtils.isDeepEqual(newItemState, emptyNewItemState)) {
      newItemLoaded = true;
      loadNewItem(newItemState, dialogState);
    }

    if (!AssertionUtils.isNullOrUndefined(dialogState) && !ObjectUtils.isDeepEqual(dialogState, emptyDialogState) && !newItemLoaded) {
      reopenDialog(dialogState);
    }
  }

  public isNextRouteRelatedToCurrent(currentRouteLeafKey: RouteLeaf, nextRoute: string): boolean {
    return !!LinkedRouteUtils.paths.get(currentRouteLeafKey)?.find((routeLeaf: RouteLeaf) => {
      return this.isRoutePathMatching(nextRoute, routeLeaf);
    });
  }

  public hasPreviousRoute(): boolean {
    const previousRoute = this.getPreviousRoute();

    return this.routingHistory.length > 0 && !AssertionUtils.isNullOrUndefined(previousRoute) && previousRoute !== this.currentRoute;
  }

  private getPreviousRoute(): string {
    return this.routingHistory[this.routingHistory.length - 1]?.route;
  }

  private getCurrentRouteForSavingState(): string {
    return this.previousRoute ?? this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
  }

  private getNextRouteForSavingState(): string {
    return this.currentRoute ?? this.router.url;
  }

  private hasState(): boolean {
    return this.routingHistory[this.routingHistory.length - 1]?.route === this.router.url;
  }

  private clearAllStates(): void {
    if (this.router.url !== this.navigationHistory.getCurrentUrl()) {
      this.clearScrollPositionInSessionStorage();
      this.routingHistory = [];
      this.initialRoute = null;
    }
  }

  private clearScrollPositionInSessionStorage(): void {
    Object.keys(sessionStorage).forEach((sessionStorageKey: string) => {
      if (sessionStorageKey.includes('gridState.') || sessionStorageKey.includes(NavigationHelperService.SESSION_STORAGE_KEY)) {
        sessionStorage.removeItem(sessionStorageKey);
      }
    });
  }

  public getCurrentRouteLeaf(route: string): RouteLeaf {
    return [...LinkedRouteUtils.paths.keys()].find((routeLeaf: RouteLeaf) => this.isRoutePathMatching(route, routeLeaf));
  }

  private isRoutePathMatching(route: string, routeToMatch: RouteLeaf): boolean {
    if (AssertionUtils.isNullOrUndefined(route) || AssertionUtils.isNullOrUndefined(routeToMatch)) {
      return false;
    }

    const patternParts = routeToMatch.absolutePath
      .replace(/:\w+/g, '*')
      .split('/')
      .map((part: string) => (part === '*' ? '.*' : part));

    return new RegExp(`^${patternParts.join('\\/')}$`).test(window?.location?.origin ? route.replace(window.location.origin, '') : route);
  }

  private savePartialStateData<K>(data: K, route: string): void {
    const currentRouteData = this.routingHistory.find((history: {route: string; data: T}) => history.route === route);

    if (currentRouteData) {
      currentRouteData.data = {...data, ...currentRouteData.data};
    } else {
      this.routingHistory.push({route, data: data as any});
    }

    if (AssertionUtils.isNullOrUndefined(this.initialRoute)) {
      this.initialRoute = this.navigationHistory.getPreviousUrl();
    }
  }
}
