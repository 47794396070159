<section class="flex-column full-height">
  <vdw-header
    [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'PRODUCTION_ORDER.CUSTOM_SETTINGS.NAME_GENERATION' | bmsTranslate | lowercase}"
    svgIcon="select-blue"
    [canShowClose]="true"
  />
  <article class="dialog-content full-height flex-column gap-24 overflow-hidden">
    <mat-card class="full-height overflow-hidden">
      <mat-card-header class="header flex-start-center">
        <mat-card-title class="antialiased">
          <h3 class="font-weight-semibold">{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.NAME_GENERATION' | bmsTranslate }}</h3>
        </mat-card-title>
        <div class="flex-end-center flex-grow">
          <button mat-icon-button class="mat-icon-action-button-lg-with-border" (click)="addDefaultPartForm()">
            <mat-icon svgIcon="add"></mat-icon>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content class="full-height overflow-auto">
        @if (partsForm?.length > 0) {
          <div class="properties-header">
            <h4 class="font-weight-semibold">{{ 'GENERAL.PLACEHOLDER.PATTERN_NAME' | bmsTranslate }}</h4>
          </div>
          @for (partForm of partsForm.controls; track $index) {
            <div
              #container
              class="pattern-item"
              [formGroup]="partForm"
              [appRunEventsOutsideAngular]="['dragover']"
              (outSideAngularEvent)="onDragOver($event, $index)"
              (dragleave)="onDraggedPatternLeavesPatternItem($event)"
              (drop)="onDraggedPatternDroppedOnPatternItem($event, $index)"
            >
              <div
                #element
                class="pattern-item-container"
                draggable="true"
                [appRunEventsOutsideAngular]="['drag']"
                (dragstart)="onDragStart($event, element, container, $index)"
                (dragend)="onDragEnd()"
                (outSideAngularEvent)="onDrag($event)"
              >
                <div class="pattern-name-with-drag-handle">
                  <mat-icon class="cursor-grab" svgIcon="drag-handle"></mat-icon>
                  <mat-form-field class="no-subscript flex-grow">
                    <mat-select formControlName="property" disableOptionCentering>
                      @for (nameGenerationProperty of nameGenerationProperties; track nameGenerationProperty) {
                        <mat-option [attr.value]="nameGenerationProperty" [value]="nameGenerationProperty">
                          {{ getPatternName(nameGenerationProperty) }}
                        </mat-option>
                      }
                    </mat-select>
                    <mat-error *vdwCanShowErrorForFormControl="'property'; error: 'serialNumberInMiddle'">
                      <vdw-ellipsis-label [text]="'GENERAL.PLACEHOLDER.SERIAL_NUMBER_SEQUENCE_ERROR' | bmsTranslate" matTooltipPosition="below"></vdw-ellipsis-label>
                    </mat-error>
                  </mat-form-field>
                </div>

                @if (partTypeEnum[partForm.value.property] === partTypeEnum.ALPHABETIC_SERIAL_NUMBER || partTypeEnum[partForm.value.property] === partTypeEnum.NUMERIC_SERIAL_NUMBER) {
                  <ng-container formGroupName="serialNumber">
                    <mat-form-field class="flex-1">
                      <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.MAX_LENGTH' | bmsTranslate }}</mat-label>
                      <input formControlName="maxLength" matInput vdwL10nDecimal min="0" type="number" />
                      <mat-error *vdwCanShowErrorForFormControl="'serialNumber.maxLength'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
                      <mat-error *vdwCanShowErrorForFormControl="'serialNumber.maxLength'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
                      <mat-error *vdwCanShowErrorForFormControl="'serialNumber.maxLength'; error: 'max'">{{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: '5'} }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="flex-1">
                      <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.START_VALUE' | bmsTranslate }}</mat-label>
                      <input formControlName="startValue" matInput type="text" />
                      <mat-error *vdwCanShowErrorForFormControl="'serialNumber.startValue'; error: 'maxlength'">
                        {{ 'GENERAL.ERRORS.LENGTH_LIMIT' | bmsTranslate: {max: partForm.value.serialNumber.maxLength ?? '5'} }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="flex-1">
                      <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.STEP_SIZE' | bmsTranslate }}</mat-label>
                      <input formControlName="stepSize" matInput vdwL10nDecimal min="0" type="number" />
                      <mat-error *vdwCanShowErrorForFormControl="'serialNumber.stepSize'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
                    </mat-form-field>
                  </ng-container>
                } @else if (partTypeEnum[partForm.value.property] === partTypeEnum.CUSTOM_TEXT) {
                  <ng-container formGroupName="customText">
                    <mat-form-field class="flex-1">
                      <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.VALUE' | bmsTranslate }}</mat-label>
                      <input formControlName="customText" matInput type="text" />
                      <mat-error *vdwCanShowErrorForFormControl="'customText.customText'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
                      <mat-error *vdwCanShowErrorForFormControl="'customText.customText'; error: 'maxlength'">{{ 'GENERAL.ERRORS.LENGTH_LIMIT' | bmsTranslate: {max: '20'} }}</mat-error>
                    </mat-form-field>
                  </ng-container>
                } @else if (partForm.value.property) {
                  <!--type == ENTITY_PROPERTY-->
                  <ng-container formGroupName="entityProperty">
                    <mat-form-field class="flex-1">
                      <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.LABEL' | bmsTranslate }}</mat-label>
                      <mat-select formControlName="cutFrom" disableOptionCentering>
                        @for (cutFrom of cutFromEnum | keyvalue; track cutFrom) {
                          <mat-option [attr.value]="cutFrom.key" [value]="cutFrom.key">
                            {{ 'GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.' + cutFrom.value | bmsTranslate }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    @if (partForm.value.entityProperty.cutFrom !== cutFromEnum.NONE) {
                      <div class="cut-from-left-or-right">
                        <mat-form-field class="flex-1">
                          <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.START_CHARACTER' | bmsTranslate }}</mat-label>
                          <input formControlName="startCharacter" matInput vdwL10nDecimal min="0" type="number" />
                          <mat-error *vdwCanShowErrorForFormControl="'entityProperty.startCharacter'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
                          <mat-error *vdwCanShowErrorForFormControl="'entityProperty.startCharacter'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
                        </mat-form-field>
                        <mat-form-field class="flex-1">
                          <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.LENGTH' | bmsTranslate }}</mat-label>
                          <input formControlName="length" matInput vdwL10nDecimal min="0" type="number" />
                          <mat-error *vdwCanShowErrorForFormControl="'entityProperty.length'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
                          <mat-error *vdwCanShowErrorForFormControl="'entityProperty.length'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
                          <mat-error *vdwCanShowErrorForFormControl="'entityProperty.length'; error: 'max'">{{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: '20'} }}</mat-error>
                        </mat-form-field>
                      </div>
                    }
                  </ng-container>
                }

                <div class="flex-start-center">
                  <button mat-icon-button class="mat-icon-action-button-lg-with-border" type="button" (click)="removePartForm($index)">
                    <mat-icon svgIcon="trash"></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          }
        } @else {
          <vdw-nodata-overlay
            class="no-data-overlay"
            titleParam="GENERAL.PLACEHOLDER.NAME_PATTERN"
            [shouldShowFilterNoResult]="false"
            [hideDescription]="true"
            [scale]="0.6"
            [actionsParam]="noDataOverlayActions"
          />
        }
      </mat-card-content>
    </mat-card>
    @if (patternPreview().length > 0) {
      <div class="flex-column gap-8">
        <h4 class="font-weight-semibold">{{ 'GENERAL.PREVIEW' | bmsTranslate }}</h4>
        <h4 class="font-weight-regular">{{ patternPreview() }}</h4>
      </div>
    }
  </article>
  <vdw-footer [centered]="true" (chooseEvent)="confirmNameGeneratorParts()" />
</section>
