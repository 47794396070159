import {Directive, ElementRef, Inject, OnChanges, OnInit, Renderer2} from '@angular/core';
import {LoadImageDirective} from '@application/directives/load-image.directive';
import {FinishingTemplates, FINISHING_TEMPLATES} from '@infrastructure/http/finishing-template/finishing-templates';
import {BlobUtils} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {fromEvent} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[appLoadImageLabel]'
})
export class LoadImageLabelDirective extends LoadImageDirective implements OnInit, OnChanges {
  public constructor(
    @Inject(FINISHING_TEMPLATES) private readonly finishingTemplates: FinishingTemplates,
    renderer: Renderer2,
    elementRef: ElementRef<HTMLElement>
  ) {
    super(renderer, elementRef);
  }

  public ngOnChanges(): void {
    if (!isNil(this.imageId)) {
      fromEvent(this.elementRef.nativeElement, 'load')
        .pipe(first())
        .subscribe(() => {
          this.downloadAndSetImage(this.imageId);
        });
    }
  }

  protected setDownloadedImage(blob: Blob): void {
    BlobUtils.blobToImageData(blob).then((imageData: string) => {
      this.setImageFromData(imageData);
    });
  }

  protected downloadAndSetImage(imageId: string): void {
    this.finishingTemplates
      .getImageForLabels(imageId)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((blob: Blob) => {
        this.setDownloadedImage(blob);
      });
  }
}
