export class AutomaticStopInput {
  private _stopTextId: number;
  private _inputMask: string;

  public constructor(stopTextId: number, inputMask: string) {
    this._stopTextId = stopTextId;
    this._inputMask = inputMask;
  }

  public get stopTextId(): number {
    return this._stopTextId;
  }

  public set stopTextId(stopTextId: number) {
    this._stopTextId = stopTextId;
  }

  public get inputMask(): string {
    return this._inputMask;
  }

  public set inputMask(inputMask: string) {
    this._inputMask = inputMask;
  }

  public static fromJSON(automaticStopInputJSON: any): AutomaticStopInput {
    return new AutomaticStopInput(automaticStopInputJSON.stopTextId, automaticStopInputJSON.inputMask);
  }

  public toJSON(): JSON {
    return {
      stopTextId: this._stopTextId,
      inputMask: this._inputMask
    } as any as JSON;
  }
}
