import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {PreviewType} from '@presentation/pages/textile-data/creel/add/preview-type';
import {TranslateService} from '@vdw/angular-component-library';
import {cloneDeep, isEqual} from 'lodash-es';

@Component({
  selector: 'app-creel-mapping',
  templateUrl: './creel-mapping.component.html',
  styleUrls: ['./creel-mapping.component.scss']
})
export class CreelMappingComponent implements OnChanges {
  @Input() public coloredYarnSetsWithStartDent: OverviewListColoredYarnSetWithStartDent[];
  @Input() public creelMap: CreelMap[];
  @Input() public readOnly: boolean;
  @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() public save: EventEmitter<CreelMap[]> = new EventEmitter<CreelMap[]>();
  public creelPreviewType: PreviewType = PreviewType.COLOR;

  private newCreelMap: CreelMap[];

  public constructor(private readonly translate: TranslateService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('creelMap' in changes && changes.creelMap.isFirstChange()) {
      this.newCreelMap = cloneDeep(this.creelMap);
    }
  }

  public isCurrentViewModeYarnType(): boolean {
    return isEqual(this.creelPreviewType, PreviewType.YARN_TYPE);
  }

  public onCreelTypeViewModeChange(matSlideToggleChange: MatSlideToggleChange): void {
    this.creelPreviewType = matSlideToggleChange.checked ? PreviewType.YARN_TYPE : PreviewType.COLOR;
  }

  public cancelAction(): void {
    this.cancel.emit();
  }

  public getColoredYarnSetCreelMap(index: number): CreelMap {
    return cloneDeep(this.newCreelMap[index]);
  }

  public updateAction(event: CreelMap, sourceIndex: number): void {
    this.newCreelMap[sourceIndex] = event;
  }

  public saveAction(): void {
    this.save.emit(this.newCreelMap);
  }

  public getTabAction(): string {
    if (this.readOnly) {
      return this.translate.instant('MACHINE.DETAILS.CREEL_MAPPING.TITLE');
    }
    return this.translate.instant('GENERAL.ACTIONS.EDIT_OBJECT', {
      object: this.translate.instant('MACHINE.DETAILS.CREEL_MAPPING.TITLE').toLowerCase()
    });
  }
}
