export class ErrorDetails {
  private _errorKey: string;
  private _errorMessage: string;

  public constructor(errorKey: string, errorMessage: string) {
    this._errorKey = errorKey;
    this._errorMessage = errorMessage;
  }

  public get errorKey(): string {
    return this._errorKey;
  }

  public set errorKey(errorKey: string) {
    this._errorKey = errorKey;
  }

  public get errorMessage(): string {
    return this._errorMessage;
  }

  public set errorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }
}
