@if (!entry.shouldHide) {
  <button mat-button class="menu-item" vdwElementPressed [class.active]="isActive" [class.expanded]="entry.isExpanded" (click)="menuItemClicked.emit($event)">
    <div class="flex-row flex-start-center full-width gap-6">
      @if (hasIcon()) {
        <mat-icon class="icon" *ngIf="hasIcon()" [style.min-width.px]="iconWidthInPx" [svgIcon]="entry.icon"></mat-icon>
      }
      <vdw-ellipsis-label class="text" [text]="entry.title"></vdw-ellipsis-label>
      @if (hasChildEntries()) {
        <mat-icon [style.min-width.px]="iconWidthInPx" class="arrow-right" [svgIcon]="entry.isExpanded ? 'solid-expand-more' : 'solid-keyboard-arrow-right'"></mat-icon>
      } @else if (hasLink()) {
        <div class="star-container" [class.favorited]="entry.isFavorited">
          <mat-icon
            (click)="favoriteClicked($event)"
            [style.min-width.px]="iconWidthInPx"
            [style.max-width.px]="iconWidthInPx"
            class="star-icon"
            [svgIcon]="entry.isFavorited ? 'solid-star' : 'solid-star-rate'"
          ></mat-icon>
        </div>
      }
    </div>
  </button>
}
