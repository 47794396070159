<ng-container *ngIf="patternVisualisations | async as patternVisualisations">
  <div *ngIf="patternVisualisations.length" #container class="container full-height full-width flex-row flex-start-center">
    <ng-container *ngFor="let patternVisualisation of patternVisualisations; let patternIndex = index">
      <ng-container *ngIf="(numberOfPatternVisualisationsToShow | async) > patternIndex">
        <div #thumbnailsContainer class="full-height full-width thumbnails-container flex-row flex-start-center">
          <ng-template #placeholder>
            <div class="placeholder-wrapper">
              <mat-icon svgIcon="design-library" class="placeholder-icon"></mat-icon>
            </div>
          </ng-template>
          <div class="pattern-visualisation-thumbnail-wrapper cursor-zoom-in" *ngIf="patternVisualisation | containsVisualisationData; else placeholder">
            <app-pattern-preview (click)="openWeavePatternPreviewDialog(patternIndex)" [patternVisualisation]="patternVisualisation" viewMode="thumbnail"></app-pattern-preview>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <button #plusButton mat-stroked-button (click)="openWeavePatternPreviewDialog(numberOfPatternVisualisationsToShow.value)" [hidden]="(numberOfHiddenPatternVisualisations | async) <= 0">
      +{{ numberOfHiddenPatternVisualisations | async }}
    </button>
  </div>
</ng-container>
