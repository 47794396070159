import {Component} from '@angular/core';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {isEmpty} from 'lodash-es';

@Component({
  templateUrl: './finishing-labels-indication.component.html'
})
export class FinishingLabelsIndicationComponent implements ICellRendererAngularComp {
  private finishing: OverviewListFinishing;

  public agInit(params: ICellRendererParams): void {
    this.finishing = params.data;
  }

  public refresh(params: any): boolean {
    return false;
  }

  public hasLabelsAdded(): boolean {
    return !isEmpty(this.finishing.labels);
  }
}
