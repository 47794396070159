import {DOCUMENT} from '@angular/common';
import {ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, Renderer2, signal, ViewChild, WritableSignal} from '@angular/core';
import {FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NameGeneratorPart} from '@domain/custom-settings/name-generator-part';
import {NameGeneratorPartType} from '@domain/custom-settings/name-generator-part-type.enum';
import {CustomTextPart} from '@domain/custom-settings/name-generator-parts/custom-text-part';
import {EntityPropertyPart} from '@domain/custom-settings/name-generator-parts/entity-property-part';
import {SerialNumberPart} from '@domain/custom-settings/name-generator-parts/serial-number-part';
import {CutFrom} from '@domain/name-generator/enums/cut-from.enum';
import {NameGenerationProperty} from '@domain/name-generator/name-generation-property';
import {AssertionUtils, BaseComponent, FormValidationHelper, OverlayActionsService, TranslateService} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {getPreviewText} from '../name-generation-pattern-preview';
import {NameGenerationPartForm} from './name-generation-part-form';
import {SelectNameGenerationPartsData} from './select-name-generation-parts-data';

@Component({
  templateUrl: './select-name-generation-parts.component.html',
  styleUrl: '../../name-generation-pattern/select-name-generation-pattern/select-name-generation-pattern.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectNameGenerationPartsComponent extends BaseComponent implements OnInit {
  @ViewChild('container') protected partsContainer: ElementRef<HTMLElement>;

  protected partTypeEnum = NameGeneratorPartType;
  protected cutFromEnum = CutFrom;
  protected partsForm: FormArray<NameGenerationPartForm> = new FormArray([]);
  protected noDataOverlayActions = [
    {
      key: 'ADD_NAME_PATTERN',
      titleKey: this.translate.instant('GENERAL.ACTIONS.ADD_OBJECT', {object: this.translate.instant('GENERAL.PLACEHOLDER.NAME_PATTERN', {count: 1})}),
      isPrimary: true
    }
  ];

  protected nameGenerationProperties: string[];
  protected separator: string;
  protected parts: NameGeneratorPart[];
  protected patternPreview: WritableSignal<string> = signal('');

  // dragging
  protected dragging = false;
  private draggedIndex = -1;
  private dropIndicator: HTMLElement;
  private isOnBottomHalfOfRow = false;
  private dragPreviewElementDOMRect: DOMRect;
  private baseElementForDragPreview: HTMLElement;
  private dragPreviewElement: HTMLElement;
  private readonly CLASS_NAME_FOR_DROP_INDICATOR = 'drop-indicator';
  private readonly CLASS_NAME_FOR_PATTERN_ITEM = 'pattern-item';
  private readonly CLASS_NAME_FOR_DRAGGED_ELEMENT = 'dragging';
  private readonly DRAG_PREVIEW_ELEMENT_WIDTH = 248;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: SelectNameGenerationPartsData,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialogRef: MatDialogRef<SelectNameGenerationPartsComponent>,
    private readonly translate: TranslateService,
    private readonly overlayActionsService: OverlayActionsService,
    private readonly renderer: Renderer2
  ) {
    super();

    this.nameGenerationProperties = data.nameGenerationProperties;
    this.separator = data.namePlaceholderSeparator;
    this.parts = data.nameGeneratorParts;
  }

  public ngOnInit(): void {
    this.overlayActionsService.actionTriggeredEmitter.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((actionKey: string) => {
      if (actionKey === this.noDataOverlayActions[0].key) {
        this.addDefaultPartForm();
      }
    });

    this.parts?.forEach((part: NameGeneratorPart) => this.partsForm.push(this.getPartForm(part)));
    this.patternPreview.set(getPreviewText(this.parts, this.separator, this.translate));

    this.partsForm.valueChanges.subscribe(() => {
      this.patternPreview.set(getPreviewText(this.getCurrentNameGeneratorParts(), this.separator, this.translate));
    });
  }

  protected getPatternName(nameGenerationProperty: string): string {
    const propertyTranslationKey = NameGenerationProperty.getTranslationKey(nameGenerationProperty);
    return propertyTranslationKey ? this.translate.instant(propertyTranslationKey, {count: 1}) : nameGenerationProperty;
  }

  protected addDefaultPartForm(): void {
    this.partsForm.push(this.getDefaultPartForm());
  }

  protected removePartForm(index: number): void {
    this.partsForm.removeAt(index);
  }

  private getDefaultPartForm(type: NameGeneratorPartType = NameGeneratorPartType.CUSTOM_TEXT): NameGenerationPartForm {
    const newPartForm: NameGenerationPartForm = new FormGroup({
      property: new FormControl<string>(type),
      entityProperty: new FormGroup({
        cutFrom: new FormControl<CutFrom>(CutFrom.NONE),
        startCharacter: new FormControl(null, [Validators.required, Validators.min(1)]),
        length: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(20)])
      }),
      customText: new FormGroup({
        customText: new FormControl(null, [Validators.required, Validators.maxLength(20)])
      }),
      serialNumber: new FormGroup({
        maxLength: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(5)]),
        startValue: new FormControl(null, Validators.maxLength(5)),
        stepSize: new FormControl(null, Validators.min(1))
      })
    });

    newPartForm.controls.property.addValidators(this.serialNumberNotInMiddleValidator(newPartForm));
    this.disableFormGroups(newPartForm);

    newPartForm.controls.property.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((property: string) => {
      newPartForm.reset({property: property, entityProperty: {cutFrom: CutFrom.NONE}}, {emitEvent: false});
      this.disableFormGroups(newPartForm);
    });
    newPartForm.controls.entityProperty.controls.cutFrom.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((cutFrom: CutFrom) => {
      this.disablePropertyValueControls(newPartForm, cutFrom);
    });
    newPartForm.controls.serialNumber.controls.maxLength.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((maxLenght: number) => {
      newPartForm.controls.serialNumber.controls.startValue.setValidators(Validators.maxLength(maxLenght ?? 5));
      newPartForm.controls.serialNumber.controls.startValue.updateValueAndValidity({emitEvent: false});
    });
    newPartForm.controls.serialNumber.controls.startValue.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((startValue: string) => {
      if (NameGeneratorPartType[newPartForm.value.property] === NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER) {
        newPartForm.controls.serialNumber.controls.startValue.patchValue(startValue.replace(/[^A-Za-z]/g, '').toUpperCase(), {emitEvent: false});
      } else if (NameGeneratorPartType[newPartForm.value.property] === NameGeneratorPartType.NUMERIC_SERIAL_NUMBER) {
        newPartForm.controls.serialNumber.controls.startValue.patchValue(startValue.replace(/\D/g, ''), {emitEvent: false});
      }
    });

    return newPartForm;
  }

  private disableFormGroups(partForm: NameGenerationPartForm): void {
    switch (NameGeneratorPartType[partForm.value.property]) {
      case NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER:
      case NameGeneratorPartType.NUMERIC_SERIAL_NUMBER:
        partForm.controls.serialNumber.enable({emitEvent: false});
        partForm.controls.entityProperty.disable({emitEvent: false});
        partForm.controls.customText.disable({emitEvent: false});
        break;
      case NameGeneratorPartType.CUSTOM_TEXT:
        partForm.controls.serialNumber.disable({emitEvent: false});
        partForm.controls.entityProperty.disable({emitEvent: false});
        partForm.controls.customText.enable({emitEvent: false});
        break;
      default:
        partForm.controls.serialNumber.disable({emitEvent: false});
        partForm.controls.entityProperty.enable({emitEvent: false});
        partForm.controls.customText.disable({emitEvent: false});

        this.disablePropertyValueControls(partForm, partForm.value.entityProperty.cutFrom);
        break;
    }
  }

  private disablePropertyValueControls(partForm: NameGenerationPartForm, cutFrom: CutFrom): void {
    if (cutFrom === CutFrom.NONE) {
      partForm.controls.entityProperty.controls.startCharacter.disable({emitEvent: false});
      partForm.controls.entityProperty.controls.length.disable({emitEvent: false});
    } else {
      partForm.controls.entityProperty.controls.startCharacter.enable({emitEvent: false});
      partForm.controls.entityProperty.controls.length.enable({emitEvent: false});
    }
  }

  private serialNumberNotInMiddleValidator(partForm: NameGenerationPartForm): ValidatorFn {
    return (property: FormControl<string>): ValidationErrors | null => {
      if (property.value !== NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER && property.value !== NameGeneratorPartType.NUMERIC_SERIAL_NUMBER) {
        return null;
      }
      const index = this.partsForm?.controls.findIndex((form: NameGenerationPartForm) => form === partForm);
      return index !== 0 && index !== this.partsForm?.length - 1 ? {serialNumberInMiddle: true} : null;
    };
  }

  private getPartForm(part: NameGeneratorPart): NameGenerationPartForm {
    let partForm = this.getDefaultPartForm(part.type);
    switch (part.type) {
      case NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER:
      case NameGeneratorPartType.NUMERIC_SERIAL_NUMBER:
        const serialNumberPart = part as SerialNumberPart;
        partForm.patchValue({
          serialNumber: {
            maxLength: serialNumberPart.maxLength,
            startValue: serialNumberPart.startValue,
            stepSize: serialNumberPart.stepSize
          }
        });
        break;
      case NameGeneratorPartType.PROPERTY_VALUE:
        const entityPropertyPart = part as EntityPropertyPart;
        partForm.patchValue({
          property: entityPropertyPart.name,
          entityProperty: {
            cutFrom: entityPropertyPart.cutFromDirection,
            startCharacter: entityPropertyPart.cutStartCharacter,
            length: entityPropertyPart.cutLength
          }
        });
        break;
      case NameGeneratorPartType.CUSTOM_TEXT:
        const customTextPart = part as CustomTextPart;
        partForm.patchValue({
          customText: {
            customText: customTextPart.customText
          }
        });
        break;
    }

    return partForm;
  }

  protected confirmNameGeneratorParts(): void {
    const isValid = new FormValidationHelper().checkForms(this.partsForm.controls, this.document, undefined, true);

    if (isValid) {
      this.dialogRef.close(this.getCurrentNameGeneratorParts());
    }
  }

  private getCurrentNameGeneratorParts(): NameGeneratorPart[] {
    return this.partsForm.controls.map((partForm: NameGenerationPartForm) => {
      if (
        NameGeneratorPartType[partForm.value.property] === NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER ||
        NameGeneratorPartType[partForm.value.property] === NameGeneratorPartType.NUMERIC_SERIAL_NUMBER
      ) {
        return new SerialNumberPart(
          NameGeneratorPartType[partForm.value.property],
          partForm.value.serialNumber.maxLength,
          partForm.value.serialNumber.startValue,
          partForm.value.serialNumber.stepSize
        );
      } else if (NameGeneratorPartType[partForm.value.property] === NameGeneratorPartType.CUSTOM_TEXT) {
        return new CustomTextPart(partForm.value.customText.customText);
      } else if (!AssertionUtils.isNullOrUndefined(partForm.value.property)) {
        const cutFromNone = partForm.value.entityProperty.cutFrom === CutFrom.NONE;
        return new EntityPropertyPart(
          partForm.value.property,
          partForm.value.entityProperty.cutFrom,
          cutFromNone ? null : partForm.value.entityProperty.startCharacter,
          cutFromNone ? null : partForm.value.entityProperty.length
        );
      }
    });
  }

  // dragging logic, seems quite convoluted, update when switching to new dialog routing
  public onDragOver(event: any, index: number): void {
    const target = event.target as HTMLElement;
    if (!target.classList.contains('pattern-item-container')) {
      return;
    }
    event.preventDefault();

    const draggedItemDifferentFromTargetItem = index !== this.draggedIndex;
    const isMouseOnBottomHalfOfTarget = this.isMouseOnBottomHalfOfRow(target.getBoundingClientRect(), event.y);
    const isMouseOnBottomHalfOfPreviousItem = isMouseOnBottomHalfOfTarget && index === this.draggedIndex - 1;
    const isMouseOnTopHalfOfNextItem = !isMouseOnBottomHalfOfTarget && index === this.draggedIndex + 1;

    if (draggedItemDifferentFromTargetItem && !isMouseOnBottomHalfOfPreviousItem && !isMouseOnTopHalfOfNextItem) {
      this.addDropIndicator(target, event.y);
    }
  }

  public onDraggedPatternLeavesPatternItem(event: any): void {
    event.preventDefault();

    if (AssertionUtils.isNullOrUndefined(event.relatedTarget) || !(event.relatedTarget as HTMLElement).classList.contains(this.CLASS_NAME_FOR_PATTERN_ITEM)) {
      this.removeDropIndicator();
    }
  }

  public onDraggedPatternDroppedOnPatternItem(event: any, index: number): void {
    event.preventDefault();

    const currentNameGenerationPatternForm = this.partsForm.at(this.draggedIndex);
    this.partsForm.removeAt(this.draggedIndex);

    if (this.draggedIndex < index && !this.isOnBottomHalfOfRow) {
      index -= 1;
    }

    this.partsForm.insert(index, currentNameGenerationPatternForm);
  }

  public onDragStart(event: DragEvent, baseElementForDragPreview: HTMLElement, containerElement: HTMLElement, index: number): void {
    this.dragPreviewElementDOMRect = this.createDragPreviewElement(event, baseElementForDragPreview, containerElement, index);
    this.baseElementForDragPreview = baseElementForDragPreview;

    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.renderer.removeStyle(this.dragPreviewElement, 'max-width');
    this.renderer.removeStyle(this.dragPreviewElement, 'min-width');
    this.renderer.setStyle(baseElementForDragPreview, 'opacity', 0.5);
    this.renderer.setStyle(this.dragPreviewElement, 'width', `${this.DRAG_PREVIEW_ELEMENT_WIDTH}px`);
    this.renderer.appendChild(document.body, this.dragPreviewElement);
    this.draggedIndex = index;

    event.stopPropagation();
  }

  public onDragEnd(): void {
    this.removeDragPreviewElement();
    this.removeDropIndicator();
    this.renderer.setStyle(this.baseElementForDragPreview, 'opacity', 1);
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.dragging = false;
  }

  public onDrag(event: DragEvent): void {
    this.dragging = true;
    if (event.screenX !== 0 && event.screenY !== 0) {
      this.renderer.setStyle(this.dragPreviewElement, 'transform', `translate3d(${event.x + 5}px, ${event.y - this.dragPreviewElementDOMRect.height / 2}px, 0)`);
    }
  }

  private isMouseOnBottomHalfOfRow(patternItemElementBoundingDOMRect: DOMRect, mouseYPosition: number): boolean {
    return mouseYPosition > patternItemElementBoundingDOMRect.top + patternItemElementBoundingDOMRect.height / 2;
  }

  private addDropIndicator(element: HTMLElement, eventY: number): void {
    if (AssertionUtils.isNullOrUndefined(this.dropIndicator)) {
      this.dropIndicator = this.renderer.createElement('hr');
      this.renderer.addClass(this.dropIndicator, this.CLASS_NAME_FOR_DROP_INDICATOR);
      this.renderer.appendChild(this.partsContainer.nativeElement, this.dropIndicator);
    }

    const elementBoundingDOMRect = element.getBoundingClientRect();
    this.isOnBottomHalfOfRow = this.isMouseOnBottomHalfOfRow(elementBoundingDOMRect, eventY);

    const width = elementBoundingDOMRect.width;
    const yPosition = this.isOnBottomHalfOfRow ? elementBoundingDOMRect.y + elementBoundingDOMRect.height : elementBoundingDOMRect.y;

    this.renderer.setStyle(this.dropIndicator, 'width', `${width}px`);
    this.renderer.setStyle(this.dropIndicator, 'transform', `translate3d(${elementBoundingDOMRect.x}px, ${yPosition}px, 0)`);
  }

  private removeDropIndicator(): void {
    if (this.dropIndicator != null) {
      this.renderer.removeChild(document.body, this.dropIndicator);
      this.dropIndicator = null;
    }
  }

  private createDragPreviewElement(event: any, baseElementForDragPreview: HTMLElement, containerElement: HTMLElement, index: number): DOMRect {
    const dragPreviewElementDOMRect = baseElementForDragPreview.getBoundingClientRect();
    this.dragPreviewElement = baseElementForDragPreview.cloneNode(true) as HTMLElement;

    const dragIcon = this.dragPreviewElement.querySelector('mat-icon');
    this.dragPreviewElement.replaceChildren(dragIcon);

    this.renderer.addClass(this.dragPreviewElement, 'pattern-drag-preview');
    this.renderer.addClass(containerElement, this.CLASS_NAME_FOR_DRAGGED_ELEMENT);
    this.renderer.setStyle(this.dragPreviewElement, 'transform', `translate3d(${dragPreviewElementDOMRect.left}px, -${dragPreviewElementDOMRect.height}px, 0)`);

    const dragPreviewTextElement = this.document.createElement('div');

    dragPreviewTextElement.style.paddingLeft = '12px';
    this.renderer.addClass(this.dragPreviewElement, 'b1');
    dragPreviewTextElement.textContent = this.getPatternName(this.partsForm.controls[index].value.property);
    this.dragPreviewElement.appendChild(dragPreviewTextElement);

    const dummyPreview = document.createElement('div');
    this.renderer.setStyle(dummyPreview, 'display', `none`);
    event.dataTransfer.setDragImage(dummyPreview, 0, 0);

    return dragPreviewElementDOMRect;
  }

  private removeDragPreviewElement(): void {
    if (this.dragPreviewElement != null) {
      this.renderer.removeChild(this.dragPreviewElement.parentElement, this.dragPreviewElement);
      this.renderer.removeClass(document.querySelector(`.${this.CLASS_NAME_FOR_DRAGGED_ELEMENT}`), this.CLASS_NAME_FOR_DRAGGED_ELEMENT);
      this.dragPreviewElement = null;
    }
  }
}
