import {Company} from '@domain/profile/company';
import {Subscription} from '@domain/profile/subscription';
import {each, filter, first, isEmpty, isEqual} from 'lodash-es';

export abstract class BaseChooseSubscription {
  public companies: Company[];
  public subscriptions: Subscription[];

  public selectedCompany: Company;
  public selectedSubscription: Subscription;

  public companySubscriptions: Map<string, Subscription[]> = new Map<string, Subscription[]>();

  public hasSubscriptions(): boolean {
    return !isEmpty(this.subscriptions);
  }

  public selectedCompanyChanged(selectedCompany: Company): void {
    this.selectedCompany = selectedCompany;
    this.selectedSubscription = first(this.companySubscriptions.get(this.selectedCompany.id));
  }

  public selectedSubscriptionChanged(selectedSubscription: Subscription): void {
    this.selectedSubscription = selectedSubscription;
  }

  protected initializeCompanySubscriptions(): void {
    each(this.companies, (company: Company) => {
      const subscriptions: Subscription[] = filter(this.subscriptions, (subscription: Subscription) => isEqual(subscription.company.id, company.id));

      this.companySubscriptions.set(company.id, subscriptions);
    });
  }

  protected abstract chooseSubscription(subscription: Subscription): void;
}
