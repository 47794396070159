<div class="flex-column gap-16">
  <div class="slide-toggle gap-8">
    <mat-label class="b2 font-weight-semibold">{{ 'PRODUCTION_ORDER.DESIGN_LIBRARY.SHOW_ONLY_PRESELECTED_ITEMS' | bmsTranslate }}</mat-label>
    <mat-slide-toggle (change)="onShowPreselectedItemsChanged($event.checked)" [checked]="showOnlyPreselectedItems"></mat-slide-toggle>
  </div>
  <div class="slide-toggle gap-8" *ngIf="canShowOnlyShowCompatibleColorSets()">
    <mat-label class="b2 font-weight-semibold">{{ 'PRODUCTION_ORDER.DESIGN_LIBRARY.SHOW_ONLY_COMPATIBLE_COLOR_SETS' | bmsTranslate }}</mat-label>
    <mat-slide-toggle (change)="onShowOnlyQueryCompatibleOrderlinesChanged($event.checked)" [checked]="showOnlyQueryCompatibleOrderlines"></mat-slide-toggle>
  </div>

  <button mat-stroked-button (click)="viewAllSettings()">{{ 'GENERAL.ACTIONS.VIEW_ALL_SETTINGS' | bmsTranslate }}</button>
</div>
