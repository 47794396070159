<article class="flex-row">
  <div class="flex-column">
    <div class="color-picker-skeleton-wrapper flex-column">
      <h4 class="font-weight-semibold color-picker-label">{{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.COLOR_PICKER' | bmsTranslate }}</h4>
      <div id="colorPickerContainer" [ngStyle]="getStyleForColorPickerContainer()" (click)="onColorPickerClick($event)">
        <div id="colorPicker" class="color-picker" [ngStyle]="getStyleForColorPicker()">
          <div class="color-picker-indicator" [ngStyle]="getStyleForColorPickerIndicator()"></div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="addColorForm">
    <fieldset [disabled]="!canEditColor()">
      <div class="flex-row name-and-preview-wrapper">
        <div class="form-field-skeleton-wrapper name-wrapper">
          <mat-form-field class="full-width">
            <mat-label>{{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.NAME' | bmsTranslate }}</mat-label>
            <input matInput type="text" formControlName="name" />
            <mat-hint *ngIf="canShowSuggestedColorName()" class="flex-row">
              <vdw-ellipsis-label class="overflow-hidden" [text]="'GENERAL.SUGGESTION' | bmsTranslate: {value: suggestedColorName}" matTooltipPosition="below"></vdw-ellipsis-label>
              <a class="url" (mousedown)="applySuggestionForColorName()">{{ 'GENERAL.ACTIONS.APPLY' | bmsTranslate }}</a>
            </mat-hint>
            <mat-error appDataCy="name-taken-error-message" *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'">
              {{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.NAME_DUPLICATE' | bmsTranslate }}
              <a class="url" [href]="getLoadLink()" (click)="onLoadClick($event)">{{ 'GENERAL.ACTIONS.LOAD' | bmsTranslate }}</a>
            </mat-error>
            <mat-error *vdwCanShowErrorForFormControl="'name'; error: 'required'">
              {{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.REQUIRED' | bmsTranslate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field-skeleton-wrapper preview-wrapper">
          <mat-form-field class="preview full-width">
            <mat-label>{{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.PREVIEW' | bmsTranslate }}</mat-label>
            <input matInput readonly [style.background]="getPreviewColorCode()" name="preview" />
          </mat-form-field>
        </div>
      </div>

      <div class="form-field-skeleton-wrapper preview-wrapper">
        <mat-form-field class="full-width">
          <mat-label>
            {{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.DESCRIPTION' | bmsTranslate }}
            <span class="optional">({{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.OPTIONAL' | bmsTranslate | lowercase }})</span>
          </mat-label>
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
      </div>

      <div class="rgb-wrapper flex-row" formGroupName="rgb">
        <div class="form-field-skeleton-wrapper rgb-input">
          <mat-form-field class="full-width">
            <mat-label> R </mat-label>
            <input matInput vdwL10nDecimal type="number" min="0" max="255" formControlName="red" />
          </mat-form-field>
        </div>

        <div class="form-field-skeleton-wrapper rgb-input">
          <mat-form-field class="full-width">
            <mat-label> G </mat-label>
            <input matInput vdwL10nDecimal type="number" min="0" max="255" formControlName="green" />
          </mat-form-field>
        </div>

        <div class="form-field-skeleton-wrapper rgb-input">
          <mat-form-field class="full-width">
            <mat-label> B </mat-label>
            <input matInput vdwL10nDecimal type="number" min="0" max="255" formControlName="blue" />
          </mat-form-field>
        </div>
      </div>

      <section formGroupName="hsl" class="flex-column">
        <article class="flex-column">
          <mat-label class="mat-select-placeholder">{{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.HUE' | bmsTranslate }} </mat-label>
          <div class="hsl-wrapper flex-row">
            <div class="slider-wrapper flex form-field-skeleton-wrapper">
              <mat-slider [disabled]="!canEditColor()" class="slider" vdwSliderTrackBackground [sliderTrackBackground]="getTrackBackgroundForHueSlider()" min="0" max="360" step="1" #ngSlider>
                <input matSliderThumb [value]="previewHSL.hue" (input)="onHueChange($event.target.value)" />
              </mat-slider>
            </div>

            <div class="hsl-value form-field-without-title-skeleton-wrapper">
              <mat-form-field class="hue-sat-lum-field full-width no-subscript">
                <input matInput vdwL10nDecimal type="number" min="0" max="360" step="1" formControlName="hue" />
              </mat-form-field>
            </div>
          </div>
        </article>

        <article class="flex-column">
          <mat-label class="mat-select-placeholder">{{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.SATURATION' | bmsTranslate }}</mat-label>
          <div class="hsl-wrapper flex-row">
            <div class="slider-wrapper flex form-field-skeleton-wrapper">
              <mat-slider [disabled]="!canEditColor()" class="slider" vdwSliderTrackBackground [sliderTrackBackground]="getTrackBackgroundForSaturationSlider()" min="0" max="100" step="1" #ngSlider>
                <input matSliderThumb [value]="previewHSL.saturation" (input)="onSaturationChange($event.target.value)" />
              </mat-slider>
            </div>

            <div class="hsl-value form-field-without-title-skeleton-wrapper">
              <mat-form-field class="hue-sat-lum-field full-width no-subscript">
                <input matInput vdwL10nDecimal type="number" min="0" max="100" step="1" formControlName="saturation" />
              </mat-form-field>
            </div>
          </div>
        </article>

        <article class="flex-column">
          <mat-label class="mat-select-placeholder">{{ 'ANGULAR_COMPONENT_LIBRARY.COLOR_PICKER.LUMINANCE' | bmsTranslate }}</mat-label>
          <div class="hsl-wrapper flex-row">
            <div class="slider-wrapper flex form-field-skeleton-wrapper">
              <mat-slider [disabled]="!canEditColor()" class="slider" vdwSliderTrackBackground [sliderTrackBackground]="getTrackBackgroundForLuminanceSlider()" min="0" max="100" step="1" #ngSlider>
                <input matSliderThumb [value]="previewHSL.luminance" (input)="onLuminanceChange($event.target.value)" />
              </mat-slider>
            </div>

            <div class="hsl-value form-field-without-title-skeleton-wrapper">
              <mat-form-field class="hue-sat-lum-field full-width no-subscript">
                <input matInput vdwL10nDecimal type="number" min="0" max="100" step="1" formControlName="luminance" />
              </mat-form-field>
            </div>
          </div>
        </article>
      </section>
    </fieldset>
  </form>
</article>
