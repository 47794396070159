import {Component, Input, OnInit} from '@angular/core';
import {Event, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {MenuItemEntry} from './menu-item/menu-item-entry.interface';
import {MenuTreeViewContextService} from './menu-tree-view-context.service';

@Component({
  selector: 'vdw-menu-tree-view',
  templateUrl: './menu-tree-view.component.html',
  styleUrls: ['./menu-tree-view.component.scss']
})
export class MenuTreeViewComponent implements OnInit {
  @Input() public isRoot = false;
  @Input() public iconWidthInPx = 24;
  @Input() public isFavorites = false;
  @Input() public entry: MenuItemEntry;

  public constructor(
    public readonly menuTreeViewContext: MenuTreeViewContextService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    if (this.isLinkActive()) {
      setTimeout(() => this.menuTreeViewContext.setActive(this.entry));
    }

    this.router.events
      .pipe(filter((event: RouterEvent | Event): boolean => event instanceof NavigationEnd && this.isLinkActive()))
      .subscribe((_: RouterEvent) => this.menuTreeViewContext.setActive(this.entry));
  }

  public isLinkActive(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.entry.link) && this.router.isActive(this.entry.link, {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'});
  }
}
