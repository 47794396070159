import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {YarnType} from '@domain/textile-data/yarn-type/yarn-type';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {TextileDataDetailComponent} from '@presentation/pages/textile-data/textile-data-detail/textile-data-detail.component';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {DialogBuilderFactoryService, ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE} from '@vdw/angular-component-library';
import {includes, isNil, isUndefined} from 'lodash-es';

@Component({
  templateUrl: './grid-yarn-set-preview.component.html',
  styleUrls: ['./grid-yarn-set-preview.component.scss']
})
export class GridYarnSetPreviewComponent implements AfterViewChecked {
  @ViewChild('yarntypes') public yarntypes: ElementRef;
  public yarnSet: OverviewListYarnSet;
  public showTooltip = false;

  public constructor(
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {}

  public ngAfterViewChecked(): void {
    this.showTooltip = this.canShowTooltip();
    this.changeDetectorRef.detectChanges();
  }

  public agInit(params: any): void {
    this.yarnSet = params.data;
  }

  public getYarnTypes(): string {
    return this.yarnSet
      .getUniqueYarnTypes()
      .map((yarnType: YarnType) => yarnType.name)
      .join(', ');
  }

  public showYarnSetDetails(event: MouseEvent): void {
    if (!this.isMobile()) {
      let source: Element = event.target as Element;
      while (!includes(source.classList, 'yarn-types') && !isNil(source.parentElement)) {
        source = source.parentElement;
      }

      this.dialogBuilderFactoryService.getBuilder().withClass('reposition-dialog').withWidth('706px').withMaxHeight('100%').openDialog(RepositionDialogComponent, {
        textileData: this.yarnSet,
        component: TextileDataDetailComponent,
        sourceElement: source,
        positionOfDialog: PositionOfDialog.LEFT
      });
    }
  }

  private canShowTooltip(): boolean {
    return !isUndefined(this.yarntypes) && this.yarntypes.nativeElement.offsetWidth < this.yarntypes.nativeElement.scrollWidth;
  }

  private isMobile(): boolean {
    return this.responsivenessViewMode.isPhone;
  }
}
