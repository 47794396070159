import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {ProductionScheduleDrawingViewMode} from '@domain/production-schedule/production-schedule-drawing-view-mode.enum';
import {DialogData} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {OrderlineSettingsData} from './order-lines-setting-data.interface';

@Component({
  templateUrl: './order-lines-settings.component.html',
  styleUrls: ['./order-lines-settings.component.scss']
})
export class OrderLinesCustomSettingsDialogComponent implements DialogData<OrderlineSettingsData>, OnInit {
  public form: UntypedFormGroup;
  public showOnlyPreselectedItems = false;
  public showOnlyQueryCompatibleOrderlines = true;
  private viewMode: ProductionScheduleDrawingViewMode;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.showOnlyPreselectedItems = data.showPreselectedItems;
    this.showOnlyQueryCompatibleOrderlines = data.showOnlyQueryCompatibleOrderlines;
    this.viewMode = data.viewMode;
  }

  public ngOnInit(): void {
    this.setFormFields(this.data.showOnlySelectedItems, this.data.showOnlyQueryCompatibleOrderlines);
  }

  public canShowOnlyShowCompatibleColorSets(): boolean {
    return this.viewMode === ProductionScheduleDrawingViewMode.ORDER_LINES || this.viewMode === ProductionScheduleDrawingViewMode.GROUPED_ORDER_LINES;
  }

  public onShowPreselectedItemsChanged(showPreSelectedItems: boolean): void {
    this.showOnlyPreselectedItems = showPreSelectedItems;
    this.form.patchValue({showPreselectedItems: showPreSelectedItems});
  }

  public onShowOnlyQueryCompatibleOrderlinesChanged(showOnlyQueryCompatibleOrderlines: boolean): void {
    this.showOnlyQueryCompatibleOrderlines = showOnlyQueryCompatibleOrderlines;
    this.form.patchValue({showOnlyQueryCompatibleOrderlines: showOnlyQueryCompatibleOrderlines});
  }

  public getValue(): Observable<{showPreselectedItems: boolean; showOnlyQueryCompatibleOrderlines: boolean; viewMode: ProductionScheduleDrawingViewMode}> {
    return this.form.valueChanges;
  }

  public viewAllSettings(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.productionOrder.settings.absolutePath);
  }

  private setFormFields(showOnlySelectedItems: boolean, showOnlyQueryCompatibleOrderlines: boolean): void {
    this.form = this.formBuilder.group({
      showPreselectedItems: [showOnlySelectedItems],
      showOnlyQueryCompatibleOrderlines: [showOnlyQueryCompatibleOrderlines]
    });
  }
}
