import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {getEquipmentTypeClassFromEquipmentType} from '@application/helper/machine/get-machine-type-class-from-machine-type';
import {IdName} from '@domain/id-name';
import {BaseMachineType} from '@domain/machine/base-machine-type';
import {ControllerType} from '@domain/machine/controller-type';
import {MachineType} from '@domain/machine/machine-type.enum';
import {Technology} from '@domain/machine/technology';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpMachineTypesService implements UniqueIdentifierObjectRepository {
  private readonly equipmentTypeToBackendRouteMap = new Map<MachineType, string>([
    [MachineType.WEAVING_MACHINE, 'weaving'],
    [MachineType.TUFT, 'tufting'],
    [MachineType.WINDER, 'winder'],
    [MachineType.DYEING, 'dyeing'],
    [MachineType.PLASTIC_MACHINE, 'plastic']
  ]);

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(equipmentType: MachineType): Observable<any[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machine-types/${this.equipmentTypeToBackendRouteMap.get(equipmentType)}`).pipe(
      map((equipmentTypesJSON: any) =>
        equipmentTypesJSON.map((equipmentTypeJSON: any) => {
          return getEquipmentTypeClassFromEquipmentType(equipmentType).fromJSON(equipmentTypeJSON);
        })
      )
    );
  }

  public getById(id: number, equipmentType: MachineType): Observable<any> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machine-types/${this.equipmentTypeToBackendRouteMap.get(equipmentType)}/${id}`).pipe(
      map((machineTypeJSON: any) => {
        return getEquipmentTypeClassFromEquipmentType(equipmentType).fromJSON(machineTypeJSON);
      })
    );
  }

  public save<T extends BaseMachineType>(machineType: T, equipmentType: MachineType): Observable<number> {
    return this.httpClient
      .post(`${this.backendConfiguration.getMachineEndpoint()}machine-types/${this.equipmentTypeToBackendRouteMap.get(equipmentType)}`, machineType.toJSON())
      .pipe(map((response: {id: number}) => response.id));
  }

  public getMachineTechnologies(equipmentType: MachineType): Observable<Technology[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machine-types/technologies/${equipmentType.toString()}`).pipe(
      map((technologiesJSON: any) => {
        return technologiesJSON.map((technologyJSON: any) => {
          return Technology.fromJSON(technologyJSON);
        });
      })
    );
  }

  public getControllerTypes(): Observable<ControllerType[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machine-types/dyeing-machine-controllers`).pipe(
      map((controllerTypesJSON: any) => {
        return controllerTypesJSON.map((controllerTypeJSON: any) => {
          return ControllerType.fromJSON(controllerTypeJSON);
        });
      })
    );
  }

  public isIdentifierAvailable(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machine-types/check-name`, {params}).pipe(
      map((response: {machineTypeNameAvailable: boolean}) => {
        return response.machineTypeNameAvailable;
      })
    );
  }

  public machineTypesForSubscription(): Observable<IdName[]> {
    return this.httpClient.get(`${this.backendConfiguration.getMachineEndpoint()}machine-types/subscription`).pipe(map((response: any) => response as IdName[]));
  }
}
