import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './grid-production-status.component.html',
  styleUrls: ['./grid-production-status.component.scss']
})
export class GridProductionStatusComponent implements ICellRendererAngularComp {
  public readyForProduction: boolean;

  public agInit(params: ICellRendererParams): void {
    this.readyForProduction = params.value;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
