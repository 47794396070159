import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {StringUtils} from '@application/helper/string-utils';
import {AssertionUtils, BaseComponent, ColDefBuilderFactoryService, GridOptionsBuilderFactoryService, SelectGridDialog, TranslateService} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, GridReadyEvent, RowNode, ValueGetterParams} from 'ag-grid-community';
import {includes} from 'lodash-es';

@Component({
  templateUrl: './select-pattern-field.component.html',
  styleUrls: ['./select-pattern-field.component.scss']
})
export class SelectPatternFieldComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('patternFieldsGrid') public patternFieldsGrid: AgGridAngular;
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  private listOfSelectedPatternFields: string[];
  private listOfPatternFields: string[];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: {listOfPatternFields: string[]; listOfSelectedPatternFields},
    private readonly dialogRef: MatDialogRef<SelectPatternFieldComponent>,
    private readonly translate: TranslateService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService
  ) {
    super();
    this.listOfPatternFields = data.listOfPatternFields;
    this.listOfSelectedPatternFields = data.listOfSelectedPatternFields;
  }

  public get gridOptionsForListOfPatternFields(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.setGridOptionsForListOfPatternFields();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.patternFieldsGrid.api];
  }

  public canSelectPatternFields(): boolean {
    return !AssertionUtils.isEmpty(this.getSelectedPatternFields());
  }

  public selectPatternFields(): void {
    this.dialogRef.close(this.getSelectedPatternFields());
  }

  private setGridOptionsForListOfPatternFields(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfPatternFields(), GridIdentifier.LIST_OF_PATTERN_FIELDS, this.listOfPatternFields)
        .withoutColumnMenu()
        .withoutGetRowId()
        .withOnGridReady(({api}: GridReadyEvent) => this.onGridReadyEvent(api))
        .withRowSelection(true, true, true)
        .withNoRowsOverlay()
        .withLoadingOverlay({scale: 0.7})
        .build()
    ];
  }

  private onGridReadyEvent(gridApi: GridApi): void {
    gridApi.forEachNode((rowNode: RowNode) => {
      if (includes(this.listOfSelectedPatternFields, rowNode.data)) {
        rowNode.setSelected(true);
      }
    });
  }

  private getColumnDefsForListOfPatternFields(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withField('name', true)
        .withHeaderName('GENERAL.NAME')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams): string => this.translate.instant(`TEXTILE_DATA.MACHINE_QUALITY.JUTE_CONDITIONS.PATTERN_FIELDS_OPTIONS.${params.data}`))
        .withCellClass('checkbox')
        .withoutFilter()
        .build()
    ];
  }

  private getSelectedPatternFields(): any {
    return this.patternFieldsGrid?.api?.getSelectedRows();
  }
}
